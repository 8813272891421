<a
  *ngIf="sectionMode == 'DEVICE'"
  download
  href="assets/sample/devices_Bulk_Upload.xlsx"
  title="DOWNLOAD SAMPLE EXCEL"
  style="float: right; font-size: 25px; color: #07c907; margin-left: 30px"
>
  <i class="fa fa-download"></i>
</a>
<a
  *ngIf="sectionMode == 'USER'"
  download
  href="assets/sample/users_Bulk_Upload.xlsx"
  title="DOWNLOAD SAMPLE EXCEL"
  style="float: right; font-size: 25px; color: #07c907; margin-left: 30px"
>
  <i class="fa fa-download"></i>
</a>
<i
  class="fa fa-upload"
  data-bs-toggle="modal"
  data-bs-target="#excelUploadPopup"
  aria-hidden="true"
  data-toggle="tooltip"
  data-placement="top"
  title="UPLOAD DEVICE"
  style="
    cursor: pointer;
    font-size: 25px;
    float: right;
    margin-top: 4px;
    color: #2c3e50 !important;
  "
></i>

<div
  class="modal"
  id="excelUploadPopup"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content" style="border: none">
      <div class="modal-header addtriphead">
        <div class="headtext">{{ "Bulk Upload Device" | translate }}</div>
        <button
        (click)="onPopupClose()"
          type="button"
          class="btnclose"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span class="material-icons"> close </span>
        </button>
      </div>
      <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
        <div>
          <div class="container">
            <div
              class="col-md-12 col-md-offset-2"
              style="margin-top: 10%; margin-bottom: 10%"
            >
              <!-- <form method="POST" action="#" enctype="multipart/form-data"> -->
              <!-- COMPONENT START -->
              <div class="form-group">
                <div class="input-group input-file" name="Fichier1">
                  <!-- <span class="input-group-btn">
                            <button class="btn btn-default btn-choose" type="button">Choose</button>
                        </span> -->
                  <input
                    type="file"
                    #fileInput
                    id="excelFile"
                    class="form-control"
                    (change)="onChange($event)"
                    placeholder="Choose a file..."
                  />
                  <!-- <span class="input-group-btn">
                              <button class="btn btn-warning btn-reset" type="button">Reset</button>
                        </span> -->
                </div>
              </div>
              <!-- COMPONENT END -->
              <div
                class="form-group"
                style="margin-left: 110px; margin-top: 15px"
              >
                <button
                  type="submit"
                  class="btn btn-primary"
                  style="float: unset"
                  (click)="onUpload()"
                >
                  Submit
                </button>

                <button
                (click)="onPopupClose()"
                  type="button"
                  class="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style="
                    float: unset;
                    background: red;
                    margin-left: 10px;
                    border: none;
                  "
                >
                  Cancel
                </button>
                <!-- <button type="reset" class="btn btn-danger" (click)="reset()">Reset</button> -->
              </div>
              <!-- </form> -->
            </div>

            <div *ngIf="showError">
              <p style="text-align: center; color: red">{{ errorMsg }}</p>
              <!-- <button type="submit" class="btn btn-primary"  style="margin-left: 154px; margin-bottom: 10px; width: 120px; margin-top: 10px; background: #d22020; border: none;" (click)="downloadFailedData()">Download</button> -->
            </div>
            <div *ngIf="uploadingData.total">
              <table>
                <tr>
                  <th>Completed</th>
                  <th>Failed</th>
                  <th>Total</th>
                </tr>
                <tr>
                  <td style="color:#07c907">{{uploadingData.completed}}</td>
                  <td style="color:red">{{uploadingData.failed}}</td>
                  <td><strong>{{uploadingData.total}}</strong></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
