<p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ User Master</span></p>

<div class="container-fluid ">
  <div class="row p-0">
    <div class="card p-0 headcard">

      <!-- HEADER WITH SEARCH BTNS -->
      <div class="card-header1 flex_row_center_spacebetween">
        <div class="cardHead_text">User Master</div>
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item">
            <div class="d-flex flex-row align-items-center" style="width: 93vh;justify-content: space-between;">
              <div class="d-flex flex-row align-items-center">
                <label class="searchby">Search By :</label>
                <mat-radio-group aria-label="Select an option">
                  <mat-radio-button class="searchby" value="1" [checked]="checked" style="margin-left: 5vh;">Phone</mat-radio-button>
                  <mat-radio-button class="searchby" value="2" style="margin: 0 4vh 0 2vh;">Name</mat-radio-button>
                </mat-radio-group> 
              </div>
              <div class="input-group searchvehicles">
                <input type="text" class="form-control reportsbox" placeholder="{{'Search'|translate}}"
                  aria-label="Recipient's username" aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh;">
                    <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
                  </button>
                </div>
              </div>
              <div class="reports_dropdown">
                <select class="form-select" style="height: 5.5vh !important;">
                  <option value="Today" class="select_options">Today</option>
                  <option value="Yesterday" class="select_options">Yesterday</option>
                  <option value="Week" class="select_options">Week</option>
                  <option value="Month" class="select_options">Month</option>
                  <option value="Last Month" class="select_options">Last Month</option>
                  <option value="Custom" class="select_options">Custom</option>
                </select>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- DATE, FILTER & DOWNLOAD HEADER -->
      <div class="card-header flex_row_center_spacebetween">
        <div class="flex_row_center_spacebetween" style="width: 122vh;">
          <select class="form-select selectimportant">
            <option value="10" class="select_options">10</option>
            <option value="20" class="select_options">20</option>
            <option value="30" class="select_options">30</option>
            <option value="40" class="select_options">40</option>
            <option value="50" class="select_options">50</option>
            <option value="60" class="select_options">60</option>
          </select>
          <input type="date" id="meeting" value="2022-03-26" />
          <span class="to">{{'To'|translate}}</span>
          <input type="date" id="meeting" value="2022-03-27" />
          <select class="form-select selectimportant common_scroller" style="width: 25vh !important;">
            <option value="" class="select_options" disabled>Phone</option>
            <option *ngFor="let user of UsermasterList;let i = index" value="{{user.phone}}" class="select_options">{{user.phone}}</option>
          </select>
          <select class="form-select selectimportant common_scroller" style="width: 25vh !important;">
            <option value="" class="select_options" disabled>Name</option>
            <option *ngFor="let user of UsermasterList;let i = index" value="{{user.first_name}}" class="select_options">{{user.first_name}}</option>
          </select>
          <div class="d-flex flex-row align-items-center" style="padding: 0.4vh 0 0 0;">
            <span class="addlabels">Deleted User</span>
            <mat-slide-toggle style="padding-left: 2vh;"></mat-slide-toggle>
          </div>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;">
          <span class="btn"><img src="/assets/icons/report_refresh.png" alt="refresh" style="width: 96%;"></span>
          <span class="btn"><img src="/assets/icons/report_download.png" alt="download" style="width: 96%;"></span>
        </div>
      </div>
      <!-- USER MASTER TABLE -->
      <div class="card-body common_scroller fixed_height p-0">
        <table class="table table-borderless m-0">

          <thead style="background-color:  #24282E;">
            <tr>
              <th class="table_head_text text-center">Name</th>
              <th class="table_head_text text-center">Dealer</th>
              <th class="table_head_text text-center">Is Dealer</th>
              <th class="table_head_text text-center">Created On</th>
              <th class="table_head_text text-center">Email</th>
              <th class="table_head_text text-center">Phone</th>
              <th class="table_head_text text-center">Token</th>
              <th class="table_head_text text-center">Action</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td class="table_data text-center">demo</td>
              <td class="table_data text-center">demo</td>
              <td class="table_data text-center">demo</td>
              <td class="table_data text-center">demo</td>
              <td class="table_data text-center">demo</td>
              <td class="table_data text-center">demo</td>
              <td class="table_data text-center">demo</td>
              <td class="table_data text-center" style="cursor: pointer;">
                <div class="dropdown">
                  <button class="btn dropdown-toggle p-0 border-none" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="/assets/icons/hollow_options.png" alt="car">
                  </button>
                  <ul class="dropdown-menu option_drop py-0" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item pt-2" style="justify-content: flex-start;">
                        <img src="/assets/icons/editgeo.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 1.6vh;">{{'Edit'|translate}}</span>
                      </a>
                    </li>
                    <li><a class="dropdown-item py-2" style="justify-content: flex-start;">
                        <img src="/assets/icons/deletegeo.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 2.5vh;">{{'Delete'|translate}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="UserMastersList.length">
            <tr>
              <td colspan="8">
                <div class="d-flex justify-content-center align-items-center" style="height: 48vh;">
                  <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE">
                </div>
              </td>
            </tr>
          </tbody>

        </table>
      </div>
      <!-- PAGINATION -->
      <!-- <div *ngIf="deviceModelList.length" class="flex_row_center_spacebetween"
        style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
        <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{deviceModelList.length/10 | number: '1.0-0'}} {{'of'|translate}}
          {{deviceModelList.length}} {{'entries'|translate}}</div>
        <pagination-controls (pageChange)="page = $event"></pagination-controls>
      </div> -->
      <div class="flex_row_center_spacebetween" style="padding: 1vh 2vh;">
        <div>{{'Showing'|translate}} 1 to 9 of 9 entries</div>
        <div>1 2 3 4 6 7 8 9 10</div>
      </div>

    </div>
  </div>
</div>
