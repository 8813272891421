import { Component, OnInit } from '@angular/core';
import { UsersettingService } from 'src/app/services/usersetting.service';

@Component({
  selector: 'app-user-master',
  templateUrl: './user-master.component.html',
  styleUrls: ['./user-master.component.css']
})
export class UserMasterComponent implements OnInit {

  UserMastersList = [];
  UsermasterList = [];
  checked = true;
  todaysDate = "";
  yesterdayDate = "";
  page: number = 1;
  userId = localStorage.getItem("userId");

  constructor(private user: UsersettingService) {
    // TODAY & YESTERDAY DATE
    this.todaysDate = new Date().toISOString();
    const yesterday = new Date(this.todaysDate)
    yesterday.setDate(yesterday.getDate() - 1)
    this.yesterdayDate = yesterday.toISOString()
    console.log(this.todaysDate, this.yesterdayDate);
  }

  ngOnInit(): void {
    this.getInitialUserMasterDatatable();
    this.getuserMasterDataTable();
  }

  getInitialUserMasterDatatable() {
    let body = {
      "columns": [
        { "data": "_id" },
        { "data": "first_name" },
        { "data": "phone" },
        { "data": "last_name" }
      ],
      "draw": 1,
      "find": {
        "supAdmin": this.userId,
        "DeletedUser": { "$exists": false }
      },
      "length": -1,
      "op": {},
      "order": [{
        "column": 0,
        "dir": "desc"
      }],
      "search": {
        "regex": false,
        "value": ""
      },
      "select": [],
      "start": 0
    }
    this.user.getUserMaster(body).subscribe(resp => {
      this.UsermasterList = resp.data;
    })
  }

  getuserMasterDataTable() {
    let body = {
      "columns": [
        { "data": "_id", "orderable": "true" },
        { "data": "first_name" },
        { "data": "email" },
        { "data": "phone" },
        { "data": "isDealer" },
        { "data": "pushNotification" },
        { "data": "created_on" },
        { "data": "std_code" },
        { "data": "Dealer.first_name" },
        { "data": "Dealer.last_name" },
        { "data": "last_name" },
        { "data": "isSuperAdmin" }
      ],
      "draw": 1,
      "find": {
        "supAdmin": this.userId,
        "DeletedUser": { "$exists": false },
        "created_on": {
          "$gte": {
            "value": this.yesterdayDate,
            "_eval": "date"
          },
          "$lte":{
            "value": this.todaysDate,
            "_eval": "date"
          }
        }
      },
      "length": 10,
      "op": {},
      "order": [{
        "column": 0,
        "dir": "desc"
      }],
      "search": {
        "regex": false,
        "value": ""
      },
      "select": [],
      "start": 0
    }
    this.user.getUserMaster(body).subscribe(resp => {
      this.UserMastersList = resp.data;
    })
  }

}
