import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from 'src/app/services/common.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  addMode = false;
  userData:any = {};
  constructor(private commonService: CommonService,
    private fb: FormBuilder,
    private router: Router,
    private cookieService: CookieService) { 
    this.changePasswordForm = this.fb.group({
      newpassword: ['', [Validators.required]],
      confirmpassword: ['', [Validators.required]]
    })
    this.commonService.pageLoadInModal.subscribe((result: string) => {
      if (result == "changepassword") {        
        this.addMode = true;    
      } 
    });
    let userData = localStorage.getItem('userData');
    this.userData = userData ? JSON.parse(userData) : {};
  }

  ngOnInit(): void {
  }
  onChangePasswordSubmit() {
    this.changePasswordForm.controls.newpassword.markAsDirty();
    this.changePasswordForm.controls.newpassword.markAsTouched();
    this.changePasswordForm.controls.confirmpassword.markAsDirty();
    this.changePasswordForm.controls.confirmpassword.markAsTouched();
    if (this.changePasswordForm.valid) {
      if (this.changePasswordForm.value.newpassword == this.changePasswordForm.value.confirmpassword) {
        if (this.userData.id) { 
          this.userData['password'] = this.changePasswordForm.value.newpassword;
          this.userData['distanceInKm'] =0;        
          this.commonService
            .putRequest('users/' + this.userData.id, this.userData)
            .then((resp: any) => {
              if (resp?.status) {
                Swal.fire({
                  icon: "success",
                  title: "Password change successfully. Please login with new password.",
                  showConfirmButton: false,
                  timer: 2500,
                });
                this.onCloseModal();
                localStorage.clear();
                this.cookieService.delete("JSESSIONID");
                this.commonService.closeSocket();
                this.router.navigate(['/login']);
              }
            },(err)=>{
              console.log(err);
               Swal.fire({
                 icon: "error",
                 title: "Something Went Wrong",
                 showConfirmButton: false,
                 timer: 1500,
               });
             });
        } 
      } else {
        alert('Password and confirmpassword should match.');
      }
    }
  }
  onCloseModal(){    
    this.addMode = false;
    this.changePasswordForm.reset();
    this.commonService.commonModel.next(false);
  }
}
