<div class="card-header flex_row_center_spacebetween" style="padding: 0.6rem 1rem;">
    <div class="cardHead_text">{{'Notifications' | translate}}</div>
  </div>
  <div class="card-body settingbody">
    <div class="row">
        <form [formGroup]="modelForm">
            <div class="input-group searchvehicles">

                <input formControlName="searchValue" name="searchValue" type="text"
                    class="form-control reportsbox" placeholder="{{'Search'|translate}}" autocomplete="off" />

                <div class="input-group-append">
                    <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh">
                        <img (click)="searchData()" src="/assets/icons/searchicon.png" alt="search"
                            style="width: 2.3vh" />
                    </button>
                </div>
            </div>
        </form>
        <div class="row min-height-scroll">
            <div class="col-12 form-group"
                *ngFor="let notification of notificationsMasterList | searchPipe : modelForm.value.searchValue; index as i">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [id]="'notification-' + i"
                        [checked]="notification.isSelectedDevice" (change)="
        changeEvent($event.target, notification.id, notification.mapUserId)
      " />
                    <label class="form-check-label" for="notification-{{ i }}">
                        {{ notification.type }} ({{notification.notificators}})
                    </label>
                </div>
            </div>
        </div>
    </div>
  </div>