import { Component, Injectable, VERSION } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubjectSubscribeService {
  DeviceId = new BehaviorSubject<string>('');
  searchDevice = new Subject<string>();
  vehiclesCountComponent = new Subject<boolean>();

  constructor() {}
}
