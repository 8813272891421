import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
@Component({
  selector: 'app-task-add',
  templateUrl: './task-add.component.html',
  styleUrls: ['./task-add.component.css'],
})
export class TaskAddComponent implements OnInit {
  selectedFile: File;
  orgId:any;
  userData:any;
  loading: boolean = false;
  @ViewChild('selectFile') selectFile: ElementRef;
  constructor(private commonService: CommonService,private router: Router) { }

  ngOnInit(): void {

    let userDataObj = localStorage.getItem('userData');
    if (userDataObj) {
      try {
        this.userData = JSON.parse(userDataObj);
      if(this.userData.attributes.role === "admin"){
        this.orgId= this.userData.id;
      }
      else{
        this.orgId= this.userData.orgId;
      }
      } catch (error) {}
    }
  }

  uploadDocuments() {
    this.selectFile.nativeElement.click();
  }
  onFileUpload(event: any) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      this.uploadDocs();
    } else {
      Swal.fire({
        icon: "error",
        title: "Unsupported File Type. Only xls/xlsx/csv is allowed!",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  uploadDocs() {
    this.loading=true;
    if (this.selectedFile) {
      const formData: FormData = new FormData();
      formData.append('file', this.selectedFile);
      
      this.commonService
        .postRequestForFile(`traccartasks/uploadTaks?orgId=${this.orgId}`, formData)
        .then((resp: any) => {
          this.loading=false;
          if (resp?.status) {
            Swal.fire({
              icon: "success",
              title: `Task data added successfully`,
              showConfirmButton: false,
              timer: 1500,
            });
       
          }
          this.commonService.commonModel.next(false);
         
        }, (error:any) => {
          this.loading=false;
          console.log('error=>',error)
            Swal.fire({
              // position: 'top-end',
              icon: 'error',
              text: error.data.statusText,
              showConfirmButton: false,
              // toast: true,
              timer: 1500,
            });
          
          // Swal.fire({
          //   icon: "error",
          //   title: "Something Went Wrong!",
          //   showConfirmButton: false,
          //   timer: 1500,
          // });
         
          this.commonService.commonModel.next(false);
        });
    }
   
  }
}
