import { Component, OnInit } from '@angular/core';
import { UsersettingService } from 'src/app/services/usersetting.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
})
export class SidenavComponent implements OnInit {
  dealer = false;
  customer = false;

  userData: any = {};

  constructor(private user: UsersettingService) {}

  ngOnInit(): void {
    // this.onUserSetting();
    let userData = localStorage.getItem('userData');
    if (userData) {
      try {
        this.userData = JSON.parse(userData);
        console.log('userData', this.userData);
      } catch (error) {}
    }
  }

  onUserSetting() {
    this.user.userSetting().subscribe((resp) => {
      console.log('ok');
      let SuperAdmin = resp.isSuperAdmin;
      let Dealer = resp.isDealer;
      if (SuperAdmin == true) {
        this.dealer = true;
        this.customer = true;
      }
      if (Dealer) {
        this.dealer = false;
        this.customer = true;
      }
    });
  }

  get check() {
    return window.location.href.includes('admin');
  }
}
