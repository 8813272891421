

<div class="container-fluid">
  <div class="row">
    <div class="col-sm-4 col-12 device-list-setting" id="closeFullMap">

      <div class="card expand-object-list" [class.device-list]="isDeviceListVisible"
        title="{{ 'Expand Object list' | translate }}s" (click)="expandDeviceList()">
        >
      </div>
      <div class="card main_card" *ngIf="main_col" [class.device-list]="!isDeviceListVisible">
        <div style="position: relative">
          <div title="Collapse" class="collapse-task-panel" style="cursor: pointer" (click)="collapseTaskList()">
            < </div>
          </div>
          <div class="row bg-light m-0">
            <!-- <div class="col-3 col-sm-3 d-flex align-items-center justify-content-center font-size-12 device-tabs"
                 title="{{ 'Objects' | translate }}" [class.select]="isObjectsTab" (click)="tabChange('object')">
              {{ "Objects" | translate }}
            </div>  -->
        
            <!-- <div class="col-3 col-sm-3 d-flex align-items-center justify-content-center font-size-12 device-tabs"
                 [class.select]="isHistoryTab" title="{{ 'History' | translate }}" (click)="tabChange('history')">
              {{ "History" | translate }}
            </div> -->
          
            <div class="col-3 col-sm-3 d-flex align-items-center justify-content-center font-size-12 device-tabs"
                 [class.select]="isTaskTab" title="{{ 'Tasks' | translate }}" (click)="tabChange('Tasks')">
              {{ "Tasks" | translate }}
            </div>
          </div>
          
          <!-- <ng-contaier *ngIf="isObjectsTab">
            <div class="row bg-light padding-left-0_8 m-0">
              <div class="col-10 p-1">
                <app-search-device class="w-100"></app-search-device>
              </div>
              <div class="col-2"></div>
            </div>

            <div class="row bg-light m-0">
              <div class="col-1 border-end d-flex align-items-center justify-content-center" title="Show/Hide all">
                <i class="bi bi-eye ms-1"></i>
              </div>
              <div class="col-1 border-end" title="Follow/Unfollow all">
                <img src="/assets/icons/follow.svg" [ngStyle]="{ width: '14px', height: '15px' }" alt="img" />
              </div>
              <div class="col-2 border-end"></div>
              <div class="col-7 border-end d-flex align-items-center justify-content-center font-size-12">
                {{ "Object" | translate }}
              </div>
              <div class="col-1"></div>
            </div>
            <div class="row m-0">
              <div class="col-1 d-flex align-items-center justify-content-center">
                <div class="form-check ms-2">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="selectAllObjects"
                    (change)="selectAllObjectChange()" />
                </div>
              </div>
              <div class="col-1" title="Follow/Unfollow all">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" />
                </div>
              </div>
              <div class="col-2"></div>
              <div class="col-4 d-flex align-items-center justify-content-center"></div>
              <div class="col-3"></div>
              <div class="col-1"></div>
            </div>
            <div class="scroller" (scroll)="
              allCounts[statusFilter] > devicesList.length && onScroll($event)
            ">
            <div
  (click)="onFullVehicleDetail(device, true)"
  [class.bg-light]="selectedDevice?.deviceId == device.deviceId"
  class="row m-0 d-flex align-items-center justify-content-center car-content"
  *ngFor="let device of devicesList; let i = index"
>
  <div class="col-1 d-flex align-items-center justify-content-center">
    <div class="form-check ms-2">
      <input
        class="form-check-input"
        type="checkbox"
        (change)="onObjectSelectionChange(device, $event)"
        [(ngModel)]="device.isSelected"
      />
    </div>
  </div>
  <div class="col-1" title="Follow/Unfollow all">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" />
    </div>
  </div>
  <div class="col-2">
    <div class="fix_align d-flex align-items-center justify-content-center">
      <img
        [src]="
          device?.deviceDetails?.vehicleImg ||
          '/assets/icons/devices/car/card.png'
        "
        [ngStyle]="{ width: '33px', height: '39px' }"
        alt="img"
        class="vehicleimg"
      />
      <ng-container *ngIf="device.statuss === 'STOPPED'">
        <img src="/assets/icons/red_car.png" alt="img" class="vehicleimg" />
      </ng-container>
      <ng-container *ngIf="device.statuss === 'RUNNING'">
        <img src="/assets/icons/running_car.png" alt="img" class="vehicleimg" />
      </ng-container>
      <ng-container *ngIf="device.statuss === 'IDLE'">
        <img src="/assets/icons/idle_car.png" alt="img" class="vehicleimg" />
      </ng-container>
      <ng-container *ngIf="device.statuss === 'NOT_REPORTING'">
        <img src="/assets/icons/no_reporting_car.png" alt="img" class="vehicleimg" />
      </ng-container>
    </div>
  </div>
  <div class="col-4 d-flex justify-content-center flex-column">
    <span class="font-size-12">{{ device.name }}</span>
    <span class="d-flex align-items-center" *ngIf="['EXPIRED', 'NO_DATA'].indexOf(device.status) === -1">
      <img src="/assets/icons/vehicletimer.png" alt="timer" class="img_timer" />
      <span class="update pl-1">{{ device.serverTime | dateFormat }}</span>
    </span>
  </div>
  <div class="col-3 d-flex align-items-center justify-content-center">
    <div class="vehicle_features mt-1" *ngIf="['EXPIRED', 'NO_DATA'].indexOf(device.status) === -1">
      <div class="bottomIcons">
        <span class="mat_icon d-flex align-items-center justify-content-center flex-column">
          <span class="speedalign">{{ device?.speed | number : "1.0-2" }}</span>
          <span class="dist_fuel_speed" style="padding-left: 5px; white-space: nowrap">{{ "kmph" | translate }}</span>
        </span>
      </div>
      <div class="bottomIcons">
        <img
          src="/assets/icons/battery_play.png"
          alt="img"
          title="{{ 'Battery' | translate }}"
          *ngIf="device?.attributes?.ignition; else showIgnRed"
        />
        <span class="bottom_tags">{{ "BAT" | translate }}</span>
      </div>
      <div class="bottomIcons">
        <img
          src="/assets/icons/bottom6.png"
          alt="img"
          title="{{ 'GPS' | translate }}"
          *ngIf="device?.valid; else showGPSGray"
        />
        <span class="bottom_tags">{{ "GPS" | translate }}</span>
      </div>
    </div>
  </div>
  <div class="col-1">
    <div class="dropdown">
      <div class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa fa-ellipsis-v"></i>
      </div>
      <ul class="dropdown-menu option_drop pb-1 pt-1" aria-labelledby="dropdownMenuButton1">
        <li>
          <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#staticBackdropAdd" (click)="editDevice(device)">
            <span class="optionstext">
              <i class="fa fa-edit"></i> {{ "Edit" | translate }}
            </span>
          </a>
        </li>
        <li *ngIf="userData.administrator">
          <a class="dropdown-item" (click)="deleteDevice(device.deviceId)">
            <span class="optionstext">
              <i class="fa fa-trash"></i> {{ "Delete" | translate }}
            </span>
          </a>
        </li>
        <li *ngIf="false">
          <a class="dropdown-item" (click)="sendGprsCommand(device)">
            <span class="optionstext">
              <i class="fa fa-trash"></i> {{ "Send GPRS Command" | translate }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
            </div>
          </ng-contaier>  -->
          
          

          <!-- <ng-contaier *ngIf="isHistoryTab">
            <form [formGroup]="historyForm">
              <div class="row d-flex align-items-center mb-1 mt-1">
                <div class="col-3 font-size-12">
                  {{ "Driver" | translate }}
                </div>
                <div class="col-9" style="display: grid; grid-template-columns: 1fr">
              
                        <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Select Driver</mat-label>
                    <input type="text"
                           placeholder="Select one"
                           aria-label="Number"
                           matInput
                           [formControl]="myControl"
                           [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                    (optionSelected)="onOptionSelected($event);">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.display" (onSelectionChange)="driverSelected(option)">
                        {{option.display}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
              <div class="row d-flex align-items-center mb-1">
                <div class="col-3 font-size-12">
                  {{ "Filter" | translate }}
                </div>
                <div class="col-9">
                  <select class="form-select history form-select-sm" aria-label=".form-select-sm example"
                    formControlName="filter" (change)="setHistoryDate(historyForm.get('filter')?.value)">
                    <option selected value=""></option>
                    <option value="0">{{ "Today" | translate }}</option>
                    <option value="1">{{ "Last 24 Hour" | translate }}</option>
                    <option value="2">{{ "Yesterday" | translate }}</option>
                    <option value="3">{{ "This Week" | translate }}</option>
                    <option value="4">{{ "Last Week" | translate }}</option>
                    <option value="5">{{ "This Month" | translate }}</option>
                    <option value="6">{{ "Last Month" | translate }}</option>
                  </select>
                </div>
              </div>
              <div class="row d-flex align-items-center mb-1">
                <div class="col-3 font-size-12">
                  {{ "Time from" | translate }}
                </div>
                <div class="col-9">
                  <input type="datetime" formControlName="timeFrom" />
                </div>
              </div>
              <div class="row d-flex align-items-center mb-1">
                <div class="col-3 font-size-12">
                  {{ "Time to" | translate }}
                </div>
                <div class="col-9">
                  <input type="datetime" formControlName="timeTo" name="todate" id="meeting" />
                </div>
              </div>
              <div class="row d-flex align-items-center mb-1">
                <div class="col-3 font-size-12">
                  {{ "Stops" | translate }}
                </div>
                <div class="col-9">
                  <select class="form-select history form-select-sm" aria-label=".form-select-sm example">
                    <option selected value=""></option>
                    <option value="0">> 1 min</option>
                    <option value="1">>2 min</option>
                    <option value="2">> 5 min</option>
                    <option value="3">> 10 min</option>
                    <option value="4">> 20 min</option>
                    <option value="5">> 30 min</option>
                    <option value="6">> 1 h</option>
                    <option value="6">> 2 h</option>
                    <option value="6">> 3 h</option>
                  </select>
                </div>
              </div>
              <div class="row d-flex align-items-center mt-3 mb-3">
                <div class="col-3">
                  <button type="button" class="btn btn-light w-100"
                    (click)="showTracking(historyForm.get('filter')?.value)">
                    {{ "Show" | translate }}
                  </button>
                </div>
                <div class="col-3">
                  <button type="button" class="btn btn-light w-100" (click)="hideTracking($event)">
                    {{ "Hide" | translate }}
                  </button>
                </div>
                <div class="col-4">
                  <button type="button" class="btn btn-light w-100">
                    {{ "Import/Export" | translate }}
                  </button>
                </div>
              </div>
            </form>
          </ng-contaier> -->

          <ng-container *ngIf="isTaskTab">
            <div class="row mt-2" *ngIf="!isTaskDetails">
              <div class="row mt-2 mb-1">
                <div class="col-4 col-sm-4 pe-md-0">
                  <select class="form-select taskspanel" [(ngModel)]="tasksFilter" (change)="tasksFilterChange()">
                    <option value="sortby">Sort By</option>
                    <option value="task">Task</option>
                    <option value="shift">Shift</option>
                    <option value="client">Company</option>
                    <option value="cabno">Cab</option>
                  </select>
                </div>
              
                <div class="col-7 col-sm-7" *ngIf="tasksFilter === 'task'">
                  <div class="input-group">
                    <input type="text" class="form-control border-end-0 ht-40-px" placeholder="Search task" [(ngModel)]="searchTasks" (change)="tasksFilterByTaskId()" />
                    <span class="input-group-text bg-white border-start-0 ht-40-px"><i class="bi bi-search fw-bold" style="color: #f84a67"></i></span>
                  </div>
                </div>
            
                <div class="col-7 col-sm-7" *ngIf="tasksFilter === 'client' && !isSupervisor">
                  <div class="input-group">
                    <select class="form-select taskspanel" [(ngModel)]="searchClients" (change)="clientFilterChange()">
                      <option value="-1">Select Company</option>
                      <option value="{{ c.email }}" *ngFor="let c of customerList">{{ c.name }}</option>
                    </select>
                  </div>
                </div>
              
                <div class="col-7 col-sm-7" *ngIf="tasksFilter === 'client' && isSupervisor">
                  <div class="input-group">
                    <select class="form-select taskspanel" [(ngModel)]="searchClients" (change)="clientFilterChange()">
                      <option value="-1">Select Company</option>
                      <option value="{{ c.email }}" *ngFor="let c of clientList">{{ c.name }}</option>
                    </select>
                  </div>
                </div>
              
                <div class="col-7 col-sm-7" *ngIf="tasksFilter === 'cabno'">
                  <div class="input-group">
                    <input type="text" class="form-control border-end-0 ht-40-px" placeholder="Search Cab" [(ngModel)]="searchCab" (change)="tasksFilterByTaskByCab()" />
                    <span class="input-group-text bg-white border-start-0 ht-40-px"><i class="bi bi-search fw-bold" style="color: #f84a67"></i></span>
                  </div>
                </div>
                <div class="col-7 col-sm-7" *ngIf="tasksFilter === 'shift'">
                  <div class="input-group">
                    <mat-form-field class="example-full-width" appearance="fill"> <!-- Adjust margin as needed -->
                      <input type="text"   matInput
                      placeholder="Select Shift" aria-label="Number"
                      [formControl]="shiftControl" 
                      [matAutocomplete]="auto" 
                      style="width: 100%;">
                     <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" style="width: 100%;">
                    <mat-option *ngFor="let shift of filteredShifts | async" [value]="shift" style="width: 100%;"
                    (onSelectionChange)="tasksFilterByTaskByShift(shift)"
                    >
                    {{ shift }}
                    </mat-option>
                    </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>
              
                <div class="col-7 col-sm-7" *ngIf="tasksFilter === 'sortby'">
                  <div class="input-group">
                    <select class="form-select taskspanel" [(ngModel)]="sortby" (change)="sortFilterChange()">
                      <option value="-1">Select</option>
                      <option value="fno">Flap No</option>
                      <option value="date">Time</option>
                    </select>
                  </div>
                </div>
              
                <div class="col-1 col-sm-1 mt-1 text-center">
                  <span class="cursorPointer" (click)="openFilterModal()">
                    <i style="font-size: 33px" class="fa-solid fa-filter" title="Filter Tasks"></i>
                  </span>
                </div>
              </div>
              
              
              <div class="row tasks-tabs mb-2 ml-1">
                             <div class="col-md-4 col-4 ">
                               <span  role="button" [class.selected]="isTaskUnassigned"
                                 (click)="tasksTabsChange('unassigned')"><span class="badge bg-secondary p-1 me-1 ms-3">
                                  <span *ngIf="!isSupervisor">{{ unassignedCount }}</span>
                                  <span *ngIf="isSupervisor">{{unassignedTaskCount}}</span>
                                 </span>
                                 
                                 Unassigned</span>
                             </div>
                             <div class="col-md-4 col-4">
                               <span  role="button" [class.selected]="isTaskAssigned"
                                 (click)="tasksTabsChange('assigned')"><span class="badge bg-secondary p-1 me-1">
                                   
                                   <span *ngIf="!isSupervisor">{{ totalRecordCount }}</span>
                                   <span *ngIf="isSupervisor">{{assignedTaskCount}}</span>
                                 </span>
                                 Assigned</span>
                             </div>
                             <div class="col-md-4 col-4">
                               <span role="button" [class.selected]="isTaskCompleted"
                                 (click)="tasksTabsChange('completed')"><span class="badge bg-secondary p-1 me-1">
                                    <span *ngIf="!isSupervisor">{{ completedCount }}</span>
                                    <span *ngIf="isSupervisor">{{completedTaskCount}}</span>
                                 </span>
                                 Completed</span>  
                             </div>
              </div>
              <ng-container *ngIf="isTaskUnassigned || isTaskAssigned || isTaskCompleted">
                <div class="scroller" (scroll)="onScrollTasks($event)" style="width: 99%"
                *ngIf="isTaskUnassigned"
                >  
                  <ng-container *ngIf="tasksList.length > 0; else noTasks">
                 
                    <ng-container *ngFor="let t of tasksList">
                      <div class="row pb-3" >     
                        <div class="d-flex align-items-center justify-content-between card-header" style="
                          padding-bottom: 12px;
                          background-color: #ffd892;
                          margin-left: 4px;  
                          padding-top: 15px;
                        ">
                          <span class="badge rounded-pill" [ngClass]="getBgColorBasedOnTaskStatus(t.status)">{{ t.status
                            | uppercase }}</span>
                          <div class="d-flex">
                            <span class="gray-button header-btn" [ngClass]="getBgColorBasedOnTaskStatus(t.status)">
                              <span class="font-size-18">{{
                                t.attributes?.actual_emp ?? 0
                                }}</span>
                              <span>Actual Emp</span>
                            </span>
                            <span class="gray-button header-btn" [ngClass]="getBgColorBasedOnTaskStatus(t.status)">
                              <span class="font-size-18">{{
                                t.attributes?.planned_emp ?? 0
                                }}</span>
                              <span>Planned Emp</span>
                            </span>
                            <span class="gray-button header-btn" [ngClass]="getBgColorBasedOnTaskStatus(t.status)">
                              <span class="font-size-18">
                                {{
                                t.attributes?.escort === "0" ? "No" : "Yes"
                                }}</span>
                              <span>Escort</span>
                            </span>
                            <span (click)="openRoutePathModal(t)" class="default" style="display: block"
                              title="{{ 'Live Tracking' | translate }}" style="cursor: pointer"><i
                                class="fa-solid fa-route"></i></span>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="row mt-1">
                            <div class="col-12 col-md-12">
                              <div>
                                <span class="fw-bold"><i class="fa-solid fa-id-card me-1" style="color:crimson"></i></span>
                                <span class="span-inputs me-1">{{ t.id }}</span>
                     
                                <span class="fw-bold"><i class="fa-solid fa-route span-icon me-1" [ngClass]="getColorBasedOnTaskType(t.type)"></i></span>
                                <span class="span-inputs">{{ t.type | titlecase }}</span>&nbsp;
                                <span *ngIf="t.attributes?.Shift_Timing != ''">
                                  <img src="/assets/icons/vehicletimer.png" alt="Time" />
                                </span>
                                <span class="me-1 span-inputs">{{
                                  t.attributes?.Shift_Timing
                                  }}</span>
                                <span class="me-1" *ngIf="t.status != 'unassigned' || t.attributes?.remarks != '' || t?.type === 'cabin'  || t?.type === 'cabout'">
                                  <i *ngIf="t.attributes?.remarks != ''" class="fa-solid fa-taxi span-icon me-1" style="color:rgb(128, 0, 255)"></i>
                                  <span class="span-inputs" >{{t.attributes.remarks | titlecase}}</span>
                                </span>  
                                  <span    
                                  *ngIf="t.attributes.startDelayStatus === 'delayed' && (t.status === 'assigned' || t.status === 'acknowledged' || t.status === 'unassigned' )"
                                  class="border  text-center" 
                                  style="
                                  font-weight: 600;
                                  border-radius: 3px;  
                                  line-height: 0.7;
                                  background: #e355552e !important;
                                  padding: 0 2px 0 2px;
                                "> 
                            
                                <span style="color:#ff0000;font-size:13px;">DELAYED</span>
                                </span>

                                <span 
                                *ngIf="t.attributes.endDelayStatus === 'delayed' && (t.status== 'completed' || t.status === 'started')"
                                class="border  text-center" 
                                style="
                                font-weight: 600;
                                border-radius: 3px;
                                line-height: 0.7;
                                background: #e355552e !important;
                                padding: 0 3px 0 3px;
                              "> 
                           
                              <span style="color:#ff0000;font-size:13px;">DELAYED</span>
                              </span>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-9 ">
                       
                              <div>
                                <span class="fw-bold">Company: </span>
                                <span class="ms-1">{{
                                  t?.attributes?.clientName | titlecase
                                  }}</span>
                              </div>
                 
                              <div *ngIf=" t?.fno">
                                <span class="fw-bold">Flap No: </span>
                                <span class="ms-1">{{
                                  t?.fno
                                  }}</span>
                              </div>
                              <div>
                                <span class="fw-bold">Location: </span>
                                <span *ngIf="t.type == 'pickup' || t.type == 'Pickup' " class="ms-1">
                                  {{ getSlicedAddress(t?.pickuplocation) }}
                                </span>
                                <span *ngIf="t.type == 'drop' || t.type == 'Drop'" class="ms-1">
                                  {{ getSlicedAddress(t?.droplocation) }}
                                </span>
                              
                                
                              </div>
                            </div>
                            <div class="col-2 p-0 d-flex align-items-center justify-content-center flex-column" *ngIf="
                              isTaskUnassigned && !isCompliance && !isAccountant
                            ">
                              <span class="mb-1" (click)="assignDriverEvent(t)">
                                <i class="bi bi-plus-circle-fill text-primary font-size-30"
                                  (click)="assignDriverEvent(t)"></i>
                              </span>
                              <span class="text-center font-size-12 text-nowrap" (click)="assignDriverEvent(t)">Assign Driver</span>
                            </div>
                            <div class="col-2 p-0 d-flex align-items-center justify-content-center flex-column"
                              *ngIf="isTaskAssigned || isTaskCompleted">
                          
                                <i class="bi bi-person-circle font-size-40 cursorPointer"(click)="goToDetails(t)"></i>
                                <!-- <span class="fw-bold font-size-12">{{t.attributes?.driverName}}</span> -->
                                <span class="fw-bold font-size-12 cursorPointer" (click)="goToDetails(t)">{{
                                  t.attributes?.cabno
                                  }}</span>
   
                            </div>
                            <div class="col-1 d-flex align-items-center justify-content-center">
                              <span class=""><i class="bi bi-caret-right-fill text-secondary" style="cursor: pointer"
                                  (click)="goToDetails(t)"></i></span>
                            </div>
                          </div>
                        </div>
          
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-template #noTasks>
                    <div class="d-flex justify-content-center align-items-center" style="height: 20vh">
                      <div>No task available for the day</div>
                    </div>
                  </ng-template>
                </div>
                <!-- <div class="scroller"style="width: 99%"
                *ngIf="isTaskAssigned"
              (scroll)="
              totalRecordCount > assignedTaskslist.length && onScrollAssignedTasks($event)
            "> -->
            <div class="scroller" (scroll)="onScrollAssignedTasks($event)" style="width: 99%"
                *ngIf="isTaskAssigned"
                >
                
                  <ng-container *ngIf="assignedTaskslist.length > 0; else noTasks">
                    <ng-container *ngFor="let t of assignedTaskslist">
                      <div class="row pb-3" >     
                        <!-- <div class="card-header"style="background-color:aquamarine;">Vivek</div> -->
                        <div class="d-flex align-items-center justify-content-between card-header" style="
                          padding-bottom: 12px;
                          background-color: #ffd892;
                          margin-left: 4px;  
                          padding-top: 15px;
                        ">
                          <span class="badge rounded-pill" [ngClass]="getBgColorBasedOnTaskStatus(t.status)">{{ t.status
                            | uppercase }}</span>
                          <div class="d-flex">
                            <span class="gray-button header-btn" [ngClass]="getBgColorBasedOnTaskStatus(t.status)">
                              <span class="font-size-18">{{
                                t.attributes?.actual_emp ?? 0
                                }}</span>
                              <span>Actual Emp</span>
                            </span>
                            <span class="gray-button header-btn" [ngClass]="getBgColorBasedOnTaskStatus(t.status)">
                              <span class="font-size-18">{{
                                t.attributes?.planned_emp ?? 0
                                }}</span>
                              <span>Planned Emp</span>
                            </span>
                            <span class="gray-button header-btn" [ngClass]="getBgColorBasedOnTaskStatus(t.status)">
                              <span class="font-size-18">
                                {{
                                t.attributes?.escort === "0" ? "No" : "Yes"
                                }}</span>
                              <span>Escort</span>
                            </span>
                            <span (click)="openRoutePathModal(t)" class="default" style="display: block"
                              title="{{ 'Live Tracking' | translate }}" style="cursor: pointer"><i
                                class="fa-solid fa-route"></i></span>
                          </div>
                          <!-- <span class="gray-button align-items-center d-flex justify-content-center" ></span> -->
                        </div>
                        <div class="col-12">
                          <div class="row mt-1">
                            <div class="col-12 col-md-12">
                              <div>
                                <span class="fw-bold"><i class="fa-solid fa-id-card me-1" style="color:crimson"></i></span>
                                <span class="span-inputs me-1">{{ t.id }}</span>
                                <!-- <span *ngIf="t.type === 'pickup'" class="fw-bold"  style="color:darkorange"><i
                                    class="fa-solid fa-route span-icon"></i></span> -->
                                <span class="fw-bold"><i class="fa-solid fa-route span-icon me-1" [ngClass]="getColorBasedOnTaskType(t.type)"></i></span>
                                <span class="span-inputs">{{ t.type | titlecase }}</span>&nbsp;
                                <span *ngIf="t.attributes?.Shift_Timing !=''">
                                  <img src="/assets/icons/vehicletimer.png" alt="Time" />
                                </span>
                                <span class="me-1 span-inputs">{{
                                  t.attributes?.Shift_Timing
                                  }}</span>
                                <span class="me-1" *ngIf="t.status != 'unassigned' || t.attributes?.remarks != '' || t?.type === 'cabin'  || t?.type === 'cabout'">
                                  <i *ngIf="t.attributes?.remarks != ''" class="fa-solid fa-taxi span-icon me-1" style="color:rgb(128, 0, 255)"></i>
                                  <span class="span-inputs" >{{t.attributes.remarks | titlecase}}</span>
                                </span>  
                                  <span    
                                  *ngIf="t.attributes.startDelayStatus === 'delayed' && (t.status === 'assigned' || t.status === 'acknowledged' || t.status === 'unassigned' )"
                                  class="border  text-center" 
                                  style="
                                  font-weight: 600;
                                  border-radius: 3px;  
                                  line-height: 0.7;
                                  background: #e355552e !important;
                                  padding: 0 2px 0 2px;
                                "> 
                            
                                <span style="color:#ff0000;font-size:13px;">DELAYED</span>
                                </span>

                                <span 
                                *ngIf="t.attributes.endDelayStatus === 'delayed' && (t.status== 'completed' || t.status === 'started')"
                                class="border  text-center" 
                                style="
                                font-weight: 600;
                                border-radius: 3px;
                                line-height: 0.7;
                                background: #e355552e !important;
                                padding: 0 3px 0 3px;
                              "> 
                           
                              <span style="color:#ff0000;font-size:13px;">DELAYED</span>
                              </span>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-9 ">
                   
                              <div>
                                <span class="fw-bold">Company: </span>
                                <span class="ms-1">{{
                                  t?.attributes?.clientName | titlecase
                                  }}</span>
                              </div>
                              <div *ngIf=" t?.fno">
                                <span class="fw-bold">Flap No: </span>
                                <span class="ms-1">{{
                                  t?.fno 
                                  }}</span>
                              </div>
                              <div>
                                <span class="fw-bold">Location: </span>
                                <span *ngIf="t.type == 'pickup'" class="ms-1">
                                  {{ getSlicedAddress(t.pickuplocation) }}
                                </span>
                                <span *ngIf="t.type == 'drop'" class="ms-1">
                                  {{ getSlicedAddress(t.droplocation) }}
                                </span>
                              </div>
                            </div>
                            <div class="col-2 p-0 d-flex align-items-center justify-content-center flex-column" *ngIf="
                              isTaskUnassigned && !isCompliance && !isAccountant
                            ">
                              <span class="mb-1" (click)="assignDriverEvent(t)">
                                <i class="bi bi-plus-circle-fill text-primary font-size-30"
                                  (click)="assignDriverEvent(t)"></i>
                              </span>
                              <span class="text-center font-size-12 text-nowrap" (click)="assignDriverEvent(t)">Assign Driver</span>
                            </div>
                            <div class="col-2 p-0 d-flex align-items-center justify-content-center flex-column"
                              *ngIf="isTaskAssigned || isTaskCompleted">
                          
                                <i class="bi bi-person-circle font-size-40 cursorPointer"(click)="goToDetails(t)"></i>
                                <!-- <span class="fw-bold font-size-12">{{t.attributes?.driverName}}</span> -->
                                <span class="fw-bold font-size-12 cursorPointer" (click)="goToDetails(t)">{{
                                  t.attributes?.cabno
                                  }}</span>
   
                            </div>
                            <div class="col-1 d-flex align-items-center justify-content-center">
                              <span class=""><i class="bi bi-caret-right-fill text-secondary" style="cursor: pointer"
                                  (click)="goToDetails(t)"></i></span>
                            </div>
                          </div>
                        </div>
            
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-template #noTasks>
                    <div class="d-flex justify-content-center align-items-center" style="height: 20vh">
                      <div>No task available for the day</div>
                    </div>
                  </ng-template>
                </div>
                <div class="scroller" (scroll)="onScrollCompletedTasks($event)" style="width: 99%"
                *ngIf="isTaskCompleted"
                >
                  <ng-container *ngIf="completedTaskslist.length > 0; else noTasks">
                    <ng-container *ngFor="let t of completedTaskslist">
                      <div class="row pb-3">     
                        <!-- <div class="card-header"style="background-color:aquamarine;">Vivek</div> -->
                        <div class="d-flex align-items-center justify-content-between card-header" style="
                          padding-bottom: 12px;
                          background-color: #ffd892;
                          margin-left: 4px;  
                          padding-top: 15px;
                        ">
                          <span class="badge rounded-pill" [ngClass]="getBgColorBasedOnTaskStatus(t.status)">{{ t.status
                            | uppercase }}</span>
                          <div class="d-flex">
                            <span class="gray-button header-btn" [ngClass]="getBgColorBasedOnTaskStatus(t.status)">
                              <span class="font-size-18">{{
                                t.attributes?.actual_emp ?? 0
                                }}</span>
                              <span>Actual Emp</span>
                            </span>
                            <span class="gray-button header-btn" [ngClass]="getBgColorBasedOnTaskStatus(t.status)">
                              <span class="font-size-18">{{
                                t.attributes?.planned_emp ?? 0
                                }}</span>
                              <span>Planned Emp</span>
                            </span>
                            <span class="gray-button header-btn" [ngClass]="getBgColorBasedOnTaskStatus(t.status)">
                              <span class="font-size-18">
                                {{
                                t.attributes?.escort === "0" ? "No" : "Yes"
                                }}</span>
                              <span>Escort</span>
                            </span>
                            <span (click)="openRoutePathModal(t)" class="default" style="display: block"
                              title="{{ 'Live Tracking' | translate }}" style="cursor: pointer"><i
                                class="fa-solid fa-route"></i></span>
                          </div>
                          <!-- <span class="gray-button align-items-center d-flex justify-content-center" ></span> -->
                        </div>
                        <div class="col-12">
                          <div class="row mt-1">
                            <div class="col-12 col-md-12">
                              <div>
                                <span class="fw-bold"><i class="fa-solid fa-id-card me-1" style="color:crimson"></i></span>
                                <span class="span-inputs me-1">{{ t.id }}</span>
                                <!-- <span *ngIf="t.type === 'pickup'" class="fw-bold"  style="color:darkorange"><i
                                    class="fa-solid fa-route span-icon"></i></span> -->
                                <span class="fw-bold"><i class="fa-solid fa-route span-icon me-1" [ngClass]="getColorBasedOnTaskType(t.type)"></i></span>
                                <span class="span-inputs">{{ t.type | titlecase }}</span>&nbsp;
                                <span>
                                  <img src="/assets/icons/vehicletimer.png" alt="Time" />
                                </span>
                                <span class="me-1 span-inputs" *ngIf="t.attributes?.Shift_Timing !=''">{{
                                  t.attributes?.Shift_Timing
                                  }}</span>
                                <span class="me-1" *ngIf="t.status != 'unassigned' || t.attributes?.remarks != '' || t?.type === 'cabin'  || t?.type === 'cabout'">
                                  <i *ngIf="t.attributes?.remarks != ''" class="fa-solid fa-taxi span-icon me-1" style="color:rgb(128, 0, 255)"></i>
                                  <span class="span-inputs" >{{t.attributes.remarks | titlecase}}</span>
                                </span>  
                                  <span    
                                  *ngIf="t.attributes.startDelayStatus === 'delayed' && (t.status === 'assigned' || t.status === 'acknowledged' || t.status === 'unassigned' )"
                                  class="border  text-center" 
                                  style="
                                  font-weight: 600;
                                  border-radius: 3px;  
                                  line-height: 0.7;
                                  background: #e355552e !important;
                                  padding: 0 2px 0 2px;
                                "> 
                            
                                <span style="color:#ff0000;font-size:13px;">DELAYED</span>
                                </span>

                                <span 
                                *ngIf="t.attributes.endDelayStatus === 'delayed' && (t.status== 'completed' || t.status === 'started')"
                                class="border  text-center" 
                                style="
                                font-weight: 600;
                                border-radius: 3px;
                                line-height: 0.7;
                                background: #e355552e !important;
                                padding: 0 3px 0 3px;
                              "> 
                           
                              <span style="color:#ff0000;font-size:13px;">DELAYED</span>
                              </span>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-9 ">
                           
                              <div>
                                <span class="fw-bold">Company: </span>
                                <span class="ms-1">{{
                                  t?.attributes?.clientName | titlecase
                                  }}</span>
                              </div>
                              <div *ngIf=" t?.fno">
                                <span class="fw-bold">Flap No: </span>
                                <span class="ms-1">{{
                                  t?.fno 
                                  }}</span>
                              </div>
                              <div>
                                <span class="fw-bold">Location: </span>
                                <span *ngIf="t.type == 'pickup'" class="ms-1">
                                  {{ getSlicedAddress(t.pickuplocation) }}
                                </span>
                                <span *ngIf="t.type == 'drop'" class="ms-1">
                                  {{ getSlicedAddress(t.droplocation) }}
                                </span>
                              </div>
                            </div>
                            <div class="col-2 p-0 d-flex align-items-center justify-content-center flex-column" *ngIf="
                              isTaskUnassigned && !isCompliance && !isAccountant
                            ">
                              <span class="mb-1" (click)="assignDriverEvent(t)">
                                <i class="bi bi-plus-circle-fill text-primary font-size-30"
                                  (click)="assignDriverEvent(t)"></i>
                              </span>
                              <span class="text-center font-size-12" (click)="assignDriverEvent(t)">Assign Driver</span>
                            </div>
                            <div class="col-2 p-0 d-flex align-items-center justify-content-center flex-column"
                              *ngIf="isTaskAssigned || isTaskCompleted">
                          
                                <i class="bi bi-person-circle font-size-40 cursorPointer"(click)="goToDetails(t)"></i>
                                <!-- <span class="fw-bold font-size-12">{{t.attributes?.driverName}}</span> -->
                                <span class="fw-bold font-size-12 cursorPointer" (click)="goToDetails(t)">{{
                                  t.attributes?.cabno
                                  }}</span>
   
                            </div>
                            <div class="col-1 d-flex align-items-center justify-content-center">
                              <span class=""><i class="bi bi-caret-right-fill text-secondary" style="cursor: pointer"
                                  (click)="goToDetails(t)"></i></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-template #noTasks>
                    <div class="d-flex justify-content-center align-items-center" style="height: 20vh">
                      <div>No task available for the day</div>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </div>
            <div class="row" *ngIf="isTaskDetails">
              <div class="col-12 bg-light d-flex align-items-center justify-content-between task-detail-header">
                <span>
                  Task / <strong>#{{ selectedTaskIdInTaskDetail }}</strong>
               
                </span>
                <span>
                  <i class="bi bi-x-circle-fill" (click)="closeDetails()"></i>
                </span>
              </div>
              <div class="col-12 tasks-tabs mb-1 mt-3">
                <div class="row">
                  <div class="col-12 d-flex align-items-center justify-content-between">
               
                      <div class="text-center">
                        <span class=" cursorPointer" [class.selected]="isTaskDetailDetail"
                        (click)="tasksDetailsTabsChange('details')">Details
                      </span>
                      </div>
                      <div>
                        <span class=" cursorPointer" [class.selected]="isTaskDetailsCustomer"
                        (click)="tasksDetailsTabsChange('customer')">Company
                      </span>
                      </div>
                   <div>
                    <span class="cursorPointer" [class.selected]="isTaskDetailsHistory"
                    (click)="tasksDetailsTabsChange('history')">History
                  </span>
                   </div>
                   
                  
                        <div class="dropdown cursorPointer me-2" style="margin-top:0.4rem" *ngIf="!isAccountant || !isCompliance">
                          <div class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false"  *ngIf="!(isAccountant || isCompliance)">
                            <i class="fa fa-ellipsis-v"></i>
                          </div>
                          <ul class="dropdown-menu option_drop pb-1 pt-1" aria-labelledby="dropdownMenuButton1">
                            <li *ngIf="
                              !isSupervisor &&
                              (selectedTaskDeails[0].status == 'assigned' ||
                                selectedTaskDeails[0].status == 'unassigned' ||
                                selectedTaskDeails[0].status == 'acknowledged' ||
                                selectedTaskDeails[0].status == 'started' ||
                                selectedTaskDeails[0].status == 'inprogress' ||
                                selectedTaskDeails[0].status == 'dropped' ||
                                selectedTaskDeails[0].status == 'pickedup'
                                )
                            ">
                              <a href="javascript:void(0);" class="dropdown-item" (mouseover)="hoverEffect($event)"
                                (mouseout)="removeHoverEffect($event)" (click)="
                                openEditStopStatusModal(selectedTaskDeails[0])
                              ">
                                <span class="optionstext">
                                  {{ "Edit Stop" | translate }}
                                </span>
                              </a>
                            </li>
                            <li *ngIf="
                              selectedTaskDeails[0].status == 'assigned' ||
                              selectedTaskDeails[0].status == 'unassigned' ||
                              selectedTaskDeails[0].status == 'inprogress' ||
                              selectedTaskDeails[0].status == 'acknowledged' ||
                              selectedTaskDeails[0].status == 'started' ||
                              selectedTaskDeails[0].status == 'completed' ||
                                selectedTaskDeails[0].status == 'dropped' ||
                                selectedTaskDeails[0].status == 'pickedup'
                            ">
                              <a href="javascript:void(0);" class="dropdown-item" (mouseover)="hoverEffect($event)"
                                (mouseout)="removeHoverEffect($event)" (click)="
                                downloadAsPDFForReference(selectedTaskDeails[0])
                              ">
                                <span class="optionstext">
                                  {{ "Export Stop" | translate }}
                                </span>
                              </a>
                            </li>
                            <li *ngIf="
                              !isSupervisor &&
                              (selectedTaskDeails[0].status == 'assigned' ||
                                selectedTaskDeails[0].status == 'unassigned' ||
                                selectedTaskDeails[0].status == 'inprogress' ||
                                selectedTaskDeails[0].status == 'acknowledged')
                            ">
                              <a href="javascript:void(0);" class="dropdown-item" (mouseover)="hoverEffect($event)"
                                (mouseout)="removeHoverEffect($event)" (click)="deleteTask(selectedTaskDeails[0])">
                                <span class="optionstext">
                                  {{ "Delete Stop" | translate }}
                                </span>
                              </a>
                            </li>
                            <li *ngIf="
                              selectedTaskDeails[0].status !== 'unassigned' &&
                              selectedTaskDeails[0].status !== 'completed'
                             
                            ">
                              <a href="javascript:void(0);" class="dropdown-item" (mouseover)="hoverEffect($event)"
                                (mouseout)="removeHoverEffect($event)" (click)="assignDriverEvent(selectedTaskDeails[0])">
                                <span class="optionstext">
                                  {{ "Reassign Driver" | translate }}
                                </span>
                              </a>
                            </li>
                            <li *ngIf="
                              !isSupervisor &&
                              (selectedTaskDeails[0].status == 'assigned' ||
                                selectedTaskDeails[0].status == 'unassigned' ||
                                selectedTaskDeails[0].status == 'acknowledged' ||
                                selectedTaskDeails[0].status == 'started')
                            ">
                              <a href="javascript:void(0);" class="dropdown-item" (mouseover)="hoverEffect($event)"
                                (mouseout)="removeHoverEffect($event)" (click)="
                                openChangeStopStatusModal(selectedTaskDeails[0])
                              ">
                                <span class="optionstext">
                                  {{ "Change stop status" | translate }}
                                </span>
                              </a>
                            </li>
  
                            <!-- <li *ngIf="selectedTaskDeails[0].status == 'unassigned'">
                            <a href="javascript:void(0);" class="dropdown-item"
                              (click)="assignDriverEvent(selectedTaskDeails[0], true)">
                              <span class="optionstext">
                                <i class="fa fa-edit"></i> {{'Assigned Driver'|translate}}
                              </span>
                            </a>
                          </li>
                          <li *ngIf="selectedTaskDeails[0].status == 'unassigned'">
                            <a href="javascript:void(0);" class="dropdown-item" (click)="editTask(selectedTaskDeails[0])">
                              <span class="optionstext">
                                <i class="fa fa-edit"></i> {{'Edit Task'|translate}}
                              </span>
                            </a>
                          </li>
                          <li>
                            <a *ngIf="selectedTaskDeails[0].status != 'unassigned' && selectedTaskDeails[0].status != 'acknowledge'"
                              class="dropdown-item" (click)="updateTasks(selectedTaskDeails[0], {status: 'acknowledge'})">
                              <span class="optionstext">
                                <i class="fa fa-trash"></i> {{'Mark as Aknowledge'|translate}}
                              </span>
                            </a>
                          </li>
                          <li>
                            <a *ngIf="selectedTaskDeails[0].status != 'unassigned' && selectedTaskDeails[0].status != 'inprogress'"
                              class="dropdown-item" (click)="updateTasks(selectedTaskDeails[0], {status: 'inprogress'})">
                              <span class="optionstext">
                                <i class="fa fa-trash"></i> {{'Mark as Started'|translate}}
                              </span>
                            </a>
                          </li>
                          <li>
                            <a *ngIf="selectedTaskDeails[0].status != 'unassigned' && selectedTaskDeails[0].status != 'completed'"
                              class="dropdown-item" (click)="updateTasks(selectedTaskDeails[0], {status: 'completed'})">
                              <span class="optionstext">
                                <i class="fa fa-trash"></i> {{'Mark as Completed'|translate}}
                              </span>
                            </a>
                          </li> -->
                          </ul>
                        </div>
                    </div>  
                  
                    
                  
             
                </div>
              </div>
              <ng-container *ngIf="isTaskDetailDetail">
                <div class="col-12">
                  <div class="row">
                    <div class="col-5">Status</div>
                    <div class="col-7" [ngClass]="
                      getTextColorBasedOnTaskStatus(
                        selectedTaskDeails[0].status
                      )
                    ">
                      {{
                      selectedTaskDeails[0].status == "completed"
                      ? "Successfull"
                      : (selectedTaskDeails[0].status | uppercase)
                      }}
                    </div>
                  </div>
                </div>
                <!-- <div class="col-12">
                <div class="row">
                  <div class="col-5">Delayed By</div>
                  <div class="col-7">{{selectedTaskDeails[0].delayedby}}</div>
                </div>                
              </div> -->
                <!-- <div class="col-12">
                <div class="row">
                  <div class="col-5">Task Description</div>
                  <div class="col-7">{{selectedTaskDeails[0].name}}</div>
                </div>
              </div> -->
                <div class="col-12">
                  <div class="row">
                    <div class="col-5">Start Before</div>
                    <div class="col-7">
                      :{{
                      selectedTaskDeails[0].expectedstarttime
                      | date : "dd MMM yyyy hh:mm:a"
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-5">Complete Before</div>
                    <div class="col-7">
                      :{{
                      selectedTaskDeails[0].expectedendtime
                      | date : "dd MMM yyyy hh:mm:a"
                      }}  
                    </div>
                  </div>
                </div>
                <!-- <div class="col-12">
                  <div class="row">
                    <div class="col-5">Tracking Link</div>
                    <div class="col-7">
                      <span class="trackingLink cursorPointer" (click)="
                        getDeviceInformationByUserIdForTracking(
                          selectedTaskDeails[0]
                        )
                      ">Click here to track</span>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="col-12">
                <div class="row">
                  <div class="col-5">Tracking Link</div>
                  <div class="col-7"><a style="cursor:pointer;" (click)="getLocationOnMap()">Click here to track</a>
                  </div>
                </div>
              </div> -->
                <!-- <div class="col-12">
                <div class="row">
                  <div class="col-5">Team</div>
                  <div class="col-7">NA</div>
                </div>
              </div> -->
                <div class="col-12">
                  <div class="row">
                    <div class="col-5">Cab Number</div>
                    <div class="col-7 cursorPointer driverSelect">
                      <span (click)="
                        onDriverCabSelect(selectedTaskDeails[0].assignedto)
                      " style="cursor: pointer">:{{
                        selectedTaskDeails[0].attributes.cabno
                        ? selectedTaskDeails[0].attributes.cabno
                        : "NA"
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-12">
                <div class="row">
                  <div class="col-5">Order ID</div>
                  <div class="col-7">NA</div>
                </div>
              </div> -->
                <!-- <div class="col-12">
                <div class="row">
                  <div class="col-5">Shift</div>
                  <div class="col-7">{{selectedTaskDeails[0].shift|date:'hh:mm'}}</div>
                </div>                
              </div> -->
                <div class="col-12">
                  <div class="row">
                    <div class="col-5">Trip Type</div>
                    <div class="col-7">
                      :{{
                      selectedTaskDeails[0].type == "pick"
                      ? "Pickup"
                      : (selectedTaskDeails[0].type | titlecase)
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-5">Remarks</div>
                    <div class="col-7">
                      :{{
                      selectedTaskDeails[0].attributes.remarks
                      ? (selectedTaskDeails[0].attributes.remarks | titlecase)
                      : "NA"
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-5">Planned Employee</div>
                    <div class="col-7">
                      :{{ selectedTaskDeails[0].attributes?.planned_emp }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-5">Actual Employee</div>
                    <div class="col-7">
                      :{{ selectedTaskDeails[0].attributes?.actual_emp }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-5">Escort</div>
                

                    <div class="col-7">
                      :{{
                      selectedTaskDeails[0].attributes?.escort === '0' ? "No" : "Yes"
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-5">Travel time</div>
                    <div class="col-7">
                      :{{
                      selectedTaskDeails[0].duration
                      ? (selectedTaskDeails[0].duration | duration)
                      : "NA"
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-5">Total Km</div>
                    <div class="col-7">
                      :{{
                      selectedTaskDeails[0].gpsDistance
                      ? (selectedTaskDeails[0].gpsDistance | meterTokm)
                      : "0"
                      }}
                      km
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-4" *ngIf="selectedTaskDeails[0].status == 'completed'">
                  <div class="row">
                    <div class="col-12 text-center mb-4">
                      <span class="history-path-button" style="cursor: pointer"
                        (click)="onCustomSubmitHistory1(selectedTaskDeails[0])">Show History</span>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="isTaskDetailsCustomer">
                <div class="col-12">
                  <div class="row">
                    <div class="col-5" style="display: flex; justify-content: space-between">
                      Company Name <span style="margin-left: auto">:</span>
                    </div>
                    <div class="col-7">
                      {{ clientData[0].name ? clientData[0].name : "NA" }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-5" style="display: flex; justify-content: space-between">
                      Address <span style="margin-left: auto">:</span>
                    </div>

                    <div class="col-7">
                      {{ clientLocation ? clientLocation : "NA" }}
                    </div>
                  </div>
                </div>

                <div class="col-12 mb-2">
                  <div class="row">
                    <div class="col-5" style="display: flex; justify-content: space-between">
                      Contact Number <span style="margin-left: auto">:</span>
                    </div>

                    <div class="col-7" style="display: flex; align-items: center">
                      {{ clientData[0].phone ? clientData[0].phone : "-"}}
                      <div 
                      *ngIf="clientData[0].phone"
                      style="
                        border-radius: 50%;
                        width: 24px;
                        height: 24px;
                        background-color: rgb(5, 237, 5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 5px;
                      " (click)="callToDriverRequest(clientData[0])">
                        <i class="fa-solid fa-phone cursorPointer" matTooltip="Call to Company"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="isTaskDetailsHistory">
                <div class="col-12">
                  <div class="row d-flex align-items-center justify-content-center bg-light w-100 ms-0">
                    <div class="col-2 text-center">
                      <i class="bi bi-person-circle font-size-30"></i>
                    </div>
                    <div class="col-10">
                      <div class="row">
                        <!-- <div class="col-12 fw-bold">
                        {{ selectedTaskDeails[0]?.id }}
                      </div> -->
                        <div class="col-12">
                          {{
                          selectedTaskDeails[0].gpsDistance
                          ? (selectedTaskDeails[0].gpsDistance | meterTokm)
                          : "0"
                          }}
                          km
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="scroller scrollerTaskHistoryDetails" (scroll)="(taskHistoryDetails.length)">
                    <div class="row" *ngFor="let tdh of taskHistoryDetails.slice().reverse()">
                      <div class="col-2 d-flex text-end justify-content-center flex-column border-bottom pb-2"
                        >
                       <span class="font-size:8px"> {{ tdh.createddate | date : "dd MMM yyyy HH:mm" }}</span>
                      </div>
                      <div class="col-1 d-flex align-items-center flex-column ps-0 border-bottom pb-2"
                        >
                        <span>
                          <i class="bi bi-circle-fill selected"></i>
                        </span>
                        <div class="connected-line-dotted selected"></div>
                        <!-- <span>
                        <i class="bi bi-circle"></i>
                      </span> -->
                      </div>
                      <div class="col-9 border-bottom pb-2">
                        <div class="row bg-light w-100 mt-2 hover-enlarge">
                          <div class="col-12 py-2 d-flex">
                            <div class="d-flex align-items-center" style="width: 50%">
                              <span class="border p-1 text-center" [ngClass]="
                                getBgColorBasedOnTaskStatus(tdh.status)
                              " style="
                                width: 100%;
                                font-weight: 500;
                                border-radius: 3px;
                                line-height: 0.9;
                              ">{{ tdh.status === 'unassigned'? 'Created by' : tdh.status | titlecase }}</span>
                            </div>
                            <div class="text-center mt-1" >
                              by <span style="font-weight: 600;">{{ tdh.username | titlecase}}</span>
                            </div>
                          </div>
                          <div class="col-12">
                            <span style="cursor: pointer" id="locatedMap" (click)="openLocationModal(tdh)">Located on
                              map</span>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-11">
                      <div class="row">
                        <div class="col-12">
                          <div class="fw-bold">
                            {{tdh.time|date:'hh:mm'}} - {{tdh.type}} <span class="text-success">{{tdh.status}}</span>
                          </div>
                          <div>
                            {{tdh.desc}}
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="fw-bold">
                            {{tdh.time|date:'hh:mm'}} - {{tdh.type}} <span class="text-success">{{tdh.status}}</span>
                          </div>
                          <div>
                            {{tdh.desc}}
                          </div>
                        </div>
                      </div>
                    </div> -->
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
       

        <div class="card main_card2" *ngIf="fulldetails">


          <div class="btngroup">
            <div>
              <div class="btn detailbtn" (click)="onClickDetails()">
                {{ "Vehicle Details" | translate }}
              </div>
            </div>
            <div class="btn" style="padding: 0 0.5vh">
              <span class="material-icons" style="color: #fff" (click)="onBack()">
                arrow_back
              </span>
            </div>
          </div>


          <div class="vehicle_fullDetail" *ngIf="vehicle_fullDetail">
            <div class="mat_icon">
              <div class="fix_align">
                <img [src]="
                  selectedDevice?.deviceDetails?.vehicleImg ||
                  '/assets/icons/devices/car/card.png'
                " [ngStyle]="{ width: '45px', height: '60px' }" alt="img" class="vehicleimg" />
              </div>
              <span class="vehicle_update" style="margin-left: 1vh">
                <span class="vehicle_number">{{ selectedDevice.name }}</span>
                <span class="height-17">
                  <img [src]="
                    selectedDevice?.deviceDetails?.dotImg ||
                    '/assets/icons/no_data.png'
                  " alt="dot" class="dot" />
                  <span class="update" style="font-size: 1.4vh">{{ "Status" | translate }}
                    <span [ngStyle]="{
                      color: selectedDevice?.deviceDetails?.color,
                      'padding-left': '5px'
                    }">
                      {{ selectedDevice?.deviceDetails?.msg }}
                    </span>
                  </span>
                </span>
                <span class="">
                  <img src="/assets/icons/vehicletimer.png" alt="timer" class="img_timer2" />
                  <span class="update" style="font-size: 1.4vh">{{ "Last Updated" | translate }} -
                    {{ selectedDevice.deviceTime | dateFormat }}</span>
                </span>
              </span>
              <div class="speed-large">
                <span class="dist_fuel_speed_span" style="padding-left: 5px">
                  {{ selectedDevice?.speed | number : "1.0-2"
                  }}<small>{{ "kmph" | translate }}</small>
                </span>
              </div>
            </div>

            <!-- <div class="row pt-1  m-0">
            <div class="col-6 p-0">
              <span class="mat_icon">
                <span class="material-icons speedalign"> {{'speed'|translate}} </span>
                <span class="dist_fuel_speed" style="padding-left: 5px"
                  >{{ selectedDevice?.speed || 0 }}{{'kmph'|translate}}</span
                >
              </span>
            </div>
            <div class="col-6 p-0">
              <span class="mat_icon">
                <img src="/assets/icons/petrolremaining.png" alt="dot" />
                <span class="dist_fuel_speed">NA</span>
              </span>
            </div>
          </div> -->

            <!-- <div class="row py-1 m-0">
            <div class="col-6 p-0">
              <span class="mat_icon">
                <span class="material-icons speedalign"> schedule </span>
                <span
                  class="dist_fuel_speed"
                  style="padding-left: 5px"
                  *ngIf="selectedDevice?.attributes?.hours; else showDuration"
                  >{{ selectedDevice?.attributes?.hours | dateDuration }}</span
                >
                <ng-template #showDuration>
                  <span class="dist_fuel_speed" style="padding-left: 5px"
                    >0</span
                  >
                </ng-template>
              </span>
            </div>
            <div class="col-6 p-0">
              <span class="mat_icon">
                <img src="/assets/icons/speed.png" alt="dot" />
                <span class="dist_fuel_speed"
                  >{{ selectedDevice?.attributes?.distance || 0 }}Km</span
                >
              </span>
            </div>
          </div> -->

            <div class="row py-1 m-0">
              <div class="mat_icon" style="padding-left: 4px">
                <img src="/assets/icons/address.png" alt="address" class="address_img" />
                <span class="address">{{ selectedDevice.address ? selectedDevice.address : "NA" }}
                </span>
              </div>
            </div>
            <div class="position-relative" style="margin: 3vh 0">
              <hr style="height: 0.3vh; color: black" />
              <div class="d-flex flex-row timer_boxes">
                <!-- {{selectedDevice?.today_odo | number : '1.0-0'}} -->
                <div class="num_box">{{ my_string[0] }}</div>
                <div class="num_box">{{ my_string[1] }}</div>
                <div class="num_box">{{ my_string[2] }}</div>
                <div class="num_box">{{ my_string[3] }}</div>
                <div class="num_box">{{ my_string[4] }}</div>
                <div class="num_box">{{ my_string[5] }}</div>
                <div class="num_box">{{ my_string[6] }}</div>
              </div>
            </div>
            <div class="vehicle_features my-1 px-2">
              <div class="bottomIcons">
                <img src="/assets/icons/bottom1.png" alt="img"
                  *ngIf="selectedDevice?.attributes?.ignition; else showIgnRed" />
                <span class="bottom_tags">{{ "IGN" | translate }}</span>
              </div>
              <div class="bottomIcons">
                <img src="/assets/icons/bottom2.png" alt="img" />
                <span class="bottom_tags">{{ "AC" | translate }}</span>
              </div>
              <div class="bottomIcons">
                <img src="/assets/icons/bottom3.png" alt="img" />
                <span class="bottom_tags">{{ "Fuel" | translate }}</span>
              </div>
              <div class="bottomIcons">
                <img src="/assets/icons/bottom4.png" alt="img"
                  *ngIf="selectedDevice?.attributes?.charge; else showPowerRed" />
                <span class="bottom_tags">{{
                  selectedDevice?.attributes?.power | number : "1.0-2"
                  }}
                  v</span>
              </div>
              <div class="bottomIcons">
                <img src="/assets/icons/bottom5.png" alt="img" />
                <span class="bottom_tags">{{ "Temp" | translate }}</span>
              </div>
              <div class="bottomIcons">
                <img src="/assets/icons/bottom6.png" alt="img" />
                <span class="bottom_tags">{{ "GPS" | translate }}</span>
              </div>
              <div class="bottomIcons">
                <img src="/assets/icons/bottom7.png" alt="img" />
                <span class="bottom_tags">{{ "Door" | translate }}</span>
              </div>
            </div>

            <div class="day_calendar pt-3 pb-2 m-0 px-2">
              <div class="date">{{ selectedDate | date : "mediumDate" }}</div>
              <input type="date" id="meeting" value="2022-03-26" [(ngModel)]="selectedDate" name="selectedDate"
                (change)="onDateSelection()" />
              <!-- <img
              src="/assets/icons/vehc_calendar.png"
              alt="calendar"
              class="cal_img"
            /> -->
            </div>

            <div class="row m-0 borderrow">
              <div class="col-4" style="border-right: 2px solid #324151; padding: 0.75rem 0">
                <div class="mat_icon justify-content-center">
                  <span class="material-icons movingimg"> schedule </span>
                  <span class="flexcolumn" style="padding-left: 1vh">
                    <span class="daytext">{{ "Moving Time" | translate }}</span>
                    <span class="dayconts">{{
                      vehicleDetailsReport.summary.engineHours | convert : "hh:mm"
                      }}</span>
                  </span>
                </div>
              </div>
              <div class="col-4" style="border-right: 2px solid #324151; padding: 0.75rem 0">
                <div class="mat_icon justify-content-center">
                  <span class="material-icons movingimg" style="background-color: #e8ad21">
                    speed
                  </span>
                  <span class="flexcolumn" style="padding-left: 1vh">
                    <span class="daytext">{{
                      "Total Kilometer" | translate
                      }}</span>
                    <span class="dayconts">{{ vehicleDetailsReport.summary.distance | meterTokm }}
                      {{ "Kms" | translate }}</span>
                  </span>
                </div>
              </div>
              <div class="col-4" style="padding: 0.75rem 0">
                <div class="mat_icon justify-content-center">
                  <img src="/assets/icons/time_icon.png" alt="moving" class="movingimg"
                    style="background-color: #f9536f" />
                  <span class="flexcolumn" style="padding-left: 1vh">
                    <span class="daytext">{{ "Parked" | translate }}</span>
                    <span class="dayconts">{{
                      vehicleDetailsReport.summary.parkedTime | convert : "hh:mm"
                      }}</span>
                  </span>
                </div>
              </div>
            </div>

            <div class="btngroup1">
              <!-- <div class="btn detailbtn">All</div> -->
              <div [class.active]="activeSection == 1" (click)="activeSection = 1" class="btn detailbtn">
                {{ "Moving" | translate }}
              </div>
              <div [class.active]="activeSection == 2" (click)="activeSection = 2" class="btn detailbtn">
                {{ "Parked" | translate }}
              </div>
              <div [class.active]="activeSection == 3" (click)="activeSection = 3" class="btn detailbtn">
                {{ "Alert" | translate }}
              </div>
            </div>

            <div class="row timelinerow scroller2">
              <div id="content">
                <ul *ngIf="activeSection == 1" class="timeline">
                  <li *ngFor="let item of vehicleDetailsReport.moving" class="event"
                    [attr.data-date]="item.startTime | date : 'shortTime'">
                    <h3>{{ item.endTime | date : "shortTime" }}</h3>
                    <div class="mat_icon" style="padding-left: 4px; margin-bottom: 3vh">
                      <img src="/assets/icons/address.png" alt="address" class="address_img" />
                      <span class="address" style="font-size: 1.6vh">{{ "Distance" | translate }} -
                        {{ item.distance | meterTokm }} KM</span>
                    </div>
                  </li>
                  <!-- <li class="event" data-date="2:30 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li>
                <li class="event" data-date="5:00 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li>
                <li class="event" data-date="8:30 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li> -->
                </ul>
                <ul *ngIf="activeSection == 2" class="timeline">
                  <li *ngFor="let item of vehicleDetailsReport.parked" class="event"
                    [attr.data-date]="item.startTime | date : 'shortTime'">
                    <h3>{{ item.endTime | date : "shortTime" }}</h3>
                    <div class="mat_icon" style="padding-left: 4px; margin-bottom: 3vh">
                      <img src="/assets/icons/address.png" alt="address" class="address_img" />
                      <span class="address" style="font-size: 1.6vh">{{ "Duration" | translate }} -
                        {{ item.duration | convert : "hh:mm" }}
                      </span>
                    </div>
                  </li>
                  <!-- <li class="event" data-date="2:30 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li>
                <li class="event" data-date="5:00 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li>
                <li class="event" data-date="8:30 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li> -->
                </ul>
                <ul *ngIf="activeSection == 3" class="timeline">
                  <li *ngFor="let item of vehicleDetailsReport.alert" class="event"
                    [attr.data-date]="item.eventTime | date : 'shortTime'">
                    <!-- <h3>{{item.eventTime | date:'shortTime'}}</h3> -->
                    <div class="mat_icon" style="padding-left: 4px; margin-bottom: 3vh">
                      <img src="/assets/icons/address.png" alt="address" class="address_img" />
                      <span class="address" style="font-size: 1.6vh">{{ "Type" | translate }} - {{ item.type }}
                      </span>
                    </div>
                  </li>
                  <!-- <li class="event" data-date="2:30 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li>
                <li class="event" data-date="5:00 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li>
                <li class="event" data-date="8:30 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li> -->
                </ul>
              </div>
            </div>
          </div>



          <div class="row timelinerow1" *ngIf="vehicle_status">
            <div class="destination">
              <ul class="timeline2" style="padding: 4px 7px">
                <li>
                  <span class="dot" style="padding: 0 9px"></span>
                  <span class="address2">Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                    394210 (India)
                  </span>
                </li>
                <li style="margin-top: 1vh">
                  <span class="dot" style="padding: 0 9px"></span>
                  <span class="address2">Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                    394210 (India)
                  </span>
                </li>
              </ul>
            </div>
            <div class="row m-0 py-3">
              <div class="col-6">
                <div class="mat_icon" style="padding: 0 3vh">
                  <img src="/assets/icons/vector_timedistance.png" alt="timedistance" class="vectorimg" />
                  <span class="flexcolumn" style="padding-left: 1vh">
                    <span class="totaltimedistance">{{
                      "Total Travel Time" | translate
                      }}</span>
                    <span class="timedistancecounts">11h : 35m</span>
                  </span>
                </div>
              </div>
              <div class="col-6">
                <div class="mat_icon" style="padding: 0 3vh">
                  <img src="/assets/icons/vector_timedistance.png" alt="timedistance" class="vectorimg" />
                  <span class="flexcolumn" style="padding-left: 1vh">
                    <span class="totaltimedistance">{{
                      "Total Distance" | translate
                      }}</span>
                    <span class="timedistancecounts">350.8 {{ "km" | translate }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="vehcleDataRows scroller2">
    

              <div class="row m-0 odometer_row">
                <div class="card odometerbox">
                  <span>
                    <img src="/assets/icons/odometer.png" alt="odometer" />
                  </span>
                  <span class="vehicle_info_data">85,660.18</span>
                  <span class="vehicle_info_text">{{
                    "Odometer" | translate
                    }}</span>
                </div>
                <div class="card odometerbox">
                  <span>
                    <img src="/assets/icons/speedify.png" alt="odometer" />
                  </span>
                  <span class="vehicle_info_data">54 {{ "kmph" | translate }}</span>
                  <span class="vehicle_info_text">{{ "Speed" | translate }}</span>
                </div>
                <div class="card odometerbox">
                  <span>
                    <img src="/assets/icons/Avg.speed.png" alt="odometer" />
                  </span>
                  <span class="vehicle_info_data">35 {{ "kmph" | translate }}</span>
                  <span class="vehicle_info_text">
                    {{ "Avg. Speed" | translate }}</span>
                </div>
                <div class="card odometerbox">
                  <span>
                    <img src="/assets/icons/Max.speed.png" alt="odometer" />
                  </span>
                  <span class="vehicle_info_data">91 {{ "kmph" | translate }}</span>
                  <span class="vehicle_info_text">{{
                    "Max. Speed" | translate
                    }}</span>
                </div>
              </div>
              <div class="row m-0 odometer_row">
                <div class="card odometerbox">
                  <span>
                    <img src="/assets/icons/Overspeed.png" alt="odometer" />
                  </span>
                  <span class="vehicle_info_data">10 {{ "km" | translate }}
                  </span>
                  <span class="vehicle_info_text">{{
                    "Over speed" | translate
                    }}</span>
                </div>
                <div class="card odometerbox">
                  <span>
                    <img src="/assets/icons/thermometer_pink.png" alt="odometer" />
                  </span>
                  <span class="vehicle_info_data">29°C</span>
                  <span class="vehicle_info_text">{{ "Temp" | translate }}</span>
                </div>
                <div class="card odometerbox">
                  <span>
                    <img src="/assets/icons/FuelPump.png" alt="odometer" />
                  </span>
                  <span class="vehicle_info_data">50L</span>
                  <span class="vehicle_info_text">{{ "Fuel" | translate }}</span>
                </div>
                <div class="card odometerbox">
                  <span>
                    <img src="/assets/icons/NoOfStops.png" alt="odometer" />
                  </span>
                  <span class="vehicle_info_data">05</span>
                  <span class="vehicle_info_text">{{
                    "No of stops" | translate
                    }}</span>
                </div>
              </div>
              <div class="row m-0 odometer_row">
                <div class="card odometerbox">
                  <span>
                    <img src="/assets/icons/Mileage.png" alt="odometer" />
                  </span>
                  <span class="vehicle_info_data">10 {{ "km" | translate }}
                  </span>
                  <span class="vehicle_info_text">Mileage</span>
                </div>
                <div class="card odometerbox">
                  <span>
                    <img src="/assets/icons/movingtime_pink.png" alt="odometer" />
                  </span>
                  <span class="vehicle_info_data">59m 34s</span>
                  <span class="vehicle_info_text">{{
                    "Moving Time" | translate
                    }}</span>
                </div>
                <div class="card odometerbox">
                  <span>
                    <img src="/assets/icons/Parked.png" alt="odometer" />
                  </span>
                  <span class="vehicle_info_data">8hr 24m 22s</span>
                  <span class="vehicle_info_text">{{
                    "Parked" | translate
                    }}</span>
                </div>
                <div class="card odometerbox">
                  <span>
                    <img src="/assets/icons/Idling.png" alt="odometer" />
                  </span>
                  <span class="vehicle_info_data">36m 24s</span>
                  <span class="vehicle_info_text">36m 24s</span>
                </div>
              </div>
              <div class="row m-0 odometer_row">
                <div class="card odometerbox">
                  <span>
                    <img src="/assets/icons/Voltage.png" alt="odometer" />
                  </span>
                  <span class="vehicle_info_data">90%</span>
                  <span class="vehicle_info_text">{{
                    "Voltage" | translate
                    }}</span>
                </div>
                <div class="card odometerbox">
                  <span>
                    <img src="/assets/icons/Battery.png" alt="odometer" />
                  </span>
                  <span class="vehicle_info_data">100%</span>
                  <span class="vehicle_info_text">{{
                    "Battery" | translate
                    }}</span>
                </div>
                <div class="card odometerbox">
                  <span>
                    <img src="/assets/icons/PrimaryEng.png" alt="odometer" />
                  </span>
                  <span class="vehicle_info_data">1hr 24m 22s</span>
                  <span class="vehicle_info_text">{{ "primary Eng" | translate }}.</span>
                </div>
                <div class="card odometerbox">
                  <span>
                    <img src="/assets/icons/sec.eng.png" alt="odometer" />
                  </span>
                  <span class="vehicle_info_data">0s</span>
                  <span class="vehicle_info_text">{{ "Sec. Eng" | translate }}.</span>
                </div>
              </div>

              <!-- VEHICLE TIME STATUS -->

              <div class="activity_head">{{ "Today Activity" | translate }}</div>
              <div class="mat_icon alignmat">
                <span class="mat_icon">
                  <img src="/assets/icons/vector_timedistance.png" alt="timedistance" class="vectorimg1" />
                  <span class="activity_status">{{ "Running" | translate }}</span>
                </span>
                <span class="activity_time">00.13 {{ "Hrs" | translate }}</span>
              </div>
              <div class="mat_icon alignmat">
                <span class="mat_icon">
                  <img src="/assets/icons/vector_timedistance.png" alt="timedistance" class="vectorimg2" />
                  <span class="activity_status">{{ "Idle" | translate }}</span>
                </span>
                <span class="activity_time">00.40 {{ "Hrs" | translate }}</span>
              </div>
              <div class="mat_icon alignmat">
                <span class="mat_icon">
                  <img src="/assets/icons/vector_timedistance.png" alt="timedistance" class="vectorimg3" />
                  <span class="activity_status">{{ "Stop" | translate }}</span>
                </span>
                <span class="activity_time">00.00 {{ "Hrs" | translate }}</span>
              </div>
              <div class="mat_icon alignmat pb-3">
                <span class="mat_icon">
                  <img src="/assets/icons/vector_timedistance.png" alt="timedistance" class="vectorimg4" />
                  <span class="activity_status">{{
                    "Inactive" | translate
                    }}</span>
                </span>
                <span class="activity_time">17.35 {{ "Hrs" | translate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col_map" id="myMap">
        <div class="card main_card p-0">
          <!-- <agm-map [latitude]="18.5632" [longitude]="73.9176" [zoom]="zoom">
            <agm-marker [latitude]="18.5632" [longitude]="73.9176"></agm-marker>
          </agm-map> -->
          <ng-container *ngIf="allvehicles">
            <agm-map [mapTypeId]="mapTypeId" class="multiple-vehicle"
                    [latitude]="lat2" [longitude]="lng2" 
                    [zoom]="zoom"
                    [disableDefaultUI]="false" (mapClick)="mapClicked($event)" (mapReady)="onMapReady($event)"
                    (zoomChange)="changeMapZoom($event)" [zoomControl]="false">
                    <agm-marker-cluster imagePath="/assets/icons/clusterImg">
                     <ng-container *ngFor="let device of markersList; let i = index">
                         <agm-marker
                         [latitude]="device.latitude" [longitude]="device.longitude"
                         (markerClick)="clickedMarker(device.deviceId)"
                         [label]="{
                             fontSize: '10px',
                             color: '#fbfbfc',
                             text: device.name
                         }"
                         [iconUrl]="device.deviceDetails.vehicleMapImg">
                         <agm-info-window [isOpen]="previousInfoWindow === device.deviceId" #infowindow>
                          <div class="container p-3">
                            <div class="row mb-2">
                              <div class="col-4 fw-bold">
                                {{ "Object" | translate }}:
                              </div>
                              <div class="col-8">
                                {{ device.name }}
                              </div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-4 fw-bold">
                                {{ "Position" | translate }}:
                              </div>
                              <div class="col-8">
                                <a href="https://maps.google.com/maps?q={{ device?.latitude }},{{ device.longitude }}&amp;t=m" target="_blank">
                                  {{ device?.latitude }} °, {{ device.longitude }} °
                                </a>
                              </div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-4 fw-bold">
                                {{ "Speed" | translate }}:
                              </div>
                              <div class="col-8">
                                {{ device?.speed | number: "1.0-3" }} {{ "kmph" | translate }}
                              </div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-4 fw-bold">
                                {{ "Time" | translate }}:
                              </div>
                              {{device?.lastUpdate  | json}}
                              <div class="col-8">
                                {{ device?.lastUpdate | date:"yyyy-MM-dd hh:mm:ss a"}}
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-4 fw-bold">
                                {{ "Odometer" | translate }}:
                              </div>
                              <div class="col-8">
                                {{ device?.attributes?.totalDistance | meterTokm }} {{ "km" | translate }}
                              </div>
                            </div>
                          </div>
                        </agm-info-window>
                        </agm-marker>
                         </ng-container>
                 </agm-marker-cluster>
           </agm-map>
         </ng-container>   
          <!-- <ng-container *ngIf="drawPolygonGeofence">
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="false"
              (mapReady)="polygonDrawingManager($event)" (zoomChange)="changeMapZoom($event)">
              <ng-container *ngFor="let poi of markerGeofenceList; let i = index">
                <agm-circle *ngIf="
                  circularGeofence &&
                  poi.geofence?.type &&
                  poi.geofence.type === 'Point'
                " [latitude]="poi.geofence.coordinates[0]" [longitude]="poi.geofence.coordinates[1]"
                  [radius]="poi.geofence.radius" [fillColor]="'red'" [circleDraggable]="false" [editable]="false">
                </agm-circle>

                <agm-polygon *ngIf="
                  polygonGeofence &&
                  poi.geofence?.type &&
                  poi.geofence.type === 'Feature'
                " [visible]="true" [paths]="poi.geofence.geometry.coordinates" [fillColor]="'red'" [clickable]="true">
                </agm-polygon>
              </ng-container>
              <ng-container *ngFor="let poi of markerGeofenceList; let i = index">
                <agm-circle *ngIf="
                  isGeofenceVisiable &&
                  poi.geofence?.type &&
                  poi.geofence.type === 'Point'
                " [latitude]="poi.geofence.coordinates[0]" [longitude]="poi.geofence.coordinates[1]"
                  [radius]="poi.geofence.radius" [fillColor]="'red'" [circleDraggable]="false" [editable]="false">
                </agm-circle>

                <agm-polygon *ngIf="
                  isGeofenceVisiable &&
                  poi.geofence?.type &&
                  poi.geofence.type === 'Feature'
                " [visible]="true" [paths]="poi.geofence.geometry.coordinates" [fillColor]="'red'" [clickable]="true">
                </agm-polygon>
              </ng-container>
            </agm-map>
          </ng-container>
          <ng-container *ngIf="drawCircularGeofence">
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="false"
              (mapReady)="circularDrawingManager($event)" (zoomChange)="changeMapZoom($event)">
              <ng-container *ngFor="let poi of markerGeofenceList; let i = index">
                <agm-circle *ngIf="
                  circularGeofence &&
                  poi.geofence?.type &&
                  poi.geofence.type === 'Point'
                " [latitude]="poi.geofence.coordinates[0]" [longitude]="poi.geofence.coordinates[1]"
                  [radius]="poi.geofence.radius" [fillColor]="'red'" [circleDraggable]="false" [editable]="false">
                </agm-circle>

                <agm-polygon *ngIf="
                  polygonGeofence &&
                  poi.geofence?.type &&
                  poi.geofence.type === 'Feature'
                " [visible]="true" [paths]="poi.geofence.geometry.coordinates" [fillColor]="'red'" [clickable]="true">
                </agm-polygon>
              </ng-container>
              <ng-container *ngFor="let poi of markerGeofenceList; let i = index">
                <agm-circle *ngIf="
                  isGeofenceVisiable &&
                  poi.geofence?.type &&
                  poi.geofence.type === 'Point'
                " [latitude]="poi.geofence.coordinates[0]" [longitude]="poi.geofence.coordinates[1]"
                  [radius]="poi.geofence.radius" [fillColor]="'red'" [circleDraggable]="false" [editable]="false">
                </agm-circle>

                <agm-polygon *ngIf="
                  isGeofenceVisiable &&
                  poi.geofence?.type &&
                  poi.geofence.type === 'Feature'
                " [visible]="true" [paths]="poi.geofence.geometry.coordinates" [fillColor]="'red'" [clickable]="true">
                </agm-polygon>
              </ng-container>
            </agm-map>
          </ng-container> -->
        </div>
        <!-- TOP PLAYBACK BUTTTON -->

        <!-- BOTTOM RIGHT ICONS -->

        <div class="btn-group-vertical btn-vertical-bar-map" [class.right-3-vh]="!rightPanelExpand"
          [class.right-29-vh]="rightPanelExpand" [class.right-73-vh]="isDriverPanelVisible"
          [class.right-3-vh]="!isDriverPanelVisible" role="group" aria-label="Basic example">
          <button *ngIf="back_button" matTooltip="Back" type="button" class="btn mapbtns"
            style="margin-top: 1vh; border-radius: 5px; padding-bottom: 0" (click)="closeHistorytrack()">
            <span class="material-icons" style="color: #fff"> arrow_back </span>
          </button>
          <button *ngIf="rightPanelExpand && selectedDevice?.deviceId && false" matTooltip="Collapse" type="button"
            class="btn mapbtns" style="margin-top: 1vh; border-radius: 5px; padding-bottom: 0"
            (click)="collapseRightPanel()">
            <span class="d-flex align-items-center justify-content-center fw-bold font-size-18 mb-1"
              style="color: #fff">
              >>
            </span>
          </button>
          <button *ngIf="!isDriverPanelVisible" matTooltip="Expand Driver Panel" type="button" class="btn mapbtns"
            style="
            margin-top: 1vh;
            border-radius: 5px;
            padding-bottom: 0;
            cursor: pointer;
          " (click)="handleButtonClick()">
            <span class="d-flex align-items-center justify-content-center fw-bold font-size-18 mb-1"
              style="color: #fff; cursor: pointer">
              << </span>
          </button>
          <!-- <button *ngIf="false" matTooltip="Expand" type="button" class="btn mapbtns"
            style="margin-top: 1vh; border-radius: 5px; padding-bottom: 0" (click)="expandRightPanel()">
            <span class="d-flex align-items-center justify-content-center fw-bold font-size-18 mb-1"
              style="color: #fff">
              >>
            </span>
          </button> -->

        

          <!-- <button *ngIf="oneVehicle" type="button" [class.active]="isDataPointsVisiable" title="Data Points"
            class="btn mapbtns" style="margin-top: 1vh; border-radius: 5px" (click)="dataPointsToggle()">
            <img src="/assets/icons/tracking.png" alt="mapicon" />
          </button> -->

        </div>
        <!-- Right vertical bar panel -->
        <div class="btn-vertical-bar-right-panel scroller scroller-right-panel"
          (scroll)="onRigthSidePanelScroll($event)" [class.d-none]="!rightPanelExpand" *ngIf="false">
          <div class="card shadow-set">
            <div class="card-body p-1 w-170">
              <div class="font-size-12 fw-bold">
                {{ selectedDevice?.name }}
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <img src="/assets/images/{{
                  getDeviceImage(selectedDevice?.category)
                }}" style="height: 100px" alt="" />
              </div>
              <div class="bg-light d-flex align-items-center justify-content-between fw-bold font-size-10 p-1">
                <span class="p-1 text-white" [class.running-bg-color]="selectedDevice?.status == 'RUNNING'"
                  [class.stopped-bg-color]="selectedDevice?.status == 'STOPPED'"
                  [class.idle-bg-color]="selectedDevice?.status == 'IDLE'" [class.not-reporting-bg-color]="
                  selectedDevice?.status == 'NOT_REPORTING' ||
                  selectedDevice?.status == 'EXPIRED' ||
                  selectedDevice?.status == 'NO_DATA' ||
                  selectedDevice?.status == 'TOWWED'
                ">{{
                  selectedDevice?.status == "NOT_REPORTING"
                  ? "Not Reporting"
                  : ((selectedDevice?.status == "NO_DATA"
                  ? "No
                  Data"
                  : selectedDevice?.status
                  ) | titlecase)
                  }}</span>
                <span>{{
                  selectedDevice.statusLastUpdated | convert : "hh:mm:ss"
                  }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-center p-2">
                <span class="bg-light p-1 border-end font-size-12" *ngFor="let o of selectedDevice?.odometerArray">{{ o
                  }}</span>
      
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <span class="font-size-12">Driver</span>
                <span class="font-size-12" *ngIf="driverDetails.length > 0 && driverDetails[0].name">{{
                  driverDetails[0].name }}</span>
                <span class="font-size-12" *ngIf="driverDetails.length == 0">NA</span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <span class="font-size-12">Mobile</span>
                <span class="font-size-12" *ngIf="
                  driverDetails.length > 0 && driverDetails[0].attributes?.phone
                ">{{ driverDetails[0].attributes?.phone }}</span>
                <span class="font-size-12" *ngIf="driverDetails.length == 0">NA</span>
              </div>
              <div class="d-flex align-items-center justify-content-around mt-2 bg-light">
                <span><i class="bi bi-snow" [class.text-secondary]="
                    selectedDevice?.attributes?.ac == undefined
                  " [class.text-danger]="selectedDevice?.attributes?.ac == false"
                    [class.text-success]="selectedDevice?.attributes?.ac == true"></i>
                </span>
                <span><i class="bi bi-key-fill" [class.text-secondary]="
                    selectedDevice?.attributes?.ignition == undefined
                  " [class.text-danger]="
                    selectedDevice?.attributes?.ignition == false
                  " [class.text-success]="
                    selectedDevice?.attributes?.ignition == true
                  "></i></span>
                <span><i class="bi" [class.bi-battery]="
                    selectedDevice?.attributes?.batteryLevel == undefined ||
                    selectedDevice?.attributes?.batteryLevel < 30
                  " [class.text-danger]="
                    selectedDevice?.attributes?.batteryLevel == undefined ||
                    selectedDevice?.attributes?.batteryLevel < 30
                  " [class.bi-battery-half]="
                    selectedDevice?.attributes?.batteryLevel &&
                    selectedDevice?.attributes?.batteryLevel > 30 &&
                    selectedDevice?.attributes?.batteryLevel < 80
                  " [class.text-danger]="
                    selectedDevice?.attributes?.batteryLevel &&
                    selectedDevice?.attributes?.batteryLevel > 30 &&
                    selectedDevice?.attributes?.batteryLevel < 80
                  " [class.bi-battery-full]="
                    selectedDevice?.attributes?.batteryLevel &&
                    selectedDevice?.attributes?.batteryLevel > 80
                  " [class.text-success]="
                    selectedDevice?.attributes?.batteryLevel &&
                    selectedDevice?.attributes?.batteryLevel > 80
                  "></i></span>
                <span><i class="bi bi-power" [class.text-secondary]="
                    selectedDevice?.attributes?.charge == undefined
                  " [class.text-danger]="
                    selectedDevice?.attributes?.charge == false
                  " [class.text-success]="
                    selectedDevice?.attributes?.charge == true
                  "></i></span>
                <span><i class="bi bi-lock-fill" style="color: gray" [class.text-secondary]="
                    selectedDevice?.attributes?.blocked == undefined
                  " [class.text-danger]="
                    selectedDevice?.attributes?.blocked == false
                  " [class.text-success]="
                    selectedDevice?.attributes?.blocked == true
                  "></i></span>
              </div>
            </div>
          </div>
          <div class="card shadow-set mt-3">
            <div class="card-body p-1 w-170">
              <div class="font-size-12 fw-bold">Location</div>
              <div class="d-flex align-items-center font-size-12 mt-2 mb-2">
                {{ selectedDevice?.address }}
              </div>
              <div class="d-flex align-items-center font-size-10 p-1">
                <a href="https://maps.google.com/maps?q={{
                  selectedDevice?.latitude
                }},{{ selectedDevice.longitude }}&amp;t=m" target="_blank">{{ selectedDevice?.latitude | number :
                  "1.0-6" }} °,
                  {{ selectedDevice.longitude | number : "1.0-6" }} °</a>
              </div>
            </div>
          </div>
          <div class="card shadow-set mt-3">
            <div class="card-body p-1 w-170">
              <div class="font-size-12 fw-bold">Today Activity</div>
              <div class="d-flex align-items-center font-size-12 mt-2 mb-2">
                <div class="d-flex align-items-center justify-content-around w-100">
                  <span><img src="../../../assets/icons/trip_start_flag.svg" alt="" /></span>
                  <span class="text-center" style="border-bottom: 2px dashed black; width: 100%">{{
                    selectedDevice?.attributes?.distanceForday | meterTokm }}
                    {{ "km" | translate }}</span>
                  <span><img src="../../../assets/icons/trip_end_flag.svg" alt="" /></span>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>Running</span>
                <span class="running-text-color">{{
                  selectedDevice?.attributes?.hoursForday | convert : "hh:mm"
                  }}
                  hrs</span>
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>Idle</span>
                <span class="idle-text-color">{{
                  selectedDevice?.attributes?.idlehoursForday
                  | convert : "hh:mm"
                  }}
                  hrs</span>
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>Stop</span>
                <span class="stopped-text-color">{{
                  selectedDevice?.attributes?.stophoursForday
                  | convert : "hh:mm"
                  }}
                  hrs</span>
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>Inactive</span>
                <span class="not-reporting-text-color">00:00 hrs</span>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-center mt-3">
            <div class="avg-speed d-flex align-items-center justify-content-center">
              <div class="font-size-10 text-center">
                {{ "Avg Speed" | translate }}
              </div>
              <div class="font-size-10 text-center">
                {{
                onSpeedgenerateBasedOnuserPreference(
                vehicleDetailsReport?.summary?.averageSpeed
                ) | number : "1.0-2"
                }}
                {{ userData.attributes.speedUnit | translate }}
              </div>
            </div>
            <div class="max-speed d-flex align-items-center justify-content-center ms-1">
              <div class="font-size-10 text-center">
                {{ "Max Speed" | translate }}
              </div>
              <div class="font-size-10 text-center">
                {{
                onSpeedgenerateBasedOnuserPreference(
                vehicleDetailsReport?.summary?.maxSpeed
                ) | number : "1.0-2"
                }}
                {{ userData.attributes.speedUnit | translate }}
              </div>
            </div>
          </div>

          <div class="card shadow-set mt-3" *ngIf="selectedDevice?.attributes?.fuel">
            <div class="card-body p-1 w-170">
              <div class="font-size-12 fw-bold">Fuel</div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <div class="gauge">
                  <div class="gauge__body">
                    <div class="gaugeg__fill" [ngStyle]="
                      getFuelFill(
                        selectedDevice?.attributes?.fuel
                          ? selectedDevice?.attributes?.fuel
                          : 0
                      )
                    "></div>
                    <div class="gauge__cover">
                      {{ selectedDevice?.attributes?.fuel }}
                    </div>
                  </div>
                </div>
                <!-- <div class="progress">
                <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.vh]="selectedDevice?.attributes?.fuel?selectedDevice?.attributes?.fuel:0" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
              </div> -->
              </div>
              <div class="d-flex align-items-center justify-content-around font-size-10 mt-1">
                <span class="stopped-text-color">Empty</span>
                <span>{{ selectedDevice?.attributes?.fuel }} ltr</span>
                <span class="running-text-color">Full</span>
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>Refill</span>
                <span class="running-text-color">0 ltr</span>
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>Drain</span>
                <span class="idle-text-color">0 ltr</span>
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>Consumption</span>
                <span>0 ltr</span>
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>Waste</span>
                <span class="stopped-text-color">0 ltr</span>
              </div>
              <div class="d-flex align-items-center justify-content-end font-size-10 mt-1">
                <span>Due to 0 hrs idling</span>
              </div>
            </div>
          </div>
          <div class="card shadow-set mt-3" *ngIf="false">
            <div class="card-body p-1 w-170">
              <div class="font-size-12 fw-bold">Driving Behaviour</div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>Harsh Braking</span>
                <span>No</span>
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>Sharp Cornering</span>
                <span>No</span>
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>Harsh Acceleration</span>
                <span>No</span>
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>Over Speeding</span>
                <span>No</span>
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>Seat Belt</span>
                <span>NA</span>
              </div>
            </div>
          </div>
          <div class="card shadow-set mt-3">
            <div class="card-body p-1 w-170">
              <div class="font-size-12 fw-bold">
                {{ "Network Parameter" | translate }}
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>{{ "Radio Type" | translate }}</span>
                <span *ngIf="selectedDeviceNetworkData">{{
                  selectedDeviceNetworkData?.radioType
                  }}</span>
                <span *ngIf="!selectedDeviceNetworkData">NA</span>
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>{{ "Cell Id" | translate }}</span>
                <span *ngIf="selectedDeviceNetworkData">{{
                  selectedDeviceNetworkData?.cellTowers[0]?.cellId
                  }}</span>
                <span *ngIf="!selectedDeviceNetworkData">NA</span>
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>{{ "Area Code" | translate }}</span>
                <span *ngIf="selectedDeviceNetworkData">{{
                  selectedDeviceNetworkData?.cellTowers[0]?.locationAreaCode
                  }}</span>
                <span *ngIf="!selectedDeviceNetworkData">NA</span>
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>{{ "Country Code" | translate }}</span>
                <span *ngIf="selectedDeviceNetworkData">{{
                  selectedDeviceNetworkData?.cellTowers[0]?.mobileCountryCode
                  }}</span>
                <span *ngIf="!selectedDeviceNetworkData">NA</span>
              </div>
              <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                <span>{{ "Network Code" | translate }}</span>
                <span *ngIf="selectedDeviceNetworkData">{{
                  selectedDeviceNetworkData?.cellTowers[0]?.mobileNetworkCode
                  }}</span>
                <span *ngIf="!selectedDeviceNetworkData">NA</span>
              </div>
            </div>
          </div>
          <div class="card shadow-set mt-3">
            <div class="card-body p-1 w-170">
              <!-- <iframe src="https://www.google.com/maps?cbll=26.5562333,80.5288433&layer=c"  width="600"
            frameborder="0" style="border:0"
            allowfullscreen>
              </iframe> -->
              <agm-map style="height: 20vh" [latitude]="selectedDevice?.latitude" [longitude]="selectedDevice.longitude"
                [zoom]="10.5" [mapTypeId]="'hybrid'"></agm-map>
            </div>
          </div>
        </div>

        <!-- Right driver panel -->
        <div class="device-list-setting driver-list-setting" *ngIf="isDriverPanelVisible">
          <div class="row w-100">
            <div class="col-12 card main_card">
              <div style="position: relative">
                <div title="Collapse Driver list" class="collapse-drivers-panel" style="cursor: pointer"
                  (click)="collapseDriverPanel()">
                  >
                </div>
              </div>
              <div class="row d-flex align-items-center justify-content-center">
                <div class="col-12 bg-light m-0 ps-0" style="width: 95%">
                  <div class="col-2 d-flex align-items-center justify-content-center font-size-12 device-tabs"
                    title="{{ 'Driver' | translate }}" [class.select]="isDriversTab"
                    (click)="driverPanelTabChange('driver')">
                    {{ "Driver" | translate }}
                  </div>
                  <!-- <div class="col-2 d-flex align-items-center justify-content-center font-size-12 device-tabs" 
                [class.select]="isEventsTab"
                title="{{'Events'|translate}}" (click)="tabChange('event')">
                  {{'Events'|translate}}
                </div> -->
                  <!-- <div class="col-2 d-flex align-items-center justify-content-center font-size-12 device-tabs" [class.select]="isHistoryTab"
                title="{{'History'|translate}}" (click)="tabChange('history')">
                  {{'History'|translate}}
                </div> -->
                  <!-- <div class="col-2 d-flex align-items-center justify-content-center font-size-12 device-tabs" [class.select]="isGeofenceTab"
                title="{{'Geofence'|translate}}" (click)="tabChange('Geofence')">
                  {{'Geofence'|translate}}
                </div>                 -->
                </div>
                <ng-container *ngIf="isDriversTab">
                  <div class="col-12 mt-2">
                    <div class="input-group mb-3 ht-40-px">
                      <input type="text" class="form-control border-end-0 ht-40-px" placeholder="Search Driver"
                        [(ngModel)]="searchDriver" />
                      <span class="input-group-text bg-white border-start-0 ht-40-px" id="basic-addon2"><i
                          class="bi bi-search fw-bold" style="color: #f84a67"></i></span>
                    </div>
                  </div>
                  <div class="col-12 tasks-tabs2 mb-3 d-flex flex-wrap justify-content-between">
                    <span class="ms-1 me-2 px-2 mb-2" [class.selected]="isDriverFree" role="button" style="cursor: pointer"
                      (click)="driverTabChange('free')">
                      <span class="badge bg-secondary p-2">{{ getRecordCount("AVAILABLE") }}</span>
                      Available
                    </span>
                    <span class="me-3 px-2 mb-2" [class.selected]="isDriverBusy" role="button" style="cursor: pointer"
                      (click)="driverTabChange('busy')">
                      <span class="badge bg-secondary p-2">{{ getRecordCount("BUSY") }}</span>
                      Busy
                    </span>
                    <span class="me-1 px-2 mb-2" [class.selected]="isDriverInactive" role="button" style="cursor: pointer"
                      (click)="driverTabChange('inactive')">
                      <span class="badge bg-secondary p-2">{{ getRecordCount("INACTIVE") }}</span>
                      Inactive
                    </span>
                  </div>
                  
                  <ng-container *ngIf="isDriverFree">
                    <div class="scroller" (scroll)="(driversFreeList.length)"
                      style="width: 97%; border-top: 5px solid #dee2e6 !important">
                      <ng-container *ngFor="let d of driversFreeList">
                        <div class="row pb-2 mt-2" style="border-bottom: 5px solid #dee2e6 !important" 
                        *ngIf="d.userattributes?.did.indexOf(searchDriver) >= 0 ||
                        d.userattributes?.cabno.indexOf(searchDriver) >= 0||
                         d.userattributes?.cabno.includes(searchDriver)
                        ">
                          <div class="col-1 d-flex align-items-center">
                            <i class="bi bi-person-circle font-size-26"></i>
                            <i class="bi bi-circle-fill" style="
                              font-size: 12px;
                              position: relative;
                              left: -9px;
                              bottom: -8px;
                            " [style.color]="
                              d.userattributes?.status == 'offline'
                                ? '#ff0000'  
                                : '#05e705'
                            "></i>
                          </div>
                          <div class="col-9" style="width: 70%">
                            <div class="row">
                              <div class="col-12 fw-bold" style="display: flex; align-items: center">
                                {{ d.userattributes?.cabno }}
                              </div>
                              <div class="col-12 text-secondary" style="display: flex; align-items: center">
                                <span *ngIf="d.phone" style="display: flex; align-items: center">
                                  {{ d.phone }}
                                </span>
                                <div class="callingIcon" *ngIf="d.phone" (click)="callToDriverRequest(d)">
                                  <i class="fa-solid fa-phone cursorPointer p-1" style="color: black"
                                    matTooltip="Call to Driver" (click)="callToDriverRequest(d)"></i>
                                </div>
                              </div>
                              <div class="col-12 text-secondary">
                                {{ d?.hoursAgo }}
                              </div>
                            </div>
                          </div>
                          <div class="col-2 d-flex align-items-center">
                            <div class="flex-column d-flex align-items-center justify-content-center me-1">
                              <span class="task-count">
                                {{ d.taskcount }}
                              </span>
                              Task
                            </div>
                            <div>
                              <i class="bi bi-caret-right-fill cursorPointer" (click)="openDriverTaskDetails(d)"></i>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="isDriverBusy">
                    <div class="scroller" (scroll)="(driverBusyList.length)"
                      style="width: 97%; border-top: 5px solid #dee2e6 !important">
                      <ng-container *ngFor="let d of driversBusyList">
                        <div class="row pb-2 mt-2" style="border-bottom: 5px solid #dee2e6 !important" *ngIf="
                          d.userattributes?.did.indexOf(searchDriver) >= 0 ||
                          d.userattributes?.cabno.indexOf(searchDriver) >= 0 ||
                          d.userattributes?.cabno.includes(searchDriver)
                        ">
                          <div class="col-1 d-flex align-items-center">
                            <i class="bi bi-person-circle font-size-26"></i>
                            <i class="bi bi-circle-fill" style="
                              font-size: 12px;
                              position: relative;
                              left: -9px;
                              bottom: -8px;
                            " [style.color]="
                              d.userattributes?.status == 'offline'
                                ? '#ff0000'
                                : '#FFA500'
                            "></i>
                          </div>
                          <div class="col-9" style="width: 70%">
                            <div class="row">
                              <div class="col-12 fw-bold">
                                {{ d.userattributes?.cabno }}&nbsp;
                              </div>
                              <div class="col-12 text-secondary" style="display: flex; align-items: center">
                                <span *ngIf="d.phone" style="display: flex; align-items: center">
                                  {{ d.phone }}
                                </span>
                                <div *ngIf="d.phone" 
                                (click)="callToDriverRequest(d)"
                                style="
                                  border-radius: 50%;
                                  width: 24px;
                                  height: 24px;
                                  background-color: rgb(5, 237, 5);
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;
                                  margin-left: 5px;
                                ">
                                  <i class="fa-solid fa-phone cursorPointer" 
                                  (click)="callToDriverRequest(d)"
                                  style="color: black"
                                    matTooltip="Call to Driver"></i>
                                </div>
                              </div>
                              <div class="col-12 text-secondary">
                                {{ d?.hoursAgo }}
                              </div>
                            </div>
                          </div>
                          <div class="col-2 d-flex align-items-center">
                            <div class="flex-column d-flex align-items-center justify-content-center me-1">
                              <span class="task-count">
                                {{ d.taskcount }}
                              </span>
                              Task
                            </div>
                            <div>
                              <i class="bi bi-caret-right-fill cursorPointer" (click)="openDriverTaskDetails(d)"></i>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="isDriverInactive">
                    <div class="scroller" (scroll)="(driversInactiveList.length)"
                      style="width: 97%; border-top: 5px solid #dee2e6 !important">
                      <ng-container *ngFor="let d of driversInactiveList">
                        <div class="row pb-2 mt-2" style="border-bottom: 5px solid #dee2e6 !important" *ngIf="
                          d.userattributes?.did.indexOf(searchDriver) >= 0 ||
                          d.userattributes?.cabno.indexOf(searchDriver) >= 0 ||
                          d.userattributes?.cabno.includes(searchDriver)
                        ">
                          <div class="col-1 d-flex align-items-center">
                            <i class="bi bi-person-circle font-size-26"></i>
                            <i class="bi bi-circle-fill" style="
                              font-size: 12px;
                              position: relative;
                              left: -9px;
                              bottom: -8px;
                            " [style.color]="
                              d.userattributes?.status == 'offline'
                                ? '#ff0000'
                                : '#05e705'
                            "></i>
                          </div>
                          <div class="col-9" style="width: 70%">
                            <div class="row">
                              <div class="col-12 fw-bold" style="display: flex; align-items: center">
                                {{ d.userattributes?.cabno }}
                              </div>

                              <div class="col-12 text-secondary" style="display: flex; align-items: center">
                                <span *ngIf="d.phone" style="display: flex; align-items: center">
                                  {{ d.phone }}
                                </span>
                                <div *ngIf="d.phone"
                                (click)="callToDriverRequest(d)"
                                style="
                                  border-radius: 50%;
                                  width: 24px;
                                  height: 24px;
                                  background-color: rgb(5, 237, 5);
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;
                                  margin-left: 5px;
                                ">
                                  <i class="fa-solid fa-phone cursorPointer"
                                  (click)="callToDriverRequest(d)"
                                  style="color: black"
                                    matTooltip="Call to Driver"></i>
                                </div>
                              </div>

                              <div class="col-12 text-secondary">
                                {{ d?.hoursAgo }}
                              </div>
                            </div>
                          </div>
                          <div class="col-2 d-flex align-items-center">
                            <div class="flex-column d-flex align-items-center justify-content-center me-1">
                              <span class="task-count">
                                {{ d.taskcount }}
                              </span>
                              Task
                            </div>
                            <div>
                              <i class="bi bi-caret-right-fill cursorPointer" (click)="openDriverTaskDetails(d)"></i>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!isDriversTab && isDriversTaskDetails">
                  <div class="col-12 bg-light m-0 ps-3 pb-2 d-flex justify-content-between" style="width: 95%">
                    <span>
                      Driver /<strong>#{{ cabno }}</strong>
                    </span>
                    <span>
                      <i class="bi bi-x-circle-fill" (click)="closeDriverDetails()"></i>
                    </span>
                  </div>
                  <div class="col-12 mb-1">
                    <div class="row">
                      <div class="row mx-1">
                        <div class="col-6 tasks-tabs d-flex justify-content-center align-items-center">
                          <span class="me-3" style="cursor: pointer" [class.selected]="isTaskDetailDetail1"
                            (click)="tasksDetailsTabsChange2('details')">Details</span>
                        </div>
                        <div class="col-6 tasks-tabs d-flex justify-content-center align-items-center">
                          <span class="me-3" style="cursor: pointer" [class.selected]="isTaskTimeline"
                            (click)="tasksDetailsTabsChange2('timeline')">Timeline</span>
                        </div>
                      </div>
                      <div *ngIf="isDriversDetailTaskDetails">
                        <div class="pt-3 mt-1" style="
                          width: 99%;
                          border-top: 5px solid #dee2e6 !important;
                        ">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-5">
                                <span class="font-15px-title text-dark" style="color: #000">Name</span>
                              </div>
                              <div class="col-7">
                                <span class="font-15px-info">
                                  <span class="font-15px-title">:</span>{{ driverFullName ? driverFullName : "NA" }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="row">
                              <div class="col-5">
                                <span class="font-15px-title">Cab Number</span>
                              </div>
                              <div class="col-7">
                                <span class="font-15px-info">
                                  <span class="font-15px-title">:</span>{{
                                  this.driverDetailsOnSelect.userattributes
                                  .cabno
                                  ? this.driverDetailsOnSelect.userattributes
                                  .cabno
                                  : "NA"
                                  }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="row">
                              <div class="col-5">
                                <span class="font-15px-title">Contact Number</span>
                              </div>
                              <div class="col-7">
                                <span class="font-15px-info">
                                  <span class="font-15px-title">:</span>
                                  {{
                                  this.driverDetailsOnSelect.phone
                                  ? this.driverDetailsOnSelect.phone
                                  : "NA"
                                  }}</span>
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div class="row">
                              <div class="col-5">
                                <span class="font-15px-title">Last location</span>
                              </div>
                              <div class="col-7">
                                <span class="font-15px-info">
                                  <span class="font-15px-title">:</span>
                                  {{ this.lastLocation }}</span>
                              </div>
                            </div>
                          </div>

                          <div class="col-12" *ngIf="this.isDriverDetails">
                            <div class="row">
                              <div class="col-5">
                                <span class="font-15px-title">Vehicle Number</span>
                              </div>
                              <div class="col-7">
                                <span class="font-15px-info">
                                  <span class="font-15px-title">:</span>{{
                                  this.driverDetailsOnSelect.userattributes
                                  .vehicleno
                                  ? this.driverDetailsOnSelect.userattributes
                                  .vehicleno
                                  : "NA"
                                  }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12" *ngIf="this.isDriverDetails">
                            <div class="row">
                              <div class="col-5">
                                <span class="font-15px-title">Home Location</span>
                              </div>
                              <div class="col-7">
                                <span class="font-15px-info">
                                  <span class="font-15px-title">:</span>{{ "NA" }}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="col-12" *ngIf="this.isDriverDetails">
                            <div class="row">
                              <div class="col-5">
                                <span class="font-15px-title">Device</span>
                              </div>
                              <div class="col-7">
                                <span class="font-15px-info">
                                  <span class="font-15px-title">:</span>{{ "NA" }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12" *ngIf="this.isDriverDetails">
                            <div class="row">
                              <div class="col-5">
                                <span class="font-15px-title">Battery</span>
                              </div>
                              <div class="col-7">
                                <span class="font-15px-info">
                                  <span class="font-15px-title">:</span>{{ "NA" }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 mb-2" *ngIf="this.isDriverDetails">
                            <div class="row">
                              <div class="col-5">
                                <span class="font-15px-title">Version</span>
                              </div>
                              <div class="col-7">
                                <span class="font-15px-info">
                                  <span class="font-15px-title">:</span>{{ "NA" }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 mb-2" *ngIf="this.isDriverDetails">
                            <div class="row">
                              <div class="col-5">
                                <span class="font-15px-title">Rating</span>
                              </div>
                              <div class="col-7">
                                <span class="font-15px-info">
                                  <span class="font-15px-title">:</span>{{ "NA" }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="border-bottom mb-2" style="width: 95%"></div>
                        </div>
                        <div class="d-flex">
                          <div class="justify-content-start">
                            <span>{{ this.totalResult }} Tasks ({{
                              remainingTasks
                              }}
                              Tasks left)</span>
                          </div>

                          <div class="justify-content-end" style="margin-left: auto">
                            <span style="color: #72befc" class="more-info pointer" *ngIf="isMoreInfo"
                              (click)="driversTaskDetailsTabsChange('isMoreInfo')">
                              More Info
                            </span>
                            <span style="color: #72befc" class="more-info pointer" *ngIf="isLessInfo"
                              (click)="driversTaskDetailsTabsChange('isLessInfo')">
                              Less Info
                            </span>
                          </div>
                        </div>
                      </div>
                      <ng-container *ngIf="isTaskHistory">
                        <div class="pt-3 p-3">
                          <div class="col-12">
                            <div class="scroller scrollerTaskHistoryDetails" (scroll)="(taskHistoryDetails2.length)">
                              <div class="row border-bottom pb-2" *ngFor="
                                let tdh of taskHistoryDetails2.slice().reverse()
                              ">
                                <div class="col-2 d-flex text-end justify-content-center flex-column"
                                  *ngIf="tdh.status !== 'unassigned'">
                                  {{
                                  tdh.createddate | date : "dd MMM yyyy hh:mm"
                                  }}
                                </div>
                                <div class="col-1 d-flex align-items-center flex-column ps-0"
                                  *ngIf="tdh.status !== 'unassigned'">
                                  <span>
                                    <i class="bi bi-circle-fill selected"></i>
                                  </span>
                                  <div class="connected-line-dotted selected" *ngIf="tdh.status !== 'unassigned'"></div>
                                  <!-- <span>
                        <i class="bi bi-circle"></i>
                      </span> -->
                                </div>
                                <div class="col-9" *ngIf="tdh.status !== 'unassigned'">
                                  <div class="row bg-light w-100 mt-2 hover-enlarge">
                                    <div class="col-12 py-2 d-flex">
                                      <div class="d-flex align-items-center" style="width: 50%">
                                        <span class="border p-1 text-center" [ngClass]="
                                          getBgColorBasedOnTaskStatus(
                                            tdh.status
                                          )
                                        " style="
                                          width: 100%;
                                          font-weight: 500;
                                          border-radius: 5px;
                                          line-height: 1.2;
                                        ">{{ tdh.status | titlecase }}</span>
                                      </div>
                                      <div class="text-center mt-1">
                                        by {{ tdh.username }}
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <span style="cursor: pointer" id="locatedMap"
                                        (click)="openLocationModal(tdh)">Located on map</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isTaskTimeline">
                        <div class="pt-1">
                          <div class="scroller scrollerTaskTimelineDetails" style="
                            width: 100%;
                            border-top: 5px solid #dee2e6 !important;
                          " (scroll)="(timelinetaskList.length)">
                            <div class="p-2 ms-2">
                              <div class="row heading text-center" style="min-height: 1.8rem; align-items: center">
                                <div class="col-4 nopad">
                                  <span>Moving</span>
                                </div>
                                <div class="col-4 nopad">
                                  <span>Stoppage</span>
                                </div>
                                <div class="col-4 nopad">
                                  <span>Travelled</span>
                                </div>
                              </div>
                              <div class="row text-center border border-top-0">
                                <div class="col-4 nopad">
                                  <span>{{
                                    this.movingTime
                                    ? (this.movingTime | convert : "hh:mm")
                                    : "00:00"
                                    }}</span>
                                </div>
                                <div class="col-4 nopad">
                                  <span>{{
                                    this.stoppedTime
                                    ? (this.stoppedTime | convert : "hh:mm")
                                    : "00:00"
                                    }}</span>
                                </div>
                                <div class="col-4 nopad">
                                  <span>{{
                                    this.travelledDistance
                                    ? (this.travelledDistance | meterTokm)
                                    : "0"
                                    }}kms</span>
                                </div>
                              </div>
                            </div>

                            <div class="col-12">
                              <div class="row border-bottom pb-2" *ngFor="
                                let tdh of timelinetaskList.slice().reverse()
                              ">
                                <div class="col-2 d-flex text-end justify-content-center flex-column"
                                  *ngIf="tdh.status !== 'unassigned'">
                                  <span style="font-size: 14px">
                                    {{
                                    tdh.createddate | date : "dd MMM yyyy hh:mm"
                                    }}</span>
                                </div>
                                <div class="col-1 d-flex align-items-center flex-column ps-0"
                                  *ngIf="tdh.status !== 'unassigned'">
                                  <span>
                                    <i class="bi bi-circle-fill selected"></i>
                                  </span>
                                  <div class="connected-line-dotted selected" *ngIf="tdh.status !== 'unassigned'"></div>
                                  <!-- <span>
                        <i class="bi bi-circle"></i>
                          </span> -->
                                </div>
                                <div class="col-9" *ngIf="tdh.status !== 'unassigned'">
                                  <div class="row bg-light w-100 mt-2 hover-enlarge">
                                    <div class="col-12 py-2 d-flex">
                                      <div class="d-flex align-items-center" style="width: 50%">
                                        <span class="border p-1 text-center" [ngClass]="
                                          getBgColorBasedOnTaskStatus(
                                            tdh.status
                                          )
                                        " style="
                                          width: 100%;
                                          font-weight: 400;
                                          border-radius: 4px;
                                          line-height: 0.8;
                                          font-size: 14px;
                                        ">{{ tdh.status | titlecase }}</span>
                                      </div>
                                      <div class="text-center mt-1" *ngIf="tdh.status == 'assigned'" style="
                                        font-size: 10px;
                                        font-weight: 500;
                                        color: black;
                                      ">
                                        Assigned {{ driverDid }} to Task -
                                        {{ tdh.userid }}
                                      </div>
                                    </div>
                                    <div class="col-8">
                                      <span style="cursor: pointer" id="locatedMap"
                                        (click)="openLocationModal(tdh)">Located on map</span>
                                    </div>
                                    <div class="col-4">
                                      <span style="
                                        color: black;
                                        font-weight: 600;
                                        font-size: 1rem;
                                      ">#{{ tdh.taskid }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="pt-1 d-flex align-items-center justify-content-center"
                            *ngIf="timelinetaskList.length === 0" style="height: 20vh">
                            <div>
                              <span class="idleTask">No timeline available for the day</span>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <ng-container *ngIf="isDriversDetailTodaysTaskDetails">
                    <div class="scrollerTaskDetails" (scroll)="(taskListData.length)"
                      style="width: 95%; border-top: 5px solid #dee2e6 !important">
                      <ng-container *ngFor="let dtd of taskListData.slice().reverse()">
                        <div class="row mb-1">
                          <div class="col-md-1 d-flex align-items-center flex-column mx-1">
                            <div class="circle-pickup mt-1" *ngIf="dtd.type == 'pickup'">
                              <p>P</p>
                            </div>
                            <div class="circle-drop1 mt-1" *ngIf="dtd.type == 'drop'">
                              <p>D</p>
                            </div>
                            <div class="connected-line-dotted1 selected1"></div>
                            <span class="p-0">
                              <i class="bi bi-circle-fill selected1 smaller-circle"></i>
                            </span>
                          </div>
                          <div class="col-md-7" style="padding-left: 0">
                            <span class="timing">{{
                              dtd.expectedstarttime | date : "dd MMM yyyy hh:mm"
                              }}</span>
                            <div class="row">
                              <div class="col-md-12">
                                <span class="address" *ngIf="dtd.type === 'pickup'">
                                  {{ getSlicedAddress(dtd.pickuplocation) }}
                                  </span>
                                  <span class="address" *ngIf="dtd.type === 'drop'">
                                  {{ getSlicedAddress(dtd.droplocation) }}
                                  </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3" style="width: 30%; padding: 0">
                            <div class="d-flex align-items-center justify-content-center" style="height: 100%">
                              <span style="
                                cursor: pointer;
                                display: inline-block;
                                text-align: center;
                                width: 100%;
                                font-weight: 600;
                              " class="badge" (click)="getHistoryDetails(dtd)" [ngClass]="
                                getBgColorBasedOnTaskStatus(dtd.status)
                              ">{{ dtd.status | titlecase }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="border-bottom mb-2" style="width: 95%"></div>
                      </ng-container>
                    </div>
                    <div *ngIf="taskListData.length === 0" class="d-flex justify-content-center align-items-center"
                      style="height: 20vh">
                      <div>
                        <span>No task available for the day</span>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <!-- BOTTOM ICONS -->

        <div class="btn-group" role="group" aria-label="Basic example"
          style="position: absolute; bottom: 7vh; left: 4vh" [style.bottom.vh]="isHistoryTab ? 7 : 3">
          <button type="button" class="btn mapbtns" style="margin-left: 2vh; border-radius: 5px; padding: 0.5vh 0.8vh">
            <img src="/assets/icons/map8.png" alt="mapicon" />
          </button>
          <button type="button" class="btn mapbtns" style="margin-left: 2vh; border-radius: 5px; padding: 0.5vh 0.8vh">
            <img src="/assets/icons/map9.png" alt="mapicon" />
          </button>
          <button *ngIf="fullwidth" type="button" class="btn mapbtns"
            style="margin-left: 2vh; border-radius: 5px; padding: 0.5vh 0.8vh" (click)="getFullWidth()">
            <img src="/assets/icons/map10.png" alt="mapicon" />
          </button>
          <button matTooltip="Satelite View" (click)="changeMapTypeId()" type="button" class="btn mapbtns"
            [class.active]="mapTypeId == 'satellite'"
            style="margin-left: 2vh; border-radius: 5px; padding: 0.5vh 0.8vh">
            <img src="/assets/icons/map1.png" alt="mapicon" />
          </button>
        </div>
      </div>
    </div>
    <button *ngIf="!main_col" class="btn expand-tasks-panel mapbtns mt-7" matTooltip="Expand" type="button" style="
      margin-top: 4vh;
      border-radius: 5px;
      padding-bottom: 0;
      margin-left: 18px;
    " (click)="expandTaskPanel()">
      <span class="d-flex align-items-center justify-content-center fw-bold font-size-18 mb-1" style="color: #fff">
        >>
      </span>
    </button>
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="background-color: #324151; border-radius: 9px">
          <div class="modal-header" style="border: none !important">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ "Custom Tracking" | translate }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group pb-2">
                <label for="device_id" class="addlabels pb-2">{{ "Vehicle Name" | translate }} :</label>
                <input type="text" [(ngModel)]="device_id" name="device_id" class="form-control inputfields"
                  id="device_id" aria-describedby="device_id" disabled />
              </div>

              <div class="d-flex flex-row align-items-center py-3">
                <div class="col-6">
                  <div class="form-group">
                    <label for="location" class="addlabels pb-2">{{ "From" | translate }} :</label>
                    <input type="datetime-local" [(ngModel)]="fromdate" name="fromdate" id="meeting" />
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label for="location" class="addlabels pb-2">{{ "To" | translate }} :</label>
                    <input type="datetime-local" [(ngModel)]="todate" name="todate" id="meeting" />
                  </div>
                </div>
              </div>

              <button type="submit" class="showTrack" data-bs-dismiss="modal" aria-label="Close">
                {{ "Show Track" | translate }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid" *ngIf="bottomNav">
    <div class="row" style="margin-right: 0">
      <div class="navbar px-4" id="myNavbar" [class.taskHistory]="isTaskTab">
        <a class="bottom_navs" [class.history-tab-selected]="fuelsummary" (click)="onClickFuel()">{{ "Summary" |
          translate }}</a>
        <a class="bottom_navs" [class.history-tab-selected]="history" (click)="onClickHistory()">{{ "Logs" | translate
          }}
        </a>
        <a><img src="/assets/icons/doubledown.png" alt="doubledown" /></a>
        <a class="playbox" [class.taskHistory]="isTaskTab">
          <div class="card playcard pt-2" style="width: 71vh">
            <div class="row m-0">
              <div class="col-2">
                <img src="{{
                  playpause
                    ? '/assets/icons/pause.png'
                    : '/assets/icons/play.png'
                }}" alt="play" class="iconPlay" (click)="onPlayPause()" style="cursor: pointer" />
              </div>
              <div class="col-5 d-flex align-items-center">
                <mat-slider [min]="0" [max]="this.arrlength" [value]="values"
                  (change)="onSlideEvent($event)"></mat-slider>
              </div>
              <div class="col-5" style="justify-content: space-between; display: flex">
                <span class="playIcons" style="padding: 0.8vh 1.5vh" (click)="on1xSpeed()">{{ speedCount }}x</span>
                <img src="/assets/icons/play_rewind.png" alt="1x" class="playIcons" id="speed" (click)="onReset()" />
                <img src="/assets/icons/distance_play.png" alt="1x" class="playIcons" style="padding: 0.9vh 1.1vh" />
                <img src="/assets/icons/folder_play.png" alt="1x" class="playIcons" style="padding: 0.9vh 1.1vh" />
                <img src="/assets/icons/out_play.png" alt="1x" class="playIcons" style="padding: 0.9vh 1.1vh" />
              </div>
            </div>

            <div class="row m-0 py-3 playaddrow">
              <div class="col-8">
                <span class="mat_icon">
                  <img src="/assets/icons/address.png" alt="adressicon" style="height: 2.5vh" />
                  <span class="playaddress">{{
                    historyPath && historyPath.displayAddress
                    ? historyPath.displayAddress
                    : "NA"
                    }}</span>
                </span>
              </div>
              <div class="col-4">
                <span class="mat_icon">
                  <img src="/assets/icons/watch_play.png" alt="watch" />
                  <span class="playaddress">
                    {{
                    allLocationTrackData[values] &&
                    allLocationTrackData[values].deviceTime
                    ? (allLocationTrackData[values].deviceTime | dateFormat)
                    : ""
                    }}</span>
                </span>
              </div>
            </div>

            <div class="d-flex align-items-center justify-content-between text-center ps-2 pe-2 m-0">
              <div class="colplay">
                <span class="iconstext">
                  <img src="/assets/icons/battery_play.png" alt="battery" />
                  0v
                </span>
              </div>
              <div class="colplay">
                <span style="min-width: 100px" class="iconstext">
                  <img src="/assets/icons/speedometer.png" alt="battery" />
                  {{
                  allLocationTrackData[values] &&
                  allLocationTrackData[values].speed
                  ? (onSpeedgenerateBasedOnuserPreference(
                  allLocationTrackData[values].speed
                  ) | number : "1.0-2")
                  : "0"
                  }}
                  {{ userData.attributes.speedUnit | translate }}
                </span>
              </div>
              <div class="colplay">
                <span style="min-width: 100px" class="iconstext">
                  <img src="/assets/icons/watch_play.png" alt="battery" />
                  {{
                  vehicleDetailsReport.summary.engineHours | convert : "hh:mm"
                  }}
                </span>
              </div>
              <div class="colplay">
                <span class="iconstext">
                  <img src="/assets/icons/distance_meter.png" alt="battery" />
                  {{
                  onSpeedgenerateBasedOnuserPreference(
                  vehicleDetailsReport?.summary?.averageSpeed
                  ) | number : "1.0-2"
                  }}
                  {{ userData.attributes.speedUnit | translate }}
                </span>
              </div>
              <div class="colplay">
                <span class="iconstext">
                  <img src="/assets/icons/route_play.png" alt="battery" />
                  {{
                  vehicleDetailsReport.summary.distance
                  | meterTokm : userData.attributes.distanceUnit
                  }}
                  {{ userData.attributes.distanceUnit | translate }}
                </span>
              </div>
            </div>
          </div>
        </a>
        <a> <img src="/assets/icons/doubledown.png" alt="doubledown" /> </a>
        <a class="bottom_navs" [class.history-tab-selected]="parking" (click)="onClickParking()">{{ "Parking" |
          translate }}
        </a>
        <a class="bottom_navs" [class.history-tab-selected]="event" (click)="onClickEvent()">{{ "Events" | translate }}
        </a>
      </div>
    </div>

    <div class="row" style="margin-right: 0">
      <div *ngIf="history">
        <table class="table table-light table-borderless historytable">
          <thead class="historyheadBorder">
            <tr>
              <th scope="col" class="bottomNavHeads">
                {{ "Fix Time" | translate }}
              </th>
              <th scope="col" class="bottomNavHeads">
                {{ "Latitude " | translate }}
              </th>
              <th scope="col" class="bottomNavHeads">
                {{ "Longitude " | translate }}
              </th>
              <th scope="col" class="bottomNavHeads">
                {{ "Speed" | translate }}
              </th>
              <th scope="col" class="bottomNavHeads">
                {{ "Address" | translate }}
              </th>
            </tr>  
          </thead>
          <tbody *ngIf="allLocationTrackData.length">
  <tr *ngFor="let repo of allLocationTrackData | paginate: { itemsPerPage: itemsPerPage, currentPage: page, totalItems: allLocationTrackData.length }; let i = index">
    <td class="bottomNavBody" style="padding-left: 3vh">
      <img src="/assets/icons/table_vehicle.png" alt="car" style="margin-right: 1vh" />{{ repo.serverTime | date : "dd/MM/yyyy hh:mm a" }}
    </td>
    <td class="bottomNavBody">
      {{ repo.latitude | number : "1.0-6" }}
    </td>
    <td class="bottomNavBody">
      {{ repo.longitude | number : "1.0-6" }}
    </td>
    <td class="bottomNavBody">
      {{ onSpeedgenerateBasedOnuserPreference(repo.speed) | number : "1.0-2" }}
      {{ userData.attributes.speedUnit | translate }}
    </td>
    <td class="bottomNavBody" (click)="getDeviceAddressOnRoutes(repo.latitude, repo.longitude, i)">
  <span style="font-size:15px" *ngIf="selectedRowIndex !== i || !repo.address">
    <i class="fa-solid fa-circle-info"></i>
  </span>
  <span *ngIf="selectedRowIndex === i && repo.address">{{ repo.address }}</span>
</td>
  </tr>
</tbody>

        </table>
        <div *ngIf="allLocationTrackData.length" class="pagination-flex_row_center_spacebetween"
          style="padding: 1.5vh 2vh; border-top: 1px solid #e3e7ec">
          <div>
            {{ "Showing" | translate }} {{ page }} {{ "to" | translate }}
            {{ allLocationTrackData.length / 10 | number : "1.0-0" }}
            {{ "of" | translate }}
            {{ allLocationTrackData.length }} {{ "entries" | translate }}
          </div>
          <pagination-controls (pageChange)="page = $event"></pagination-controls>
        </div>
      </div>
      <table class="table table-light table-borderless parkingtable" *ngIf="parking">
        <thead class="historyheadBorder">
          <tr>
            <th scope="col" class="bottomNavHeads">
              {{ "Vehicle Name" | translate }}
            </th>
            <th scope="col" class="bottomNavHeads">
              {{ "From" | translate }} Time
            </th>
            <th scope="col" class="bottomNavHeads">
              {{ "To Time" | translate }}
            </th>
            <th scope="col" class="bottomNavHeads">
              {{ "Duration" | translate }}
            </th>
            <th scope="col" class="bottomNavHeads">
              {{ "Location" | translate }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of stoppagesReports; index as i">
            <td class="bottomNavBody">{{ item.deviceName }}</td>
            <td class="bottomNavBody">
              {{ item.startTime | date : "dd/MM/yyyy hh:mm a" }}
            </td>
            <td class="bottomNavBody">
              {{ item.endTime | date : "dd/MM/yyyy hh:mm a" }}
            </td>
            <td class="bottomNavBody">{{ item.duration | convert : "hh:mm" }}</td>
            <td class="bottomNavBody">{{ item.address }}</td>
          </tr>
        </tbody>
      </table>

      <table class="table table-light table-borderless eventtable" *ngIf="event">
        <thead class="historyheadBorder">
          <tr>
            <th scope="col" class="bottomNavHeads">{{ "Event" | translate }}</th>
            <th scope="col" class="bottomNavHeads">{{ "Time" | translate }}</th>
            <th scope="col" class="bottomNavHeads">
              {{ "Address" | translate }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td class="bottomNavBody">Idle 1</td>
            <td class="bottomNavBody">21-02-2022, 12:57:58 AM</td>
            <td class="bottomNavBody">
              Mother India Intermediate College, 147 E, Kalpi Rd, Panki, Kanpur,
              Uttar Pradesh (SE)
            </td>
          </tr>
          <tr>
            <td class="bottomNavBody">Idle 1</td>
            <td class="bottomNavBody">21-02-2022, 12:57:58 AM</td>
            <td class="bottomNavBody">
              Mother India Intermediate College, 147 E, Kalpi Rd, Panki, Kanpur,
              Uttar Pradesh (SE)
            </td>
          </tr>
          <tr>
            <td class="bottomNavBody">Idle 1</td>
            <td class="bottomNavBody">21-02-2022, 12:57:58 AM</td>
            <td class="bottomNavBody">
              Mother India Intermediate College, 147 E, Kalpi Rd, Panki, Kanpur,
              Uttar Pradesh (SE)
            </td>
          </tr>
        </tbody>
      </table>

      <div class="container-fluid fuelsummarycontainer" *ngIf="fuelsummary">
        <div class="row fuelsummaryrow" *ngIf="false">
          <div class="col-3 border_right colfuel_summary" style="padding: 3vh">
            <span class="bottomNavHeads">{{ "Fuel Capacity" | translate }}</span>
            <span class="fuelcapcity">250 L</span>
          </div>
          <div class="col-3 border_right colfuel_summary" style="padding: 3vh">
            <span class="bottomNavHeads">{{ "Fuel Consumed" | translate }}</span>
            <span class="fuelcapcity">230.46 L</span>
          </div>
          <div class="col-3 border_right colfuel_summary" style="padding: 3vh">
            <span class="bottomNavHeads">{{ "Refuel" | translate }}</span>
            <span class="fuelcapcity">209.95 L</span>
            <span class="bottomNavHeads">Total Refueled : 1 {{ "times" | translate }}</span>
          </div>
          <div class="col-3 colfuel_summary" style="padding: 3vh">
            <span class="bottomNavHeads">{{ "Fuel Theft" | translate }}</span>
            <span class="fuelcapcity">0 L</span>
            <span class="bottomNavHeads">{{ "Total Theft" | translate }} : 0 {{ "times" | translate }}</span>
          </div>
        </div>

        <div class="p-4 fuelheads">{{ "Driving Metrics" | translate }}</div>

        <div class="row fuelsummaryrow">
          <div class="col-2 border_right colfuel_summary" style="padding: 1.5vh">
            <span class="metrics">{{ "Distance" | translate }}</span>
            <span class="metricspeed">{{
              vehicleDetailsReport.summary.distance
              | meterTokm : userData.attributes.distanceUnit
              }}
              {{ userData.attributes.distanceUnit | translate }}
            </span>
          </div>
          <div class="col-2 border_right colfuel_summary" style="padding: 1.5vh">
            <span class="metrics">{{ "Average Speed" | translate }}</span>
            <span class="metricspeed">{{
              onSpeedgenerateBasedOnuserPreference(
              vehicleDetailsReport?.summary?.averageSpeed
              ) | number : "1.0-2"
              }}
              {{ userData.attributes.speedUnit | translate }}</span>
          </div>
          <div class="col-2 border_right colfuel_summary" style="padding: 1.5vh">
            <span class="metrics">{{ "Top Speed" | translate }}</span>
            <span class="metricspeed">{{
              onSpeedgenerateBasedOnuserPreference(
              vehicleDetailsReport?.summary?.maxSpeed
              ) | number : "1.0-2"
              }}
              {{ userData.attributes.speedUnit | translate }}</span>
          </div>
          <div class="col-2 border_right colfuel_summary" style="padding: 1.5vh">
            <span class="metrics">{{ "Sudden Acceleration" | translate }}</span>
            <span class="metricspeed">0 {{ "times" | translate }}</span>
          </div>
          <div class="col-2 border_right colfuel_summary" style="padding: 1.5vh">
            <span class="metrics">{{ "Sudden Brake" | translate }}</span>
            <span class="metricspeed">0 {{ "times" | translate }}</span>
          </div>
          <div class="col-2 colfuel_summary" style="padding: 1.5vh">
            <span class="metrics">{{ "Fuel Efficiency" | translate }}</span>
            <span class="metricspeed">0 Km/L</span>
          </div>
        </div>

        <div class="p-4 fuelheads">
          {{ "Todays Fuel & Efficiency Graph" | translate }}
        </div>

        <div class="row">
          <div class="col-9">
            <div id="chart"></div>
          </div>
          <div class="col-3 colfuel_summary" style="justify-content: space-evenly; padding-bottom: 3rem">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [(ngModel)]="isHistorySpeedChart"
                (change)="resetHistoryChartSeries()" />
              <label class="form-check-label" for="flexCheckDefault">
                {{ "Speed" | translate }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [(ngModel)]="isHistoryFuelChart"
                (change)="resetHistoryChartSeries()" />
              <label class="form-check-label" for="flexCheckDefault">
                {{ "Fuel" | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="staticBackdropAdd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropAddLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content" style="border: none">
        <div class="modal-header addtriphead">
          <div class="headtext addlabels">
            {{ addDevice.id ? ("Edit" | translate) : ("Add" | translate) }}
            {{ "Device" | translate }}
          </div>
          <button type="button" class="btnclose" id="btnclose" data-bs-dismiss="modal" aria-label="Close">
            <span class="material-icons"> close </span>
          </button>
        </div>
        <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
          <div class="row">
            <div class="col-12">
              <accordion [isAnimated]="true">
                <accordion-group heading="Required" [isOpen]="true">
                  <div class="form-group pb-2">
                    <label for="name" class="pb-2">{{
                      "Registration No" | translate
                      }}</label>
                    <input type="text" [(ngModel)]="addDevice.name" class="form-control inputfields" id="name"
                      aria-describedby="name" />
                    <span class="text-danger validation" *ngIf="errors?.Devicename">
                      {{ errors.Devicename }}
                    </span>
                  </div>
                  <div class="form-group pb-2">
                    <label for="name" class="pb-2">{{
                      "IMEI" | translate
                      }}</label>
                    <input type="text" [(ngModel)]="addDevice.identifier" class="form-control inputfields"
                      id="identifier" aria-describedby="identifier" />
                    <span class="text-danger validation" *ngIf="errors?.Deviceidentifier">
                      {{ errors.Deviceidentifier }}
                    </span>
                  </div>
                </accordion-group>
                <accordion-group heading="Extra">
                  <div class="form-group pb-2">
                    <label for="group" class="pb-2">{{
                      "Group" | translate
                      }}</label>
                    <select class="form-control" [(ngModel)]="addDevice.groupId">
                      <option></option>
                    </select>
                  </div>
                  <div class="form-group pb-2">
                    <label for="phone" class="pb-2">{{
                      "Sim number" | translate
                      }}</label>
                    <input type="text" [(ngModel)]="addDevice.phone" class="form-control inputfields" id="phone"
                      aria-describedby="phone" />
                  </div>
                  <div class="form-group pb-2">
                    <label for="model" class="pb-2">{{
                      "Model" | translate
                      }}</label>
                    <input type="text" [(ngModel)]="addDevice.model" class="form-control inputfields" id="model"
                      aria-describedby="model" />
                  </div>
                  <div class="form-group pb-2">
                    <label for="contact" class="pb-2">{{
                      "Driver no" | translate
                      }}</label>
                    <input type="text" [(ngModel)]="addDevice.contact" class="form-control inputfields" id="contact"
                      aria-describedby="contact" />
                  </div>
                  <div class="form-group pb-2">
                    <label for="category" class="pb-2">{{
                      "Icon" | translate
                      }}</label>
                    <select class="form-control" [(ngModel)]="addDevice.category">
                      <option [value]="deviceCategory.id" *ngFor="let deviceCategory of deviceCategoryList">
                        {{ deviceCategory.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group pb-2">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" [checked]="addDevice.disabled"
                        (change)="checkboxEvent($event.target)" id="disabled" />
                      <label class="form-check-label" for="disabled">{{
                        "Disabled" | translate
                        }}</label>
                    </div>
                  </div>
                  <div class="form-group pb-2 date-p">
                    <label for="expirationTime" class="pb-2">{{
                      "Expiration" | translate
                      }}</label>
                    <input type="text" value="{{ addDevice.expirationTime }}" [(ngModel)]="addDevice.expirationTime"
                      class="form-control inputfields" id="expirationTime" aria-describedby="expirationTime" />
                  </div>
                </accordion-group>
                <accordion-group heading="Attributes">
                  <ng-container *ngFor="let attribute of addDevice.attributes; index as i">
                    <div class="row">
                      <div class="col-11 form-group mb-2" *ngIf="attribute.attribute === 'speedLimit'">
                        <label>{{ "Speed Limit(km)" | translate }}</label>
                        <input type="number" min="1" class="form-control" [(ngModel)]="attribute.value" />
                      </div>
                      <div class="col-11 form-group mb-2" *ngIf="attribute.attribute === 'timezone'">
                        <label>{{ "Timezone" | translate }}</label>
                        <input type="text" class="form-control" [(ngModel)]="attribute.value" />
                      </div>
                      <div class="col-11 form-group mb-2" *ngIf="attribute.attribute === 'deviceInactivityStart'">
                        <label>{{ "Device Inactivity Start" | translate }}</label>
                        <input type="number" class="form-control" [(ngModel)]="attribute.value" />
                      </div>
                      <div class="col-11 form-group mb-2" *ngIf="attribute.attribute === 'deviceInactivityPeriod'">
                        <label>{{
                          "Device Inactivity Period" | translate
                          }}</label>
                        <input type="number" class="form-control" [(ngModel)]="attribute.value" />
                      </div>
                      <div class="col-1">
                        <label>&nbsp;</label>
                        <i class="fa fa-trash text-danger" (click)="deleteAttr(i)"></i>
                      </div>
                    </div>
                  </ng-container>

                  <div class="btn btn-outline-dark w-100" (click)="addAttri(template)">
                    <i class="fa fa-plus"></i> {{ "ADD" | translate }}
                  </div>
                </accordion-group>
              </accordion>
            </div>
          </div>

          <div class="flex_row_center_spacebetween pt-4 pb-2">
            <div class="col-8"></div>

            <div class="col-2">
              <button mat-raised-button class="cancelbtn" data-bs-dismiss="modal" aria-label="Close">
                {{ "Cancel" | translate }}
              </button>
            </div>
            <div class="col-2">
              <button mat-raised-button class="cancelbtn" (click)="deviceSubmit()">
                {{ "Save" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #template>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <label>{{ "Attribute" | translate }}</label>
          <select class="form-control" [(ngModel)]="addAttr.attribute" (change)="changeAttrEvent($event.target)">
            <option *ngFor="let attr of attrList" [value]="attr.id">
              {{ attr.name }}
            </option>
          </select>
        </div>
        <div class="col-12">
          <label>{{ "Type" | translate }}</label>
          <input type="text" class="form-control" [(ngModel)]="addAttr.type" [readonly]="true" />
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="btn btn-light btn-sm" (click)="addAttrbute()">
        {{ "ADD" | translate }}
      </div>
      <div class="btn btn-light btn-sm" (click)="modalRef.hide()">
        {{ "CANCEL" | translate }}
      </div>
    </div>
  </ng-template>

  <ng-template #showIgnRed>
    <img src="/assets/icons/engine-off.svg" alt="img" title="{{ 'Engine off' | translate }}" />
  </ng-template>

  <ng-template #showPowerRed>
    <img src="/assets/icons/Voltage.png" alt="img" />
  </ng-template>
  <ng-template #showGPSGray>
    <img src="/assets/icons/bottom6_gray.png" alt="img" />
  </ng-template>
  <app-common-modal-popup [modelSize]="'modal-xs'" *ngIf="isSendGPRSCommandPopupOpen">
    <div class="" modelheadertext>
      {{ "Send GPRS Command" | translate }}
    </div>
    <div class="container-fluid" modeldata>
      <div class="dropdown mt-2">

        <span class="me-4 ms-2">{{ "Object" | translate }}:</span>
        <span>{{ selectedDevice?.name }}</span>
      </div>
      <br />
      <div class="dropdown">
        <select class="form-select history selectimportant common_scroller"
          style="width: 95% !important; height: 5vh !important; margin-left: 2%" name="type" id="subject"
          [(ngModel)]="sendCommand.type">
          <option value="{{ data.type }}" selected="selected" *ngFor="let data of typesList">
            {{ data.type }}
          </option>
        </select>
      </div>
      <br />
      <div class="mb-3 form-check" style="margin-left: 2%">
        <input type="checkbox" [(ngModel)]="sendCommand.textChannel" class="form-check-input" id="exampleCheck1" />
        <label class="form-check-label" for="exampleCheck1">{{
          "Send SMS" | translate
          }}</label>
      </div>
      <div class="row" style="margin-bottom: 4%; margin-left: 2%">
        <div class="col-8 ps-0 d-flex align-items-center">
          <button mat-raised-button class="cancelbtn theme2" data-bs-dismiss="modal" aria-label="Close"
            (click)="sendCommandDetails()">
            {{ "Send" | translate }}
          </button>

          <button mat-raised-button class="cancelbtn bg-secondary text-white ms-2" data-bs-dismiss="modal"
            aria-label="Close">
            {{ "Cancel" | translate }}
          </button>
        </div>
      </div>
    </div>
  </app-common-modal-popup>

  <app-assign-driver *ngIf="isAssignDriverPopupOpen" [tasks]="selectedTaskDeails[0]"  [driverList]="driversList2" 
    [reaasignDriver]="" (driverAssignedEvent)="driverAssignedEvent($event)"></app-assign-driver>

   