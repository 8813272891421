import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './pages/admin/admin.component';
import { AnnouncementComponent } from './pages/announcement/announcement.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DealersComponent } from './pages/dealers/dealers.component';
import { DeviceModelComponent } from './pages/device-model/device-model.component';
import { DevicesMasterComponent } from './pages/devices-master/devices-master.component';
import { DevicesComponent } from './pages/devices/devices.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { GeofencesComponent } from './pages/geofences/geofences.component';
import { LicensesComponent } from './pages/licenses/licenses.component';
import { LoginComponent } from './pages/login/login.component';
import { ManagetripsComponent } from './pages/managetrips/managetrips.component';
import { ModelMasterComponent } from './pages/model-master/model-master.component';
import { PoiListComponent } from './pages/poi-list/poi-list.component';
import { RegisterComponent } from './pages/register/register.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { RouteComponent } from './pages/route/route.component';
import { RouteplanComponent } from './pages/routeplan/routeplan.component';
import { RTOMasterComponent } from './pages/rto-master/rto-master.component';
import { SensorComponent } from './pages/sensor/sensor.component';
import { AccountSettingsComponent } from './pages/Settings/account-settings/account-settings.component';
import { NotificationComponent } from './pages/Settings/notification/notification.component';
import { SupportComponent } from './pages/support/support.component';
import { TrackingComponent } from './pages/tracking/tracking.component';
import { UserMasterComponent } from './pages/user-master/user-master.component';
import { MainComponent } from './shared/main/main.component';
import { VehicleTrackingComponent } from './pages/vehicle-tracking/vehicle-tracking.component';
import { NewTrackingComponent } from './pages/new-tracking/new-tracking.component';


const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'forgotpassword',
    component: ForgotpasswordComponent,
  },
  {
    path: 'main',
    component: MainComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'tracking',
        component: TrackingComponent,
      },
      {
        path: 'vehicleTracking',
        component: VehicleTrackingComponent,
      },
      {
        path: 'track',
        component: NewTrackingComponent,
      },
      {
        path: 'geofences',
        component: GeofencesComponent,
      },
      {
        path: 'reports',
        component: ReportsComponent,
      },
      {
        path: 'poilist',
        component: PoiListComponent,
      },
      {
        path: 'routelist',
        component: RouteComponent,
      },
      {
        path: 'managetrips',
        component: ManagetripsComponent,
      },
      {
        path: 'routeplan',
        component: RouteplanComponent,
      },
      {
        path: 'notifications',
        component: NotificationComponent,
      },
      {
        path: 'dealers',
        component: DealersComponent,
      },
      {
        path: 'user/customers',
        component: CustomersComponent,
      },
      {
        path: 'user/dealers',
        component: CustomersComponent,
      },
      {
        path: 'licenses',
        component: LicensesComponent,
      },
      {
        path: 'devices',
        component: DevicesComponent,
      },
      {
        path: 'sensor',
        component: SensorComponent,
      },
      {
        path: 'admin',
        component: AdminComponent,
      },
      {
        path: 'deviceModel',
        component: DeviceModelComponent,
      },
      {
        path: 'devicesMaster',
        component: DevicesMasterComponent,
      },
      {
        path: 'modelMaster',
        component: ModelMasterComponent,
      },
      {
        path: 'rtomaster',
        component: RTOMasterComponent,
      },
      {
        path: 'usermaster',
        component: UserMasterComponent,
      },
      {
        path: 'accountssettings',
        component: AccountSettingsComponent,
      },
      {
        path:'announcement',
        component:AnnouncementComponent
      },
      {
        path:'support',
        component:SupportComponent
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy',useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
