
import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moments from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersettingService } from 'src/app/services/usersetting.service';
import { ValidationService } from 'src/app/services/validation.service';
import { CommonService } from '../../services/common.service';
import * as moment from 'moment-timezone';
import { ExternalLibraryService } from 'src/app/services/payment.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare var $: any;
declare let Razorpay: any;
@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.css']
})
export class LicensesComponent implements OnInit {
  form:FormGroup;
  modelForm:FormGroup;
  dealerList =[];
  pageOption = 10;
  pageOptionModal = 10;
  page: number = 1;
  submitted = false;
  myDealerControl:FormControl;
  userId = localStorage.getItem('userId');
  userData = {};
  
  filteredOptions: Observable<any[]>;
  licensesList = [];
  addLicensesForm: any;
  editCustomerForm: FormGroup;
  timezoneArray = [];
  showModal = false;
  deviceList: any = [];
  errors: any = {};
  response;
  superAdmin:boolean = false;
  razorpayResponse;
  modalRef?: BsModalRef;
  RAZORPAY_OPTIONS = {
    "key": "rzp_test_BClgEY2hkt8ayQ",
    "amount": "100",
    "name": "",
    "currency":'INR',
    "order_id": "",
    "description": "purchase license",
    "image": `https://${environment.baseUrl}/assets/images/main-logo.png`,
    "prefill": {
      "name": "",
      "email": "test@test.com",
      "contact": "",
      "method": ""
    },
    "modal": {},
    "theme": {
      "color": "#0096C5"
    }
  };
  @Input()
  addMode = false;
  @Input()
  gridMode = false;
  constructor(
    private modalService: BsModalService,
    private user: UsersettingService,
    private fb: FormBuilder,
    private customValidator: ValidationService,
    private commonService: CommonService,
    private razorpayService: ExternalLibraryService, private cd:  ChangeDetectorRef
  ) {
    this.commonService.pageLoadInModal.subscribe((result: string) => { 
      if (result == "license") {
        this.gridMode = true;
        this.addMode = false;
      }
    });
    this.myDealerControl = new FormControl();
    let userData = localStorage.getItem('userData');
   this.userData = userData ? JSON.parse(userData) : {};
   
   this.initPaymentForm();
  }
  
  ngOnInit(): void {
    this.filteredOptions = this.myDealerControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.dealerList.slice();
      }),
    );
    this.superAdmin = this.userData && this.userData['administrator'];
    if(this.superAdmin){
      this.getAllCustomers();
    }
    this.form = new FormGroup({
      searchValue: new FormControl(''),
    });
    
    this.modelForm = new FormGroup({
      searchValue: new FormControl(''),
    });
    this.getAllLicenses();
    this.initPaymentFlow();
   
  }

  dealerChange() {
    console.log(this.addLicensesForm.userId);
  }
  initPaymentForm(){
  let userDataAttr = this.userData['attributes'] && this.userData['attributes'].unitrate ? this.userData['attributes'] :{
    "unitrate": "100",
    "currency": "INR"
  }
   this.addLicensesForm = {
    amount: parseInt(userDataAttr.unitrate),
    currency: userDataAttr.currency,
    devicecount: 0,
    total:0,
    tax:18,
    userId:0,
    status:'Completed',
  };
}
getAllCustomers() {
  this.commonService.getRequest('users', []).then((resp: any) => {
    if (resp?.status) {
        this.dealerList = resp.data.filter(customer=>!customer.administrator && customer.userLimit !== 0)
    }
  });
}
  searchData() {
    
  }
    getAllLicenses() {
    
    this.commonService.getRequest( this.superAdmin ? 'razorpay/listAllLicenses' : 'razorpay', [],false,false).then((resp: any) => {
      if (resp?.status) {
        this.licensesList = resp.data;
       
      }
    });
  }

  onOrderPlace() {
    this.errors = {};
    let error = false;
    if(!this.addLicensesForm.total) {
      this.errors.total = 'Amount is required field';
      error = true;
    } 
    if(!this.addLicensesForm.devicecount) {
      this.errors.devicecount = 'Count is required field';
      error = true;
    } 
    if(!this.addLicensesForm.currency) {
      this.errors.currency = 'Currency is required field';
      error = true;
    } 
    if( !this.addLicensesForm.status && this.superAdmin) {
      this.errors.status = 'Status is required field';
      error = true;
    } 
    if(!(this.addLicensesForm.userId  && this.myDealerControl.value) && this.superAdmin) {
      this.errors.userId = 'Dealer is required field';
      error = true;
    } 
    if(!error) {
      if(this.superAdmin) {
        this.manualLicence();
      } else {
        this.addLicense();
      }
    }
    
  }
  addLicense(){
    const body = new HttpParams()
    .set('amount', this.addLicensesForm.total)
    .set('currency', this.addLicensesForm.currency)
    .set('devicecount', this.addLicensesForm.devicecount);
   // $('#staticBackdrop').modal('show');
    this.commonService
    .postFormRequest('razorpay/order', body,true)
    .then((resp: any) => {
      console.log(resp);
      if (resp?.status && resp?.data?.orderId) {
        //$('#staticBackdrop').modal('hide');
        this.gridMode = true;
        this.addMode = false;
        this.addLicensesForm.orderId = resp.data.orderId;
        this.proceed(resp.data.orderId)
      }
    });
  }
  manualLicence() {
    // const body = new HttpParams()
    // .set('amount', this.addLicensesForm.total)
    // .set('currency', this.addLicensesForm.currency)
    // .set('devicecount', this.addLicensesForm.devicecount);
    let bodyObj = {
      "userId": this.addLicensesForm.userId,
      "units": this.addLicensesForm.devicecount,
      "amount": this.addLicensesForm.total,
      "currency": this.addLicensesForm.currency,
      "status": this.addLicensesForm.status,
      "recepitNo": "",
      "orderId": "",
      
      "transactiondate": (new Date()).toISOString()
  }
    //$('#staticBackdrop').modal('show');
    this.commonService
    .postRequest('razorpay/manualAddLicense', bodyObj)
    .then((resp: any) => {
        //$('#staticBackdrop').modal('hide');
        this.gridMode = true;
        this.addMode = false;
        this.getAllLicenses();
    });
  }
  
  dealerSelected(dealer) {
    this.addLicensesForm.userId = dealer && dealer.id? dealer.id : '';  
  }
  displayFn(dealer: any): string {
    return dealer && dealer.name ? dealer.name : '';
  }
  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.dealerList.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  onChangeCount() {
    let totalPrice  = this.addLicensesForm.amount * this.addLicensesForm.devicecount;
    this.addLicensesForm.total =totalPrice + totalPrice*this.addLicensesForm.tax/100;
  }

  purchaseLicense() {
    this.gridMode = false;
    this.addMode = true;
  }
  initPaymentFlow() {
    this.razorpayService
    .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js')
    .subscribe();
  }
  public proceed(orderId) {
    this.RAZORPAY_OPTIONS.amount = (this.addLicensesForm.total).toString();
    this.RAZORPAY_OPTIONS.order_id = orderId
    //this.RAZORPAY_OPTIONS.currency = this.addLicensesForm.currency;

    // binding this object to both success and dismiss handler
    this.RAZORPAY_OPTIONS['handler'] = this.razorPaySuccessHandler.bind(this);

    // this.showPopup();

   let razorpay = new Razorpay(this.RAZORPAY_OPTIONS)
    razorpay.open();
  }

  public razorPaySuccessHandler(response) {
    console.log(response);
   
    this.cd.detectChanges()
   this.verifyOrder(response.razorpay_payment_id);
  }
  verifyOrder(paymentId,orderStatus="success",details='') {
    let postData = {
      "orderId":this.addLicensesForm.orderId,
      "paymentId":paymentId,
      "failureDetails":details,
      "razorPayPaymentStatus":orderStatus,
    }
    
    this.commonService
      .postRequest('razorpay/verifyPayment', postData)
      .then((resp: any) => {
        console.log(resp);
        this.gridMode = true;
        this.addMode = false;
        this.getAllLicenses();
      });
  }
  addLicenseButton() {
    this.gridMode = false;
    this.addMode = true;
  }
  onCancelClick() {
    this.gridMode = true;
    this.addMode = false;
  }
}
