import { Component, OnInit } from '@angular/core';
import { DashboardDataService } from 'src/app/services/dashboard-data.service';
import * as moment from 'moment-timezone';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Subscription } from 'rxjs';
import { UsersettingService } from 'src/app/services/usersetting.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent implements OnInit {

  checked=true;
  langSub : Subscription;
  userProfileData = {};
  timezoneArray = [
    {id: 1, country: 'Dubai', tz: 'Asia/Dubai'},
    {id: 2, country: 'Kabul', tz: 'Asia/Kabul'},
    {id: 3, country: 'Yerevan', tz: 'Asia/Yerevan'},
    {id: 4, country: 'Baku', tz: 'Asia/Baku'},
    {id: 5, country: 'Dhaka', tz: 'Asia/Dhaka'},
    {id: 6, country: 'Brunei', tz: 'Asia/Brunei'},
    {id: 7, country: 'Thimphu', tz: 'Asia/Thimphu'},
    {id: 8, country: 'Shanghai', tz: 'Asia/Shanghai'},
    {id: 9, country: 'Urumqi', tz: 'Asia/Urumqi'},
    {id: 10, country: 'Nicosia', tz: 'Asia/Nicosia'},
    {id: 11, country: 'Famagusta', tz: 'Asia/Famagusta'},
    {id: 12, country: 'Tbilisi', tz: 'Asia/Tbilisi'},
    {id: 13, country: 'Hong_Kong', tz: 'Asia/Hong_Kong'},
    {id: 14, country: 'Jakarta', tz: 'Asia/Jakarta'},
    {id: 15, country: 'Pontianak', tz: 'Asia/Pontianak'},
    {id: 16, country: 'Makassar', tz: 'Asia/Makassar'},
    {id: 17, country: 'Jayapura', tz: 'Asia/Jayapura'},
    {id: 18, country: 'Jerusalem', tz: 'Asia/Jerusalem'},
    {id: 19, country: 'Kolkata', tz: 'Asia/Kolkata'},
    {id: 20, country: 'Baghdad', tz: 'Asia/Baghdad'},
    {id: 21, country: 'Tehran', tz: 'Asia/Tehran'},
    {id: 22, country: 'Amman', tz: 'Asia/Amman'},
    {id: 23, country: 'Tokyo', tz: 'Asia/Tokyo'},
    {id: 24, country: 'Bishkek', tz: 'Asia/Bishkek'},
    {id: 25, country: 'Pyongyang', tz: 'Asia/Pyongyang'},
    {id: 26, country: 'Seoul', tz: 'Asia/Seoul'},
    {id: 27, country: 'Almaty', tz: 'Asia/Almaty'},
    {id: 28, country: 'Qyzylorda', tz: 'Asia/Qyzylorda'},
    {id: 29, country: 'Qostanay', tz: 'Asia/Qostanay'},
    {id: 30, country: 'Aqtobe', tz: 'Asia/Aqtobe'},
    {id: 31, country: 'Aqtau', tz: 'Asia/Aqtau'},
    {id: 32, country: 'Atyrau', tz: 'Asia/Atyrau'},
    {id: 33, country: 'Oral', tz: 'Asia/Oral'},
    {id: 34, country: 'Beirut', tz: 'Asia/Beirut'},
    {id: 35, country: 'Colombo', tz: 'Asia/Colombo'},
    {id: 36, country: 'Yangon', tz: 'Asia/Yangon'},
    {id: 37, country: 'Ulaanbaatar', tz: 'Asia/Ulaanbaatar'},
    {id: 38, country: 'Hovd', tz: 'Asia/Hovd'},
    {id: 39, country: 'Choibalsan', tz: 'Asia/Choibalsan'},
    {id: 40, country: 'Macau', tz: 'Asia/Macau'},
    {id: 41, country: 'Kuala_Lumpur', tz: 'Asia/Kuala_Lumpur'},
    {id: 42, country: 'Kuching', tz: 'Asia/Kuching'},
    {id: 43, country: 'Karachi', tz: 'Asia/Karachi'},
    {id: 44, country: 'Gaza', tz: 'Asia/Gaza'},
    {id: 45, country: 'Hebron', tz: 'Asia/Hebron'},
    {id: 46, country: 'Kathmandu', tz: 'Asia/Kathmandu'},
    {id: 47, country: 'Yekaterinburg', tz: 'Asia/Yekaterinburg'},
    {id: 48, country: 'Qatar', tz: 'Asia/Qatar'},
    {id: 49, country: 'Omsk', tz: 'Asia/Omsk'},
    {id: 50, country: 'Novosibirsk', tz: 'Asia/Novosibirsk'},
    {id: 51, country: 'Barnaul', tz: 'Asia/Barnaul'},
    {id: 52, country: 'Tomsk', tz: 'Asia/Tomsk'},
    {id: 53, country: 'Novokuznetsk', tz: 'Asia/Novokuznetsk'},
    {id: 54, country: 'Krasnoyarsk', tz: 'Asia/Krasnoyarsk'},
    {id: 55, country: 'Irkutsk', tz: 'Asia/Irkutsk'},
    {id: 56, country: 'Chita', tz: 'Asia/Chita'},
    {id: 57, country: 'Yakutsk', tz: 'Asia/Yakutsk'},
    {id: 58, country: 'Khandyga', tz: 'Asia/Khandyga'},
    {id: 59, country: 'Vladivostok', tz: 'Asia/Vladivostok'},
    {id: 60, country: 'Ust-Nera', tz: 'Asia/Ust-Nera'},
    {id: 61, country: 'Singapore', tz: 'Asia/Singapore'},
    {id: 62, country: 'Magadan', tz: 'Asia/Magadan'},
    {id: 63, country: 'Sakhalin', tz: 'Asia/Sakhalin'},
    {id: 64, country: 'Srednekolymsk', tz: 'Asia/Srednekolymsk'},
    {id: 65, country: 'Kamchatka', tz: 'Asia/Kamchatka'},
    {id: 66, country: 'Anadyr', tz: 'Asia/Anadyr'},
    {id: 67, country: 'Bangkok', tz: 'Asia/Bangkok'},
    {id: 68, country: 'Dushanbe', tz: 'Asia/Dushanbe'},
    {id: 69, country: 'Taipei', tz: 'Asia/Taipei'},
    {id: 70, country: 'Dili', tz: 'Asia/Dili'},
    {id: 71, country: 'Ashgabat', tz: 'Asia/Ashgabat'},
    {id: 72, country: 'Damascus', tz: 'Asia/Damascus'},
    {id: 73, country: 'Riyadh', tz: 'Asia/Riyadh'},
    {id: 74, country: 'Samarkand', tz: 'Asia/Samarkand'},
    {id: 75, country: 'Tashkent', tz: 'Asia/Tashkent'},
    {id: 76, country: 'Ho_Chi_Minh', tz: 'Asia/Ho_Chi_Minh'},
    // {id: 77, country: 'Andorra', tz: 'Europe/Andorra'},
    // {id: 78, country: 'Tirane', tz: 'Europe/Tirane'},
    // {id: 79, country: 'Vienna', tz: 'Europe/Vienna'},
    // {id: 80, country: 'Brussels', tz: 'Europe/Brussels'},
    // {id: 81, country: 'Sofia', tz: 'Europe/Sofia'},
    // {id: 82, country: 'Minsk', tz: 'Europe/Minsk'},
    // {id: 83, country: 'Zurich', tz: 'Europe/Zurich'},
    // {id: 84, country: 'Prague', tz: 'Europe/Prague'},
    // {id: 85, country: 'Berlin', tz: 'Europe/Berlin'},
    // {id: 86, country: 'Copenhagen', tz: 'Europe/Copenhagen'},
    // {id: 87, country: 'Tallinn', tz: 'Europe/Tallinn'},
    // {id: 88, country: 'Madrid', tz: 'Europe/Madrid'},
    // {id: 89, country: 'Helsinki', tz: 'Europe/Helsinki'},
    // {id: 90, country: 'Paris', tz: 'Europe/Paris'},
    // {id: 91, country: 'London', tz: 'Europe/London'},
    // {id: 92, country: 'Gibraltar', tz: 'Europe/Gibraltar'},
    // {id: 93, country: 'Athens', tz: 'Europe/Athens'},
    // {id: 94, country: 'Budapest', tz: 'Europe/Budapest'},
    // {id: 95, country: 'Dublin', tz: 'Europe/Dublin'},
    // {id: 96, country: 'Rome', tz: 'Europe/Rome'},
    // {id: 97, country: 'Vilnius', tz: 'Europe/Vilnius'},
    // {id: 98, country: 'Luxembourg', tz: 'Europe/Luxembourg'},
    // {id: 99, country: 'Riga', tz: 'Europe/Riga'},
    // {id: 100, country: 'Monaco', tz: 'Europe/Monaco'},
    // {id: 101, country: 'Chisinau', tz: 'Europe/Chisinau'},
    // {id: 102, country: 'Malta', tz: 'Europe/Malta'},
    // {id: 103, country: 'Amsterdam', tz: 'Europe/Amsterdam'},
    // {id: 104, country: 'Oslo', tz: 'Europe/Oslo'},
    // {id: 105, country: 'Warsaw', tz: 'Europe/Warsaw'},
    // {id: 106, country: 'Warsaw', tz: 'Europe/Warsaw'},
    // {id: 107, country: 'Lisbon', tz: 'Europe/Lisbon'},
    // {id: 108, country: 'Bucharest', tz: 'Europe/Bucharest'},
    // {id: 109, country: 'Belgrade', tz: 'Europe/Belgrade'},
    // {id: 110, country: 'Kaliningrad', tz: 'Europe/Kaliningrad'},
    // {id: 111, country: 'Moscow', tz: 'Europe/Moscow'},
    // {id: 112, country: 'Simferopol', tz: 'Europe/Simferopol'},
    // {id: 113, country: 'Kirov', tz: 'Europe/Kirov'},
    // {id: 114, country: 'Astrakhan', tz: 'Europe/Astrakhan'},
    // {id: 115, country: 'Volgograd', tz: 'Europe/Volgograd'},
    // {id: 116, country: 'Saratov', tz: 'Europe/Saratov'},
    // {id: 117, country: 'Ulyanovsk', tz: 'Europe/Ulyanovsk'},
    // {id: 118, country: 'Samara', tz: 'Europe/Samara'},
    // {id: 119, country: 'Stockholm', tz: 'Europe/Stockholm'},
    // {id: 120, country: 'Istanbul', tz: 'Europe/Istanbul'},
    // {id: 121, country: 'Kiev', tz: 'Europe/Kiev'},
    // {id: 122, country: 'Uzhgorod', tz: 'Europe/Uzhgorod'},
    // {id: 123, country: 'Zaporozhye', tz: 'Europe/Zaporozhye'},
    // {id: 124, country: 'Buenos_Aires', tz: 'America/Argentina/Buenos_Aires'},
    // {id: 125, country: 'Cordoba', tz: 'America/Argentina/Cordoba'},
    // {id: 126, country: 'Salta', tz: 'America/Argentina/Salta'},
    // {id: 127, country: 'Jujuy', tz: 'America/Argentina/Jujuy'},
    // {id: 128, country: 'Tucuman', tz: 'America/Argentina/Tucuman'},
    // {id: 129, country: 'Catamarca', tz: 'America/Argentina/Catamarca'},
    // {id: 130, country: 'La_Rioja', tz: 'America/Argentina/La_Rioja'},
    // {id: 131, country: 'San_Juan', tz: 'America/Argentina/San_Juan'},
    // {id: 132, country: 'Mendoza', tz: 'America/Argentina/Mendoza'},
    // {id: 133, country: 'San_Luis', tz: 'America/Argentina/San_Luis'},
    // {id: 134, country: 'Rio_Gallegos', tz: 'America/Argentina/Rio_Gallegos'},
    // {id: 135, country: 'Ushuaia', tz: 'America/Argentina/Ushuaia'},
    // {id: 136, country: 'Barbados', tz: 'America/Barbados'},
    // {id: 137, country: 'La_Paz', tz: 'America/La_Paz'},
    // {id: 138, country: 'Belem', tz: 'America/Belem'},
    // {id: 139, country: 'Fortaleza', tz: 'America/Fortaleza'},
    // {id: 140, country: 'Recife', tz: 'America/Recife'},
    // {id: 18, country: 'Araguaina', tz: 'America/Araguaina'},
    // {id: 19, country: 'Maceio', tz: 'America/Maceio'},
    // {id: 20, country: 'Bahia', tz: 'America/Bahia'},
    // {id: 21, country: 'Sao_Paulo', tz: 'America/Sao_Paulo'},
    // {id: 22, country: 'Campo_Grande', tz: 'America/Campo_Grande'},
    // {id: 23, country: 'Cuiaba', tz: 'America/Cuiaba'},
    // {id: 24, country: 'Porto_Velho', tz: 'America/Porto_Velho'},
    // {id: 25, country: 'Boa_Vista', tz: 'America/Boa_Vista'},
    // {id: 26, country: 'Manaus', tz: 'America/Manaus'},
    // {id: 27, country: 'Eirunepe', tz: 'America/Eirunepe'},
    // {id: 28, country: 'Rio_Branco', tz: 'America/Rio_Branco'},
    // {id: 29, country: 'Nassau', tz: 'America/Nassau'},
    // {id: 30, country: 'Belize', tz: 'America/Belize'},
    // {id: 31, country: 'St_Johns', tz: 'America/St_Johns'},
    {id: 142, country: 'India', tz: 'Asia/Calcutta'},
  ];
  fromdate = "";
  todate = "";
  language="English";
  languages:String;
  reportsList = [];
  userId = localStorage.getItem("userId")
  filter = 'general';
  id='';
  //id=JSON.parse(window.atob(window.localStorage.token.split('.')[1]))._id;
  user = {
    "FirstName": "",
    "LastName": "",
    "email": "",
    "mobile": "",
    "organisation": "",
    "timezone": "",
    "fuelunit": "",
    "hours": "",
    "charges": "",
    "dealer": "",
    "notification": "",
    "payment": "",
    "label": "",
    "driver": ""
  }

  available_languages = [{
    view: "English",
    id: "en"
  }, {
    view: "Spanish",
    id: "sp"
  }, {
    view: "Persian",
    id: "pr"
  }, {
    view: "Punjabi",
    id: "punjabi"
  }, {
    view: "Arabic",
    id: "ar"
  }, {
    view: "French",
    id: "fa"
  },
  {
    view: "Portuguese",
    id: "prt"
  },
  {
    view: "Urdu",
    id: "urdu"
  }
];
  siteDetails: any;
  constructor(private setting: DashboardDataService,private usersSetting:UsersettingService,private commonService:CommonService) {
    this.siteDetails = this.commonService.getSiteDetails();
    // this.langSub = this.setting.Language.subscribe((res) => {
    //  this.languages=res;
    // })
    if (localStorage.getItem('Language') != undefined) {
      this.language = this.available_languages.filter(x => x.id == localStorage.getItem('Language'))[0].view;
      //alert(this.available_languages.filter(x => x.id == localStorage.getItem('Language'))[0].view);
    }
  }

  ngOnInit(): void {
    
    this.getUserProfileData();
  }
  userData:any = {
    id: null,
    name: '',
    email: '',
    password: '',
    deviceLimit: '0',
    userLimit: '0',
    attributesList:[],
    attributes: {
      speedUnit:'',
      distanceUnit:'',
      altitudeUnit:'',
      volumeUnit:'',
      timezone: '',
      managerContactNumber:'',
      managerEmailAddress:'',
      }
  };
  isDealer = false;
  getUserProfileData() {
    
    //let user = localStorage.getItem('userData');
    
   let user = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {};
   this.getUserDetails(user.id);
   
    // this.setting.shareProfileImage().subscribe(resp => {
    //   this.userProfileData = resp;
    //   this.user = {
    //     "FirstName": resp.first_name,
    //     "LastName": resp.last_name,
    //     "email": resp.email,
    //     "mobile": resp.phone,
    //     "organisation": resp.organisation?.first_name + resp.organisation?.last_name,
    //     "timezone": resp.timezone,
    //     "fuelunit": resp.fuel_unit,
    //     "hours": resp.digital_input,
    //     "charges": "NAN",
    //     "dealer": resp.isDealer,
    //     "notification": resp.notification_sound,
    //     "payment": resp.paymentgateway,
    //     "label": resp.label_setting,
    //     "driver": resp.driverManagement
    //   }
    // })
  }
  getUserDetails(userId) {
    this.commonService.getRequest('users/'+userId, []).then((resp: any) => {
      if (resp?.status) {
       if(resp.data) {
        this.isDealer= parseInt(resp.data.userLimit) !== 0
        this.userData = {
          id: resp.data.id,
          name: resp.data.name,
          email: resp.data.email,
          // expirationTime: moments(resp.data.expirationTime).format('DD/MM/YYYY'),
          deviceLimit: resp.data.deviceLimit,
          userLimit: resp.data.userLimit,
          readonly: resp.data.readonly,
          limitCommands: resp.data.limitCommands,
          deviceReadonly: resp.data.deviceReadonly,
          disableReports: resp.data.disableReports,
          administrator: resp.data.administrator,
          disabled: resp.data.disabled,
          attributes: resp.data.attributes,
          attributesList:[]};
    
       }
      }
    });
  }
  errors = {};
  onCustomerSubmit() {
    this.errors = {};
    let error = false;
    if (!this.userData.name) {
      this.errors['name'] = 'This field is required';
      error = true;
    }
    if (!this.userData.email) {
      this.errors['email'] = 'This field is required';
      error = true;
    }
    if (!this.userData.id && !this.userData.password) {
      this.errors['password'] = 'This field is required';
      error = true;
    }

    console.log('this.addCustomerForm', this.userData);

    let dataSet = {
      name: this.userData.name,
      email: this.userData.email,
      deviceLimit: this.userData.deviceLimit,
      userLimit: this.userData.userLimit,
      // expirationTime: moments(this.userData.expirationTime).format(),
      attributes: this.userData.attributes,
    };

    if (!error) {
      if (this.userData.readonly) {
        dataSet['readonly'] = true;
      }
      if (this.userData.limitCommands) {
        dataSet['limitCommands'] = true;
      }
      if (this.userData.disableReports) {
        dataSet['disableReports'] = true;
      }
      if (this.userData.deviceReadonly) {
        dataSet['deviceReadonly'] = true;
      }
      if (this.userData.administrator) {
        dataSet['administrator'] = true;
      }
      if (this.userData.disabled) {
        dataSet['disabled'] = true;
      }

      
    }

    if (this.userData.id && !error) {
      dataSet['id'] = this.userData.id;
      if (this.userData.password) {
        dataSet['password'] = this.userData.password;
      }
      this.commonService
        .putRequest('users/' + this.userData.id, dataSet)
        .then((resp: any) => {
          if (resp?.status && resp?.data?.id) {
          }
        },(err)=>{
          console.log(err);
           Swal.fire({
             icon: "error",
             title: "Something Went Wrong",
             showConfirmButton: false,
             timer: 1500,
           });
         });
    } 
  }
  onSetLanguageSetting(){
        var language = this.available_languages.find((x) => x.view === this.language).id;
        this.setting.Language.next(language);

    // let body={
    //  "lang": language,
    //  "uid": this.id
    // }
    
    // this.usersSetting.onSetuserSetting(body).subscribe((resp)=>{
    //   console.log(resp)
    //   sessionStorage.setItem('Language',language);
    //   Swal.fire({
    //     icon: "success",
    //     title:resp.message,
    //     showConfirmButton: false,
    //     timer: 2000,
    //   })
    // },(err)=>{
    //   Swal.fire({
    //     title:err.error.message
    //   })
    // })
  }

  // onGetlanguageSetting(){
  //   var body = { uid: this.useridd };
  //   this.usersSetting.getLanguages(body).subscribe(resp=>{
  //  this.selectedlanguage = res.language_code ; 
  //  this.translate.setDefaultLang(this.selectedlanguage);
 
  //  this.translate.use(this.selectedlanguage);
  //   })
  // }

  onClickSettings(filter) {
    this.filter = filter;
    // switch (filter) {
    //   case 'general':
    //     break;
    //   case 'trip': this.trip=true;
    //     break;
    //   case 'language': this.language=true;
    //     break;
    //   case 'currency': this.currency=true;
    //     break;
    //   case 'api': this.api=true;
    //     break;
    //   case 'notification': this.notification=true;
    //     break;
    //   case 'transaction': this.transaction=true;
    //     break;
    //   case 'announcement': this.announcement=true;
    //     break;
    //   case 'support': this.support=true;
    //     break;
    //   case 'recharge': this.recharge=true;
    //     break;
    // }
  }

  onClickEditAccount() {
    //this.setting.loader = true;
    // let body = {
    //   "digital_input": this.user.hours,
    //   "driverManagement": this.user.driver,
    //   "fname": this.user.FirstName,
    //   "fuel_unit": this.user.fuelunit,
    //   "label_setting": this.user.label,
    //   "lname": this.user.LastName,
    //   "noti": this.user.notification,
    //   "org": this.user.organisation,
    //   "paymentGateway": this.user.payment,
    //   "show_announcement": false,
    //   'timezone': this.user.timezone,
    //   "uid": this.userId
    // }
    // this.setting.editUserAccount(body).subscribe((resp) => {
    //   console.log(resp);
    //   this.setting.loader = false;
    //   Swal.fire({
    //     icon: "success",
    //     title: "User Account Updated Successfully!",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    // },
    //   (err) => {
    //     this.setting.loader = false;
    //     Swal.Fire({
    //       title: err.error.message
    //     })
    //   }
    // )
  }

}
