<app-common-modal-popup [modelSize]="'modal-xs'">

    <div class="" modelheadertext>Filter Task</div>
    <div class="container-fluid" modeldata>
        <div class="row mt-2">
            <div class="col-md-4">
                <label for="dp">from Date</label>
                <input type="text" class="form-control" id="dp" placeholder="Choose a date" bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                placeholder="dd/mm/yyyy"
                [(ngModel)]="fromDate">
            </div>
            <div class="col-md-4">
                <label for="td">To Date</label>
                <input type="text" class="form-control"  
                id="td" 
                 bsDatepicker
                 [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                 placeholder="dd/mm/yyyy"
                 [(ngModel)]="todateP">
            </div>
            <div class="col-md-4" *ngIf="!isSupervisor">
                <label for="td">Select Client</label>
                <div class="input-group">
                    <select class="form-select taskspanel1" [(ngModel)]="searchClients">
                        <option selected value="-1">Select</option>
                        <option value="{{c.email}}" *ngFor="let c of customerList">{{c.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4" *ngIf="isSupervisor">
                <label for="td">Select Client</label>
                <div class="input-group">
                    <select class="form-select taskspanel1" [(ngModel)]="searchClients">
                        <option selected value="-1">Select</option>
                        <option value="{{c.clientId}}" *ngFor="let c of customerList">{{c.clientName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-12 mt-4 mb-3">
            <button type="button" class="btn btn-primary me-3" (click)="convertedDates()">Submit</button>
            <button type="button" class="btn btn-secondary" (click)="closeForm()">Cancel</button>
        </div>
    </div>
</app-common-modal-popup>