<app-common-modal-popup [modelSize]="'modal-xs'">
    <div class="" modelheadertext>{{ 'Create task via CSV' | translate }}</div>
    <div class="container-fluid" modeldata>
        <input style="display: none" type="file" (change)="onFileUpload($event)" #selectFile>
        <div class="row">
            <div class="col-12 bg-light">
                <span><a href="./../../../assets/sample/bulk-import-sample-format.xlsx" download="bulk-import-sample-format.xlsx" class="downloadFile">Download sample file</a></span>
            </div>
            <div class="col-12 bg-light d-flex align-items-center justify-content-center p-5">
                <i class="bi bi-filetype-csv font-size-40 cursorPointer" (click)="uploadDocuments()"></i>
            </div>
        </div>
        <div class="spinner-container" *ngIf="loading">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
      
    </div>
    <!-- Spinner container -->
      
</app-common-modal-popup>  
  