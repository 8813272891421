import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { UsersettingService } from 'src/app/services/usersetting.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $ :any;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  todate = "";
  fromdate = "";
  pageOption = 10;
  driverMasterList = [];
  masterId="";
  editForm=false;
  userId = localStorage.getItem("userId");
  page:number=1;
  addDriverMasterForm:FormGroup;
  submitted = false;
  searchdealer="";

  constructor(private driver:UsersettingService,private fb: FormBuilder, private customValidator: ValidationService) { 
    this.addDriverMasterForm = this.fb.group(
      {
        drivername: ['', Validators.required],
        status: ['', Validators.required],
        driveraddress1: ['', Validators.required],
        driveraddress2: ['', Validators.required],
        licencenumber: ['', Validators.required],
        contactnumber: ['', Validators.required],
        salary: ['', Validators.required],
        djo: ['', Validators.required],
      });
  }

  ngOnInit(): void {
    this.getAllDrivers();
  }

  get AddDriverFormControl() {
    return this.addDriverMasterForm.controls;
  }

  getAllDrivers(){
    this.driver.getDrivers().subscribe(resp=>{
      console.log(resp);
      this.driverMasterList=resp;
    })
  }

  onDriverMasterSubmit(){
    if(this.editForm){
      let body={
        "address": this.addDriverMasterForm.value.driveraddress1,
        "contactNumber": this.addDriverMasterForm.value.contactnumber,
        "doj": this.addDriverMasterForm.value.djo,
        "license_number": this.addDriverMasterForm.value.licencenumber,
        "name": this.addDriverMasterForm.value.drivername,
        "salary": this.addDriverMasterForm.value.salary,
        "status": this.addDriverMasterForm.value.status,
        "userid": this.userId,
        "_id":this.masterId
      }
      this.driver.updateDriverDetails(body).subscribe(resp=>{
        Swal.fire({
          icon: "success",
          title: "Your Form has been Updated",
          showConfirmButton: false,
          timer: 1500,
        });
        this.addDriverMasterForm.reset();
        $('#staticBackdrop').modal("hide");
        this.getAllDrivers();
        this.editForm=false;
       })
    }else{
      let body={
        "address": this.addDriverMasterForm.value.driveraddress1,
        "contactNumber": this.addDriverMasterForm.value.contactnumber,
        "doj": this.addDriverMasterForm.value.djo,
        "license_number": this.addDriverMasterForm.value.licencenumber,
        "name": this.addDriverMasterForm.value.drivername,
        "salary": this.addDriverMasterForm.value.salary,
        "status": this.addDriverMasterForm.value.status,
        "userid": this.userId
      }
      this.driver.addDriver(body).subscribe(resp=>{
        Swal.fire({
          icon: "success",
          title: "Your Form has been Submitted",
          showConfirmButton: false,
          timer: 1500,
        });
        this.addDriverMasterForm.reset();
        $('#staticBackdrop').modal("hide");
        this.getAllDrivers();
       })
    }
  }

  onDeleteDriver(driverId){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this Driver!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.driver.deleteDriver(driverId).subscribe(resp => {
          console.log(resp);
          this.getAllDrivers();
        });
        Swal.fire(
          'Deleted!',
          'Driver has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Driver is safe :)',
          'error'
        )
      }
    })
  }

  onEditDriver(master){
    this.editForm=true;
    this.addDriverMasterForm.setValue({
      drivername: master.name,
      status: master.status,
      driveraddress1: master.address,
      driveraddress2: master.address,
      licencenumber: master.license_number,
      contactnumber: master.contactNumber,
      salary: master.salary,
      djo: master.date_of_joining.split("T")[0],
    })
    this.masterId = master._id;
  }

  onCloseModal(){
    this.addDriverMasterForm.reset();
  }

  onAddDealer(){
  this.editForm=false;
  this.addDriverMasterForm.reset();
  }

  values(event) {
    if (event.target.value) {
      this.driverMasterList = this.driverMasterList.filter(s => s.name.includes(event.target.value));
      console.log(this.driverMasterList)
    }
    else {
      this.getAllDrivers();
    }
  }

}
