<!-- your-modal-component.component.html -->
<div class="modal-header">
  <h4 class="modal-title">Change Task Status</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
  <div class="col-12 col-md-12">
    <div class="form-group pb-2">
      <label for="type" class="pb-2"><span style="font-weight: 500;">{{'Select Status'|translate}}</span></label>

      <select
        class="form-select me-2   taskspanel"
        id="type"
        style="height: 6.2vh;"
        [ngModel]="selectedTaskStatus"
        (ngModelChange)="selectTaskStatus($event)"

      >
      <option value="0" class="select_options" >Select</option>
      <option value="Unassigned" class="select_options" >Unassigned</option>
      <option value="Assigned" class="select_options" >Assigned</option>
      <option value="Acknowledged" class="select_options">Acknowledged</option>
      <option value="Started" class="select_options"  >Started</option>
      <option value="Completed" class="select_options">Completed</option>
   
     
              
      </select> 

   
      <!-- <span class="text-danger validation" *ngIf="errors?.name">
        {{ errors.name }}
      </span> -->
    </div>
  </div> 
  <!-- <div class="input-group">
    <input type="text" class="form-control" [ngModel]="selectedTaskStatus" readonly>
    <div class="input-group-append">
      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
      <div class="dropdown-menu" style="min-width: 100%;">
        <a class="dropdown-item" href="javascript:void(0);" (click)="selectTaskStatus('Unassigned')" >Unassigned</a>
        <a class="dropdown-item" href="javascript:void(0);" (click)="selectTaskStatus('Assigned')">Assigned</a>
        <a class="dropdown-item" href="javascript:void(0);"(click)="selectTaskStatus('Acknowledged')" >Acknowledged</a>
        <a class="dropdown-item" href="javascript:void(0);"(click)="selectTaskStatus('Started')" >Started</a>
        <a class="dropdown-item" href="javascript:void(0);"(click)="selectTaskStatus('Completed')" >Completed</a>
      </div>
    </div> -->
  </div>

  <!-- <div class="form-floating">
    <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
      <option selected>Open this select menu</option>
      <option value="1">One</option>
      <option value="2">Two</option>
      <option value="3">Three</option>
    </select>
    <label for="floatingSelect">Works with selects</label>
  </div>
  </div> -->

  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" (click)="submit(this.injectedData,this.selectedTaskStatus)">Submit</button>
  </div>
  
  <!-- <div class="dropdown">
    <button class="btn btn-secondary dropdown-toggle" type="button" id="taskStatusDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      {{this.injectedData.status}}
   
    </button>
      <ul class="dropdown-menu option_drop pb-1 pt-1" aria-labelledby="dropdownMenuButton1">
         <li>
          <a class="dropdown-item" href="javascript:void(0);" >
            
            {{'Unassigned'|translate}}</a>
        </li>
        <li>
          <a class="dropdown-item" href="javascript:void(0);">
            {{'Assigned'|translate}}</a>
        </li>
        <li>
          <a class="dropdown-item" href="javascript:void(0);" >
            {{'Acknowledged'|translate}}</a>
        </li>
        <li>
          <a class="dropdown-item" href="javascript:void(0);">
            {{'Started'|translate}}</a>
        </li>
    </ul>

</div> -->
<!-- <div class="input-group">
<input type="text" class="form-control" [ngModel]="selectedTaskStatus" readonly>
<div class="input-group-append">
  <button class="btn btn-secondary dropdown-toggle" id="dropdowntoggle1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
  <ul class="dropdown-menu w-100" aria-labelledby="dropdowntoggle1">
    <li >
      <a href="javascript:void(0);" class="dropdown-item"
   >
      <span class="optionstext">
         {{'Change stop status'|translate}}
      </span>
      </a>
     </li>
     <li >
      <a href="javascript:void(0);" class="dropdown-item"
   >
      <span class="optionstext">
         {{'Change stop status'|translate}}
      </span>
      </a>
     </li>
     <li>
    <a href="javascript:void(0);" class="dropdown-item"
 >
    <span class="optionstext">
       {{'Change stop status'|translate}}
    </span>
    </a>
   </li>
   <li >
    <a href="javascript:void(0);" class="dropdown-item"
 >
    <span class="optionstext">
       {{'Change stop status'|translate}}
    </span>
    </a>
   </li>
   <li >
    <a href="javascript:void(0);" class="dropdown-item"
 >
    <span class="optionstext">
       {{'Change stop status'|translate}}
    </span>
    </a>
   </li>
   
   
   

  </ul>
</div>
</div> -->
