import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(private router: Router, private cookieService: CookieService) {}

  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
    });

    let isFind = false;
    let headerObj = {
      setHeaders: {},
    };
    const details = localStorage.getItem('token');
    if (details && details !== '' && details != "undefined") {
      isFind = true;
      headerObj.setHeaders['authorization'] = `Basic ${details}`;
    }
    const JSESSIONID = this.cookieService.get('JSESSIONID');
    console.log('JSESSIONID----------------------JSESSIONID', JSESSIONID);
    if (JSESSIONID && JSESSIONID !== '') {
      isFind = true;
      headerObj.setHeaders['Cookie'] = `JSESSIONID=${JSESSIONID}`;
      // console.log('JSESSIONID', JSESSIONID);
    }
    if (isFind) {
      request = request.clone(headerObj);
    }
    return next.handle(request).pipe(
      tap(
        (event) => {
          console.log('event----event', event);
          if (event instanceof HttpResponse) {
            if (
              event &&
              event.body &&
              event.body.error &&
              event.body.error === 'loginUser'
            ) {
              // this.authGuard.setLogOutApplication().subscribe((resp: any) => {
              //   if (resp) {
              //     this.router.navigateByUrl('/');
              //   }
              // });
            }
          }
        },
        (error) => {
          console.error(error.status);
          console.error(error.message);
        }
      )
    );
  }
}
