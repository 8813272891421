import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DateTimePipe } from './date-time.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { DateDurationPipe } from './date-duration.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { MeterTokm } from './meter-km.pie';
import { searchPipe } from './search-pipe';

@NgModule({
  declarations: [DateAgoPipe, DateDurationPipe, DateFormatPipe, DateTimePipe,MeterTokm,searchPipe],
  imports: [CommonModule],
  exports: [DateAgoPipe, DateDurationPipe, DateFormatPipe, DateTimePipe,MeterTokm,searchPipe],
})
export class PipesModule {}
