import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { RoutesService } from 'src/app/services/routes.service';
declare var $: any;

@Component({
  selector: 'app-poi-list',
  templateUrl: './poi-list.component.html',
  styleUrls: ['./poi-list.component.css']
})

export class PoiListComponent implements OnInit {

  lat: number = 20.5937;
  lng: number = 78.9629;
  zoom = 6;
  pageOption = 10;
  fullScreen = false;
  pageOptionModal = 10;
  poiFileArray = [];
  todate = "";
  fromdate = "";
  userId = localStorage.getItem("userId");
  searchdevice = "";
  checked = true;
  PoiTable: boolean = true;
  addPoiForm: FormGroup;
  poiListData = [];
  DeviceArray = [];
  UntrackedDevice = [];
  p: number = 1;
  page: number = 1;
  poiAddress = "";
  poiCoordinates = [];
  poiID = "";
  poiRadius: number;
  userAddress: string = "";
  poId="";
  edit=false;

  poiType = [
    { view: "Provider", value: "" },
    { view: "Customer", value: "customer" },
    { view: "Garage", value: "garage" },
    { view: "Emergency", value: "emergency" },
    { view: "Others", value: "others" },
    { view: "Gas Station", value: "gas_station" },
    { view: "Residence", value: "residence" },
    { view: "City", value: "city" },
    { view: "Work", value: "work" },
    { view: "Resturant", value: "resturant" },
    { view: "Shopping", value: "shopping" },
    { view: "Bank", value: "bank" },
    { view: "School", value: "school" },
    { view: "Super Market", value: "supermarket" },
    { view: "Gym", value: "gym" },
    { view: "Home", value: "home" },
    { view: "Grocery Store", value: "grocery_store" }
  ];

  constructor(private poi: RoutesService, private fb: FormBuilder, private customValidator: ValidationService) {
    this.addPoiForm = this.fb.group(
      {
        poiname: ['', Validators.required],
        circularAddress: ['', Validators.required],
        radius: ['10', Validators.required],
        poitype: ['', Validators.required]
      })
  }

  ngOnInit(): void {
    let storedArray = localStorage.getItem("vehicleIDArray");
    this.DeviceArray = JSON.parse(storedArray);
    this.onGetPoiTableData();
  }

  get POIFormControl() {
    return this.addPoiForm.controls;
  }


  //  UNTRACKED PROGRAMS 

  onClickUntrackedVehicle(pois) {
    this.UntrackedDevice = pois['untrackedDevice'];
    this.poiAddress = pois.poi.address;
    this.poiCoordinates = pois.poi.location.coordinates;
    this.poiType = pois.poi.poi_type;
    this.poiID = pois._id;
    this.poiRadius = pois.radius;
  }

  updateUntrackedPoi() {
    let body = {
      "address": this.poiAddress,
      "deviceArr": [],
      "location": {
        "coordinates": this.poiCoordinates,
        "type": "Point"
      },
      "poi_type": this.poiType,
      "poiid": this.poiID,
      "radius": this.poiRadius
    }
    this.poi.updateUntrackedPOI(body).subscribe(resp => {
      console.log(resp);
    })
  }

  check(id) {
    return this.UntrackedDevice.findIndex(a => a == id);
  }

  untrackDevice(e, id) {
    console.log(e, id);
    if (!e.checked) {
      this.UntrackedDevice.push(id);
    }
    else {
      let i = this.UntrackedDevice.findIndex(a => a == id);
      if (i >= 0)
        this.UntrackedDevice.splice(i, 1);
    }
  }

  onUpdateUntrackedVehicles() {
    let body = {
      "address": this.poiAddress,
      "deviceArr": this.UntrackedDevice,
      "location": {
        "coordinates": this.poiCoordinates,
        "type": "Point"
      },
      "poi_type": this.poiType,
      "poiid": this.poiID,
      "radius": this.poiRadius
    }
    this.poi.updateUntrackedPOI(body).subscribe(resp => {
      console.log(resp)
      $('#staticBackdrop').modal('hide');
      this.onGetPoiTableData();
    })

  }

  // END UNTRACKED

  getFullWidth() {
    if (this.fullScreen) {
      document.getElementById("poiForm").style.display = "flex";
      document.getElementById("myMap").style.width = "66.6%";
    }
    else {
      document.getElementById("myMap").style.width = "100%";
      document.getElementById("myMap").style.paddingLeft = "0";
      document.getElementById("poiForm").style.display = "none";
    }
    this.fullScreen = !this.fullScreen;
  }

  onAddPoiBtn() {
    this.PoiTable = false;
  }

  onPoiSubmit() {
    if (this.edit) {
      let body = {
        "address": this.addPoiForm.value.circularAddress,
        "deviceArr": this.UntrackedDevice,
        "location": {
          "coordinates": [this.lng, this.lat],
          "type": "Point"
        },
        "poi_type": this.addPoiForm.value.poitype,
        "poiid":this.poId,
        "radius": this.addPoiForm.value.radius,
      }
      this.poi.updateUntrackedPOI(body).subscribe(resp => {
        console.log(resp)
        this.PoiTable = true;
        this.edit=false;
        this.onGetPoiTableData();
      })
    }
    else {
      let body = {
        "poi": [
          {
            "address": this.addPoiForm.value.circularAddress,
            "poi_type": this.addPoiForm.value.poitype,
            "poiname": this.addPoiForm.value.poiname,
            "radius": this.addPoiForm.value.radius,
            "status": "Active",
            "user": this.userId,
            "location": {
              "coordinates": [this.lng, this.lat],
              "type": "Point"
            }
          }
        ]
      }
      this.poi.addPoi(body).subscribe(resp => {
        console.log(resp);
        this.PoiTable = true;
        this.onGetPoiTableData();
      })
    }
  }

  onClickBack() {
    this.PoiTable = true;
    this.addPoiForm.reset();
  }

  onGetPoiTableData() {
    let body = {
      "columns": [
        { "data": "user" },
        { "data": "radius" },
        { "data": "poi.location.coordinates" },
        { "data": "poi.poiname" },
        { "data": "createdOn" },
        { "data": "status" },
        { "data": "poi.address" },
        { "data": "poi.poi_type" },
        { "data": "untrackedDevice" }
      ],
      "draw": 1,
      "find": { "user": this.userId },
      "length": 10,
      "op": {},
      "order": [
        {
          "column": 3,
          "dir": "desc"
        }
      ],
      "search": {
        "regex": false,
        "value": ""
      },
      "select": [],
      "start": 0
    }
    this.poi.getPoiTable(body).subscribe(resp => {
      console.log(resp)
      this.poiListData = resp.data;
    })
  }

  onDeletePoi(poiId) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this POI!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.poi.deletePoi(poiId).subscribe(resp => {
          console.log(resp);
          this.onGetPoiTableData();
        });
        Swal.fire(
          'Deleted!',
          'Your POI has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your POI is safe :)',
          'error'
        )
      }
    })
  }

  values(event) {
    if (event.target.value) {
      this.DeviceArray = this.DeviceArray.filter(s => s.Device_Name.includes(event.target.value));
      console.log(this.DeviceArray)
    }
    else {
      let storedArray = localStorage.getItem("vehicleIDArray");
      this.DeviceArray = JSON.parse(storedArray);
    }
  }

  handleAddressChange(address: any) {
    this.userAddress = address.formatted_address
    this.lat = address.geometry.location.lat()
    this.lng = address.geometry.location.lng()
  }

  onEditPoi(pois) {
    this.PoiTable = false;
    this.edit=true;
    this.addPoiForm.setValue({
      poiname: pois.poi.poiname,
      circularAddress: pois.poi.address,
      radius: pois.radius,
      poitype: pois.poi.poi_type
    })
    this.userAddress=pois.poi.address,
    this.poId=pois._id;
    this.lat = pois.poi.location.coordinates[0],
    this.lng = pois.poi.location.coordinates[1]
  }

}
