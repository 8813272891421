<mat-sidenav-container class="example-container">
    <!-- <mat-sidenav #sidenav opened>
      <app-sidenav></app-sidenav>
    </mat-sidenav> -->
    
    <mat-sidenav-content>
        <!-- <button (click)="sidenav.toggle()" mat-icon-button><i class="material-icons">menu</i> </button> -->
        <app-header class="d-flex margin_header"></app-header>

      <div class="main-content">        
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  