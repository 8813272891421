<p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ POI List</span></p>

<div class="container-fluid ">

  <div class="row p-0" *ngIf="PoiTable">
    <div class="card p-0 headcard">

      <!-- HEADER WITH ADD POI BTNS -->
      <div class="card-header1 flex_row_center_spacebetween">
        <div class="cardHead_text">POI List</div>
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item">
            <div style="display: flex;flex-direction: row;">
              <button mat-raised-button class="addbtns px-3 py-1" style="margin-right: 1vh;" (click)="onAddPoiBtn()">
                <span class="material-icons" style="font-size: 2.1vh;">
                  add
                </span> Add POI</button>
              <button mat-raised-button class="addbtns px-5 py-1" data-bs-toggle="modal"
                data-bs-target="#staticBackdropPOI">
                <span class="material-icons" style="font-size: 2.1vh;">
                  add
                </span> Add multiple POI
              </button>
            </div>
          </li>
        </ul>
      </div>
      <!-- DATE DOWNLOAD HEADER -->
      <div class="card-header flex_row_center_spacebetween">
        <div class="flex_row_center_spacebetween" style="width: 47vh;">
          <input type="date" id="meeting" value="2022-03-26" [(ngModel)]="fromdate" name="fromdate" />
          <span class="to">{{'To'|translate}}</span>
          <input type="date" id="meeting" value="2022-03-27" [(ngModel)]="todate" name="todate" />
          <select class="form-select selectimportant" [(ngModel)]="pageOption" name="pageOption">
            <option value="10" class="select_options">10</option>
            <option value="20" class="select_options">20</option>
            <option value="30" class="select_options">30</option>
            <option value="40" class="select_options">40</option>
            <option value="50" class="select_options">50</option>
            <option value="60" class="select_options">60</option>
          </select>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;">
          <span class="btn"><img src="/assets/icons/report_refresh.png" alt="refresh" style="width: 96%;"></span>
          <span class="btn"><img src="/assets/icons/report_download.png" alt="download" style="width: 96%;"></span>
        </div>
      </div>
      <!-- POI LIST TABLE -->
      <div class="card-body common_scroller fixed_height p-0">
        <table class="table table-borderless m-0" style="border-bottom: 1px solid #E3E7EC;">

          <thead style="background-color:  #24282E;">
            <tr>
              <th class="table_head_text text-center" style="width: 10%;">POI Name</th>
              <th class="table_head_text text-center" style="width: 31%;">Address</th>
              <th class="table_head_text text-center">Radius</th>
              <th class="table_head_text text-center">POI Type</th>
              <th class="table_head_text text-center">Latitude</th>
              <th class="table_head_text text-center">Longitude</th>
              <th class="table_head_text text-center">Status</th>
              <th class="table_head_text text-center">Untracked Vehicles</th>
              <th class="table_head_text text-center">Action</th>
            </tr>
          </thead>

          <tbody *ngIf="poiListData.length">
            <tr *ngFor="let pois of poiListData |  paginate : {
              itemsPerPage: 10,
              currentPage: page,
              totalItems: this.poiListData.length
            };let i=index">
              <td class="table_data text-center">{{pois.poi?.poiname}}</td>
              <td class="table_data text-center">{{pois.poi?.address}}</td>
              <td class="table_data text-center">{{pois.radius | number : '1.2-2'}}</td>
              <td class="table_data text-center">{{pois.poi?.poi_type}}</td>
              <td class="table_data text-center">{{pois.poi?.location?.coordinates[0]}}</td>
              <td class="table_data text-center">{{pois.poi?.location?.coordinates[1]}}</td>
              <td class="table_data text-center">{{pois.status}}</td>
              <td class="table_data text-center" style="cursor: pointer;" data-bs-toggle="modal" (click)="onClickUntrackedVehicle(pois)"
                data-bs-target="#staticBackdrop">
                <img src="/assets/icons/table_vehicle.png" alt="car">
              </td>
              <td class="table_data text-center" style="cursor: pointer;">
                <div class="dropdown">
                  <button class="btn dropdown-toggle p-0 border-none" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="/assets/icons/hollow_options.png" alt="car">
                  </button>
                  <ul class="dropdown-menu option_drop py-0" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item pt-2" style="justify-content: flex-start;" (click)="onEditPoi(pois)">
                        <img src="/assets/icons/editgeo.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 1.5vh;">{{'Edit'|translate}}</span>
                      </a></li>
                    <li><a class="dropdown-item py-2" style="justify-content: flex-start;" (click)="onDeletePoi(pois._id)">
                        <img src="/assets/icons/deletegeo.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 2.5vh;">{{'Delete'|translate}}</span>
                      </a></li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!poiListData.length">
            <tr>
              <td colspan="9">
                <div class="d-flex justify-content-center align-items-center" style="height: 48vh;">
                  <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE">
                </div>
              </td>
            </tr>
          </tbody>

        </table>
      </div>
      <!-- PAGINATION -->
      <div *ngIf="poiListData.length" class="flex_row_center_spacebetween"
        style="padding:2vh;border-top: 1px solid #E3E7EC;">
        <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{poiListData.length/10 | number: '1.0-0'}} {{'of'|translate}}
          {{poiListData.length/10 |  number: '1.0-0'}} {{'entries'|translate}}</div>
        <pagination-controls (pageChange)="page = $event"></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row p-0" *ngIf="!PoiTable">
    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 first_col" id="poiForm">
      <div class="card addmain_card p-0">
        <div class="addgeohead" style="padding-bottom: 0.75rem;">Add POI</div>
        <div class="card geofence_card2" style="height: 73.1vh;">
          <form [formGroup]="addPoiForm" (ngSubmit)="onPoiSubmit()">

            <div class="form-group pb-3">
              <label for="poiname" class="addlabels pb-2">Enter POI Name</label>
              <input type="text" formControlName="poiname" class="form-control inputfields" id="poiname"
                aria-describedby="poiname">
              <span class="text-danger validation"
                *ngIf="(POIFormControl.poiname.touched || submitted) && POIFormControl.poiname.errors?.required">
                *POI name is required.
              </span>
            </div>

            <div class="form-group">
              <label for="location1" class="addlabels pb-2">Add Location</label>
              <div class="input-group inputborder">
                <div class="input-group-prepend">
                  <div class="input-group-text searchIcon">
                    <img src="/assets/icons/searchwhite.png" alt="search">
                  </div>
                </div>
                <input ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" class="form-control inputfields" style="border: none !important;" id="location1"
                  formControlName="circularAddress" placeholder="Search Location">
              </div>
            </div>

            <div class="address py-3">
              <div class="flex_row_center_spacebetween">
                <div class="flex_row_center_spacebetween pb-1">
                  <img src="/assets/icons/address.png" alt="addressicon" class="address_img">
                  <div class="geobody" style="font-size: 1.5vh !important;">
                    {{ userAddress }}
                  </div>
                </div>
                <span><img src="/assets/icons/close.png" alt="close" style="cursor: pointer;"></span>
              </div>
            </div>

            <div class="form-group pb-3">
              <label for="radius" class="addlabels pb-2">Select Radius</label>
              <select class="form-select form_select1" formControlName="radius">
                <option value="10" selected>10 Meters</option>
                <option value="20">20 Meters</option>
                <option value="30">30 Meters</option>
                <option value="40">40 Meters</option>
                <option value="50">50 Meters</option>
              </select>
            </div>

            <div class="form-group pb-3">
              <label for="poitype" class="addlabels pb-2">POI Type</label>
              <select class="form-select form_select1" formControlName="poitype" id="poitype">
                <option *ngFor="let poi of poiType;let i=index" value="{{poi.value}}" class="select_options">
                  {{poi.view}}
                </option>
              </select>
              <span class="text-danger validation"
                *ngIf="(POIFormControl.poitype.touched || submitted) && POIFormControl.poitype.errors?.required">
                *POI type is required.
              </span>
            </div>

            <div class="row" style="padding-top: 9vh;">
              <div class="col-6">
                <!-- <button mat-raised-button class="cancelbtns">{{'CANCEL'|translate}}</button> -->
              </div>
              <div class="col-6">
                <button mat-raised-button type="submit" class="submitbtn">ADD</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>

    <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-12 col_map" id="myMap">
      <!-- GOOGLE MAP -->
      <div class="card main_card p-0">
        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
          <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
        </agm-map>
      </div>
      <!-- MAP RIGHT ICONS -->
      <div class="btn-group-vertical" role="group" aria-label="Basic example"
        style="position: absolute;top: 2vh;right: 3vh;">
        <button matTooltip="Back" type="button" class="btn mapbtns" style="margin-top: 1vh;border-radius: 5px;padding-bottom: 0;" (click)="onClickBack()">
          <span class="material-icons" style="color: #fff;"> 
            arrow_back 
          </span>
        </button>
        <button type="button" class="btn mapbtns" style="margin-top: 1vh;border-radius: 5px;">
          <img src="/assets/icons/map1.png" alt="mapicon">
        </button>
        <button type="button" class="btn mapbtns" style="margin-top: 1vh;border-radius: 5px;">
          <img src="/assets/icons/map4.png" alt="mapicon">
        </button>
        <button type="button" class="btn mapbtns" style="margin-top: 1vh;border-radius: 5px;">
          <img src="/assets/icons/map2.png" alt="mapicon">
        </button>
        <button type="button" class="btn mapbtns openbtn" style="margin-top: 1vh;border-radius: 5px;">
          <img src="/assets/icons/map6.png" alt="mapicon">
        </button>
      </div>
      <!-- MAP BOTTOM ICONS -->
      <div class="btn-group" role="group" aria-label="Basic example" style="position: absolute;bottom: 3vh;left: 4vh;">
        <button type="button" class="btn mapbtns" style="margin-left: 2vh;border-radius: 5px;padding: 0.5vh 0.8vh;">
          <img src="/assets/icons/map8.png" alt="mapicon">
        </button>
        <button type="button" class="btn mapbtns" style="margin-left: 2vh;border-radius: 5px;padding: 0.5vh 0.8vh;">
          <img src="/assets/icons/map9.png" alt="mapicon">
        </button>
        <button type="button" class="btn mapbtns" style="margin-left: 2vh;border-radius: 5px;padding: 0.5vh 0.8vh;"
          (click)="getFullWidth()">
          <img src="/assets/icons/map10.png" alt="mapicon">
        </button>
      </div>
    </div>
  </div>

</div>

<!-- UNTRACKED VEHICLES MODAL -->

<div class="modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content content1">
      <div class="modal-header headerModal">

        <select class="form-select selectimportant" [(ngModel)]="pageOptionModal" name="pageOptionModal">
          <option value="10" class="select_options">10</option>
          <option value="20" class="select_options">20</option>
          <option value="30" class="select_options">30</option>
          <option value="40" class="select_options">40</option>
          <option value="50" class="select_options">50</option>
          <option value="60" class="select_options">60</option>
        </select>

        <div class="input-group searchvehicles">
          <input type="text" class="form-control reportsbox" placeholder="Search Vehicle" ([ngModel])="searchdevice" (input)="values($event)"
          name="searchdevice" aria-label="Recipient's username" aria-describedby="basic-addon2">
          <div class="input-group-append">
            <button class="btn" type="button" style="padding:0.6vh 1.4vh 1.1vh 1.4vh;">
              <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
            </button>
          </div>
        </div>

        <button type="button" class="btnclose" data-bs-dismiss="modal" aria-label="Close">
          <span class="material-icons">
            close
          </span>
        </button>
      </div>
      <div class="modal-body p-0 headerModal" style="background: #FFF;border-radius: 5px;">
        <table class="table table-borderless m-0">
          <thead style="background-color:  #24282E;">
            <tr>
              <th class="table_head_text text-center" style="width: 1%;">Select</th>
              <th class="table_head_text text-center">Vehicle Name</th>
              <th class="table_head_text text-center">IMEI</th>
            </tr>
          </thead>

          <tbody *ngIf="DeviceArray.length">
            <tr *ngFor="let device of DeviceArray |  paginate : {
              itemsPerPage: 10,
              currentPage: p,
              totalItems: this.DeviceArray.length
            };let i = index">
              <td class="table_data text-center" style="background: rgba(36, 40, 46, 0.1);">
                <mat-checkbox class="example-margin" (change)="untrackDevice($event,device._id)" [checked]="(check(device._id)>=0)?false:true"></mat-checkbox>
              </td>
              <td class="table_data text-center">{{device.Device_Name}}</td>
              <td class="table_data text-center">{{device.Device_ID}}</td>
            </tr>
          </tbody>

          <tbody *ngIf="!DeviceArray.length">
            <tr>
              <td colspan="2">
                <div class="d-flex justify-content-center align-items-center" style="height: 48vh;">
                  <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE">
                </div>
              </td>
            </tr>
          </tbody>

        </table>
        <div *ngIf="DeviceArray.length" class="flex_row_center_spacebetween" style="padding:2vh;border-top: 1px solid #E3E7EC;">
          <div>{{'Showing'|translate}} {{p}} {{'to'|translate}} {{DeviceArray.length/10 | number: '1.0-0'}} {{'of'|translate}} {{DeviceArray.length/10 |  number: '1.0-0'}} {{'entries'|translate}}</div>
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>
      <div class="flex_row_center_spacebetween">
        <button mat-raised-button class="cancelbtn" data-bs-dismiss="modal">{{'CANCEL'|translate}}</button>
        <button mat-raised-button class="cancelbtn" (click)="onUpdateUntrackedVehicles()">UPDATE</button>
      </div>
    </div>
  </div>
</div>

<!-- ADD MULTIPLE POI MODAL -->

<div class="modal" id="staticBackdropPOI" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content content2">
      <div class="modal-header" style="border-bottom: 1px solid #324151;">
        <div class="card-title titlehaed m-0">Add Multiple POI</div>
        <button type="button" class="btnclose" data-bs-dismiss="modal" aria-label="Close">
          <span class="material-icons">
            close
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form>

          <div class="form-group pb-4 pt-2">
            <label class="addlabels pb-2">Browse for Excel File</label>
            <div class="input-group mb-3">
              <input type="text" class="form-control inputfiles inputfields" [(ngModel)]="poiFileArray"
                name="poiFileArray" disabled placeholder="No File Chosen" aria-label="Recipient's username"
                aria-describedby="basic-addon2">
              <div class="input-group-append" style="height: 6.6vh;">
                <span class="input-group-text choosebtn p-0" id="basic-addon2">
                  <label class="choosetext"> Choose File
                    <input type="file" formControlName="multiplepoi" class="form-control inputfields p-0"
                      id="multiplepoi" aria-describedby="multiplepoi" multiple />
                  </label>
                </span>
              </div>
            </div>
          </div>

          <div class="flex_row_center_spacebetween">
            <button mat-raised-button class="cancelbtn" style="width: 47%;padding: 0.8vh 0;">{{'CANCEL'|translate}}</button>
            <button mat-raised-button class="cancelbtn" style="width: 47%;padding: 0.8vh 0;">UPLOAD</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
