import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { UsersettingService } from 'src/app/services/usersetting.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CommonService } from 'src/app/services/common.service';
declare var $: any;
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  @ViewChild('fileInput') myInputVariable: ElementRef;
  todate = "";
  currentView= false;
  filesList = [];
  fromdate = "";
  pageOption = 10;
  supportList = [];
  deviceList = [];
  modelId = "";
  form:FormGroup;
  editForm = false;
  userList = [];
  userId = localStorage.getItem("userId");
  page: number = 1;
  annoucmentForm: FormGroup;
  submitted = false;
  searchMasterModel = "";
  superAdmin :boolean = false;
  constructor(private masterModel: UsersettingService,private commonService:CommonService, private fb: FormBuilder, private customValidator: ValidationService) {
    this.annoucmentForm = this.fb.group(
      {
        description: ['', Validators.required],
        deviceid: ['', Validators.required],
        status: ['', Validators.required],
        resolutiondate: ['', Validators.required],
        type: ['', Validators.required],
        remarks: ['', Validators.required],
        assignto: ['', Validators.required],
        expectedresolutiondate: ['', Validators.required],
       
      });
      this.form = new FormGroup({
        searchValue: new FormControl(''),
      });
  }

  ngOnInit(): void {
    
    this.getSupport();
    this.getDevice();
    this.getAllCustomers();
    let userDataObj = localStorage.getItem('userData');
    if (userDataObj) {
      try {
        let userData =  JSON.parse(userDataObj);
        this.superAdmin = userData && userData.administrator;
      } catch (error) {}
    }
  }

  get supportFormControl() {
    return this.annoucmentForm.controls;
  }
  resetFile() {
    this.myInputVariable.nativeElement.value = '';
    this.file = null;
}
  getAllCustomers() {
    this.commonService.getRequest('users', []).then((resp: any) => {
      if (resp?.status) {
          this.userList = resp.data.filter(customer=> customer.userLimit == 0)
          this.mapUserName();
      }
    });
  }
  getSupport() {
    this.masterModel.getSupport(this.currentView).subscribe(resp => {
      this.supportList = resp;
      this.mapDeviceName();
      this.mapUserName();
    })
  }
  file;
  onChangeFile(event) {
    this.file = event.target.files[0];
  }
  getDevice() {
    this.deviceList = [];
    this.commonService.getRequest('devices', []).then((resp: any) => {
      if (resp?.status) {
        this.deviceList = resp.data;
        this.mapDeviceName();
    
      }
    });
  }
  onModelMasterSubmit() {
    
      let body = this.annoucmentForm.value;
      if(this.modelId) {
        this.masterModel.supportWithFile(body,this.file,this.modelId).subscribe(resp => {
          Swal.fire({
            icon: "success",
            title: "Your Form has been Updated",
            showConfirmButton: false,
            timer: 1500,
          });
          this.annoucmentForm.reset();
          $('#staticBackdrop').modal("hide");
          this.getSupport();
        })
      } else {
        this.masterModel.supportWithFile(body,this.file).subscribe(resp => {
          Swal.fire({
            icon: "success",
            title: "Your Form has been Submitted",
            showConfirmButton: false,
            timer: 1500,
          });
          this.annoucmentForm.reset();
          $('#staticBackdrop').modal("hide");
          this.getSupport();
        })
      }
      
    
  }

  edit(model,files){
    this.editForm=true;
    this.annoucmentForm.setValue({
      type : model.type,
      description : model.description,
      assignto : model.assignto,
      status : model.status,
      remarks : model.remarks,
      deviceid : model.deviceid,
      resolutiondate : model.resolutiondate ? model.resolutiondate.split("T")[0] : null,
      expectedresolutiondate : model.expectedresolutiondate ? model.expectedresolutiondate.split("T")[0] : null,
    })
    this.modelId = model.ticketid;
    this.filesList = files.length ?files : [] ;
    this.resetFile();
    
  }

  delete(modelId){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this Announcement!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.masterModel.deleteSupport(modelId).subscribe(resp => {
          console.log(resp);
          this.getSupport();
        });
        Swal.fire(
          'Deleted!',
          'ModelMaster has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'ModelMaster is safe :)',
          'error'
        )
      }
    })
  }

  onCloseModal(){
    this.annoucmentForm.reset();
  }

  onAddModelMaster() {
    this.editForm = false;
    this.modelId = '';
    this.annoucmentForm.reset();
    this.resetFile();
  }
  fileDownload(id) {
    this.masterModel.getDownloadSupport(id);
  }
  values(event) {
    // if (event.target.value) {
    //   this.supportList = this.supportList.filter(s => s.ModelName.includes(event.target.value));
    //   console.log(this.supportList)
    // }
    // else {
    //   this.getSupport();
    // }
  }
  mapDeviceName() {
    if(this.deviceList.length && this.supportList.length) {
      this.supportList.forEach(support=>{
        let fDevice =  this.deviceList.find(device=> device.id ==support.supportDetails.deviceid);
        if(fDevice) {
          support.supportDetails.deviceName = fDevice.name;
        }
      })
    }
  }
  changeView() {
    this.getSupport();
  }
  mapUserName() {
    if(this.userList.length && this.supportList.length) {
      this.supportList.forEach(support=>{
        let fUser =  this.userList.find(user=> user.id ==support.supportDetails.assignto);
        if(fUser) {
          support.supportDetails.assigntoName = fUser.name;
        }
      })
    }
  }

}

