<!-- your-modal-component.component.html -->
<div class="container p-1">
  <div class="modal-header">
    <h4 class="modal-title">Route</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="closeModal()"
    ></button>
  </div>
  <div class="modal-body">
    <div id="mymap" style="height: 300px"></div>
    <div class="modal-footer">
      <button class="btn btn-secondary" (click)="closeModal()">Close</button>
    </div>
  </div>
  <!-- Modal -->

</div>


<div #mapContainer style="height: 0; overflow: hidden; padding: 0;">
  <div
    class="d-flex align-items-center justify-content-between card-header"
    style="
      padding-bottom: 12px;
      background-color: #ffd892;
      /* margin-left: 4px; */
      padding-top: 15px;
    "
  >
    <span
      class="badge rounded-pill"
      [ngClass]="getBgColorBasedOnTaskStatus(status)"
    >
      {{ status |uppercase }}
    </span>
    <div class="d-flex">
      <span
        class="gray-button header-btn"
        [ngClass]="getBgColorBasedOnTaskStatus(status)"
      >
        <span class="font-size-18">
          {{ actual_emp }}
        </span>
        <span>Actual Emp</span>
      </span>
      <span
        class="gray-button header-btn"
        [ngClass]="getBgColorBasedOnTaskStatus(status)"
      >
        <span class="font-size-18">
          {{ planned_emp }}
        </span>
        <span>Planned Emp</span>
      </span>
      <span
        class="gray-button header-btn"
        [ngClass]="getBgColorBasedOnTaskStatus(status)"
      >
        <span class="font-size-18">
          {{ escort === '1' ? 'Yes' :'No'}}
        </span>
        <span>Escort</span>
      </span>
    </div>
  </div>
  <div class="col-12">
    <div class="row mt-1 ms-1 p-0">
      <div class="col-9">
        <div>
          <span class="fw-bold"><i class="fa-solid fa-id-card"></i></span>
          <span class="ms-1">{{ id }}</span
          >&nbsp;&nbsp;
          <span class="fw-bold"><i class="fa-solid fa-route"></i></span>
          <span class="ms-1">{{ type | titlecase}}</span
          >&nbsp;&nbsp;
          <span>
            <img src="/assets/icons/vehicletimer.png" alt="Time" />
          </span>
          <span class="ms-1">{{ Shift_Timing }}</span>
        </div>
        <div>
          <span class="fw-bold">Company: </span>
          <span class="ms-1">{{ clientid | titlecase }}</span>
        </div>
        <div>
          <span class="fw-bold">Address: </span>
          <span *ngIf="type == 'pickup' || type == 'Pickup'" class="ms-1">
            {{ pickuplocationt }}
          </span>
          <span *ngIf="type == 'drop' || type == 'Drop'" class="ms-1">
            {{ droplocationt }}
          </span>
          <div>
            <span class="fw-bold">Cab no: </span>
            <span class="ms-1">{{ cabno }}</span>
          </div>
          <div>
            <span class="fw-bold">Last update time: </span>
            <span id='deviceTime'class="ms-1">{{ this.deviceTime | date:"yyyy-MM-dd hh:mm:ss a"}}</span>
          </div>
        
        </div>
      </div>
    </div>
  </div>
</div>

