import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { map as rxMap, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { create } from 'domain';

declare var google: any;
declare var document: any;
declare var map: any;
@Component({
  selector: 'app-createtask',
  templateUrl: './createtask.component.html',
  styleUrls: ['./createtask.component.css'],
})
export class CreatetaskComponent implements OnInit, AfterViewInit {
  pickLocation: any;
  today: Date;
  @ViewChild('inputField') inputField!: ElementRef;
  @Input() placeholder = '';
  autocomplete: google.maps.places.Autocomplete;
  disabled: boolean = true;
  lat: any;
  long: any;
  map: any = null;
  taskform: FormGroup;
  submitted: boolean = false;
  fromDateHours = '';
  fromDateMinutes = '';
  customerList: any;
  userData: any;
  orgId: any;
  clientDetail: any;
  endLat: any;
  endLong: any;
  todayDate: string;
  place: any;
  postalCodeComponent: any;
  cityComponent: any;
  latitude: any;
  longitude: any;
  shiftControl = new FormControl();
  filteredShifts: Observable<string[]>;
  dateControl = new FormControl(new Date());
 startDate: string;
 endDate: string;
  shift: any;
  pickupStartDate: string;
event: any;
selectedDate:any='';
  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private router: Router
  ) {
    this.todayDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.taskform = this.fb.group({
      clientId: ['0', Validators.required],
      address: ['', Validators.required],
      date: [this.todayDate ? this.todayDate : '', Validators.required],

      triptype: ['0', Validators.required],
      escort: ['0', Validators.required],
     
      plannedemp: ['', Validators.required],
      actualemp: ['', Validators.required],
      flapno: ['',],
      shift: ['50'],
 
    });

    this.filteredShifts = this.shiftControl.valueChanges.pipe(
      startWith(''),
      rxMap(value => this._filter(value))
    );
    this.shiftControl.valueChanges.subscribe(shift => {
      this.shift=shift;
    });

  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.shifts.filter(shift => shift.toLowerCase().includes(filterValue));
  }
  ngOnInit(): void {
    let userDataObj = localStorage.getItem('userData');
    if (userDataObj) {
      try {
        this.userData = JSON.parse(userDataObj);

        console.log('Org Id =>', this.userData.id);
        if (this.userData.attributes.role === 'admin') {
          this.orgId = this.userData.id;
        } else {
          this.orgId = this.userData.orgId;
        }
      } catch (error) {}

      this.getAllCustomers();
    }
    this.taskform.get('date').valueChanges.subscribe((selectedDate: string) => {
      console.log('Selected Date:', selectedDate);
      this.selectedDate=selectedDate;

  });
  }
  ngAfterViewInit() {
    console.log('Input Field:', this.inputField.nativeElement);
    this.autocomplete = new google.maps.places.Autocomplete(
      this.inputField.nativeElement
    );
    this.autocomplete.addListener('place_changed', () => {
      const place: any = this.autocomplete.getPlace();
      this.place = place;
      const typedAddress = this.inputField.nativeElement.value;
      const fullAddress = place.formatted_address;
      console.log(place);
      if (place && place.geometry && place.geometry.location) {
        this.lat = place.geometry.location.lat();
        this.long = place.geometry.location.lng();
        this.taskform.value.address = place.formatted_address;
        if (place) {
          this.cityComponent = place.address_components.find((component: any) =>
            component.types.includes('locality')
          );
          const stateComponent = place.address_components.find(
            (component: any) =>
              component.types.includes('administrative_area_level_1')
          );
          this.postalCodeComponent = place.address_components.find(
            (component: any) => component.types.includes('postal_code')
          );
        }
        this.taskform.patchValue({
          address: fullAddress,
        });
        if (!this.map) {
          this.map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: this.lat, lng: this.long },
            zoom: 15,
          });
        }
        const myLatlng = new google.maps.LatLng(this.lat, this.long);

        this.map.setCenter(myLatlng);
      }
    });
  }
  

  shifts = [
    "00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45",
    "02:00", "02:15", "02:30", "02:45", "03:00", "03:15", "03:30", "03:45",
    "04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45",
    "06:00", "06:15", "06:30", "06:45", "07:00", "07:15", "07:30", "07:45",
    "08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45",
    "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45",
    "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45",
    "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45",
    "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45",
    "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45",
    "20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45",
    "22:00", "22:15", "22:30", "22:45", "23:00", "23:15", "23:30", "23:45"
  ];
  setpinCity() {
    this.taskform.value.city = this.place.address_components[5].long_name;
    this.taskform.value.pincode = this.place.address_components[9].long_name;
  }
  get taskformControl() {
    return this.taskform.controls;
  }
  submit() {
    console.log("client in details=>",this.clientDetail)
    if (this.taskform.invalid) {
   
      Swal.fire({
        icon: 'error',
        text: 'All fields are required',
        showConfirmButton: false,
        timer: 1500,
        toast:true,
        position:'top-end'
      });
      return;
    }
    console.log(this.shift +''+this.shiftControl.value)
    if (this.shiftControl.value === null) {
      Swal.fire({
        icon: 'error',
        text: 'Please select Shift time',
        showConfirmButton: false,
        timer: 1500,
        toast:true,
        position:'top-end'
      });
      return;
    }
    this.submitted = true;
    let tasktype = this.taskform.value.triptype;
    let lat;
    let long;
    let pickuplocation = '';
    let droplocation = '';
    let escort = '0';
    let startLat;
    let startLong;
    let endLat;
    let endLong;
    let pickupLocation;
    let dropLocation;
    let startTime;
    let endTime;
    if(this.selectedDate != ''){

      const day1 =this.selectedDate.getDate();
      const month1 = this.selectedDate.getMonth() + 1;
      const year1 = this.selectedDate.getFullYear(); 
     
      const [hours, minutes] = this.shift.split(':');
      const selectedHours = parseInt(hours);
      const selectedMinutes = parseInt(minutes);
      this.startDate = new Date(
        `${year1}-${month1}-${day1} ${selectedHours}:${selectedMinutes}`).toISOString();
      console.log("Day:", day1);
      console.log("Month:", month1);
      console.log("Year:", year1);
    }
    else{
      const dateWithoutTime1 = this.taskform.value.date;
      this.today = new Date(dateWithoutTime1);
      const [day1, month1, year1] = dateWithoutTime1.split('/');
      const [hours, minutes] = this.shift.split(':');
      const selectedHours = parseInt(hours);
      const selectedMinutes = parseInt(minutes);
      this.startDate = new Date(
        `${year1}-${month1}-${day1} ${selectedHours}:${selectedMinutes}`).toISOString();
    }
  
   
    console.log(this.today);
 
    console.log(this.startDate);
    const updatedDate = new Date(this.startDate);
  
let date=new Date(
  this.startDate
)
date.setUTCHours(0,0,0,0)
debugger
console.log('selected date=>',date +" "+ 'update date=> ',updatedDate )
  updatedDate.setHours(updatedDate.getHours() + 1);
  updatedDate.setMinutes(updatedDate.getMinutes() + 30);
  this.endDate = updatedDate.toISOString();
  console.log('end date=>', this.endDate);
  const updatedDate1 = new Date(this.startDate);
  // Add 1 hour and 30 minutes
  updatedDate1.setHours(updatedDate1.getHours() - 1);
  updatedDate1.setMinutes(updatedDate1.getMinutes() - 30);
this.pickupStartDate= updatedDate1.toISOString();
console.log(this.pickupStartDate)


    if (navigator.geolocation) {

      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = `${position.coords.latitude}`;
        this.longitude = `${position.coords.longitude}`;
        if (tasktype === 'pickup') {
          startTime= this.pickupStartDate;
          endTime = this.startDate;
          pickupLocation = this.taskform.value.address;
          dropLocation =  this.clientDetail[0].name;
          startLat = this.lat;
          startLong = this.long;
          endLat = this.endLat;
          endLong = this.endLong;
        } else if (tasktype === 'drop') {
          startTime= this.startDate;
          endTime=this.endDate;
          pickupLocation = this.clientDetail[0].name;
          dropLocation = this.taskform.value.address;
          startLat = this.endLat;
          startLong = this.endLong;
          endLat = this.lat;
          endLong = this.long;
        
  
        }
        if (this.taskform.value.escort !== 0) {
          escort = this.taskform.value.escort;
        }
        let createdOn=new Date();
        createdOn.setHours(0,0,0,0)
        const requestBody = {
          attributes: {
            Shift_Timing: this.shift,
            planned_emp: this.taskform.value.plannedemp,
            actual_emp: this.taskform.value.actualemp,
            latitude: this.latitude,
            longitude: this.longitude,
            escort:this.taskform.value.escort,
            clientName: this.taskform.value.clientId,
            remarks: '',
          },
          fno:this.taskform.value.flapno,
          date: date,
          createdOn:new Date().toISOString(),
          type: this.taskform.value.triptype,
          expectedstarttime: startTime,
          expectedendtime : endTime,
          startlatitudelocation: startLat,
          startlongitudelocation: startLong,
          endlatitudelocation: endLat,
          endlongitudelocation: endLong,
          pickuplocation: pickupLocation,
          droplocation: dropLocation,
          status: 'unassigned',
          clientid: this.taskform.value.clientId,
          distance: 0.0,
          createdBy: this.orgId,
          orgId: this.orgId,
          shiftTiming:this.shift
        };
        this.commonService.createTask('traccartasks', requestBody).subscribe(
          (resp) => {
            console.log('Response:', resp);
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Task created successfully',
              showConfirmButton: false,
              timer: 1500,
            });

            this.taskform.reset();
          },
          (error) => {
            console.error('Error:', error);
          }
        );

        this.commonService.commonModel.next(false);
      });
    } else {
      return;
    }
  }
  cancel() {
    this.commonService.commonModel.next(false);
  }
  redirectTrackerUrl(type) {
    console.log('type', type);
    if (type === 'All') {
      this.router.navigate(['/main/tracking']);
    } else {
      this.router.navigateByUrl('/main/tracking?type=' + type);
    }
  }
  getAllCustomers() {
    this.commonService
      .getRequest(`users?orgId=${this.orgId}`, [])
      .then((resp: any) => {
        if (resp?.status) {
          this.customerList = resp.data;

          this.customerList = this.customerList.filter(
            (customer) => customer.attributes?.role == 'clients'
          );
        }
      });
  }

  getClientDetails(event: any) {
    console.log(event.target.value);
    this.commonService
      .getRequest(`users?orgId=${this.orgId}`, [])
      .then((resp: any) => {
        if (resp?.status) {
          this.clientDetail = resp.data;
          this.clientDetail = this.customerList.filter(
            (customer) => customer.email == event.target.value
          );
          console.log(this.clientDetail);
          this.endLat = this.clientDetail[0].attributes.location.lat;
          this.endLong = this.clientDetail[0].attributes.location.lon;
          console.log(this.endLat, this.endLong);
        }
      });
  }

}
