
<div class="modal-header">
  <h4 class="modal-title">{{drivrLocation}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
 <div id="map" style="height: 300px;"></div>
  <!-- <div class="modal-footer">
    <button class="btn btn-secondary" (click)="closeModal()">Close</button>
  </div>  -->



