
<app-common-modal-popup>
    <div class="" modelheadertext *ngIf="addMode">Change password</div> 
    <div class="container-fluid " modeldata *ngIf="addMode">
      <form [formGroup]="changePasswordForm" (ngSubmit)="onChangePasswordSubmit()">
  
        <div class="row">
          <div class="col-6">
            <div class="form-group pb-2">
              <label for="manufacturer" class="addlabels pb-2">{{'New Password'|translate}}</label>
              <input type="password" formControlName="newpassword" class="form-control inputfields"
                style="background: #FFF;box-shadow:none;color:#000;">
              <span class="text-danger validation"
                *ngIf="(changePasswordForm.controls.newpassword.touched || submitted) && changePasswordForm.controls.newpassword.errors?.required">
                *Password is required.
              </span>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group pb-2">
              <label for="apkCommand" class="addlabels pb-2">{{'Confirm Password'|translate}}</label>
              <input type="password" formControlName="confirmpassword" class="form-control inputfields"
                style="background: #FFF;box-shadow:none;color:#000;">
              <span class="text-danger validation"
                *ngIf="(changePasswordForm.controls.confirmpassword.touched || submitted) && changePasswordForm.controls.confirmpassword.errors?.required">
                *Confirm password is required.
              </span>
            </div>
          </div>
        </div>
  
        <div class="d-flex align-items-center pt-4 pb-2">
          <button mat-raised-button  type="submit" class="cancelbtn">SUBMIT</button>
          <button mat-raised-button type="button" class="cancelbtn ms-3"
            (click)="onCloseModal()">{{'CANCEL'|translate}}</button>
          
         </div>
  
      </form>
    </div>
  </app-common-modal-popup>
