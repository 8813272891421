import { ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersettingService } from 'src/app/services/usersetting.service';
import { ValidationService } from 'src/app/services/validation.service';
import { CommonService } from '../../../services/common.service';
import * as moment from 'moment-timezone';
import { ExternalLibraryService } from 'src/app/services/payment.service';
import { HttpParams } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  form:FormGroup;
  modelForm:FormGroup;
  addNotification : any ={};
  pageOption = 10;
  pageOptionModal = 10;
  page: number = 1;
  submitted = false;
  userId = localStorage.getItem('userId');
  userData = {};
  notificationList = [];
  addNotificationsForm: any;
  editCustomerForm: FormGroup;
  timezoneArray = [];
  showModal = false;
  errors: any = {};
  
  
  
  modalRef?: BsModalRef;
  masterData = {
    type:[],
    channel:[],
  }
  constructor(
    private modalService: BsModalService,
    private user: UsersettingService,
    private fb: FormBuilder,
    private customValidator: ValidationService,
    private commonService: CommonService,
    private razorpayService: ExternalLibraryService, private cd:  ChangeDetectorRef
  ) {
    
    let userData = localStorage.getItem('userData');
   this.userData = userData ? JSON.parse(userData) : {};
   
  }
  
  ngOnInit(): void {
    this.form = new FormGroup({
      searchValue: new FormControl(''),
    });
    
    this.modelForm = new FormGroup({
      searchValue: new FormControl(''),
    });
    this.getAllNotifications();
    this.getNotificationChannel();
    this.getNotificationType();
  }

  
  newDevice() {

  }
  editNotifications(device) {
    console.log('device', device);
    
    this.addNotification = {
      id: device.id,
      type:device.type,
      notificators:device.notificators.split(','),
      always:device.always
    };
  }
 
  searchData() {
    
  }
    getAllNotifications() {
   
    this.commonService.getRequest('notifications', []).then((resp: any) => {
      if (resp?.status) {
        this.notificationList = resp.data;
       
      }
    });
  }
  checkboxEvent(e: any) {
    this.addNotification.always = e.checked;
  }
  getNotificationType() {
   
    this.commonService.getRequest('notifications/types', []).then((resp: any) => {
      if (resp?.status) {
        this.masterData.type = resp.data;
       
      }
    });
  }
  getNotificationChannel() {
   
    this.commonService.getRequest('notifications/notificators', []).then((resp: any) => {
      if (resp?.status) {
        this.masterData.channel = resp.data;
       
      }
    });
  }
  addNotificationpopup() {
    this.addNotification = {
      id: null,
      type:'',
      notificators:[],
      always:false,
    };
  }

  submit() {
    this.errors = {};
    if (this.addNotification.type && this.addNotification.notificators) {
      

      if (this.addNotification?.id) {
        this.commonService
          .putRequest('notifications/' + this.addNotification.id, {
            id: this.addNotification.id,
            type: this.addNotification.type,
            notificators: this.addNotification.notificators.toString(),
            always:this.addNotification.always
           
            // attributes: attributes,
          })
          .then((resp: any) => {
            this.getAllNotifications();
            $('#btnclose').click();
            Swal.fire({
              icon: 'success',
              title: 'Your device has been Updated Successfully',
              showConfirmButton: false,
              timer: 1500,
            });
          });
      } else {
        this.commonService
          .postRequest('notifications', {
            type: this.addNotification.type,
            notificators: this.addNotification.notificators.toString(),
            always:this.addNotification.always
          })
          .then((resp: any) => {
            this.getAllNotifications();
            $('#btnclose').click();
            Swal.fire({
              icon: 'success',
              title: 'Your device has been Submitted Successfully',
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    } else {
      if (!this.addNotification.name) {
        this.errors['Devicename'] = 'This field is required';
      }
      if (!this.addNotification.identifier) {
        this.errors['Deviceidentifier'] = 'This field is required';
      }
    }
  }

  deleteNotifications(id) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this notification!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        let body = {
          deleteuser: true,
          userId: id,
        };
        this.commonService
          .deleteRequest('notifications/' + id)
          .then((resp) => {
            this.getAllNotifications();
          })
          .catch((e) => {
            this.getAllNotifications();
          });
        Swal.fire('Deleted!', 'Notification has been deleted.', 'success');
      }
    });
  }

  

  

  
}

