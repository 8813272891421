

import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersettingService } from 'src/app/services/usersetting.service';
import { ValidationService } from 'src/app/services/validation.service';
import { CommonService } from '../../services/common.service';
import * as moment from 'moment-timezone';
import { ExternalLibraryService } from 'src/app/services/payment.service';
import { HttpParams } from '@angular/common/http';
declare var $: any;
declare let Razorpay: any;
@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit {
  form:FormGroup;
  modelForm:FormGroup;
  resetODOForm:FormGroup;
  addDevice : any ={};
  pageOption = 10;
  pageOptionModal = 10;
  page: number = 1;
  submitted = false;
  userId = localStorage.getItem('userId');
  userData:any = {};
  isNormalUser = true;
  devicesList = [];
  geofencesList = [];
  addDevicesForm: any;
  editCustomerForm: FormGroup;
  timezoneArray = [];
  showModal = false;
  deviceList: any = [];
  errors: any = {};
  notificationsMasterList= [];
  attrList: any = [
    {
      id: 'speedLimit',
      name: 'Speed Limit',
      type: 'number',
    },
    {
      id: 'ignitionwire',
      name: 'ignition Wire',
      type: 'number',
    },
    {
      id: 'parkingMode',
      name: 'Parking Mode',
      type: 'number',
    },
    {
      id: 'timezone',
      name: 'Timezone',
      type: 'string',
    },
    {
      id: 'deviceInactivityStart',
      name: 'Device Inactivity Start',
      type: 'number',
    },
    {
      id: 'deviceInactivityPeriod',
      name: 'Device Inactivity Period',
      type: 'number',
    },
    {
      id: 'mileage',
      name: 'Mileage',
      type: 'string',
    },
    {
      id: 'relay',
      name: 'Relay',
      type: 'boolean',
    }
  ];
  deviceCategoryList: any = [
    { id: 'default', name: 'Default' },
    { id: 'animal', name: 'Animal' },
    { id: 'bicycle', name: 'Bicycle' },
    { id: 'boat', name: 'Boat' },
    { id: 'bus', name: 'Bus' },
    { id: 'car', name: 'Car' },
    { id: 'crane', name: 'Crane' },
    { id: 'helicopter', name: 'Helicopter' },
    { id: 'motorcycle', name: 'Motorcycle' },
    { id: 'offroad', name: 'Offroad' },
    { id: 'person', name: 'Person' },
    { id: 'pickup', name: 'Pickup' },
    { id: 'plane', name: 'Plane' },
    { id: 'ship', name: 'Ship' },
    { id: 'tractor', name: 'Tractor' },
    { id: 'train', name: 'Train' },
    { id: 'tram', name: 'Tram' },
    { id: 'trolleybus', name: 'Trolleybus' },
    { id: 'truck', name: 'Truck' },
    { id: 'van', name: 'Van' },
    { id: 'scooter', name: 'Scooter' },
  ];
  addAttr: any = {
    attribute: '',
    type: '',
    value: null,
  };
  modalRef?: BsModalRef;
  ModelMasterList:any[] = [];
  @Input()
  addMode = false;
  @Input()
  gridMode = false;
  geofenceSearch:any = '';
  selectedDeviceForGeofence: any = '';
  selectedEditDeviceForOdo: any = {};
  constructor(
    private modalService: BsModalService,
    private user: UsersettingService,
    private fb: FormBuilder,
    private customValidator: ValidationService,
    private commonService: CommonService,
    private razorpayService: ExternalLibraryService, private cd:  ChangeDetectorRef,
    private masterModel : UsersettingService
  ) {
    this.commonService.pageLoadInModal.subscribe((result: string) => {
      if (result == "devices") {
        this.gridMode = true;
        this.addMode = false;  
              
      }
    });
    let userData = localStorage.getItem('userData');
   this.userData = userData ? JSON.parse(userData) : {};
   this.isNormalUser = !(this.userData.administrator || this.userData.userLimit !== 0);
  }
  
  ngOnInit(): void {
    this.form = new FormGroup({
      searchValue: new FormControl(''),
    });
    this.resetODOForm = new FormGroup({
      hours: new FormControl(''),
      deviceId: new FormControl(''),
      totalDistance: new FormControl(''),
    });
    this.modelForm = new FormGroup({
      searchValue: new FormControl(''),
    });
    this.getAllDevices();
    this.masterModel.getModelMaster().subscribe(resp => {
      console.log(resp);
      this.ModelMasterList = resp;
    })
    this.getAllGeofences();
  }

  submitODO() {
    this.resetODOForm.value;
    this.commonService
          .putRequest('devices/' + this.resetODOForm.value.deviceId +'/accumulators', this.resetODOForm.value)
          .then((resp: any) => {
            this.getAllDevices();
            this.cancelODO();
            //$('#btncloseResetSubmit').click();
            Swal.fire({
              icon: 'success',
              title: 'Device Data has been Updated Successfully',
              showConfirmButton: false,
              timer: 1500,
            });
          },(err)=>{
            //console.log(err);
            //  Swal.fire({
            //    icon: "error",
            //    title: "Something Went Wrong",
            //    showConfirmButton: false,
            //    timer: 1500,
            //  });
           });

           setTimeout(() => {
              this.getAllDevices();
              this.cancelODO();              
              Swal.fire({
                icon: 'success',
                title: 'Device Data has been Updated Successfully',
                showConfirmButton: false,
                timer: 1500,
              });
           }, 100);
          
  }
  newDevice() {

  }
  resetODODetails(device, modal) {    
    this.modalRef = this.modalService.show(modal);
    console.log(device);
    this.resetODOForm.controls.hours.setValue(device.totalDistance ?device.totalDistance:0 );
    this.resetODOForm.controls.deviceId.setValue(device.id);
    this.resetODOForm.controls.totalDistance.setValue( device.attributes && device.attributes.totalDistance ?device.attributes.totalDistance : 0);
  }
  editDevice(device) {
    this.gridMode = false;
    this.addMode = true;
    console.log('device', device);
    let attributes = [];

    let attrLength = Object.keys(device.attributes);
    if (attrLength?.length) {
      attrLength.forEach((a) => {
        let dataSet = {
          attribute: '',
          type: '',
          value: null,
        };
        switch (a) {
          
          case 'speedLimit':
            if (true) {
              dataSet.attribute = 'speedLimit';
              dataSet.type = 'number';
              dataSet.value = device.attributes[a];
            }
            break;
            case 'ignitionwire':
              if (true) {
                dataSet.attribute = 'ignitionwire';
                dataSet.type = 'boolean';
                dataSet.value = device.attributes[a];
              }
              break;
              case 'parkingMode':
            if (true) {
              dataSet.attribute = 'parkingMode';
              dataSet.type = 'boolean';
              dataSet.value = device.attributes[a];
            }
            break;
          case 'decoder.timezone':
            dataSet.attribute = 'timezone';
            dataSet.type = 'string';
            dataSet.value = device.attributes[a];
            break;
          case 'deviceInactivityStart':
            dataSet.attribute = 'deviceInactivityStart';
            dataSet.type = 'number';
            dataSet.value = device.attributes[a];
            break;
          case 'deviceInactivityPeriod':
            dataSet.attribute = 'deviceInactivityPeriod';
            dataSet.type = 'number';
            dataSet.value = device.attributes[a];
            break;
          case 'mileage':
            dataSet.attribute = 'mileage';
            dataSet.type = 'string';
            dataSet.value = device.attributes[a];
            break;
          default:
            dataSet.attribute = a;
            dataSet.type = '';
            dataSet.value = device.attributes[a];
            break;
        }
        console.log('dataSet', dataSet);
        if (dataSet.attribute) {
          attributes.push(dataSet);
        }
      });
    }
    
    this.addDevice = {
      id: device.id,
      name: device.name,
      identifier: device.uniqueId,
      groupId: device.groupId,
      phone: device.phone,
      model: device.model,
      otherModelValue:'',
      contact: device.contact,
      category: device.category,
      distanceForday:device.distanceForday,
      disabled: device.disabled,
      expirationTime:device.expirationTime ? moment(new Date(device.expirationTime)).calendar() : '',
      attributes: attributes,
    };
   //
   let modelValue = this.ModelMasterList.find(mData=> mData.modelName == device.model);
    if(!modelValue) {
      this.addDevice.otherModelValue = device.model;
      this.addDevice.model = 'other';
    }
  }
 
  searchData() {
    
  }
    getAllDevices() {
   
    this.commonService.getRequest('devices/getUsersforDevices', []).then((resp: any) => {
      if (resp?.status) {
        this.devicesList = resp.data;
       
      }
    });
  }
  addDevicepopup() {
    this.gridMode = false;
    this.addMode = true;
    this.addDevice = {
      id: null,
      name: '',
      expirationTime:moment().add(1, 'years').calendar(),
      identifier: '',
      groupId: '',
      phone: '',
      model: '',
      contact: '',
      category: 'Default',
      disabled: false,
      attributes: [{
        "attribute": "speedLimit",
        "type": "",
        "value": 60
          },{
            "attribute": "mileage",
            "type": "",
            "value": 10
        },{
            "attribute": "ignitionwire",
            "type": "",
            "value": true
        },{
          "attribute": "parkingMode",
          "type": "",
          "value": false
      }],
    };
  }

  deviceSubmit() {
    let modelValue = this.addDevice && this.addDevice.model ? this.addDevice.model : '';
    if(this.addDevice && this.addDevice.model == 'other') {
      modelValue = this.addDevice.otherModelValue;
    } 
    this.errors = {};
    if (this.addDevice.name && this.addDevice.identifier) {
      let attributes = {};
      // decoder.timezone: "24"
      // deviceInactivityPeriod: 26
      // deviceInactivityStart: 25
      // speedLimit: 23

      if (this.addDevice?.attributes?.length) {
        this.addDevice.attributes.forEach((a) => {
          switch (a.attribute) {
            
            case 'speedLimit':
              attributes['speedLimit'] = a.value;
              break;
            case 'ignitionwire':
              attributes['ignitionwire'] = a.value;
              break;
            case 'parkingMode':
              attributes['parkingMode'] = a.value;
              break;
            case 'timezone':
              attributes['decoder.timezone'] = a.value;
              break;
            case 'deviceInactivityStart':
              attributes['deviceInactivityStart'] = a.value;
              break;
            case 'deviceInactivityPeriod':
              attributes['deviceInactivityPeriod'] = a.value;
              break;
            case 'relay':
              attributes['relay'] = a.value;
            case 'mileage':
              attributes['mileage'] = a.value;
              break;
            default:
              attributes[a.attribute] = a.value;
                break;
          }
        });
      }
      
      if (this.addDevice?.id) {
        this.commonService
          .putRequest('devices/' + this.addDevice.id, {
            id: this.addDevice.id,
            name: this.addDevice.name,
            uniqueId: this.addDevice.identifier,
            groupId: this.addDevice.groupId,
            phone: this.addDevice.phone,
            model: modelValue,
            contact: this.addDevice.contact,
            category: this.addDevice.category,
            disabled: this.addDevice.disabled,
            distanceForday:this.addDevice.distanceForday,
            attributes: attributes,
            expirationTime:new Date(this.addDevice.expirationTime)
          })
          .then((resp: any) => {
            this.gridMode = true;
            this.addMode = false;
            this.getAllDevices();
            $('#btnclose').click();
            Swal.fire({
              icon: 'success',
              title: 'Your device has been Updated Successfully',
              showConfirmButton: false,
              timer: 1500,
            });
          },(err)=>{
            console.log(err);
             Swal.fire({
               icon: "error",
               title: "Something Went Wrong",
               showConfirmButton: false,
               timer: 1500,
             });
           });
      } else {
        this.commonService
          .postRequest('devices', {
            name: this.addDevice.name,
            uniqueId: this.addDevice.identifier,
            groupId: this.addDevice.groupId,
            phone: this.addDevice.phone,
            model: modelValue,
            contact: this.addDevice.contact,
            category: this.addDevice.category,
            disabled: this.addDevice.disabled,
            expirationTime:new Date(this.addDevice.expirationTime),
            attributes: attributes,
          })
          .then((resp: any) => {
            this.gridMode = true;
            this.addMode = false;
            this.getAllDevices();
            $('#btnclose').click();
            Swal.fire({
              icon: 'success',
              title: 'Your device has been Submitted Successfully',
              showConfirmButton: false,
              timer: 1500,
            });
          },(err)=>{
            console.log(err);
             Swal.fire({
               icon: "error",
               title: "Something Went Wrong",
               showConfirmButton: false,
               timer: 1500,
             });
           });
      }
    } else {
      if (!this.addDevice.name) {
        this.errors['Devicename'] = 'This field is required';
      }
      if (!this.addDevice.identifier) {
        this.errors['Deviceidentifier'] = 'This field is required';
      }
    }
  }

  deleteDevice(id) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this device!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        let body = {
          deleteuser: true,
          userId: id,
        };
        this.commonService
          .deleteRequest('devices/' + id)
          .then((resp) => {
            this.gridMode = true;
            this.addMode = false;
            this.getAllDevices();
          })
          .catch((e) => {
            this.gridMode = true;
            this.addMode = false;
            this.getAllDevices();
          });
        Swal.fire('Deleted!', 'Device has been deleted.', 'success');
      }
    });
  }
  onPopupClose(data) {
    this.gridMode = true;
    this.addMode = false;
    this.getAllDevices();
  }
  addAttri(template: TemplateRef<any>) {
    this.addAttr = {
      attribute: '',
      type: '',
      value: null,
    };
    this.modalRef = this.modalService.show(template);
  }

  deleteAttr(key) {
    this.addDevice.attributes = this.addDevice.attributes.filter(
      (a, k) => k !== key
    );
  }
  changeAttrEvent(e) {
    // let findAttrName = this.attrList.find((a) => a.id === e.value);
    // if (findAttrName) {
    //   this.addAttr.type = findAttrName.type;
    // } else {
    //   this.addAttr.type = '';
    // }
  }
  checkboxEvent(e: any) {
    this.addDevice.disabled = e.checked;
  }

  deviceNotifications(device, modal) {
    this.modalRef = this.modalService.show(modal);
    this.notificationsMasterList = [];
    console.log('---------', device);
    this.commonService.getRequest('notifications', []).then((resp: any) => {
      if (resp?.status) {
        this.notificationsMasterList = resp.data;
        this.getDeviceNotifications(device.id);
      }
    });
  }
  getDeviceNotifications(deviceId) {
    this.commonService
      .getRequest('notifications', ['deviceId=' + deviceId])
      .then((resp: any) => {
        if (resp?.status) {
          let mappedId = resp.data.map((d: any) => {
            return d.id;
          });
          this.notificationsMasterList.forEach((d: any, k: number) => {
            this.notificationsMasterList[k]['mapDeviceId'] = deviceId;
            this.notificationsMasterList[k]['isSelectedDevice'] =
              mappedId.indexOf(d.id) > -1;
          });
          
        }
      });
  }

  changeEvent(e: any, notificationId,deviceId) {
    if (e.checked) {
      this.commonService
        .postRequest('permissions', {
          deviceId: deviceId,
          notificationId: notificationId,
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    } else {
      this.commonService
        .deleteRequest('permissions', {
          deviceId: deviceId,
          notificationId: notificationId,
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    }
  }
  addAttrbute() {
    if (this.addAttr.attribute) {
      let find = this.addDevice.attributes.find(
        (a) => a.attribute === this.addAttr.attribute
      );
      if (!find) {
        this.addDevice.attributes.push(this.addAttr);
      }
      this.modalRef?.hide();
    }
  }
  dealerUsersList=[];
  displyDealerUserList(device, modal){
    this.modalRef = this.modalService.show(modal);
    this.dealerUsersList = device.userData  ? device.userData : [];
  }
  onDeviceCancel() {
    this.gridMode = true;
    this.addMode = false;
  }
  closeUserModal() {
    this.modalRef.hide();
  }
  closeNotificationModal() {
    this.selectedDeviceForGeofence = '';
    this.modalRef.hide();
  }
  closeHoursModal() {
    this.modalRef.hide();
  }
  assignGeofence(deviceId: any, modal) { 
    this.deselectAllGeofenceList();   
    this.selectedDeviceForGeofence = deviceId;
    this.getAllGeofences(deviceId);
    this.modalRef = this.modalService.show(modal);
    //this.geofencesList = device.userData  ? device.userData : [];
  }
  getAllGeofences(deviceId?: any) { 
    let url = '';
    url = 'geofences';
    if (deviceId) {
      url = url+'?deviceId='+deviceId;
    } 
    
    this.commonService.getRequest(url, []).then((resp: any) => {
      if (resp?.status) {
        if (deviceId) {
          resp.data.forEach((ele) => {
            let match = this.geofencesList.findIndex((x) => x.id == ele.id);
            if (match >= 0) {
              this.geofencesList[match]['isSelected'] = true; 
            }            
          });
        } else {          
          this.geofencesList = resp.data; 
          this.deselectAllGeofenceList();
        }      
      }
    });
  }
  deselectAllGeofenceList() {
    this.geofencesList.forEach((ele, index) => {
      this.geofencesList[index]['isSelected'] = false;            
    });
  }
  searchGeofenceData() {}
  changeGeofenceEvent(e: any, fenceId) {
    if (e.checked) {
      this.commonService
        .postRequest('permissions', {
          deviceId: this.selectedDeviceForGeofence,
          geofenceId: fenceId,
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    } else {
      this.commonService
        .deleteRequest('permissions', {
          deviceId: this.selectedDeviceForGeofence,
          geofenceId: fenceId,
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    }
  }
  editOdoData(device, index) {
    this.cancelODO();   
    if (this.selectedEditDeviceForOdo && this.selectedEditDeviceForOdo?.device) { 
      this.setOdoEditFalse();
    }
    device['isEdit'] = true;
    this.selectedEditDeviceForOdo = device;
    this.resetODOForm.controls.hours.setValue(device.device?.totalDistance ?device.device?.totalDistance:0 );
    this.resetODOForm.controls.deviceId.setValue(device.device.id);
    this.resetODOForm.controls.totalDistance.setValue( device.device.attributes && device.device.attributes.totalDistance ?device.device.attributes.totalDistance : 0);
  }
  cancelODO() {
    //this.resetODOForm.reset();
    if (this.selectedEditDeviceForOdo && this.selectedEditDeviceForOdo?.device) { 
      this.setOdoEditFalse();
    }
  }
  setOdoEditFalse() {
    let match = this.devicesList.findIndex(x => x.device.uniqueId === this.selectedEditDeviceForOdo.device.uniqueId);
    if (match >= 0) {
      this.devicesList[match].isEdit = false;
    }
  }
}

