import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MeterTokm } from 'src/app/pipes/meter-km.pie';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment.prod';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { TrackdashboardService } from './track-dashboard.service';

@Component({
  selector: 'app-vehicle-tracking',
  templateUrl: './vehicle-tracking.component.html',
  styleUrls: ['./vehicle-tracking.component.css'],
  providers:[MeterTokm],
})
export class VehicleTrackingComponent implements OnInit, AfterViewInit {
  userData: any;
  orgId: string;
  devices: any[] = [];
  selectedDevice: any;
  markers: google.maps.Marker[] = [];
  markersList: any[] = [];
  allDevicesList: any[] = [];
  selectedDeviceNetworkData: any;
  secondLastLat: number;
  secondLastLng: number;
  origin_lat: number = 0; // Set your initial values
  origin_lng: number = 0; // Set your initial values
  oneVehicle: boolean = false; // Modify as needed
  allLocationTrackData: any[] = [];
  icon: string = 'path-to-your-icon'; // Specify your icon path
  _io: any;
  map: google.maps.Map;
  index: number;
  destination_lat: number;
  destination_lng: number;
  polyLineArray: { lat: number; lng: number; }[];
  numbDelta: number;
  infoWindow: google.maps.InfoWindow;
  private div: HTMLDivElement;

  private position: google.maps.LatLng;
  loading: boolean;



  constructor(private commonService: CommonService,
    private toastr: ToastrService,
     private meterTokm: MeterTokm,
    private datePipe: DatePipe,
  private tds:TrackdashboardService) {
 
    }

  ngOnInit() {
    this.infoWindow = new google.maps.InfoWindow();
    const userDataObj = localStorage.getItem('userData');
    this._io=this.tds.ioTest();
    if (userDataObj) {
      try {
        this.userData = JSON.parse(userDataObj);
        console.log('User data =>', this.userData);
        
        // Set orgId based on user role
        this.orgId = this.userData.attributes.role === 'admin' ? this.userData.id : this.userData.orgId;
        console.log('Org Id =>', this.orgId);
      } catch (error) {
        console.error('Error parsing user data:', error);
      }
    } else {
      console.warn('No user data found in localStorage.');
    }

    this.loadDevices(); // Load devices after setting orgId
    // this.getConnection(); // Initialize socket connection
    debugger
 
  
    console.log("ioTest",this._io)
  }

  ngAfterViewInit() {
    this.initMap();
  }

  initMap() {
    // [latitude]="18.5632" [longitude]="73.9176"
    const defaultLocation = { lat: 18.5632, lng: 73.9176 }; // Replace with your specified location
    const mapOptions: google.maps.MapOptions = {
      center: defaultLocation, // Set default center
      zoom: 10, // Default zoom level
    };
    this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, mapOptions);
  }

  loadDevices(type = 'ALL', isManualFilter = false) {
    const limit = 100000;
    this.loading=true;
    const searchParameter = [
      'status=' + type,
      `orgId=${this.orgId}`,
      'isAddressRequired=false',
      'limit=' + limit,
      'offset=' + 0 
    ];
  
    this.commonService.getRequest('positions', searchParameter)
      .then((resp: any) => {
        this.loading=false;
        console.log('deviceFilter=>', resp);
        if (resp?.status) {
          this.devices = resp.data;
          debugger
 
          // Loop through each device to get details
          this.devices.forEach(displayDevice => {
           displayDevice.deviceDetails= this.getDeviceDetails(displayDevice);
          });
          Object.assign(this.markersList,this.devices);
          this.updateMarkers(); // Update markers on the map
          setTimeout(() => {
            this.roatedAllDevice(); // Rotate all devices after a short delay
          }, 100);
        }
      });
  }
  
  setMap(map: google.maps.Map) {
    this.map = map;
    if (map) {
      this.map.getDiv().appendChild(this.div);
    } else if (this.div.parentNode) {
      this.div.parentNode.removeChild(this.div);
    }
  }

  setPosition(position: google.maps.LatLng) {
    this.position = position;
    // this.updateLabelPosition();
  }
 

  getProjection() {
    return this.map.getProjection();
  }
  getMarkerIcon(status: string,_id:any): string {
    switch (status) {
      case 'STOPPED':
        return '/assets/icons/red_car.png?id='+_id ;
      case 'RUNNING':
        return '/assets/icons/running_car.png?id='+_id;
      case 'IDLE':
        return '/assets/icons/idle_car.png?id='+_id;
      case 'NOT_REPORTING':
        return '/assets/icons/no_reporting_car.png?id='+_id;
      default:
        return '/assets/icons/red_car.png?id='+_id; // Default icon
    }
  }

  updateMarkers() {

    this.markers.forEach(marker => marker.setMap(null)); // Clear existing markers
    this.markers = []; // Reset markers array

    this.devices.forEach(device => {
      const marker = new google.maps.Marker({
        position: { lat: device.latitude, lng: device.longitude },
        map: this.map,
        label: {
          text: device.name ? device.name : '' ,
          fontSize: '10px',
          color: 'white',
        },
        // title:device.name ? device.name : "",
        icon: {
          url: this.getMarkerIcon(device.status,device.deviceId),
          scaledSize: new google.maps.Size(38,38 ), // Adjust icon size here
        },
      });
      marker.addListener('click', () => {
        this.selectedDevice = device; 
      const content = `
        <div>
         <p>Name: ${device?.name || ''}</p>
          <p>Status: ${device?.status || ''}</p>
          <p>Position: ${device?.latitude || ''} °, ${device?.longitude || ''} °</p>
          <p>Speed: ${device?.speed ? (device.speed).toFixed(2) +" km/h": ''} </p>
            <p>Last Update: ${device?.lastUpdate ? this.datePipe.transform(device.lastUpdate, 'yyyy-MM-dd hh:mm:ss a') : ''}</p>   
            <p>Odometer: ${device?.attributes?.totalDistance ? this.meterTokm.transform(device.attributes.totalDistance) : ''} km</p>
        </div>
      `;

      this.infoWindow.setContent(content); // Set the content of the InfoWindow
      this.infoWindow.open(this.map, marker);
      });

      this.markers.push(marker);
      this.roatedAllDevice();
    });



  
  }
  createCustomMarkerLabel(text: string): HTMLDivElement {
    const label = document.createElement('div');
    label.className = 'custom-label-map'; // Use your custom class
    label.style.position = 'absolute';
    label.style.background = 'white';
    label.style.padding = '5px';
    label.style.borderRadius = '5px';
    label.style.color = 'black';
    label.style.fontWeight = 'bold';
    label.innerHTML = text;
  
    return label;
  }
  
  getConnection() {
    try {
      this.commonService.closeSocket();
    } catch (error) {
      console.error('Error closing socket:', error);
    }

    const basicToken = localStorage.getItem('socketBasicToken');
    const apiUrl = `wss://${environment.baseUrl}:8082/api/socket?token=${basicToken}`;

    this.commonService.socketConnection = new WebSocket(apiUrl);

    this.commonService.socketConnection.onopen = (event) => {
      console.log('WebSocket connection established');
    };

    this.commonService.socketConnection.onmessage = (event) => {
      const msg = JSON.parse(event.data);

      if (msg.devices) {
        msg.devices.forEach((device) => {
          this.allDevicesList.forEach((displayDevice) => {
            if (displayDevice.deviceId === device.id) {
              displayDevice.deviceTime = device.lastUpdate;
              // Additional handling if necessary
            }
          });
        });
      } else if (msg.positions) {
        console.log('Positions', msg.positions);
        this.updatePositions(msg.positions);
      } else if (msg.events) {
        msg.events.forEach((device) => {
          this.showSuccess(device.attributes.message, device.type);
          this.commonService.playAudio(1);
        });
      }
    };

    this.commonService.socketConnection.onclose = (event) => {
      console.log('Connection closed');
    };
  }

  updatePositions(positions) {

    positions.forEach((device) => {
      this.devices.forEach((displayMarker) => {
        if (displayMarker.deviceId === device.deviceId) {
          if (!device.isHide && !this.oneVehicle) {
            this.animatedMove(displayMarker, displayMarker, device);
            this.roatedDevice(displayMarker, 10, device.course);
          } else {
            // Update marker attributes
            displayMarker.latitude = device.latitude;
            displayMarker.longitude = device.longitude;
            displayMarker.serverTime = device.serverTime;
            displayMarker.valid = device.valid;
            displayMarker.attributes = device.attributes;
            displayMarker.status = device.status;
          }

          if (this.selectedDevice && this.selectedDevice.deviceId === device.deviceId) {
            this.updateSelectedDeviceInfo(device);
          }
        }
      });

      // Update allDevicesList with the latest position data
      this.allDevicesList.forEach((displayDevice) => {
        if (displayDevice.deviceId === device.deviceId) {
          if (displayDevice) {
            displayDevice.lastLatLng = {
              latitude: device.latitude,
              longitude: device.longitude,
            };
            displayDevice.speed = device.speed;
            displayDevice.serverTime = device.serverTime;
            displayDevice.attribute = device.attributes;
            displayDevice.attributes = device.attributes;
            displayDevice.valid = device.valid;

            if (displayDevice.status !== device.status) {
              displayDevice.status = device.status;
              displayDevice.deviceDetails = this.getDeviceDetails(displayDevice);
              if (this.selectedDevice && this.selectedDevice.deviceId === device.deviceId && !this.allLocationTrackData.length) {
                this.updateMap();
              }
            }
          }
        }
      });
    });
  }
  updateLabelPosition(label: HTMLDivElement, position: google.maps.LatLng, map: google.maps.Map) {
    const projection = map.getProjection();
    const point = projection.fromLatLngToPoint(position);
  
    // Adjust the position of the label based on the marker
    label.style.left = `${point.x}px`;
    label.style.top = `${point.y - 30}px`; // Offset the label above the marker
  }
  updateSelectedDeviceInfo(device) {

  }
  transition(result) {
    this.index = 0;
    this.destination_lat = (result[0] - this.origin_lat) / this.numbDelta;
    this.destination_lng = (result[1] - this.origin_lng) / this.numbDelta;

    this.polyLineArray = [{ lat: this.origin_lat, lng: this.origin_lng }];

    // this.polyLineArray.forEach((l) => this.historyPath.poly.getPath().push(l));

    // this.moveMarker();
  }
  poly: any;
  // moveMarker() {
  //   this.origin_lat += this.destination_lat;
  //   this.origin_lng += this.destination_lng;
    
  //   var latlng = new google.maps.LatLng(this.origin_lat, this.origin_lng);
  //   this.polyLineArray.push({ lat: this.origin_lat, lng: this.origin_lng });
  //   this.historyPath.poly = new google.maps.Polyline({
  //     strokeColor: '#FF0000',
  //     strokeOpacity: 0.00001,
  //     strokeWeight: 0,
  //     path: this.polyLineArray,
  //     map: this.map,
  //   });
  //   this.mark.setPosition(latlng);
  //   //this.selectedDevice.setPosition(latlng);
  //   this.historyPath.poly.setMap(this.map);
  //   try {
  //     // this.map.setCenter(latlng); //by vivek
  //   } catch (error) {}
  //   if (this.index != this.numbDelta) {
  //     this.index++;
  //     setTimeout(() => {
  //       if (!this.allLocationTrackData.length) {
  //         this.moveMarker();
  //       }
  //     }, 10);
  //   }
  // }
  roatedAllDevice() {
    this.devices.forEach((mList) => {
      this.roatedDevice(mList, 10);  
    });
  }

  roatedDevice(device, refreshTime = 100, isCourse = 0) {

    setTimeout(() => {
      const element = document.querySelector(
        `img[src~='${device.deviceDetails.vehicleMapImg.url}?id=${device?.deviceId}']`
      ) as HTMLImageElement;
      // console.log('element=>',element)
      if (element) {
        element.style.transform =
          'rotate(' + (isCourse ? isCourse : device.course) + 'deg)';
      }
    }, refreshTime);
  }
  showSuccess(title, message) {
    this.toastr.info(title, message);
  }
  // Placeholder for other methods like getAddress, animatedMove, showSuccess, etc.
  animatedMove(marker, current, moveto) {
    let deltalat = (moveto.latitude - current.latitude) / 500;
    let deltalng = (moveto.longitude - current.longitude) / 500;
    let i = 0;
    function moveItSelf(){
      setTimeout( ()=> {
        let lat = marker.latitude;
        let lng = marker.longitude;
        lat += deltalat;
        lng += deltalng;
        //let latlng = new google.maps.LatLng(lat, lng);
        marker.latitude = lat;
        marker.longitude = lng;
        if(i<500 && this && !this.oneVehicle) {
          i++;
          moveItSelf();
        } else {
          marker.latitude = moveto.latitude;
          marker.longitude = moveto.longitude;
        }
      }, 10);
    }
    moveItSelf();
    
  }
  getAddress(data) {
    this.getDeviceAddress(data.latitude,data.longitude,false,true,data)
  }
  getDeviceAddress(longitude, latitude, isHistory = false,updateToObj=false,data={}) {
 
    let params = [
      'latitude=' + latitude,
      'longitude=' + longitude,
      'maxDistance=1000',
    ];
    if (latitude && longitude) {
      this.commonService
        .getRequest('statistics/getAddress?' + params.join('&'), [])
        .then((resp: any) => { 
          if(updateToObj){
            if (resp.data && resp.data.address) { 

              data['address'] = resp.data.address;
            }
          } else {
            if (resp.data && resp.data.address) {
              this.selectedDevice['displayAddress'] = resp.data.address;
            }
            if (isHistory) {
              // this.historyPath['displayAddress'] = resp.data.address;
            }
          }
          
        })
        .finally(() => {});
    }
  }
  getDeviceDetails(device: any) {
    let details: any = {
      vehicleImg: '/assets/icons/devices/car/no-data.png',
      vehicleMapImg: '/assets/icons/devices/car/no-data-map.png',
      dotImg: '/assets/icons/no_data.png',
      msg: 'No Data',
      color: '#646464',
    };

    let folderName = 'devices/car';
    if (device.category === 'boat') {
      folderName = 'devices/boat';
    } else if (device.category === 'bus') {
      folderName = 'devices/bus';
    } else if (device.category === 'motorcycle') {
      folderName = 'devices/motorcycle';
    } else if (device.category === 'tractor') {
      folderName = 'devices/tractor';
    } else if (device.category === 'truck') {
      folderName = 'devices/truck';
    } else if (device.category === 'scooter') {
      folderName = 'devices/scooter';
    }

    switch (device.status) {
      case 'STOPPED':
        details.vehicleImg = '/assets/icons/' + folderName + '/stop.png';
        details.vehicleMapImg = '/assets/icons/' + folderName + '/stop-map.png';
        details.dotImg = '/assets/icons/stoped.png';
        details.msg = 'Stopped';
        details.color = '#fd1b41';
        break;
      case 'TOWWED':
        details.vehicleImg = '/assets/icons/' + folderName + '/stop.png';
        details.vehicleMapImg = '/assets/icons/' + folderName + '/stop-map.png';
        details.dotImg = '/assets/icons/stoped.png';
        details.msg = 'Towwed';
        details.color = '#fd1b41';
        break;
      case 'RUNNING':
        details.vehicleImg = '/assets/icons/' + folderName + '/running.png';
        details.vehicleMapImg =
          '/assets/icons/' + folderName + '/running-map.png';
        details.dotImg = '/assets/icons/dotgreen.png';
        details.msg = 'Running';
        details.color = '#17A573';
        break;
      case 'IDLE':
        details.vehicleImg = '/assets/icons/' + folderName + '/idle.png';
        details.vehicleMapImg = '/assets/icons/' + folderName + '/idle-map.png';
        details.dotImg = '/assets/icons/idle_dot.png';
        details.msg = 'Idle';
        details.color = '#E8AD21';
        break;
      case 'NOT_REPORTING':
        details.vehicleImg =
          '/assets/icons/' + folderName + '/not-reporting.png';
        details.vehicleMapImg =
          '/assets/icons/' + folderName + '/not-reporting-map.png';
        details.dotImg = '/assets/icons/not_reporting_dot.png';
        details.msg = 'No Reporting';
        details.color = '#529BA8';
        break;
      case 'EXPIRED':
        details.vehicleImg = '/assets/icons/' + folderName + '/expired.png';
        details.vehicleMapImg =
          '/assets/icons/' + folderName + '/expired-map.png';
        details.dotImg = '/assets/icons/expired_dot.png';
        details.msg = 'Expired';
        details.color = '#EB563E';
        break;
      default:
        details.vehicleImg = '/assets/icons/' + folderName + '/no-data.png';
        details.vehicleMapImg =
          '/assets/icons/' + folderName + '/no-data-map.png';
        details.dotImg = '/assets/icons/no_data.png';
        details.msg = 'No Data';
        details.color = '#646464';
        break;
    }
    details.runningVehicleUrl =
      '/assets/icons/' + folderName + '/running-map.png';
    details.vehicleMapImg = {
      url: details.vehicleMapImg + '#' + device.deviceId,
      scaledSize: { height: 38, width: 20 },
      size: new google.maps.Size(60, 60),
      origin: new google.maps.Point(-30, 0),
      anchor: new google.maps.Point(38, 20),
      scale: 2,
    };
    return details;
  }
  updateMap() {
    this.icon = this.selectedDevice['deviceDetails'].vehicleMapImg.url;
    // this.icon['scaledSize'].width = 38;
    // this.mark.setIcon({
    //   url: this.icon,
    //   scaledSize: { height: 38, width: 20 },

    //   size: new google.maps.Size(60, 60),
    //   origin: new google.maps.Point(-10, 0),
    //   anchor: new google.maps.Point(38, 20),
    //   scale: 2,
    // });
    console.log(this.icon);
  }
}
