import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoutesService } from 'src/app/services/routes.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-managetrips',
  templateUrl: './managetrips.component.html',
  styleUrls: ['./managetrips.component.css']
})
export class ManagetripsComponent implements OnInit {

  lat: number = 20.5937;
  lng: number = 78.9629;
  zoom = 6;
  userId = localStorage.getItem("userId");
  routestatus = "";
  pageOptionModal = 10;
  pageOption = 10;
  fullScreen = false;
  vehiclename = "";
  todaysDate = "";
  yesterdayDate = "";
  tomorrowDate = "";
  addtripForm: FormGroup;
  submitted = false;
  tripList = [];
  DeviceArray = [];
  routeNameList = [];
  geofenceNameList = [];

  constructor(private route: RoutesService, private fb: FormBuilder, private customValidator: ValidationService) {
    this.addtripForm = this.fb.group(
      {
        routename: ['', Validators.required],
        vehiclename: ['', Validators.required],
        drivername: ['', Validators.required],
        loadingsite: ['', Validators.required],
        unloadingsite: ['', Validators.required],
        start: ['', Validators.required],
        end: ['', Validators.required],
        lrnumber: ['', Validators.required],
        radiocheck: ['', Validators.required]
      });

    // Today ,Yesterday & Tommorow Date
    this.todaysDate = new Date().toISOString();
    const yesterday = new Date(this.todaysDate)
    yesterday.setDate(yesterday.getDate() - 1)
    this.yesterdayDate = yesterday.toISOString()
    const tomorrow = new Date(this.todaysDate)
    tomorrow.setDate(tomorrow.getDate() + 1)
    this.tomorrowDate = tomorrow.toISOString()
  }

  ngOnInit(): void {
    this.getManageTripRouteMap();
    let storedArray = localStorage.getItem("vehicleIDArray");
    this.DeviceArray = JSON.parse(storedArray);
    this.ongetRoutes();
    this.ongetGeofenceName();
  }

  get AddTripFormControl() {
    return this.addtripForm.controls;
  }

  onTripSubmit() {
    let deviceDefaultId = this.DeviceArray.find((x) => x.Device_Name === this.addtripForm.value.vehiclename)._id;
    let routeId = this.routeNameList.find((x) => x.name === this.addtripForm.value.routename)._id;
    let body = {
      "device": deviceDefaultId,
      "deviceName": this.addtripForm.value.vehiclename,
      "driver": this.addtripForm.value.drivername,
      "endSite": this.addtripForm.value.unloadingsite,
      "expiresOn": this.addtripForm.value.end,
      "lrNumber": this.addtripForm.value.lrnumber,
      "poi": [],
      "route": routeId,
      "routeName": this.addtripForm.value.routename,
      "startSite": this.addtripForm.value.loadingsite,
      "startsOn":this.addtripForm.value.start,
      "tripType": this.addtripForm.value.radiocheck,
      "user": this.userId
    }
    this.route.addtrip(body).subscribe(resp=>{
      console.log(resp)
      this.addtripForm.reset();
      this.getManageTripRouteMap();
    })

  }

  onCloseModal() {
    this.addtripForm.reset();
  }

  getManageTripRouteMap() {
    this.route.getRouteMap().subscribe(resp => {
      console.log(resp);
      this.tripList = resp;
    })
  }

  onDeleteTrip(tripid) {
    console.log(tripid);
  }

  onEditTrip(tripid) {
    console.log(tripid);
  }

  ongetRoutes() {
    this.route.getRoutes().subscribe(resp => {
      console.log(resp);
      this.routeNameList = resp;
    })
  }

  ongetGeofenceName() {
    this.route.getAllGeofence().subscribe(resp => {
      console.log(resp);
      this.geofenceNameList = resp;
    })
  }

}
