<p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ Support</span></p>

<div class="container-fluid ">
    <div class="row p-0">
        <div class="card p-0 headcard">

            <!-- HEADER WITH ADD POI BTNS -->
            <div class="card-header1 flex_row_center_spacebetween">
                <div class="cardHead_text">{{'Support'|translate}} </div>
                <ul class="nav nav-pills card-header-pills">
                    <li class="nav-item">
                        <div style="display: flex;flex-direction: row;">
                            <div style="background-color: #eff3f9;
                            padding: 8px 20px;
                            margin-right: 12px;
                            border-radius: 6px;">
                                <div (change)="changeView()" class="form-check form-check-inline">
                                    <input class="form-check-input" [(ngModel)]="currentView"  type="radio" name="inlineRadioOptions" id="inlineRadio1" [value]="false">
                                    <label  class="form-check-label" for="inlineRadio1">Created By</label>
                                  </div>
                                  <div (change)="changeView()" class="form-check form-check-inline">
                                    <input class="form-check-input" [(ngModel)]="currentView" type="radio" name="inlineRadioOptions" id="inlineRadio2" [value]="true">
                                    <label class="form-check-label" for="inlineRadio2">Assign To</label>
                                  </div>
                            </div>
                            <button *ngIf="superAdmin" mat-raised-button class="addbtns px-3 py-1"
                                style="margin-right: 1vh;" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                (click)="onAddModelMaster()">
                                <span class="material-icons" style="font-size: 2.1vh;">
                                    add
                                </span>{{' Add Support Ticket'|translate}}
                            </button>
                            <div [formGroup]="form" class="input-group searchvehicles">
                                <input (keyup)="page =1" formControlName="searchValue" name="searchValue" type="text"
                                    class="form-control reportsbox" placeholder="{{'Search'|translate}}"
                                    autocomplete="off" />
                                <div class="input-group-append">
                                    <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh;">
                                        <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- DATE DOWNLOAD HEADER -->
            <div class="card-header flex_row_center_spacebetween">
                <div class="flex_row_center_spacebetween">
                    <select class="form-select selectimportant" [(ngModel)]="pageOption" name="pageOption">
                        <option value="10" class="select_options">10</option>
                        <option value="20" class="select_options">20</option>
                        <option value="30" class="select_options">30</option>
                        <option value="40" class="select_options">40</option>
                        <option value="50" class="select_options">50</option>
                        <option value="60" class="select_options">60</option>
                    </select>
                </div>
                <div style="display: flex;flex-direction: row;align-items: center;">
                    <span class="btn"><img src="/assets/icons/report_refresh.png" alt="refresh"
                            style="width: 96%;"></span>
                    <span class="btn"><img src="/assets/icons/report_download.png" alt="download"
                            style="width: 96%;"></span>
                </div>
            </div>
            <!-- MODEL MASTER TABLE -->
            <div class="card-body common_scroller fixed_height p-0">
                <table class="table table-borderless m-0">

                    <thead style="background-color:  #24282E;">
                        <tr>
                            <th class="table_head_text text-left">{{'Type'|translate}}</th>
                            <th class="table_head_text text-left">{{'Description'|translate}}</th>
                            <th class="table_head_text text-left">{{'Assign to'|translate}}</th>
                            <th class="table_head_text text-left">{{'Status'|translate}}</th>
                            <th class="table_head_text text-left">{{'Remarks'|translate}}</th>
                            <th class="table_head_text text-left">{{'Device'|translate}}</th>
                            
                            <th class="table_head_text text-left">{{'Created Date'|translate}}</th>
                            <th class="table_head_text text-left">{{'Resolution date'|translate}}</th>
                            <th class="table_head_text text-left">{{'Expectedre solution date'|translate}}</th>
                            <th class="table_head_text text-left">Action</th>
                        </tr>
                    </thead>

                    <tbody *ngIf="supportList.length">
                        <tr *ngFor="let model of supportList  | searchPipe : form.value.searchValue |  paginate : {
                itemsPerPage: 10,
                currentPage: page,
                totalItems: (supportList  | searchPipe : form.value.searchValue).length
              };let i=index">

                            <td class="table_data text-left">{{model.supportDetails.type}}</td>
                            <td class="table_data text-left">{{model.supportDetails.description}}</td>
                            <td class="table_data text-left">{{model.supportDetails.assigntoName}}</td>
                            <td class="table_data text-left">{{model.supportDetails.status}}</td>
                            <td class="table_data text-left">{{model.supportDetails.remarks}}</td>
                            <td class="table_data text-left">{{model.supportDetails.deviceName}}</td>
                            <td class="table_data text-left">{{model.supportDetails.createddate |date :'fullDate'}}</td>
                            <td class="table_data text-left">{{model.supportDetails.resolutiondate |date :'fullDate'}}</td>

                            <td class="table_data text-left">{{model.supportDetails.expectedresolutiondate |date :'fullDate'}}</td>

                            <td class="table_data text-center" style="cursor: pointer;">
                                <div class="btn btn-primary btn-sm mx-1" (click)="edit(model.supportDetails,model.filesDetails)" data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop">
                                    <i class="fa fa-edit"></i>
                                </div>
                                <!-- <div class="btn btn-danger btn-sm mx-1" (click)="delete(model.id)">
                                    <i class="fa fa-trash"></i>
                                </div> -->

                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="!supportList.length">
                        <tr>
                            <td colspan="9">
                                <div class="d-flex justify-content-center align-items-center" style="height: 48vh;">
                                    <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE">
                                </div>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
            <!-- PAGINATION -->
            <div *ngIf="supportList.length" class="flex_row_center_spacebetween"
                style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
                <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{(supportList | searchPipe :
                    form.value.searchValue).length/10 | number: '1.0-0'}} {{'of'|translate}}
                    {{(supportList | searchPipe : form.value.searchValue).length}} {{'entries'|translate}}</div>
                <pagination-controls (pageChange)="page = $event"></pagination-controls>
            </div>

        </div>
    </div>
</div>

<!-- ADD MODEL MASTER MODAL -->

<div class="modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content content1">
            <div class="modal-header addtriphead">
                <div class="headtext">{{'Support' | translate}}</div>
                <button type="button" class="btnclose" data-bs-dismiss="modal" aria-label="Close"
                    (click)="onCloseModal()">
                    <span class="material-icons">
                        close
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="annoucmentForm" (ngSubmit)="onModelMasterSubmit()">

                    <div class="row">
                        <div class="col-6">
                            <div class="form-group pb-2">
                                <label for="manufacturer" class="addlabels pb-2">{{'Type'|translate}}</label>
                                <input type="text" formControlName="type" class="form-control inputfields"
                                    style="background: #FFF;box-shadow:none;color:#000;" id="type"
                                    aria-describedby="type">
                                <span class="text-danger validation"
                                    *ngIf="(supportFormControl.type.touched || submitted) && supportFormControl.type.errors?.required">
                                    *type is required.
                                </span>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group pb-2">
                                <label for="manufacturer" class="addlabels pb-2">{{'Description'|translate}}</label>
                                <input type="text" formControlName="description" class="form-control inputfields"
                                    style="background: #FFF;box-shadow:none;color:#000;" id="description"
                                    aria-describedby="description">
                                <span class="text-danger validation"
                                    *ngIf="(supportFormControl.description.touched || submitted) && supportFormControl.description.errors?.required">
                                    *description is required.
                                </span>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group pb-2">
                                <label for="manufacturer" class="addlabels pb-2">{{'Remarks'|translate}}</label>
                                <input type="text" formControlName="remarks" class="form-control inputfields"
                                    style="background: #FFF;box-shadow:none;color:#000;" id="remarks"
                                    aria-describedby="remarks">
                                <span class="text-danger validation"
                                    *ngIf="(supportFormControl.remarks.touched || submitted) && supportFormControl.remarks.errors?.required">
                                    *remarks is required.
                                </span>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group pb-2">
                                <label for="deviceid" class="addlabels pb-2">{{'Device'|translate}}</label>
                                <select class="form-select form_select1" style="background-color: #FFF;box-shadow:none;color:#000;height: 5.6vh;" id="deviceid" formControlName="deviceid">
                                  <option value="" disabled>{{'Select Device'|translate}}</option>
                                  <option *ngFor="let device of deviceList; let i = index" value="{{device.id}}">{{device.name}}</option>
                                </select>
                                <span class="text-danger validation"
                                    *ngIf="(supportFormControl.deviceid.touched || submitted) && supportFormControl.deviceid.errors?.required">
                                    *device is required.
                                </span>
                              </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group pb-2">
                                <label for="assignto" class="addlabels pb-2">{{'Assign To'|translate}}</label>
                                <select class="form-select form_select1" style="background-color: #FFF;box-shadow:none;color:#000;height: 5.6vh;" id="assignto" formControlName="assignto">
                                  <option value="" disabled>{{'Select User'|translate}}</option>
                                  <option *ngFor="let user of userList; let i = index" value="{{user.id}}">{{user.name}}</option>
                                </select>
                                <span class="text-danger validation"
                                    *ngIf="(supportFormControl.assignto.touched || submitted) && supportFormControl.assignto.errors?.required">
                                    *assign to is required.
                                </span>
                              </div>
                        </div>

                    </div>

                    <div class="row">
                        
                        
                        <div class="col-6">
                            <div class="form-group pb-2">
                                <label for="resolutiondate" class="addlabels pb-2">{{'Resolution date'|translate}}</label>
                                <input type="date" formControlName="resolutiondate" class="form-control  w-100"
                                    id="expiration" />
                                <span class="text-danger validation"
                                    *ngIf="(supportFormControl.resolutiondate.touched || submitted) && supportFormControl.resolutiondate.errors?.required">
                                    *Resolution date is required.
                                </span>
                            </div>

                        </div>
                        <div class="col-6">
                            <div class="form-group pb-2">
                                <label for="expectedresolutiondate" class="addlabels pb-2">{{'Expected Resolution
                                    Date'|translate}}</label>
                                <input type="date" formControlName="expectedresolutiondate" class="form-control  w-100"
                                    id="expiration" />
                                <span class="text-danger validation"
                                    *ngIf="(supportFormControl.expectedresolutiondate.touched || submitted) && supportFormControl.expectedresolutiondate.errors?.required">
                                    *Expectedre Solution Date is required.
                                </span>
                            </div>

                        </div>
                        <div class="col-6">
                            <div class="form-group pb-2">
                                <div class="form-check">
                                    <input formControlName="status" class="form-check-input" type="checkbox"
                                        id="status" />
                                    <label class="form-check-label" for="status">Status</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <div class="input-group input-file" name="Fichier1">
                                 
                                  <input
                                    type="file"
                                    #fileInput
                                    id="excelFile"
                                    class="form-control"
                                    (change)="onChangeFile($event)"
                                    placeholder="Choose a file..."
                                  />
                                  
                                </div>
                              </div>
                        </div>
                    </div>
                    <table class="table">
                        <tr  *ngFor="let item of filesList;">
                            <td>{{item.filename}}</td>
                            <td><i (click)="fileDownload(item.id)" class="fa fa-download"></i></td>
                        </tr>
                    </table>


                    <div class="flex_row_center_spacebetween pt-4 pb-2">


                        <button mat-raised-button type="submit" class="cancelbtn">
                            {{'SAVE' | translate}}
                        </button>
                    </div>

                </form>

            </div>
        </div>
    </div>
</div>