import { Component, Input, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MeterTokm } from 'src/app/pipes/meter-km.pie';
import { convertPipe } from 'src/app/pipe/convert.pipe';
import { DurationPipe } from 'src/app/pipe/duration.pipe';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.css'],
  providers:[MeterTokm,convertPipe,DurationPipe]
})
export class ExportDataComponent implements OnInit {
  xlsxFileData:any[];
  @Input() dataList:any[];
  @Input() selectedPage:string;
  htmlString:any;
  fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';
  pdfTable:any;
  constructor(private datePipe: DatePipe,private numberPipe:DecimalPipe, private meterTokmPipe:MeterTokm,
    private convertPipe:convertPipe, private duration:DurationPipe,) { }

  ngOnInit(): void {
    debugger
    console.log("dataList",this.dataList)
  }
  downloadData(type) {
    
    debugger
    let tableList = [];
    switch (this.selectedPage) {
      case 'dealer':
        tableList = [
          {
            titleName: 'Name',
            colName: 'name',
          },
          {
            titleName: 'User ID',
            colName: 'email',
          },
          {
            titleName: 'Device Limit',
            colName: 'deviceLimit',
          },
          {
            titleName: 'User Limit',
            colName: 'userLimit',
          },
          {
            titleName: 'Disabled',
            colName: 'disabled',
          },
        ];
        break;
        case 'customer':
          tableList = [
            {
              titleName: 'Name',
              colName: 'name',
            },
            {
              titleName: 'User ID',
              colName: 'email',
            },
            
            {
              titleName: 'Disabled',
              colName: 'disabled',
            },
          ];
          break;
          case 'Trips':
            tableList = [
              {
                titleName: 'Task ID',
                type: '',
                filter: '',
                hirarchy: [],
                isHirarchy: false,
                colName: 'id',
              },
              {
                titleName: 'Client Name',
                type: '',
                filter: '',
                hirarchy: [],
                isHirarchy: false,
                colName: 'clientid',
              },
              {
                titleName: 'Task Type',
                type: '',
                filter: '',
                hirarchy: [],
                isHirarchy: false,
                colName: 'type',
              },
              {
                titleName: 'Address',
                type: '',
                filter: '',
                hirarchy: [],
                isHirarchy: false,
                colName: 'pickuplocation',
              },
              {
                titleName: 'Status',
                type: '',
                filter: '',
                hirarchy: [],
                isHirarchy: false,
                colName: 'status',
              },
              {
                titleName: 'Driver Name',
                type: '',
                filter: '',
                hirarchy: ['attributes'],
                isHirarchy: true,
                colName: 'attributes.driverName',
              },
             
            
              {
                titleName: 'Created On',
                type: 'date',
                filter: '',
                format: 'dd/MM/yyyy hh:mm a',
                hirarchy: [],
                isHirarchy: false,
                colName: 'date',
              },
              {
                titleName: 'Start Time',
                type: 'date',
                filter: '',
                format: 'dd/MM/yyyy hh:mm a',
                hirarchy: [],
                isHirarchy: false,
                colName: 'actualstarttime',
              },
              {
                titleName: 'End Time',
                type: 'date',
                filter: '',
                format: 'dd/MM/yyyy hh:mm a',
                hirarchy: [],
                isHirarchy: false,
                colName: 'actualendtime',
              },
          
              {
                titleName: 'Distance',
                type: '',
                filter: '',
                format: '',
                hirarchy: [],
                isHirarchy: false,
                colName: 'gpsDistance',
              },
              {
                titleName: 'Duration',
                type: '',
                filter: '',
                format: 'hh:mm',
                isHirarchy: false,
                colName: 'duration',
              },
              {
                titleName: 'Remarks',
                type: '',
                filter: '',
                format: '',
                hirarchy: ['attributes', 'remarks'], // Specify the hierarchy path
                isHirarchy: true,
                colName: 'remarks',
              },
            ];
            break;
          
      case 'Devices':
        tableList = [
          {
            titleName: 'Vehicle',
            colName: 'name',
          },
          {
            titleName: 'IMEI',
            colName: 'uniqueId',
          },
          {
            titleName: 'Status',
            colName: 'status',
          },
          {
            titleName: 'LastUpdate',
            colName: 'lastUpdate',
          },
          {
            titleName: 'SimNo',
            colName: 'phone',
          },
          {
            titleName: 'Model',
            colName: 'model',
          },
          {
            titleName: 'Contact',
            colName: 'contact',
          },
          {
            titleName: 'Icon',
            colName: 'category',
          },
          {
            titleName: 'Disabled',
            colName: 'disabled',
          },
          {
            titleName: 'ExpirationTime',
            colName: 'expirationTime',
          },
          {
            titleName: 'CreationTime',
            colName: 'creation_date',
          },
          {
            titleName: 'TodaysOdo',
            colName: 'distanceForday',
          },
        ];
        break;
    }

    this.xlsxFileData = [];
    if (type === 'xlsx') {
      this.dataList.forEach((r: any, i: number) => {
        let dataSet = {};
        let itrations =r;
        if(this.selectedPage == 'Devices') {
           itrations = r['device'];
        }
        tableList.forEach((t: any) => {
          debugger
            dataSet[t.titleName] = itrations[t.colName] ? itrations[t.colName] : '';
        });
        if(this.selectedPage == 'Devices') { 
          dataSet['Disabled'] = dataSet['Disabled'] ? 'Yes':'No';
          dataSet['LastUpdate'] = dataSet['LastUpdate'] ? this.transformDate(dataSet['LastUpdate'],'MMM d, y h:mm:ss a') : '';
          dataSet['ExpirationTime'] = dataSet['ExpirationTime'] ? this.transformDate(dataSet['ExpirationTime']) : '';
          dataSet['CreationTime'] = dataSet['CreationTime'] ? this.transformDate(dataSet['CreationTime']) : '';
          dataSet['TodaysOdo'] = dataSet['TodaysOdo'] ? this.meterTokmPipe.transform(dataSet['TodaysOdo']) : '';

        } else if(this.selectedPage == 'dealer' || this.selectedPage == 'customer') {
          dataSet['Disabled'] = dataSet['Disabled'] ? 'Yes':'No';
        }else if(this.selectedPage =='Trips') {
          debugger
          dataSet['Created On'] = dataSet['Created On'] ? this.transformDate(dataSet['Created On'],'dd/MM/yyyy hh:mm a') : 'N/A';
          dataSet['Start Time'] = dataSet['Start Time'] ? this.transformDate(dataSet['Start Time'],'dd/MM/yyyy hh:mm a') : 'N/A';
          dataSet['End Time'] = dataSet['End Time'] ? this.transformDate(dataSet['End Time'],'dd/MM/yyyy hh:mm a') : 'N/A';
          // dataSet['Odometer Start'] = dataSet['Odometer Start'] ? this.meterTokmPipe.transform(dataSet['Odometer Start']) : '';
          // dataSet['Odometer End'] = dataSet['Odometer End'] ? this.meterTokmPipe.transform(dataSet['Odometer End']) : '';
          dataSet['Distance'] = dataSet['Distance'] ? this.meterTokmPipe.transform(dataSet['Distance']) + ' km' : 'N/A';

          // dataSet['Maximum Speed'] = dataSet['Maximum Speed'] ? this.numberPipe.transform(dataSet['Maximum Speed'],'1.0-2') : '';
          // dataSet['Average Speed'] = dataSet['Average Speed'] ? this.numberPipe.transform(dataSet['Average Speed'],'1.0-2') : '';
          dataSet['Duration'] = dataSet['Duration'] ? this.duration.transform(dataSet['Duration']) : 'N/A';
          dataSet['Remarks'] = dataSet['Remarks'] ? (dataSet['Remarks']) : 'N/A';
      
        }

        this.xlsxFileData.push(dataSet);
      });
      this.exportExcel(this.xlsxFileData, this.selectedPage);
    } else {
      let html = `<style>.pdf-container { font-size:10px;} </style><table class="border pdf-container"><thead><tr>`;

      tableList.forEach((t) => {
        html += `<th>${t.titleName}</th>`;
      });
      html += `</tr></thead><tbody>`;
      this.dataList.forEach((r: any, i: number) => {
        html += `<tr>`;
        tableList.forEach((t: any) => {
          html += `<td>${r[t.colName] ? r[t.colName] : ''}</td>`;
        });
        html += `</tr>`;
      });
      html += `</tbody></table>`;

      this.htmlString = html;

      setTimeout(() => {
        const pdfTable = this.pdfTable.nativeElement;
        // this.convetHtmlToPDF(pdfTable.innerHTML);
        //this.downloadAsPDF();
      }, 2000);
    }
  }
  transformDate(date,format='dd/MM/yyyy') {
    return this.datePipe.transform(date, format);
  }
  exportExcel(xlsxFileData: any[], fileName: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(xlsxFileData);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveExcelFile(excelBuffer, fileName);
  }

  saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }
}
