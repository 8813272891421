<div class="container-fluid">
  <div class="row align-items-center section">

    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 logincol_1">
      <div>
        <img src="/assets/images/login.png" class="loginimg" alt="login">
      </div>
      <div>
        <div class="easyTraking mt-3">{{'Easy Traking'|translate}}</div>
        <p class="logintext1 my-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo.</p>
      </div>
    </div>

    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 logincol_2">
      <div class="card">
        <div class="card-body">
          <form class="registerform" [formGroup]="registerForm" (ngSubmit)="onSubmit()">

            <div class="form-group">
              <input type="text" formControlName="firstname" type="text" placeholder="{{'First Name'|translate}}"
                class="inputfields text-white">
              <span class="text-danger validation"
                *ngIf="(registerFormControl.firstname.touched || submitted) && registerFormControl.firstname.errors?.required">
                *{{'First Name'|translate}} {{'is required'|translate}}
              </span>
            </div>

            <div class="form-group">
              <input type="text" formControlName="lastname" class="inputfields text-white" placeholder="{{'Last Name'|translate}}">
              <span class="text-danger validation"
                *ngIf="(registerFormControl.lastname.touched || submitted) && registerFormControl.lastname.errors?.required">
                *{{'Last Name'|translate}} {{'is required'|translate}}
              </span>
            </div>

            <div class="form-group">
              <div class="phoneGroup"> 

                <select class="form-select form-select-lg table-scroller" formControlName="stdCode">
                  <option value="{{intlcode.phoneCode}}" *ngFor="let intlcode of prefixes;let i=index">
                    {{intlcode.phoneCode}} &nbsp; {{intlcode.name}}
                  </option>
                </select>

                <input type="number" formControlName="phone" class="inputfields_radio text-white"
                  placeholder="{{'Mobile Number'|translate}}">
              </div>
              <span class="text-danger validation"
                *ngIf="(registerFormControl.phone.touched || submitted) && registerFormControl.phone.errors?.required">
                *{{'Mobile Number'|translate}} {{'is required'|translate}}</span>
            </div>

            <div class="form-group">
              <input type="email" formControlName="email" class="inputfields text-white" placeholder="{{'Email ID'|translate}}">
              <span class="text-danger validation"
                *ngIf="(registerFormControl.email.touched || submitted) && registerFormControl.email.errors?.required">
                *{{'Email ID'|translate}} {{'is required'|translate}}
              </span>
              <span class="text-danger validation"
                *ngIf="registerFormControl.email.touched && registerFormControl.email.errors?.email">
                *{{'Enter a valid email address'|translate}}
              </span>
            </div>

            <div class="form-group">
              <input type="password" formControlName="password" class="inputfields text-white" placeholder="{{'Password'|translate}}*">
              <span class="text-danger validation"
                *ngIf="(registerFormControl.password.touched || submitted) && registerFormControl.password.errors?.required">
                *{{'Password'|translate}} {{'is required'|translate}}
              </span>
              <span class="text-danger validation"
                *ngIf="registerFormControl.password.touched && registerFormControl.password.errors?.invalidPassword">
                *{{'Password should have minimum 8 characters at least 1 uppercase letter 1 lowercaseletter and 1 number'|translate}}
              </span>
            </div>

            <div class="form-group">
              <input type="password" formControlName="confirmPassword" class="inputfields text-white"
                placeholder="{{'Confirm Password'|translate}}*">
              <span class="text-danger validation"
                *ngIf="(registerFormControl.confirmPassword.touched || submitted)&& registerFormControl.confirmPassword.errors?.required">
                *{{'Confirm Password'|translate}} {{'is required'|translate}}
              </span>
              <span class="text-danger validation"
                *ngIf="registerFormControl.confirmPassword.touched && registerFormControl.confirmPassword.errors?.passwordMismatch">
                *{{'Passwords does not match'|translate}}
              </span>
            </div>

            <div class="account__btns" style="width: 100%;">
              <button type="submit" class="btn signup">{{'Sign Up'|translate}}</button>
            </div>

          </form>
        </div>
      </div>
      <div class="alreadyuser">{{'Already have an account'|translate}}? <span class="signin" routerLink="/login">Sign in</span> </div>
    </div>

  </div>
</div>
