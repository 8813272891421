import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-common-modal-popup',
  templateUrl: './common-modal-popup.component.html',
  styleUrls: ['./common-modal-popup.component.css']
})
export class CommonModalPopupComponent implements OnInit {
  @Input() modelSize = 'modal-xl';
  @Input() showModel:boolean = false;
  @ViewChild('openModal') openModal: ElementRef;
  @ViewChild('btnCloseModel') btnCloseModel: ElementRef;  
  constructor(private commonService: CommonService) { 
    this.commonService.commonModel.subscribe((result: boolean) => {
      if (result) {
        this.openPopup();
      } else {
        this.closePopup();
      }
    })
  }
  applySize() {
    let modelSizeClass = {};
    modelSizeClass[this.modelSize] = true;
    return modelSizeClass;
  }

  ngOnInit(): void {
  }

  openPopup() {
    this.openModal.nativeElement.click();
    document.body.classList.add('modal-open');
  } 

  closePopup() {
    this.btnCloseModel.nativeElement.click();
    this.commonService.commonModelReset.next();
    document.body.classList.remove('modal-open');
  }
  
}
