import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'meterTokm',
})

export class MeterTokm implements PipeTransform {
  constructor() {}
  transform(meter: any, unit?: string) {
    if (unit == 'mi') {
      let mi = meter/1609.34;   
      return mi? mi.toFixed(2) :0;
    } else if (unit == 'nmi') {
      return meter.toFixed(2);
    } else {
      let km = meter/1000;   
      return km? km.toFixed(2) :0;
    }    
  }
}
