import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-tasks-filter',
  templateUrl: './tasks-filter.component.html',
  styleUrls: ['./tasks-filter.component.css'],
})
export class TasksFilterComponent implements OnInit {
  @Output() filterDataEvent = new EventEmitter<any>();
  formattedFromDate: any = '';
  formattedToDate: any = '';
  todateP: any;
  userdata: any;
  orgId: any;
  taskListData: any[] = [];
  customerList: any[] = [];
  inputFromDate: any = '';
  inputToDate: any= '';
  clientId: any= '';
  isSupervisor: boolean =false;
  constructor(private commonService: CommonService) {}
  toDate: any = '';
  fromDate: any = '';
  searchClients: any = '';
  // isClient: boolean = false;
  ngOnInit(): void {
    let userDataObj = localStorage.getItem('userData');
    if (userDataObj) {
      try {
        this.userdata = JSON.parse(userDataObj);
        console.log('Org Id =>', this.userdata.id);
        if (this.userdata.attributes.role === 'admin') {
          this.orgId = this.userdata.id;   
        }
        else if(this.userdata.attributes.role === 'sv'){  
          this.isSupervisor=true;
          this.orgId = this.userdata.orgId;
         
        }
        else{
          this.orgId = this.userdata.orgId;
        }

        this.getAllCustomers();
      } catch (error) {}
    }
    let today = moment();
    this.fromDate = today.format("DD/MM/YYYY");
    this.todateP = today.format("DD/MM/YYYY");
  }
  getAllCustomers() {
    if(this.isSupervisor){
      const uniqueClientNames = new Set();
      const today = new Date();
      const formattedToday = today.toISOString().split('T')[0] + 'T00:00:00Z';
      const formattedToday1 = today.toISOString().split('T')[0] + 'T23:59:59Z';
      this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,formattedToday,formattedToday1,'','','','','','')
      .subscribe((response) => {
       console.log("task list for supervisor=>",response)
      const uniqueClientData = new Set();
     response.forEach(item => {
    const clientName = item.attributes.clientName;
    const clientId = item.clientid;
    const clientDataString = `${clientName}_${clientId}`;
    uniqueClientData.add(clientDataString);
});


const uniqueClientDataArray = Array.from(uniqueClientData).map(string => {
    const [clientName, clientId] =  (string as string).split('_');
    return { clientName, clientId };
});
this.customerList=uniqueClientDataArray

      
      }, (error) => {
      
        console.error(error);
      });
    }
    else{
      this.commonService
      .getRequest(`users?orgId=${this.orgId}`, [])
      .then((resp: any) => {
        console.log('userslist',resp)
        if (resp?.status) {
          this.customerList = resp.data;
          this.customerList = this.customerList.filter(
            (customer) => customer.attributes?.role == 'clients'
          );
        }
      });
    }
  }
  
  convertedDates() {
  if(this.fromDate != '' && this.toDate!= ''){
    this.inputFromDate = new Date(this.fromDate);
    this.inputToDate = new Date(this.toDate);
  this.formattedFromDate = this.inputFromDate.toISOString().slice(0, 10) + 'T00:00:00Z';
  this.formattedToDate = this.inputToDate.toISOString().slice(0, 10) + 'T23:59:59Z';
  }
   
    const assignedTo: any = '';
    const supervisorId: any = '';
  const clientId: any = this.searchClients ? this.searchClients : '';
  if(this.isSupervisor){
    this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,this.formattedFromDate,this.formattedToDate,assignedTo,clientId,'','','','')
    .subscribe((response) => {
      this.taskListData = response;
      this.sendDataToParent(this.taskListData);
      // Handle the API response here
      console.log(response);
      this.closeForm();
    }, (error) => {
      console.error(error);
    });

  }
  else{

    this.commonService
    .getTasks(
      `traccartasks/orgId/${this.orgId}`,
      assignedTo,
      supervisorId,
      this.formattedFromDate,
      this.formattedToDate,
      clientId,
      ''
    )
    .subscribe(
      (response) => {
        this.taskListData = response;
        this.sendDataToParent(this.taskListData);
        // Handle the API response here
        console.log(response);
        this.closeForm();
      },
      (error) => {
        // Handle errors here
        console.error(error);
      }
    );
  }
  
// }
  
  }
  sendDataToParent(data: any) {
    // Process your data as needed
    this.filterDataEvent.emit(data);
    // this.closeForm();
  }
  closeForm() {
    this.commonService.commonModel.next(false);
  }

  filteredData() {
    this.formattedFromDate = this.fromDate ? `${this.fromDate}T00:00:00Z` : '';
    this.formattedToDate = this.toDate ? `${this.toDate}T00:00:00Z` : '';
    console.log(this.formattedFromDate);
    console.log(this.formattedToDate);
    const assignedTo: any = '';
    const supervisorId: any = '';
    const clientId: any =this.searchClients ? this.searchClients : '';
   if(this.isSupervisor){
  this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,this.formattedFromDate,this.formattedToDate,assignedTo,clientId,'','','','')
  .subscribe((response) => {

  }, (error) => {
    console.error(error);
  });
}
else{
  this.commonService
  .getTasks(
    `traccartasks/orgId/${this.orgId}`,
    assignedTo,
    supervisorId,
    this.formattedFromDate,
    this.formattedToDate,
    clientId,
    ''
  )
  .subscribe(
    (response) => {
      this.taskListData = response;
      // Handle the API response here
      console.log(response);
    },
    (error) => {
      // Handle errors here
      console.error(error);
    }
  );
}

   
  }
}
