import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { url, environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private httpOptions = {};

  constructor(private auth: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      ),
      credentials: 'same-origin',
      withCredentials: true,
    };
  }

  postRequest(url: string, body: any) {
    const payload = new HttpParams()
      .set('email', body.email)
      .set('password', body.password);

    let _url = environment.apiUrl + url;
    return this.auth.post<any>(_url, payload, this.httpOptions);
  }

  postRequest2(url: string, body: any) {
    const payload = new HttpParams()
      .set('email', body.email)
      .set('password', body.password);

    let _url = environment.apiUrl + url;
    return this.auth.post<any>(_url, payload, {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/json; charset=utf8'
      ),
    });
  }

  getRequest(url: string, body: any) {
    let _url = environment.apiUrl + url;
    return this.auth.get<any>(_url, {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/json; charset=utf8'
      ),
    });
  }

  login(body) {
    let _url = url + 'users/LoginWithOtp';
    return this.auth.post<any>(_url, body);
  }

  register(body) {
    let _url = url + 'users/signUp';
    return this.auth.post<any>(_url, body);
  }
}
