
<div [formGroup]="driverForm">
  <div class="p-0" >
  
    <div class="row">
      <div class="col-md-11 col-11">
        <h3>Edit Stop</h3>
      </div>
      <div class="col-md-1 col-1">
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
      </div>
   
    </div>

    <div  style="width: 100%;border-top: 5px solid #dee2e6 !important;" class="mt-2 mb-3"></div>
    <div class="row mt-2 mb-2">
    <div class="col-md-6">
      <div class="form-group">
        <label for="name" class="pb-1"  *ngIf="isPickup">{{'Pickup'|translate}}</label> 
        <label for="name" class="pb-1" *ngIf="isDrop">{{'Drop'|translate}}</label>
        <input
          type="text"
          class="form-control inputfields"
          id="name"
          aria-describedby="name"
          formControlName="taskid"
          [(ngModel)]="this.taskid"
          readonly
     
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="escort1" class="pb-1">{{'Task Status '|translate}}</label>
        <select
          class="form-select me-2  taskspanel"
          id="escort1"
 
          formControlName="status"
          (change)="handleStatusChange($event)"   
        >
        <option value="unassigned">Unassigned</option>                      
        <option value="assigned">Assigned</option>
        <option value="acknowledged">Acknowledged</option>
        <option value="started">Started</option>
        <option *ngIf="this.taskDetails.attributes.remarks=== 'separate' && this.taskDetails.type === 'drop' " value="dropped">Dropped</option>
        <option *ngIf="this.taskDetails.attributes.remarks=== 'separate' && this.taskDetails.type === 'pickup' " value="pickedup">Picked Up</option>
        <option value="completed">Completed</option>
        <option value="cancel">Cancel</option>       
        </select> 
      </div>  
  </div>
    </div>
    <div *ngIf="isAssignDriver" class="row mb-2">
      <div class="col-md-6 col-12">
        <div class="form-group">
        <label for="driver">Select Driver</label>
        <mat-form-field  class="example-full-width" appearance="fill">
          <input  type="text" placeholder="Select Driver"  matInput 
          [formControl]="myDriverControl"
          [matAutocomplete]="autoDriver" 
          (click)="filteredOptionDriver()"
          id="driver"
    
           />
           <mat-autocomplete [displayWith]="displayFnDriver" #autoDriver="matAutocomplete"
          (optionSelected)="driverSelected($event.option.value)"
          (closed)="onDriverAutocompleteClosed()"
          >
           <mat-option *ngFor="let option of filteredOptionsDriver | async" [value]="option">
       {{ (option.attributes.cabno ? option.attributes.cabno : '') + ' ' + (option.name ? option.name : '') }}
           </mat-option>
           </mat-autocomplete>
        </mat-form-field>
          </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
        <label for="remarks">Remarks</label>
        <select class="form-select taskspanel" formControlName="remarks" id="remarks">
            <option value="0" selected>Select</option>
            <option value="cabin">Cab In</option>                      
            <option value="cabout">Cab Out</option>
            <option value="separate">Separate</option>
            <option value="backtoback">Back to Back</option> 
            <option value="garage">Garage</option>                     
        </select>
      </div>
      </div>
    </div>
</div>
<div class="modal-body p-0">
<div class="row mb-2">
    <div class="col-md-6">
      <div class="form-group">
        <label for="name" class="pb-1" >{{'Company Name'|translate}}</label> 
 
        <input
          type="text"
          class="form-control inputfields"
          id="name"
          aria-describedby="name"
          formControlName="name"
          [(ngModel)]="this.client"
          readonly
     
        />
     
      </div>
    </div>
 
    <div class="col-md-6">
      <div class="form-group">
        <label for="date" class="pb-1">{{ 'Date and Shift Time ' | translate }}</label>
    
        <div class="d-flex align-items-center">
            <input
                type="text"
                id="date"
                style="height: 5.5vh; border-radius: 5px; width: 121px;"
                placeholder=" dd/mm/yyyy"
                formControlName="date"   
                readonly
            />
        
            <input type="text"
            formControlName="fromDateHours"
            class="hrWidth"
            readonly
            >
            <input type="text"
            formControlName="fromDateMinutes"
        class="hrWidth"
            readonly
            >
      </div>
  </div>
  </div>
  </div>
  <div class="row mb-4">
    <div class="col-12">
      <div class="form-group">
        <label for="name" class="pb-1"  *ngIf="isPickup">{{'Pickup Address'|translate}}</label> 
        <label for="name" class="pb-1" *ngIf="isDrop">{{'Drop Address'|translate}}</label>
        <input
        #inputField
          type="text"
          class="form-control inputfields"
          id="name"
          aria-describedby="name"
          formControlName="pickupLocation"
          [(ngModel)]="this.Location"
        />
          </div>   
      </div>
  </div> 

<div class="row mb-3" *ngIf="false">
    <div class="col-12">
        <mat-form-field
        appearance="outline"
        class="w-100"
        floatLabel="always"
       
      >
        <mat-label class="custom-label">Description</mat-label>
        <input
          formControlName="description"
          matInput
          
        />
      </mat-form-field>
    </div>
</div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="this.closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="update()">Update Stop</button>
    
</div>
</div>
  
