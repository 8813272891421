import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root',
})
export class SignzyApiService {
    private BASE_URL = environment.signzyUrl;

  constructor(private http: HttpClient) {}

  getDLDetailsData(requestBody): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.102 Safari/537.36'
    });

    requestBody['service']="Identity";
    requestBody['itemId']="65bb484bef44ad002389bba9";
    requestBody['accessToken']="e0kfqov4k2hfk8u2scumycemx43c2b55i";
    requestBody['task']="fetch";
    return this.http.post(this.BASE_URL, requestBody, { headers});

  }
}
