

<div class="container-fluid">
    <div class="row">
      <div class="col-sm-4 col-12 device-list-setting" id="closeFullMap">
  
        <div class="card expand-object-list" [class.device-list]="isDeviceListVisible"
          title="{{ 'Expand Object list' | translate }}s" (click)="expandDeviceList()">
          >
        </div>
        <div class="card main_card" *ngIf="main_col" [class.device-list]="!isDeviceListVisible">
          <div style="position: relative">
            <div title="Collapse" class="collapse-task-panel" style="cursor: pointer" (click)="collapseTaskList()">
              < </div>
            </div>
            <div class="row bg-light m-0">
              <div class="col-3 col-sm-3 d-flex align-items-center justify-content-center font-size-12 device-tabs"
                   title="{{ 'Objects' | translate }}" [class.select]="isObjectsTab" (click)="tabChange('object')">
                {{ "Objects" | translate }}
              </div>
            
              <div class="col-3 col-sm-3 d-flex align-items-center justify-content-center font-size-12 device-tabs"
                   [class.select]="isHistoryTab" title="{{ 'History' | translate }}" (click)="tabChange('history')">
                {{ "History" | translate }}
              </div>
            
            
            </div>
            
            <ng-contaier *ngIf="isObjectsTab">
              <div class="row bg-light padding-left-0_8 m-0">
                <div class="col-10 p-1">
                  <app-search-device class="w-100"></app-search-device>
                </div>
                <div class="col-2"></div>
              </div>  
  
              <div class="row bg-light m-0">
                <div class="col-1 border-end d-flex align-items-center justify-content-center" title="Show/Hide all">
                  <i class="bi bi-eye ms-1"></i>
                </div>
                <div class="col-1 border-end" title="Follow/Unfollow all">
                  <img src="/assets/icons/follow.svg" [ngStyle]="{ width: '14px', height: '15px' }" alt="img" />
                </div>
                <div class="col-2 border-end"></div>
                <div class="col-7 border-end d-flex align-items-center justify-content-center font-size-12">
                  {{ "Object" | translate }}
                </div>
                <div class="col-1"></div>
              </div>
              <div class="row m-0">
                <div class="col-1 d-flex align-items-center justify-content-center">
                  <div class="form-check ms-2">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="selectAllObjects"
                      (change)="selectAllObjectChange()" />
                  </div>
                </div>
                <div class="col-1" title="Follow/Unfollow all">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" />
                  </div>
                </div>
                <div class="col-2"></div>
                <div class="col-4 d-flex align-items-center justify-content-center"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <div class="scroller" (scroll)="
                allCounts[statusFilter] > devicesList.length && onScroll($event)
              ">
              <div
    (click)="onFullVehicleDetail(device, true)"
    [class.bg-light]="selectedDevice?.deviceId == device.deviceId"
    class="row m-0 d-flex align-items-center justify-content-center car-content"
    *ngFor="let device of devicesList; let i = index"
  >
    <div class="col-1 d-flex align-items-center justify-content-center">
      <div class="form-check ms-2">
        <input
          class="form-check-input"
          type="checkbox"
          (change)="onObjectSelectionChange(device, $event)"
          [(ngModel)]="device.isSelected"
        />
      </div>
    </div>
    <div class="col-1" title="Follow/Unfollow all">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" />
      </div>
    </div>
    <div class="col-2">
      <div class="fix_align d-flex align-items-center justify-content-center">
        <img
          [src]="
            device?.deviceDetails?.vehicleImg ||
            '/assets/icons/devices/car/card.png'
          "
          [ngStyle]="{ width: '33px', height: '39px' }"
          alt="img"
          class="vehicleimg"
        />
        <ng-container *ngIf="device.statuss === 'STOPPED'">
          <img src="/assets/icons/red_car.png" alt="img" class="vehicleimg" />
        </ng-container>
        <ng-container *ngIf="device.statuss === 'RUNNING'">
          <img src="/assets/icons/running_car.png" alt="img" class="vehicleimg" />
        </ng-container>
        <ng-container *ngIf="device.statuss === 'IDLE'">
          <img src="/assets/icons/idle_car.png" alt="img" class="vehicleimg" />
        </ng-container>
        <ng-container *ngIf="device.statuss === 'NOT_REPORTING'">
          <img src="/assets/icons/no_reporting_car.png" alt="img" class="vehicleimg" />
        </ng-container>
      </div>
    </div>
    <div class="col-4 d-flex justify-content-center flex-column">
      <span class="font-size-12">{{ device.name }}</span>
      <span class="d-flex align-items-center" *ngIf="['EXPIRED', 'NO_DATA'].indexOf(device.status) === -1">
        <img src="/assets/icons/vehicletimer.png" alt="timer" class="img_timer" />
        <span class="update pl-1">{{ device.serverTime | dateFormat }}</span>
      </span>
    </div>
    <div class="col-3 d-flex align-items-center justify-content-center">
      <div class="vehicle_features mt-1" *ngIf="['EXPIRED', 'NO_DATA'].indexOf(device.status) === -1">
        <div class="bottomIcons">
          <span class="mat_icon d-flex align-items-center justify-content-center flex-column">
            <span class="speedalign">{{ device?.speed | number : "1.0-2" }}</span>
            <span class="dist_fuel_speed" style="padding-left: 5px; white-space: nowrap">{{ "kmph" | translate }}</span>
          </span>
        </div>
        <div class="bottomIcons">
          <img
            src="/assets/icons/battery_play.png"
            alt="img"
            title="{{ 'Battery' | translate }}"
            *ngIf="device?.attributes?.ignition; else showIgnRed"
          />
          <span class="bottom_tags">{{ "BAT" | translate }}</span>
        </div>
        <div class="bottomIcons">
          <img
            src="/assets/icons/bottom6.png"
            alt="img"
            title="{{ 'GPS' | translate }}"
            *ngIf="device?.valid; else showGPSGray"
          />
          <span class="bottom_tags">{{ "GPS" | translate }}</span>
        </div>
      </div>
    </div>
    <div class="col-1">
      <div class="dropdown">
        <div class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-ellipsis-v"></i>
        </div>
        <ul class="dropdown-menu option_drop pb-1 pt-1" aria-labelledby="dropdownMenuButton1">
          <li>
            <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#staticBackdropAdd" (click)="editDevice(device)">
              <span class="optionstext">
                <i class="fa fa-edit"></i> {{ "Edit" | translate }}
              </span>
            </a>
          </li>
          <li *ngIf="userData.administrator">
            <a class="dropdown-item" (click)="deleteDevice(device.deviceId)">
              <span class="optionstext">
                <i class="fa fa-trash"></i> {{ "Delete" | translate }}
              </span>
            </a>
          </li>
          <li *ngIf="false">
            <a class="dropdown-item" (click)="sendGprsCommand(device)">
              <span class="optionstext">
                <i class="fa fa-trash"></i> {{ "Send GPRS Command" | translate }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
              </div>
            </ng-contaier>
            
            
  
            <ng-contaier *ngIf="isHistoryTab">
              <form [formGroup]="historyForm">
                <div class="row d-flex align-items-center mb-1 mt-1">
                  <div class="col-3 font-size-12">
                    {{ "Driver" | translate }}
                  </div>
                  <div class="col-9" style="display: grid; grid-template-columns: 1fr">
                
                          <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Select Driver</mat-label>
                      <input type="text"
                             placeholder="Select one"
                             aria-label="Number"
                             matInput
                             [formControl]="myControl"
                             [matAutocomplete]="auto">
                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                      (optionSelected)="onOptionSelected($event);">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.display" (onSelectionChange)="driverSelected(option)">
                          {{option.display}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-1">
                  <div class="col-3 font-size-12">
                    {{ "Filter" | translate }}
                  </div>
                  <div class="col-9">
                    <select class="form-select history form-select-sm" aria-label=".form-select-sm example"
                      formControlName="filter" (change)="setHistoryDate(historyForm.get('filter')?.value)">
                      <option selected value=""></option>
                      <option value="0">{{ "Today" | translate }}</option>
                      <option value="1">{{ "Last 24 Hour" | translate }}</option>
                      <option value="2">{{ "Yesterday" | translate }}</option>
                      <option value="3">{{ "This Week" | translate }}</option>
                      <option value="4">{{ "Last Week" | translate }}</option>
                      <option value="5">{{ "This Month" | translate }}</option>
                      <option value="6">{{ "Last Month" | translate }}</option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-1">
                  <div class="col-3 font-size-12">
                    {{ "Time from" | translate }}
                  </div>
                  <div class="col-9">
                    <input type="datetime" formControlName="timeFrom" />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-1">
                  <div class="col-3 font-size-12">
                    {{ "Time to" | translate }}
                  </div>
                  <div class="col-9">
                    <input type="datetime" formControlName="timeTo" name="todate" id="meeting" />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-1">
                  <div class="col-3 font-size-12">
                    {{ "Stops" | translate }}
                  </div>
                  <div class="col-9">
                    <select class="form-select history form-select-sm" aria-label=".form-select-sm example">
                      <option selected value=""></option>
                      <option value="0">> 1 min</option>
                      <option value="1">>2 min</option>
                      <option value="2">> 5 min</option>
                      <option value="3">> 10 min</option>
                      <option value="4">> 20 min</option>
                      <option value="5">> 30 min</option>
                      <option value="6">> 1 h</option>
                      <option value="6">> 2 h</option>
                      <option value="6">> 3 h</option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center mt-3 mb-3">
                  <div class="col-3">
                    <button type="button" class="btn btn-light w-100"
                      (click)="showTracking(historyForm.get('filter')?.value)">
                      {{ "Show" | translate }}
                    </button>
                  </div>
                  <div class="col-3">
                    <button type="button" class="btn btn-light w-100" (click)="hideTracking($event)">
                      {{ "Hide" | translate }}
                    </button>
                  </div>
                  <div class="col-4">
                    <button type="button" class="btn btn-light w-100">
                      {{ "Import/Export" | translate }}
                    </button>
                  </div>
                </div>
              </form>
            </ng-contaier>
          </div>
         
  
          <div class="card main_card2" *ngIf="fulldetails">
  
  
            <div class="btngroup">
              <div>
                <div class="btn detailbtn" (click)="onClickDetails()">
                  {{ "Vehicle Details" | translate }}
                </div>
              </div>
              <div class="btn" style="padding: 0 0.5vh">
                <span class="material-icons" style="color: #fff" (click)="onBack()">
                  arrow_back
                </span>
              </div>
            </div>
  
  
            <div class="vehicle_fullDetail" *ngIf="vehicle_fullDetail">
              <div class="mat_icon">
                <div class="fix_align">
                  <img [src]="
                    selectedDevice?.deviceDetails?.vehicleImg ||
                    '/assets/icons/devices/car/card.png'
                  " [ngStyle]="{ width: '45px', height: '60px' }" alt="img" class="vehicleimg" />
                </div>
                <span class="vehicle_update" style="margin-left: 1vh">
                  <span class="vehicle_number">{{ selectedDevice.name }}</span>
                  <span class="height-17">
                    <img [src]="
                      selectedDevice?.deviceDetails?.dotImg ||
                      '/assets/icons/no_data.png'
                    " alt="dot" class="dot" />
                    <span class="update" style="font-size: 1.4vh">{{ "Status" | translate }}
                      <span [ngStyle]="{
                        color: selectedDevice?.deviceDetails?.color,
                        'padding-left': '5px'
                      }">
                        {{ selectedDevice?.deviceDetails?.msg }}
                      </span>
                    </span>
                  </span>
                  <span class="">
                    <img src="/assets/icons/vehicletimer.png" alt="timer" class="img_timer2" />
                    <span class="update" style="font-size: 1.4vh">{{ "Last Updated" | translate }} -
                      {{ selectedDevice.deviceTime | dateFormat }}</span>
                  </span>
                </span>
                <div class="speed-large">
                  <span class="dist_fuel_speed_span" style="padding-left: 5px">
                    {{ selectedDevice?.speed | number : "1.0-2"
                    }}<small>{{ "kmph" | translate }}</small>
                  </span>
                </div>
              </div>
  
              <!-- <div class="row pt-1  m-0">
              <div class="col-6 p-0">
                <span class="mat_icon">
                  <span class="material-icons speedalign"> {{'speed'|translate}} </span>
                  <span class="dist_fuel_speed" style="padding-left: 5px"
                    >{{ selectedDevice?.speed || 0 }}{{'kmph'|translate}}</span
                  >
                </span>
              </div>
              <div class="col-6 p-0">
                <span class="mat_icon">
                  <img src="/assets/icons/petrolremaining.png" alt="dot" />
                  <span class="dist_fuel_speed">NA</span>
                </span>
              </div>
            </div> -->
  
              <!-- <div class="row py-1 m-0">
              <div class="col-6 p-0">
                <span class="mat_icon">
                  <span class="material-icons speedalign"> schedule </span>
                  <span
                    class="dist_fuel_speed"
                    style="padding-left: 5px"
                    *ngIf="selectedDevice?.attributes?.hours; else showDuration"
                    >{{ selectedDevice?.attributes?.hours | dateDuration }}</span
                  >
                  <ng-template #showDuration>
                    <span class="dist_fuel_speed" style="padding-left: 5px"
                      >0</span
                    >
                  </ng-template>
                </span>
              </div>
              <div class="col-6 p-0">
                <span class="mat_icon">
                  <img src="/assets/icons/speed.png" alt="dot" />
                  <span class="dist_fuel_speed"
                    >{{ selectedDevice?.attributes?.distance || 0 }}Km</span
                  >
                </span>
              </div>
            </div> -->
  
              <div class="row py-1 m-0">
                <div class="mat_icon" style="padding-left: 4px">
                  <img src="/assets/icons/address.png" alt="address" class="address_img" />
                  <span class="address">{{ selectedDevice.address ? selectedDevice.address : "NA" }}
                  </span>
                </div>
              </div>
              <div class="position-relative" style="margin: 3vh 0">
                <hr style="height: 0.3vh; color: black" />
                <div class="d-flex flex-row timer_boxes">
                  <!-- {{selectedDevice?.today_odo | number : '1.0-0'}} -->
                  <div class="num_box">{{ my_string[0] }}</div>
                  <div class="num_box">{{ my_string[1] }}</div>
                  <div class="num_box">{{ my_string[2] }}</div>
                  <div class="num_box">{{ my_string[3] }}</div>
                  <div class="num_box">{{ my_string[4] }}</div>
                  <div class="num_box">{{ my_string[5] }}</div>
                  <div class="num_box">{{ my_string[6] }}</div>
                </div>
              </div>
              <div class="vehicle_features my-1 px-2">
                <div class="bottomIcons">
                  <img src="/assets/icons/bottom1.png" alt="img"
                    *ngIf="selectedDevice?.attributes?.ignition; else showIgnRed" />
                  <span class="bottom_tags">{{ "IGN" | translate }}</span>
                </div>
                <div class="bottomIcons">
                  <img src="/assets/icons/bottom2.png" alt="img" />
                  <span class="bottom_tags">{{ "AC" | translate }}</span>
                </div>
                <div class="bottomIcons">
                  <img src="/assets/icons/bottom3.png" alt="img" />
                  <span class="bottom_tags">{{ "Fuel" | translate }}</span>
                </div>
                <div class="bottomIcons">
                  <img src="/assets/icons/bottom4.png" alt="img"
                    *ngIf="selectedDevice?.attributes?.charge; else showPowerRed" />
                  <span class="bottom_tags">{{
                    selectedDevice?.attributes?.power | number : "1.0-2"
                    }}
                    v</span>
                </div>
                <div class="bottomIcons">
                  <img src="/assets/icons/bottom5.png" alt="img" />
                  <span class="bottom_tags">{{ "Temp" | translate }}</span>
                </div>
                <div class="bottomIcons">
                  <img src="/assets/icons/bottom6.png" alt="img" />
                  <span class="bottom_tags">{{ "GPS" | translate }}</span>
                </div>
                <div class="bottomIcons">
                  <img src="/assets/icons/bottom7.png" alt="img" />
                  <span class="bottom_tags">{{ "Door" | translate }}</span>
                </div>
              </div>
  
              <div class="day_calendar pt-3 pb-2 m-0 px-2">
                <div class="date">{{ selectedDate | date : "mediumDate" }}</div>
                <input type="date" id="meeting" value="2022-03-26" [(ngModel)]="selectedDate" name="selectedDate"
                  (change)="onDateSelection()" />
              </div>
  
              <div class="row m-0 borderrow">
                <div class="col-4" style="border-right: 2px solid #324151; padding: 0.75rem 0">
                  <div class="mat_icon justify-content-center">
                    <span class="material-icons movingimg"> schedule </span>
                    <span class="flexcolumn" style="padding-left: 1vh">
                      <span class="daytext">{{ "Moving Time" | translate }}</span>
                      <span class="dayconts">{{
                        vehicleDetailsReport.summary.engineHours | convert : "hh:mm"
                        }}</span>
                    </span>
                  </div>
                </div>
                <div class="col-4" style="border-right: 2px solid #324151; padding: 0.75rem 0">
                  <div class="mat_icon justify-content-center">
                    <span class="material-icons movingimg" style="background-color: #e8ad21">
                      speed
                    </span>
                    <span class="flexcolumn" style="padding-left: 1vh">
                      <span class="daytext">{{
                        "Total Kilometer" | translate
                        }}</span>
                      <span class="dayconts">{{ vehicleDetailsReport.summary.distance | meterTokm }}
                        {{ "Kms" | translate }}</span>
                    </span>
                  </div>
                </div>
                <div class="col-4" style="padding: 0.75rem 0">
                  <div class="mat_icon justify-content-center">
                    <img src="/assets/icons/time_icon.png" alt="moving" class="movingimg"
                      style="background-color: #f9536f" />
                    <span class="flexcolumn" style="padding-left: 1vh">
                      <span class="daytext">{{ "Parked" | translate }}</span>
                      <span class="dayconts">{{
                        vehicleDetailsReport.summary.parkedTime | convert : "hh:mm"
                        }}</span>
                    </span>
                  </div>
                </div>
              </div>
  
              <div class="btngroup1">
                <!-- <div class="btn detailbtn">All</div> -->
                <div [class.active]="activeSection == 1" (click)="activeSection = 1" class="btn detailbtn">
                  {{ "Moving" | translate }}
                </div>
                <div [class.active]="activeSection == 2" (click)="activeSection = 2" class="btn detailbtn">
                  {{ "Parked" | translate }}
                </div>
                <div [class.active]="activeSection == 3" (click)="activeSection = 3" class="btn detailbtn">
                  {{ "Alert" | translate }}
                </div>
              </div>
  
              <div class="row timelinerow scroller2">
                <div id="content">
                  <ul *ngIf="activeSection == 1" class="timeline">
                    <li *ngFor="let item of vehicleDetailsReport.moving" class="event"
                      [attr.data-date]="item.startTime | date : 'shortTime'">
                      <h3>{{ item.endTime | date : "shortTime" }}</h3>
                      <div class="mat_icon" style="padding-left: 4px; margin-bottom: 3vh">
                        <img src="/assets/icons/address.png" alt="address" class="address_img" />
                        <span class="address" style="font-size: 1.6vh">{{ "Distance" | translate }} -
                          {{ item.distance | meterTokm }} KM</span>
                      </div>
                    </li>
               
                  </ul>
                  <ul *ngIf="activeSection == 2" class="timeline">
                    <li *ngFor="let item of vehicleDetailsReport.parked" class="event"
                      [attr.data-date]="item.startTime | date : 'shortTime'">
                      <h3>{{ item.endTime | date : "shortTime" }}</h3>
                      <div class="mat_icon" style="padding-left: 4px; margin-bottom: 3vh">
                        <img src="/assets/icons/address.png" alt="address" class="address_img" />
                        <span class="address" style="font-size: 1.6vh">{{ "Duration" | translate }} -
                          {{ item.duration | convert : "hh:mm" }}
                        </span>
                      </div>
                    </li>
                
                  </ul>
                  <ul *ngIf="activeSection == 3" class="timeline">
                    <li *ngFor="let item of vehicleDetailsReport.alert" class="event"
                      [attr.data-date]="item.eventTime | date : 'shortTime'">
                      <!-- <h3>{{item.eventTime | date:'shortTime'}}</h3> -->
                      <div class="mat_icon" style="padding-left: 4px; margin-bottom: 3vh">
                        <img src="/assets/icons/address.png" alt="address" class="address_img" />
                        <span class="address" style="font-size: 1.6vh">{{ "Type" | translate }} - {{ item.type }}
                        </span>
                      </div>
                    </li>
                    <!-- <li class="event" data-date="2:30 AM">
                    <h3>18h 17m</h3>
                    <div
                      class="mat_icon"
                      style="padding-left: 4px; margin-bottom: 3vh"
                    >
                      <img
                        src="/assets/icons/address.png"
                        alt="address"
                        class="address_img"
                      />
                      <span class="address" style="font-size: 1.6vh"
                        >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                        394210 (India)</span
                      >
                    </div>
                  </li>
                  <li class="event" data-date="5:00 AM">
                    <h3>18h 17m</h3>
                    <div
                      class="mat_icon"
                      style="padding-left: 4px; margin-bottom: 3vh"
                    >
                      <img
                        src="/assets/icons/address.png"
                        alt="address"
                        class="address_img"
                      />
                      <span class="address" style="font-size: 1.6vh"
                        >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                        394210 (India)</span
                      >
                    </div>
                  </li>
                  <li class="event" data-date="8:30 AM">
                    <h3>18h 17m</h3>
                    <div
                      class="mat_icon"
                      style="padding-left: 4px; margin-bottom: 3vh"
                    >
                      <img
                        src="/assets/icons/address.png"
                        alt="address"
                        class="address_img"
                      />
                      <span class="address" style="font-size: 1.6vh"
                        >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                        394210 (India)</span
                      >
                    </div>
                  </li> -->
                  </ul>
                </div>
              </div>
            </div>
  
  
  
            <div class="row timelinerow1" *ngIf="vehicle_status">
              <div class="destination">
                <ul class="timeline2" style="padding: 4px 7px">
                  <li>
                    <span class="dot" style="padding: 0 9px"></span>
                    <span class="address2">Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)
                    </span>
                  </li>
                  <li style="margin-top: 1vh">
                    <span class="dot" style="padding: 0 9px"></span>
                    <span class="address2">Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)
                    </span>
                  </li>
                </ul>
              </div>
              <div class="row m-0 py-3">
                <div class="col-6">
                  <div class="mat_icon" style="padding: 0 3vh">
                    <img src="/assets/icons/vector_timedistance.png" alt="timedistance" class="vectorimg" />
                    <span class="flexcolumn" style="padding-left: 1vh">
                      <span class="totaltimedistance">{{
                        "Total Travel Time" | translate
                        }}</span>
                      <span class="timedistancecounts">11h : 35m</span>
                    </span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mat_icon" style="padding: 0 3vh">
                    <img src="/assets/icons/vector_timedistance.png" alt="timedistance" class="vectorimg" />
                    <span class="flexcolumn" style="padding-left: 1vh">
                      <span class="totaltimedistance">{{
                        "Total Distance" | translate
                        }}</span>
                      <span class="timedistancecounts">350.8 {{ "km" | translate }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="vehcleDataRows scroller2">
      
  
                <div class="row m-0 odometer_row">
                  <div class="card odometerbox">
                    <span>
                      <img src="/assets/icons/odometer.png" alt="odometer" />
                    </span>
                    <span class="vehicle_info_data">85,660.18</span>
                    <span class="vehicle_info_text">{{
                      "Odometer" | translate
                      }}</span>
                  </div>
                  <div class="card odometerbox">
                    <span>
                      <img src="/assets/icons/speedify.png" alt="odometer" />
                    </span>
                    <span class="vehicle_info_data">54 {{ "kmph" | translate }}</span>
                    <span class="vehicle_info_text">{{ "Speed" | translate }}</span>
                  </div>
                  <div class="card odometerbox">
                    <span>
                      <img src="/assets/icons/Avg.speed.png" alt="odometer" />
                    </span>
                    <span class="vehicle_info_data">35 {{ "kmph" | translate }}</span>
                    <span class="vehicle_info_text">
                      {{ "Avg. Speed" | translate }}</span>
                  </div>
                  <div class="card odometerbox">
                    <span>
                      <img src="/assets/icons/Max.speed.png" alt="odometer" />
                    </span>
                    <span class="vehicle_info_data">91 {{ "kmph" | translate }}</span>
                    <span class="vehicle_info_text">{{
                      "Max. Speed" | translate
                      }}</span>
                  </div>
                </div>
                <div class="row m-0 odometer_row">
                  <div class="card odometerbox">
                    <span>
                      <img src="/assets/icons/Overspeed.png" alt="odometer" />
                    </span>
                    <span class="vehicle_info_data">10 {{ "km" | translate }}
                    </span>
                    <span class="vehicle_info_text">{{
                      "Over speed" | translate
                      }}</span>
                  </div>
                  <div class="card odometerbox">
                    <span>
                      <img src="/assets/icons/thermometer_pink.png" alt="odometer" />
                    </span>
                    <span class="vehicle_info_data">29°C</span>
                    <span class="vehicle_info_text">{{ "Temp" | translate }}</span>
                  </div>
                  <div class="card odometerbox">
                    <span>
                      <img src="/assets/icons/FuelPump.png" alt="odometer" />
                    </span>
                    <span class="vehicle_info_data">50L</span>
                    <span class="vehicle_info_text">{{ "Fuel" | translate }}</span>
                  </div>
                  <div class="card odometerbox">
                    <span>
                      <img src="/assets/icons/NoOfStops.png" alt="odometer" />
                    </span>
                    <span class="vehicle_info_data">05</span>
                    <span class="vehicle_info_text">{{
                      "No of stops" | translate
                      }}</span>
                  </div>
                </div>
                <div class="row m-0 odometer_row">
                  <div class="card odometerbox">
                    <span>
                      <img src="/assets/icons/Mileage.png" alt="odometer" />
                    </span>
                    <span class="vehicle_info_data">10 {{ "km" | translate }}
                    </span>
                    <span class="vehicle_info_text">Mileage</span>
                  </div>
                  <div class="card odometerbox">
                    <span>
                      <img src="/assets/icons/movingtime_pink.png" alt="odometer" />
                    </span>
                    <span class="vehicle_info_data">59m 34s</span>
                    <span class="vehicle_info_text">{{
                      "Moving Time" | translate
                      }}</span>
                  </div>
                  <div class="card odometerbox">
                    <span>
                      <img src="/assets/icons/Parked.png" alt="odometer" />
                    </span>
                    <span class="vehicle_info_data">8hr 24m 22s</span>
                    <span class="vehicle_info_text">{{
                      "Parked" | translate
                      }}</span>
                  </div>
                  <div class="card odometerbox">
                    <span>
                      <img src="/assets/icons/Idling.png" alt="odometer" />
                    </span>
                    <span class="vehicle_info_data">36m 24s</span>
                    <span class="vehicle_info_text">36m 24s</span>
                  </div>
                </div>
                <div class="row m-0 odometer_row">
                  <div class="card odometerbox">
                    <span>
                      <img src="/assets/icons/Voltage.png" alt="odometer" />
                    </span>
                    <span class="vehicle_info_data">90%</span>
                    <span class="vehicle_info_text">{{
                      "Voltage" | translate
                      }}</span>
                  </div>
                  <div class="card odometerbox">
                    <span>
                      <img src="/assets/icons/Battery.png" alt="odometer" />
                    </span>
                    <span class="vehicle_info_data">100%</span>
                    <span class="vehicle_info_text">{{
                      "Battery" | translate
                      }}</span>
                  </div>
                  <div class="card odometerbox">
                    <span>
                      <img src="/assets/icons/PrimaryEng.png" alt="odometer" />
                    </span>
                    <span class="vehicle_info_data">1hr 24m 22s</span>
                    <span class="vehicle_info_text">{{ "primary Eng" | translate }}.</span>
                  </div>
                  <div class="card odometerbox">
                    <span>
                      <img src="/assets/icons/sec.eng.png" alt="odometer" />
                    </span>
                    <span class="vehicle_info_data">0s</span>
                    <span class="vehicle_info_text">{{ "Sec. Eng" | translate }}.</span>
                  </div>
                </div>
  
                <!-- VEHICLE TIME STATUS -->
  
                <div class="activity_head">{{ "Today Activity" | translate }}</div>
                <div class="mat_icon alignmat">
                  <span class="mat_icon">
                    <img src="/assets/icons/vector_timedistance.png" alt="timedistance" class="vectorimg1" />
                    <span class="activity_status">{{ "Running" | translate }}</span>
                  </span>
                  <span class="activity_time">00.13 {{ "Hrs" | translate }}</span>
                </div>
                <div class="mat_icon alignmat">
                  <span class="mat_icon">
                    <img src="/assets/icons/vector_timedistance.png" alt="timedistance" class="vectorimg2" />
                    <span class="activity_status">{{ "Idle" | translate }}</span>
                  </span>
                  <span class="activity_time">00.40 {{ "Hrs" | translate }}</span>
                </div>
                <div class="mat_icon alignmat">
                  <span class="mat_icon">
                    <img src="/assets/icons/vector_timedistance.png" alt="timedistance" class="vectorimg3" />
                    <span class="activity_status">{{ "Stop" | translate }}</span>
                  </span>
                  <span class="activity_time">00.00 {{ "Hrs" | translate }}</span>
                </div>
                <div class="mat_icon alignmat pb-3">
                  <span class="mat_icon">
                    <img src="/assets/icons/vector_timedistance.png" alt="timedistance" class="vectorimg4" />
                    <span class="activity_status">{{
                      "Inactive" | translate
                      }}</span>
                  </span>
                  <span class="activity_time">17.35 {{ "Hrs" | translate }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="col-12 col_map" id="myMap">
          <div class="card main_card p-0">
            <!-- <agm-map [latitude]="18.5632" [longitude]="73.9176" [zoom]="zoom">
              <agm-marker [latitude]="18.5632" [longitude]="73.9176"></agm-marker>
            </agm-map> -->
            <ng-container *ngIf="allvehicles">
               <agm-map [mapTypeId]="mapTypeId" class="multiple-vehicle"
                       [latitude]="lat2" [longitude]="lng2" 
                       [zoom]="zoom"
                       [disableDefaultUI]="false" (mapClick)="mapClicked($event)" (mapReady)="onMapReady($event)"
                       (zoomChange)="changeMapZoom($event)" [zoomControl]="false">
                       <agm-marker-cluster imagePath="/assets/icons/clusterImg">
                        <ng-container *ngFor="let device of markersList; let i = index">
                            <agm-marker
                            [latitude]="device.latitude" [longitude]="device.longitude"
                            (markerClick)="clickedMarker(device.deviceId)"
                            [label]="{
                                fontSize: '10px',
                                color: '#fbfbfc',
                                text: device.name
                            }"
                            [iconUrl]="device.deviceDetails.vehicleMapImg">
                            <agm-info-window [isOpen]="previousInfoWindow === device.deviceId" #infowindow>
                             <div class="container p-3">
                               <div class="row mb-2">
                                 <div class="col-4 fw-bold">
                                   {{ "Object" | translate }}:
                                 </div>
                                 <div class="col-8">
                                   {{ device.name }}
                                 </div>
                               </div>
                               <div class="row mb-2">
                                 <div class="col-4 fw-bold">
                                   {{ "Position" | translate }}:
                                 </div>
                                 <div class="col-8">
                                   <a href="https://maps.google.com/maps?q={{ device?.latitude }},{{ device.longitude }}&amp;t=m" target="_blank">
                                     {{ device?.latitude }} °, {{ device.longitude }} °
                                   </a>
                                 </div>
                               </div>
                               <div class="row mb-2">
                                 <div class="col-4 fw-bold">
                                   {{ "Speed" | translate }}:
                                 </div>
                                 <div class="col-8">
                                   {{ device?.speed | number: "1.0-3" }} {{ "kmph" | translate }}
                                 </div>
                               </div>
                               <div class="row mb-2">
                                 <div class="col-4 fw-bold">
                                   {{ "Time" | translate }}:
                                 </div>
                                 <!-- {{device?.lastUpdate  | json}} -->
                                 <div class="col-8">
                                   {{ device?.lastUpdate | date:"yyyy-MM-dd hh:mm:ss a"}}
                                 </div>
                               </div>
                               <div class="row">
                                 <div class="col-4 fw-bold">
                                   {{ "Odometer" | translate }}:
                                 </div>
                                 <div class="col-8">
                                   {{ device?.attributes?.totalDistance | meterTokm }} {{ "km" | translate }}
                                 </div>
                               </div>
                             </div>
                           </agm-info-window>
                           </agm-marker>
                            </ng-container>
                    </agm-marker-cluster>
              </agm-map>
            </ng-container>  
        
          </div>
          <!-- TOP PLAYBACK BUTTTON -->
  
          <!-- BOTTOM RIGHT ICONS -->
  
          <div class="btn-group-vertical btn-vertical-bar-map" [class.right-3-vh]="!rightPanelExpand"
            [class.right-29-vh]="rightPanelExpand" [class.right-73-vh]="isDriverPanelVisible"
            [class.right-3-vh]="!isDriverPanelVisible" role="group" aria-label="Basic example">
            <button *ngIf="back_button" matTooltip="Back" type="button" class="btn mapbtns"
              style="margin-top: 1vh; border-radius: 5px; padding-bottom: 0" (click)="closeHistorytrack()">
              <span class="material-icons" style="color: #fff"> arrow_back </span>
            </button>
      
  
          </div>
          <!-- Right vertical bar panel -->
          <div class="btn-vertical-bar-right-panel scroller scroller-right-panel"
            (scroll)="onRigthSidePanelScroll($event)" [class.d-none]="!rightPanelExpand" *ngIf="false">
            <div class="card shadow-set">
              <div class="card-body p-1 w-170">
                <div class="font-size-12 fw-bold">
                  {{ selectedDevice?.name }}
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <img src="/assets/images/{{
                    getDeviceImage(selectedDevice?.category)
                  }}" style="height: 100px" alt="" />
                </div>
                <div class="bg-light d-flex align-items-center justify-content-between fw-bold font-size-10 p-1">
                  <span class="p-1 text-white" [class.running-bg-color]="selectedDevice?.status == 'RUNNING'"
                    [class.stopped-bg-color]="selectedDevice?.status == 'STOPPED'"
                    [class.idle-bg-color]="selectedDevice?.status == 'IDLE'" [class.not-reporting-bg-color]="
                    selectedDevice?.status == 'NOT_REPORTING' ||
                    selectedDevice?.status == 'EXPIRED' ||
                    selectedDevice?.status == 'NO_DATA' ||
                    selectedDevice?.status == 'TOWWED'
                  ">{{
                    selectedDevice?.status == "NOT_REPORTING"
                    ? "Not Reporting"
                    : ((selectedDevice?.status == "NO_DATA"
                    ? "No
                    Data"
                    : selectedDevice?.status
                    ) | titlecase)
                    }}</span>
                  <span>{{
                    selectedDevice.statusLastUpdated | convert : "hh:mm:ss"
                    }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-center p-2">
                  <span class="bg-light p-1 border-end font-size-12" *ngFor="let o of selectedDevice?.odometerArray">{{ o
                    }}</span>
        
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="font-size-12">Driver</span>
                  <span class="font-size-12" *ngIf="driverDetails.length > 0 && driverDetails[0].name">{{
                    driverDetails[0].name }}</span>
                  <span class="font-size-12" *ngIf="driverDetails.length == 0">NA</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="font-size-12">Mobile</span>
                  <span class="font-size-12" *ngIf="
                    driverDetails.length > 0 && driverDetails[0].attributes?.phone
                  ">{{ driverDetails[0].attributes?.phone }}</span>
                  <span class="font-size-12" *ngIf="driverDetails.length == 0">NA</span>
                </div>
                <div class="d-flex align-items-center justify-content-around mt-2 bg-light">
                  <span><i class="bi bi-snow" [class.text-secondary]="
                      selectedDevice?.attributes?.ac == undefined
                    " [class.text-danger]="selectedDevice?.attributes?.ac == false"
                      [class.text-success]="selectedDevice?.attributes?.ac == true"></i>
                  </span>
                  <span><i class="bi bi-key-fill" [class.text-secondary]="
                      selectedDevice?.attributes?.ignition == undefined
                    " [class.text-danger]="
                      selectedDevice?.attributes?.ignition == false
                    " [class.text-success]="
                      selectedDevice?.attributes?.ignition == true
                    "></i></span>
                  <span><i class="bi" [class.bi-battery]="
                      selectedDevice?.attributes?.batteryLevel == undefined ||
                      selectedDevice?.attributes?.batteryLevel < 30
                    " [class.text-danger]="
                      selectedDevice?.attributes?.batteryLevel == undefined ||
                      selectedDevice?.attributes?.batteryLevel < 30
                    " [class.bi-battery-half]="
                      selectedDevice?.attributes?.batteryLevel &&
                      selectedDevice?.attributes?.batteryLevel > 30 &&
                      selectedDevice?.attributes?.batteryLevel < 80
                    " [class.text-danger]="
                      selectedDevice?.attributes?.batteryLevel &&
                      selectedDevice?.attributes?.batteryLevel > 30 &&
                      selectedDevice?.attributes?.batteryLevel < 80
                    " [class.bi-battery-full]="
                      selectedDevice?.attributes?.batteryLevel &&
                      selectedDevice?.attributes?.batteryLevel > 80
                    " [class.text-success]="
                      selectedDevice?.attributes?.batteryLevel &&
                      selectedDevice?.attributes?.batteryLevel > 80
                    "></i></span>
                  <span><i class="bi bi-power" [class.text-secondary]="
                      selectedDevice?.attributes?.charge == undefined
                    " [class.text-danger]="
                      selectedDevice?.attributes?.charge == false
                    " [class.text-success]="
                      selectedDevice?.attributes?.charge == true
                    "></i></span>
                  <span><i class="bi bi-lock-fill" style="color: gray" [class.text-secondary]="
                      selectedDevice?.attributes?.blocked == undefined
                    " [class.text-danger]="
                      selectedDevice?.attributes?.blocked == false
                    " [class.text-success]="
                      selectedDevice?.attributes?.blocked == true
                    "></i></span>
                </div>
              </div>
            </div>
            <div class="card shadow-set mt-3">
              <div class="card-body p-1 w-170">
                <div class="font-size-12 fw-bold">Location</div>
                <div class="d-flex align-items-center font-size-12 mt-2 mb-2">
                  {{ selectedDevice?.address }}
                </div>
                <div class="d-flex align-items-center font-size-10 p-1">
                  <a href="https://maps.google.com/maps?q={{
                    selectedDevice?.latitude
                  }},{{ selectedDevice.longitude }}&amp;t=m" target="_blank">{{ selectedDevice?.latitude | number :
                    "1.0-6" }} °,
                    {{ selectedDevice.longitude | number : "1.0-6" }} °</a>
                </div>
              </div>
            </div>
            <div class="card shadow-set mt-3">
              <div class="card-body p-1 w-170">
                <div class="font-size-12 fw-bold">Today Activity</div>
                <div class="d-flex align-items-center font-size-12 mt-2 mb-2">
                  <div class="d-flex align-items-center justify-content-around w-100">
                    <span><img src="../../../assets/icons/trip_start_flag.svg" alt="" /></span>
                    <span class="text-center" style="border-bottom: 2px dashed black; width: 100%">{{
                      selectedDevice?.attributes?.distanceForday | meterTokm }}
                      {{ "km" | translate }}</span>
                    <span><img src="../../../assets/icons/trip_end_flag.svg" alt="" /></span>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                  <span>Running</span>
                  <span class="running-text-color">{{
                    selectedDevice?.attributes?.hoursForday | convert : "hh:mm"
                    }}
                    hrs</span>
                </div>
                <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                  <span>Idle</span>
                  <span class="idle-text-color">{{
                    selectedDevice?.attributes?.idlehoursForday
                    | convert : "hh:mm"
                    }}
                    hrs</span>
                </div>
                <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                  <span>Stop</span>
                  <span class="stopped-text-color">{{
                    selectedDevice?.attributes?.stophoursForday
                    | convert : "hh:mm"
                    }}
                    hrs</span>
                </div>
                <div class="d-flex align-items-center justify-content-between font-size-10 mt-1">
                  <span>Inactive</span>
                  <span class="not-reporting-text-color">00:00 hrs</span>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-center mt-3">
              <div class="avg-speed d-flex align-items-center justify-content-center">
                <div class="font-size-10 text-center">
                  {{ "Avg Speed" | translate }}
                </div>
                <div class="font-size-10 text-center">
                  {{
                  onSpeedgenerateBasedOnuserPreference(
                  vehicleDetailsReport?.summary?.averageSpeed
                  ) | number : "1.0-2"
                  }}
                  {{ userData.attributes.speedUnit | translate }}
                </div>
              </div>
              <div class="max-speed d-flex align-items-center justify-content-center ms-1">
                <div class="font-size-10 text-center">
                  {{ "Max Speed" | translate }}
                </div>
                <div class="font-size-10 text-center">
                  {{
                  onSpeedgenerateBasedOnuserPreference(
                  vehicleDetailsReport?.summary?.maxSpeed
                  ) | number : "1.0-2"
                  }}
                  {{ userData.attributes.speedUnit | translate }}
                </div>
              </div>
            </div>

          </div>
  
      
          <!-- BOTTOM ICONS -->
  
          <div class="btn-group" role="group" aria-label="Basic example"
            style="position: absolute; bottom: 7vh; left: 4vh" [style.bottom.vh]="isHistoryTab ? 7 : 3">
            <button type="button" class="btn mapbtns" style="margin-left: 2vh; border-radius: 5px; padding: 0.5vh 0.8vh">
              <img src="/assets/icons/map8.png" alt="mapicon" />
            </button>
            <button type="button" class="btn mapbtns" style="margin-left: 2vh; border-radius: 5px; padding: 0.5vh 0.8vh">
              <img src="/assets/icons/map9.png" alt="mapicon" />
            </button>
            <button *ngIf="fullwidth" type="button" class="btn mapbtns"
              style="margin-left: 2vh; border-radius: 5px; padding: 0.5vh 0.8vh" (click)="getFullWidth()">
              <img src="/assets/icons/map10.png" alt="mapicon" />
            </button>
            <button matTooltip="Satelite View" (click)="changeMapTypeId()" type="button" class="btn mapbtns"
              [class.active]="mapTypeId == 'satellite'"
              style="margin-left: 2vh; border-radius: 5px; padding: 0.5vh 0.8vh">
              <img src="/assets/icons/map1.png" alt="mapicon" />
            </button>
          </div>
        </div>
      </div>
      <!-- <button *ngIf="!main_col" class="btn expand-tasks-panel mapbtns mt-7" matTooltip="Expand" type="button" style="
        margin-top: 4vh;
        border-radius: 5px;
        padding-bottom: 0;
        margin-left: 18px;
      " (click)="expandTaskPanel()">
        <span class="d-flex align-items-center justify-content-center fw-bold font-size-18 mb-1" style="color: #fff">
          >>
        </span>
      </button> -->
      <!-- <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="background-color: #324151; border-radius: 9px">
            <div class="modal-header" style="border: none !important">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ "Custom Tracking" | translate }}
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group pb-2">
                  <label for="device_id" class="addlabels pb-2">{{ "Vehicle Name" | translate }} :</label>
                  <input type="text" [(ngModel)]="device_id" name="device_id" class="form-control inputfields"
                    id="device_id" aria-describedby="device_id" disabled />
                </div>
  
                <div class="d-flex flex-row align-items-center py-3">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="location" class="addlabels pb-2">{{ "From" | translate }} :</label>
                      <input type="datetime-local" [(ngModel)]="fromdate" name="fromdate" id="meeting" />
                    </div>
                  </div>
  
                  <div class="col-6">
                    <div class="form-group">
                      <label for="location" class="addlabels pb-2">{{ "To" | translate }} :</label>
                      <input type="datetime-local" [(ngModel)]="todate" name="todate" id="meeting" />
                    </div>
                  </div>
                </div>
  
                <button type="submit" class="showTrack" data-bs-dismiss="modal" aria-label="Close">
                  {{ "Show Track" | translate }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  
    <div class="container-fluid" *ngIf="bottomNav">
      <div class="row" style="margin-right: 0">
        <div class="navbar px-4" id="myNavbar" [class.taskHistory]="isTaskTab">
          <a class="bottom_navs" [class.history-tab-selected]="fuelsummary" (click)="onClickFuel()">{{ "Summary" |
            translate }}</a>
          <a class="bottom_navs" [class.history-tab-selected]="history" (click)="onClickHistory()">{{ "Logs" | translate
            }}
          </a>
          <a><img src="/assets/icons/doubledown.png" alt="doubledown" /></a>
          <a class="playbox" [class.taskHistory]="isTaskTab">
            <div class="card playcard pt-2" style="width: 71vh">
              <div class="row m-0">
                <div class="col-2">
                  <img src="{{
                    playpause
                      ? '/assets/icons/pause.png'
                      : '/assets/icons/play.png'
                  }}" alt="play" class="iconPlay" (click)="onPlayPause()" style="cursor: pointer" />
                </div>
                <div class="col-5 d-flex align-items-center">
                  <mat-slider [min]="0" [max]="this.arrlength" [value]="values"
                    (change)="onSlideEvent($event)"></mat-slider>
                </div>
                <div class="col-5" style="justify-content: space-between; display: flex">
                  <span class="playIcons" style="padding: 0.8vh 1.5vh" (click)="on1xSpeed()">{{ speedCount }}x</span>
                  <img src="/assets/icons/play_rewind.png" alt="1x" class="playIcons" id="speed" (click)="onReset()" />
                  <img src="/assets/icons/distance_play.png" alt="1x" class="playIcons" style="padding: 0.9vh 1.1vh" />
                  <img src="/assets/icons/folder_play.png" alt="1x" class="playIcons" style="padding: 0.9vh 1.1vh" />
                  <img src="/assets/icons/out_play.png" alt="1x" class="playIcons" style="padding: 0.9vh 1.1vh" />
                </div>
              </div>
  
              <div class="row m-0 py-3 playaddrow">
                <div class="col-8">
                  <span class="mat_icon">
                    <img src="/assets/icons/address.png" alt="adressicon" style="height: 2.5vh" />
                    <span class="playaddress">{{
                      historyPath && historyPath.displayAddress
                      ? historyPath.displayAddress
                      : "NA"
                      }}</span>
                  </span>
                </div>
                <div class="col-4">
                  <span class="mat_icon">
                    <img src="/assets/icons/watch_play.png" alt="watch" />
                    <span class="playaddress">
                      {{
                      allLocationTrackData[values] &&
                      allLocationTrackData[values].deviceTime
                      ? (allLocationTrackData[values].deviceTime | dateFormat)
                      : ""
                      }}</span>
                  </span>
                </div>
              </div>
  
              <div class="d-flex align-items-center justify-content-between text-center ps-2 pe-2 m-0">
                <div class="colplay">
                  <span class="iconstext">
                    <img src="/assets/icons/battery_play.png" alt="battery" />
                    0v
                  </span>
                </div>
                <div class="colplay">
                  <span style="min-width: 100px" class="iconstext">
                    <img src="/assets/icons/speedometer.png" alt="battery" />
                    {{
                    allLocationTrackData[values] &&
                    allLocationTrackData[values].speed
                    ? (onSpeedgenerateBasedOnuserPreference(
                    allLocationTrackData[values].speed
                    ) | number : "1.0-2")
                    : "0"
                    }}
                    {{ userData.attributes.speedUnit | translate }}
                  </span>
                </div>
                <div class="colplay">
                  <span style="min-width: 100px" class="iconstext">
                    <img src="/assets/icons/watch_play.png" alt="battery" />
                    {{
                    vehicleDetailsReport.summary.engineHours | convert : "hh:mm"
                    }}
                  </span>
                </div>
                <div class="colplay">
                  <span class="iconstext">
                    <img src="/assets/icons/distance_meter.png" alt="battery" />
                    {{
                    onSpeedgenerateBasedOnuserPreference(
                    vehicleDetailsReport?.summary?.averageSpeed
                    ) | number : "1.0-2"
                    }}
                    {{ userData.attributes.speedUnit | translate }}
                  </span>
                </div>
                <div class="colplay">
                  <span class="iconstext">
                    <img src="/assets/icons/route_play.png" alt="battery" />
                    {{
                    vehicleDetailsReport.summary.distance
                    | meterTokm : userData.attributes.distanceUnit
                    }}
                    {{ userData.attributes.distanceUnit | translate }}
                  </span>
                </div>
              </div>
            </div>
          </a>
          <a> <img src="/assets/icons/doubledown.png" alt="doubledown" /> </a>
          <a class="bottom_navs" [class.history-tab-selected]="parking" (click)="onClickParking()">{{ "Parking" |
            translate }}
          </a>
          <a class="bottom_navs" [class.history-tab-selected]="event" (click)="onClickEvent()">{{ "Events" | translate }}
          </a>
        </div>
      </div>
  
      <div class="row" style="margin-right: 0">
        <div *ngIf="history">
          <table class="table table-light table-borderless historytable">
            <thead class="historyheadBorder">
              <tr>
                <th scope="col" class="bottomNavHeads">
                  {{ "Fix Time" | translate }}
                </th>
                <th scope="col" class="bottomNavHeads">
                  {{ "Latitude " | translate }}
                </th>
                <th scope="col" class="bottomNavHeads">
                  {{ "Longitude " | translate }}
                </th>
                <th scope="col" class="bottomNavHeads">
                  {{ "Speed" | translate }}
                </th>
                <th scope="col" class="bottomNavHeads">
                  {{ "Address" | translate }}
                </th>
              </tr>  
            </thead>
            <tbody *ngIf="allLocationTrackData.length">
    <tr *ngFor="let repo of allLocationTrackData | paginate: { itemsPerPage: itemsPerPage, currentPage: page, totalItems: allLocationTrackData.length }; let i = index">
      <td class="bottomNavBody" style="padding-left: 3vh">
        <img src="/assets/icons/table_vehicle.png" alt="car" style="margin-right: 1vh" />{{ repo.serverTime | date : "dd/MM/yyyy hh:mm a" }}
      </td>
      <td class="bottomNavBody">
        {{ repo.latitude | number : "1.0-6" }}
      </td>
      <td class="bottomNavBody">
        {{ repo.longitude | number : "1.0-6" }}
      </td>
      <td class="bottomNavBody">
        {{ onSpeedgenerateBasedOnuserPreference(repo.speed) | number : "1.0-2" }}
        {{ userData.attributes.speedUnit | translate }}
      </td>
      <td class="bottomNavBody" (click)="getDeviceAddressOnRoutes(repo.latitude, repo.longitude, i)">
    <span style="font-size:15px" *ngIf="selectedRowIndex !== i || !repo.address">
      <i class="fa-solid fa-circle-info"></i>
    </span>
    <span *ngIf="selectedRowIndex === i && repo.address">{{ repo.address }}</span>
  </td>
    </tr>
  </tbody>
  
          </table>
          <div *ngIf="allLocationTrackData.length" class="pagination-flex_row_center_spacebetween"
            style="padding: 1.5vh 2vh; border-top: 1px solid #e3e7ec">
            <div>
              {{ "Showing" | translate }} {{ page }} {{ "to" | translate }}
              {{ allLocationTrackData.length / 10 | number : "1.0-0" }}
              {{ "of" | translate }}
              {{ allLocationTrackData.length }} {{ "entries" | translate }}
            </div>
            <pagination-controls (pageChange)="page = $event"></pagination-controls>
          </div>
        </div>
        <table class="table table-light table-borderless parkingtable" *ngIf="parking">
          <thead class="historyheadBorder">
            <tr>
              <th scope="col" class="bottomNavHeads">
                {{ "Vehicle Name" | translate }}
              </th>
              <th scope="col" class="bottomNavHeads">
                {{ "From" | translate }} Time
              </th>
              <th scope="col" class="bottomNavHeads">
                {{ "To Time" | translate }}
              </th>
              <th scope="col" class="bottomNavHeads">
                {{ "Duration" | translate }}
              </th>
              <th scope="col" class="bottomNavHeads">
                {{ "Location" | translate }}
              </th>
            </tr>
          </thead>
  
          <tbody>
            <tr *ngFor="let item of stoppagesReports; index as i">
              <td class="bottomNavBody">{{ item.deviceName }}</td>
              <td class="bottomNavBody">
                {{ item.startTime | date : "dd/MM/yyyy hh:mm a" }}
              </td>
              <td class="bottomNavBody">
                {{ item.endTime | date : "dd/MM/yyyy hh:mm a" }}
              </td>
              <td class="bottomNavBody">{{ item.duration | convert : "hh:mm" }}</td>
              <td class="bottomNavBody">{{ item.address }}</td>
            </tr>
          </tbody>
        </table>
  
        <table class="table table-light table-borderless eventtable" *ngIf="event">
          <thead class="historyheadBorder">
            <tr>
              <th scope="col" class="bottomNavHeads">{{ "Event" | translate }}</th>
              <th scope="col" class="bottomNavHeads">{{ "Time" | translate }}</th>
              <th scope="col" class="bottomNavHeads">
                {{ "Address" | translate }}
              </th>
            </tr>
          </thead>
  
          <tbody>
            <tr>
              <td class="bottomNavBody">Idle 1</td>
              <td class="bottomNavBody">21-02-2022, 12:57:58 AM</td>
              <td class="bottomNavBody">
                Mother India Intermediate College, 147 E, Kalpi Rd, Panki, Kanpur,
                Uttar Pradesh (SE)
              </td>
            </tr>
            <tr>
              <td class="bottomNavBody">Idle 1</td>
              <td class="bottomNavBody">21-02-2022, 12:57:58 AM</td>
              <td class="bottomNavBody">
                Mother India Intermediate College, 147 E, Kalpi Rd, Panki, Kanpur,
                Uttar Pradesh (SE)
              </td>
            </tr>
            <tr>
              <td class="bottomNavBody">Idle 1</td>
              <td class="bottomNavBody">21-02-2022, 12:57:58 AM</td>
              <td class="bottomNavBody">
                Mother India Intermediate College, 147 E, Kalpi Rd, Panki, Kanpur,
                Uttar Pradesh (SE)
              </td>
            </tr>
          </tbody>
        </table>
  
        <div class="container-fluid fuelsummarycontainer" *ngIf="fuelsummary">
          <div class="row fuelsummaryrow" *ngIf="false">
            <div class="col-3 border_right colfuel_summary" style="padding: 3vh">
              <span class="bottomNavHeads">{{ "Fuel Capacity" | translate }}</span>
              <span class="fuelcapcity">250 L</span>
            </div>
            <div class="col-3 border_right colfuel_summary" style="padding: 3vh">
              <span class="bottomNavHeads">{{ "Fuel Consumed" | translate }}</span>
              <span class="fuelcapcity">230.46 L</span>
            </div>
            <div class="col-3 border_right colfuel_summary" style="padding: 3vh">
              <span class="bottomNavHeads">{{ "Refuel" | translate }}</span>
              <span class="fuelcapcity">209.95 L</span>
              <span class="bottomNavHeads">Total Refueled : 1 {{ "times" | translate }}</span>
            </div>
            <div class="col-3 colfuel_summary" style="padding: 3vh">
              <span class="bottomNavHeads">{{ "Fuel Theft" | translate }}</span>
              <span class="fuelcapcity">0 L</span>
              <span class="bottomNavHeads">{{ "Total Theft" | translate }} : 0 {{ "times" | translate }}</span>
            </div>
          </div>
  
          <div class="p-4 fuelheads">{{ "Driving Metrics" | translate }}</div>
  
          <div class="row fuelsummaryrow">
            <div class="col-2 border_right colfuel_summary" style="padding: 1.5vh">
              <span class="metrics">{{ "Distance" | translate }}</span>
              <span class="metricspeed">{{
                vehicleDetailsReport.summary.distance
                | meterTokm : userData.attributes.distanceUnit
                }}
                {{ userData.attributes.distanceUnit | translate }}
              </span>
            </div>
            <div class="col-2 border_right colfuel_summary" style="padding: 1.5vh">
              <span class="metrics">{{ "Average Speed" | translate }}</span>
              <span class="metricspeed">{{
                onSpeedgenerateBasedOnuserPreference(
                vehicleDetailsReport?.summary?.averageSpeed
                ) | number : "1.0-2"
                }}
                {{ userData.attributes.speedUnit | translate }}</span>
            </div>
            <div class="col-2 border_right colfuel_summary" style="padding: 1.5vh">
              <span class="metrics">{{ "Top Speed" | translate }}</span>
              <span class="metricspeed">{{
                onSpeedgenerateBasedOnuserPreference(
                vehicleDetailsReport?.summary?.maxSpeed
                ) | number : "1.0-2"
                }}
                {{ userData.attributes.speedUnit | translate }}</span>
            </div>
            <div class="col-2 border_right colfuel_summary" style="padding: 1.5vh">
              <span class="metrics">{{ "Sudden Acceleration" | translate }}</span>
              <span class="metricspeed">0 {{ "times" | translate }}</span>
            </div>
            <div class="col-2 border_right colfuel_summary" style="padding: 1.5vh">
              <span class="metrics">{{ "Sudden Brake" | translate }}</span>
              <span class="metricspeed">0 {{ "times" | translate }}</span>
            </div>
            <div class="col-2 colfuel_summary" style="padding: 1.5vh">
              <span class="metrics">{{ "Fuel Efficiency" | translate }}</span>
              <span class="metricspeed">0 Km/L</span>
            </div>
          </div>
  
          <div class="p-4 fuelheads">
            {{ "Todays Fuel & Efficiency Graph" | translate }}
          </div>
  
          <div class="row">
            <div class="col-9">
              <div id="chart"></div>
            </div>
            <div class="col-3 colfuel_summary" style="justify-content: space-evenly; padding-bottom: 3rem">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" [(ngModel)]="isHistorySpeedChart"
                  (change)="resetHistoryChartSeries()" />
                <label class="form-check-label" for="flexCheckDefault">
                  {{ "Speed" | translate }}
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" [(ngModel)]="isHistoryFuelChart"
                  (change)="resetHistoryChartSeries()" />
                <label class="form-check-label" for="flexCheckDefault">
                  {{ "Fuel" | translate }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- <div class="modal" id="staticBackdropAdd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropAddLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content" style="border: none">
          <div class="modal-header addtriphead">
            <div class="headtext addlabels">
              {{ addDevice.id ? ("Edit" | translate) : ("Add" | translate) }}
              {{ "Device" | translate }}
            </div>
            <button type="button" class="btnclose" id="btnclose" data-bs-dismiss="modal" aria-label="Close">
              <span class="material-icons"> close </span>
            </button>
          </div>
          <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
            <div class="row">
              <div class="col-12">
                <accordion [isAnimated]="true">
                  <accordion-group heading="Required" [isOpen]="true">
                    <div class="form-group pb-2">
                      <label for="name" class="pb-2">{{
                        "Registration No" | translate
                        }}</label>
                      <input type="text" [(ngModel)]="addDevice.name" class="form-control inputfields" id="name"
                        aria-describedby="name" />
                      <span class="text-danger validation" *ngIf="errors?.Devicename">
                        {{ errors.Devicename }}
                      </span>
                    </div>
                    <div class="form-group pb-2">
                      <label for="name" class="pb-2">{{
                        "IMEI" | translate
                        }}</label>
                      <input type="text" [(ngModel)]="addDevice.identifier" class="form-control inputfields"
                        id="identifier" aria-describedby="identifier" />
                      <span class="text-danger validation" *ngIf="errors?.Deviceidentifier">
                        {{ errors.Deviceidentifier }}
                      </span>
                    </div>
                  </accordion-group>
                  <accordion-group heading="Extra">
                    <div class="form-group pb-2">
                      <label for="group" class="pb-2">{{
                        "Group" | translate
                        }}</label>
                      <select class="form-control" [(ngModel)]="addDevice.groupId">
                        <option></option>
                      </select>
                    </div>
                    <div class="form-group pb-2">
                      <label for="phone" class="pb-2">{{
                        "Sim number" | translate
                        }}</label>
                      <input type="text" [(ngModel)]="addDevice.phone" class="form-control inputfields" id="phone"
                        aria-describedby="phone" />
                    </div>
                    <div class="form-group pb-2">
                      <label for="model" class="pb-2">{{
                        "Model" | translate
                        }}</label>
                      <input type="text" [(ngModel)]="addDevice.model" class="form-control inputfields" id="model"
                        aria-describedby="model" />
                    </div>
                    <div class="form-group pb-2">
                      <label for="contact" class="pb-2">{{
                        "Driver no" | translate
                        }}</label>
                      <input type="text" [(ngModel)]="addDevice.contact" class="form-control inputfields" id="contact"
                        aria-describedby="contact" />
                    </div>
                    <div class="form-group pb-2">
                      <label for="category" class="pb-2">{{
                        "Icon" | translate
                        }}</label>
                      <select class="form-control" [(ngModel)]="addDevice.category">
                        <option [value]="deviceCategory.id" *ngFor="let deviceCategory of deviceCategoryList">
                          {{ deviceCategory.name }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group pb-2">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" [checked]="addDevice.disabled"
                          (change)="checkboxEvent($event.target)" id="disabled" />
                        <label class="form-check-label" for="disabled">{{
                          "Disabled" | translate
                          }}</label>
                      </div>
                    </div>
                    <div class="form-group pb-2 date-p">
                      <label for="expirationTime" class="pb-2">{{
                        "Expiration" | translate
                        }}</label>
                      <input type="text" value="{{ addDevice.expirationTime }}" [(ngModel)]="addDevice.expirationTime"
                        class="form-control inputfields" id="expirationTime" aria-describedby="expirationTime" />
                    </div>
                  </accordion-group>
                  <accordion-group heading="Attributes">
                    <ng-container *ngFor="let attribute of addDevice.attributes; index as i">
                      <div class="row">
                        <div class="col-11 form-group mb-2" *ngIf="attribute.attribute === 'speedLimit'">
                          <label>{{ "Speed Limit(km)" | translate }}</label>
                          <input type="number" min="1" class="form-control" [(ngModel)]="attribute.value" />
                        </div>
                        <div class="col-11 form-group mb-2" *ngIf="attribute.attribute === 'timezone'">
                          <label>{{ "Timezone" | translate }}</label>
                          <input type="text" class="form-control" [(ngModel)]="attribute.value" />
                        </div>
                        <div class="col-11 form-group mb-2" *ngIf="attribute.attribute === 'deviceInactivityStart'">
                          <label>{{ "Device Inactivity Start" | translate }}</label>
                          <input type="number" class="form-control" [(ngModel)]="attribute.value" />
                        </div>
                        <div class="col-11 form-group mb-2" *ngIf="attribute.attribute === 'deviceInactivityPeriod'">
                          <label>{{
                            "Device Inactivity Period" | translate
                            }}</label>
                          <input type="number" class="form-control" [(ngModel)]="attribute.value" />
                        </div>
                        <div class="col-1">
                          <label>&nbsp;</label>
                          <i class="fa fa-trash text-danger" (click)="deleteAttr(i)"></i>
                        </div>
                      </div>
                    </ng-container>
  
                    <div class="btn btn-outline-dark w-100" (click)="addAttri(template)">
                      <i class="fa fa-plus"></i> {{ "ADD" | translate }}
                    </div>
                  </accordion-group>
                </accordion>
              </div>
            </div>
  
            <div class="flex_row_center_spacebetween pt-4 pb-2">
              <div class="col-8"></div>
  
              <div class="col-2">
                <button mat-raised-button class="cancelbtn" data-bs-dismiss="modal" aria-label="Close">
                  {{ "Cancel" | translate }}
                </button>
              </div>
              <div class="col-2">
                <button mat-raised-button class="cancelbtn" (click)="deviceSubmit()">
                  {{ "Save" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  
 
  

    <app-common-modal-popup [modelSize]="'modal-xs'" *ngIf="isSendGPRSCommandPopupOpen">
      <div class="" modelheadertext>
        {{ "Send GPRS Command" | translate }}
      </div>
      <div class="container-fluid" modeldata>
        <div class="dropdown mt-2">
  
          <span class="me-4 ms-2">{{ "Object" | translate }}:</span>
          <span>{{ selectedDevice?.name }}</span>
        </div>
        <br />
        <div class="dropdown">
          <select class="form-select history selectimportant common_scroller"
            style="width: 95% !important; height: 5vh !important; margin-left: 2%" name="type" id="subject"
            [(ngModel)]="sendCommand.type">
            <option value="{{ data.type }}" selected="selected" *ngFor="let data of typesList">
              {{ data.type }}
            </option>
          </select>
        </div>
        <br />
        <div class="mb-3 form-check" style="margin-left: 2%">
          <input type="checkbox" [(ngModel)]="sendCommand.textChannel" class="form-check-input" id="exampleCheck1" />
          <label class="form-check-label" for="exampleCheck1">{{
            "Send SMS" | translate
            }}</label>
        </div>
        <div class="row" style="margin-bottom: 4%; margin-left: 2%">
          <div class="col-8 ps-0 d-flex align-items-center">
            <button mat-raised-button class="cancelbtn theme2" data-bs-dismiss="modal" aria-label="Close"
              (click)="sendCommandDetails()">
              {{ "Send" | translate }}
            </button>
  
            <button mat-raised-button class="cancelbtn bg-secondary text-white ms-2" data-bs-dismiss="modal"
              aria-label="Close">
              {{ "Cancel" | translate }}
            </button>
          </div>
        </div>
      </div>
    </app-common-modal-popup>
  
    <!-- <app-assign-driver *ngIf="isAssignDriverPopupOpen" [tasks]="selectedTaskDeails[0]"  [driverList]="driversList2" 
      [reaasignDriver]="" (driverAssignedEvent)="driverAssignedEvent($event)"></app-assign-driver> -->
  
     