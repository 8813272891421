<div id="map" style="height:92vh; width: 100%;">
  <div class="spinner-container" *ngIf="loading">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
</div>
</div>

<!-- <ng-container *ngIf="selectedDevice">
  <div class="info-window">
    <h3>{{ selectedDevice.name }}</h3>
    <p>Latitude: {{ selectedDevice.latitude }}°</p>
    <p>Longitude: {{ selectedDevice.longitude }}°</p>
    <p>Last Update: {{ selectedDevice.lastUpdate | date: 'yyyy-MM-dd hh:mm:ss a' }}</p>
    <p>Total Distance: {{ selectedDevice.attributes?.totalDistance }} km</p>
    <p>Speed: {{ selectedDevice.speed }} km/h</p>
  </div>
</ng-container> -->