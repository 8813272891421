import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { url } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersettingService {
  baseUrl = url;
  socketBaseUrl = 'https://socket.oneqlik.in/';
  userEmail = '';
  userId = localStorage.getItem('userId');

  constructor(private user: HttpClient) {
    // this.userEmail = JSON.parse(window.atob(window.localStorage.token.split('.')[1])).email;
  }

  userSetting() {
    let body = {
      uid: this.userId,
    };
    let _url = this.baseUrl + 'users/get_user_setting';
    return this.user.post<any>(_url, body);
  }

  userCheckAvailability() {
    let _url = this.baseUrl + 'users/checkPointAvailablity';
    let httpOptions = {
      params: {
        u_id: this.userId,
        demanded_points: 0,
      },
    };
    return this.user.get<any>(_url, httpOptions);
  }

  // DEALERS MODULE

  getDealers() {
    let _url = this.baseUrl + 'users/getDealers';
    let httpOptions = {
      params: {
        supAdmin: this.userId,
        pageNo: 1,
        size: 10,
      },
    };
    return this.user.get<any>(_url, httpOptions);
  }

  addDealer(body) {
    let _url = this.baseUrl + 'users/signUp';
    return this.user.post<any>(_url, body);
  }

  editDealer(body) {
    let _url = this.baseUrl + 'users/editUserDetails';
    return this.user.post<any>(_url, body);
  }

  deleteDealer(body) {
    let _url = this.baseUrl + 'users/deleteUser';
    return this.user.post<any>(_url, body);
  }

  // CUSTOMERS MODULE

  getCustomers() {
    let _url = this.baseUrl + 'users/getCustomer';
    let httpOptions = {
      params: {
        uid: this.userId,
        pageNo: 1,
        size: 10,
      },
    };
    return this.user.get<any>(_url, httpOptions);
  }

  addCustomer(body) {
    let _url = this.baseUrl + 'users/signUp';
    return this.user.post<any>(_url, body);
  }

  editCustomer(body) {
    let _url = this.baseUrl + 'users/editUserDetails';
    return this.user.post<any>(_url, body);
  }

  deleteCustomer(body) {
    let _url = this.baseUrl + 'users/deleteUser';
    return this.user.post<any>(_url, body);
  }

  //------------------------------------------------------------------ADMIN---------------------------------------------------------------------

  // DRIVER MASTER MODULE

  getDrivers() {
    let _url = this.baseUrl + 'driver/getDrivers';
    let httpOptions = {
      params: {
        userid: this.userId,
      },
    };
    return this.user.get<any>(_url, httpOptions);
  }

  addDriver(body) {
    let _url = this.baseUrl + 'driver/addDriverDetails';
    return this.user.post<any>(_url, body);
  }

  deleteDriver(driverId) {
    let _url = this.baseUrl + 'driver/deleteDriver';
    let httpOptions = {
      params: {
        _id: driverId,
      },
    };
    return this.user.get<any>(_url, httpOptions);
  }

  updateDriverDetails(body) {
    let _url = this.baseUrl + 'driver/updateDriverDetails';
    return this.user.post<any>(_url, body);
  }

  // DEVICE MODEL MODULE

  getDeviceModel() {
    let _url = this.baseUrl + 'deviceModel/getDeviceModel';
    return this.user.get<any>(_url);
  }

  addDeviceModel(body) {
    let _url = this.baseUrl + 'deviceModel/addDeviceModel';
    return this.user.post<any>(_url, body);
  }

  updateDeviceModel(body) {
    let _url = this.baseUrl + 'deviceModel/updateDeviceModel';
    return this.user.post<any>(_url, body);
  }

  deleteDeviceModel(deviceId) {
    let _url = this.baseUrl + 'deviceModel/deleteDeviceModel';
    let httpOptions = {
      params: {
        _id: deviceId,
      },
    };
    return this.user.get<any>(_url, httpOptions);
  }

  // MODEL MASTER MODULE

  getModelMaster() {
    let _url = this.baseUrl + 'deviceModel';
    return this.user.get<any>(_url);
  }

  addModelMaster(body) {
    let _url = this.baseUrl + 'deviceModel';
    return this.user.post<any>(_url, body);
  }

  updateModelMaster(body) {
    let _url = this.baseUrl + 'model_master/update';
    return this.user.post<any>(_url, body);
  }

  deleteModelMaster(modelId) {
    let _url = this.baseUrl + 'model_master/delete';
    let httpOptions = {
      params: {
        _id: modelId,
      },
    };
    return this.user.get<any>(_url, httpOptions);
  }

  getAnnoucment() {
    let _url = this.baseUrl + 'announcement/latest';
    return this.user.get<any>(_url);
  }

  addAnnoucment(body) {
    let _url = this.baseUrl + 'announcement';
    return this.user.post<any>(_url, body);
  }

  updateAnnoucment(body,id) {
    let _url = this.baseUrl + 'announcement/'+id;
    return this.user.put<any>(_url, body);
  }

  deleteAnnoucment(id) {
    let _url = this.baseUrl + 'announcement/'+id;
   
    return this.user.delete<any>(_url);
  }

  getSupport(isAssignTo=false) {
    let _url = isAssignTo ?  this.baseUrl + 'support/assignedto' : this.baseUrl + 'support';
    return this.user.get<any>(_url);
  }
  getDownloadSupport(id) {
    let _url =   this.baseUrl + 'support/download/' + id;
    window.open(_url, '_blank');
    //return this.user.get<any>(_url);
  }
  supportWithFile(jsonData,file,id=''):Observable<any> {
    const basicToken = localStorage.getItem('basicToken');
      let options  = new HttpHeaders();
      options.set('Authorization', `Bearer ${basicToken}`);
      options.set('Content-Type', 'undefined')
      options.set('Authorization', `Bearer ${basicToken}`)
      
    let _url = this.baseUrl + 'support';
    // Create form data
    const formData = new FormData();
    formData.append("type", jsonData.type);
    formData.append("description", jsonData.description);
    formData.append("assignto", jsonData.assignto);
    formData.append("status", jsonData.status);
    formData.append("remarks", jsonData.remarks);
    formData.append("deviceid", jsonData.deviceid);
    if(jsonData.resolutiondate) {
      formData.append("resolutiondate", new Date(jsonData.resolutiondate).toISOString());
    }
    if(jsonData.expectedresolutiondate) {
      formData.append("expectedresolutiondate", new Date(jsonData.resolutiondate).toISOString());
    }
    // Store form name as "file" with file data
    if(file && file.name)  {

      formData.append("files", file, file.name);
    }
      
    // Make http post request over api
    // with formData as req
    if(id) {
      
formData.append("ticketid", id);
      return this.user.put(_url, formData,{headers:options})
    } else {
      return this.user.post(_url, formData,{headers:options})
    }
  }
  addSupport(body) {
    let _url = this.baseUrl + 'support';
    return this.user.post<any>(_url, body);
  }

  updateSupport(body,id) {
    let _url = this.baseUrl + 'support/'+id;
    return this.user.put<any>(_url, body);
  }

  deleteSupport(id) {
    let _url = this.baseUrl + 'announcement/'+id;
   
    return this.user.delete<any>(_url);
  }
  // RTO MASTER MODULE

  getRtoMaster() {
    let _url = this.baseUrl + 'RTO_master/get';
    return this.user.get<any>(_url);
  }

  addRtoMaster(body) {
    let _url = this.baseUrl + 'RTO_master/add';
    return this.user.post<any>(_url, body);
  }

  updateRtoMaster(body) {
    let _url = this.baseUrl + 'RTO_master/update';
    return this.user.post<any>(_url, body);
  }

  deleteRtoMaster(rtoId) {
    let _url = this.baseUrl + 'RTO_master/delete';
    let httpOptions = {
      params: {
        _id: rtoId,
      },
    };
    return this.user.get<any>(_url, httpOptions);
  }

  // DEVICE MASTER MODULE

  getVehicleIdbyUser() {
    let _url = this.baseUrl + 'devices/getDeviceByUserDropdown';
    let httpOptions = {
      params: {
        email: this.userEmail,
        id: this.userId,
      },
    };
    return this.user.get<any>(_url, httpOptions);
  }

  getDataTable(body) {
    let _url = this.baseUrl + 'devices/datatable';
    return this.user.post<any>(_url, body);
  }

  deleteDeviceMaster(id) {
    let _url = this.baseUrl + 'devices/delete';
    let httpOptions = {
      params: {
        _id: id,
      },
    };
    return this.user.get<any>(_url, httpOptions);
  }

  //  USER MASTER MODULE

  getUserMaster(body) {
    let _url = this.baseUrl + 'users/datatable';
    return this.user.post<any>(_url, body);
  }
}
