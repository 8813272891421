import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment-timezone';
import { CommonService } from 'src/app/services/common.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.css']
})
export class TaskEditComponent implements OnInit {
  tasksform: FormGroup;
  editMode: true;
  @Input()
  taskData:any = {};
  driversList:any[] = [];
  constructor(private fb: FormBuilder,
    private commonService: CommonService) { 
    this.tasksform = this.fb.group({
      id: [0],
      description: [''],
      expectedstarttime: [''],
      expectedendtime: [''],
      name: [''],
      team: ['NA'],
      driver: [0],
      orderId: ['NA'],
      type: ['trip'],
      status: [''],
      attributes: this.fb.group({
        triptype: [''],
        planned_emp: [0],
        actual_emp: [0],
        escort: ['0']
      })
    })
  }

  ngOnInit(): void {
    //console.log(moment(this.taskData.expectedstarttime).format("yyyy-MM-DDThh:mm"));
    //console.log(new Date().toISOString().slice(0, 16));
    this.getDriversList();
    if (this.taskData && this.taskData.id) {
      //this.tasksform.patchValue(this.taskData);
      this.tasksform.patchValue({
        id: this.taskData.id,
        description: this.taskData.description,
        expectedstarttime: moment(new Date(this.taskData.expectedstarttime)).format("yyyy-MM-DDTHH:mm"),
        expectedendtime: moment(this.taskData.expectedendtime).format("yyyy-MM-DDThh:mm"),
        name: this.taskData.name,
        team: 'NA',
        driver: this.taskData.assignedto,
        orderId: 'NA',
        type: this.taskData.type,
        status: this.taskData.status,
        attributes: {
          planned_emp: this.taskData?.attributes?.planned_emp,
          actual_emp: this.taskData?.attributes?.actual_emp,
          escort: this.taskData?.attributes?.escort
        }      
      });
    }
  }

  getDriversList() {
    this.commonService.getRequest('users', []).then((resp: any) => {
      if (resp?.status) {
          this.driversList = resp.data.filter(x => x.attributes.role == 'driver' && x.attributes?.status != 'offline');
          if (this.driversList.length > 0) {
            // this.driverForm.patchValue({
            //   assignedto: this.driversList[0].id
            // })
          }
      }
    });
  }
  editTask() {
    //console.log(this.tasksform.value);
    this.taskData.description = this.tasksform.value.description;
    this.taskData.expectedstarttime = new Date(this.tasksform.value.expectedstarttime).toISOString();
    this.taskData.expectedendtime = new Date(this.tasksform.value.expectedendtime).toISOString();
    this.taskData.name = this.tasksform.value.name;
    this.taskData.assignedto = this.tasksform.value.driver;
    this.taskData.type = this.tasksform.value.type;
    this.taskData.status = this.tasksform.value.status;
    this.taskData.attributes.planned_emp = this.tasksform.value?.attributes?.planned_emp;
    this.taskData.attributes.actual_emp = this.tasksform.value?.attributes?.actual_emp;
    this.taskData.attributes.escort = this.tasksform.value?.attributes?.escort;    
    this.commonService
    .putRequest(`traccartasks/${this.taskData.id}`, this.taskData)
    .then((resp: any) => {
      if (resp?.status) {
        //this.commonService.pageLoadInModal.next("assigndriver");
        this.commonService.commonModel.next(false);
        Swal.fire({
          icon: "success",
          title: `Task updated successfully`,
          showConfirmButton: false,
          timer: 1500,
        });        
      }      
    }, err => {
      this.commonService.commonModel.next(false);
      Swal.fire({
        icon: "error",
        title: "Something Went Wrong",
        showConfirmButton: false,
        timer: 1500,
      });
    });
  }

  onCancelClick() {
    this.commonService.commonModel.next(false);
  }

}
