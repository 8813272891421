<p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ {{'Notifications'|translate}}</span></p>

<div class="container-fluid">
    
    <div class="row p-0">
        <div class="card p-0 headcard">
            <!-- HEADER WITH ADD POI BTNS -->
            <div class="card-header1 flex_row_center_spacebetween">
                <div class="cardHead_text">{{'Notifications'|translate}}</div>
                <ul class="nav nav-pills card-header-pills">
                    <li class="nav-item">
                        <div style="display: flex; flex-direction: row">
                            <button mat-raised-button class="addbtns px-3 py-1" style="margin-right: 1vh"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdropAdd"
                            (click)="addNotificationpopup()">
                                <span class="material-icons" style="font-size: 2.1vh">
                                    add
                                </span>
                                {{'New'|translate}}
                            </button>
                            <form [formGroup]="form">
                                <div class="input-group searchvehicles">

                                    <input (keyup)="page =1" formControlName="searchValue" name="searchValue"
                                        type="text" class="form-control reportsbox" placeholder="{{'Search'|translate}}"
                                        autocomplete="off" />

                                    <div class="input-group-append">
                                        <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh">
                                            <img (click)="searchData()" src="/assets/icons/searchicon.png" alt="search"
                                                style="width: 2.3vh" />
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- DATE DOWNLOAD HEADER -->
            <div class="card-header flex_row_center_spacebetween">
                <div class="flex_row_center_spacebetween">
                    <select class="form-select selectimportant" [(ngModel)]="pageOption" name="pageOption">
                        <option value="10" class="select_options">10</option>
                        <option value="20" class="select_options">20</option>
                        <option value="30" class="select_options">30</option>
                        <option value="40" class="select_options">40</option>
                        <option value="50" class="select_options">50</option>
                        <option value="60" class="select_options">60</option>
                    </select>
                </div>
                <div style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 27vh;
          ">
                    <div class="d-flex flex-row align-items-center" style="padding: 0.4vh 0 0 0">
                        <span class="addlabels">{{'Active'|translate}}</span>
                        <mat-slide-toggle style="padding-left: 1vh"></mat-slide-toggle>
                    </div>
                    <span (click)="getAllNotifications()" class="btn"><img src="/assets/icons/report_refresh.png"
                            alt="refresh" style="width: 96%" /></span>
                    <span class="btn"><img src="/assets/icons/report_download.png" alt="download"
                            style="width: 96%" /></span>
                </div>
            </div>
            <!-- notification TABLE -->
            <div class="card-body common_scroller fixed_height p-0">
                <table class="table table-borderless m-0">
                    <thead style="background-color: #24282e">

                        <tr>
                            <th class="table_head_text">{{'Type Of Notification'|translate}}</th>
                            <th class="table_head_text">{{'All Devices'|translate}}</th>
                            <th class="table_head_text">{{'Channels'|translate}}</th>
                            <th class="table_head_text">{{'Actions'|translate}}</th>
                        </tr>
                    </thead>

                    <tbody *ngIf="notificationList.length">
                        <tr *ngFor="let notification of notificationList  | searchPipe : form.value.searchValue |  paginate : {
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: (notificationList  | searchPipe : form.value.searchValue).length
            };let i=index">
                            <td class="table_data">{{ notification.type }}</td>
                            <td class="table_data">{{ notification.always ? 'Yes':'No' }}</td>
                            <td class="table_data">{{ notification.notificators }}</td>


                            <td class="table_data">
                                <div class="btn btn-primary btn-sm mx-1" (click)="editNotifications(notification)"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdropAdd">
                                    <i class="fa fa-edit"></i>
                                </div>
                                <div class="btn btn-danger btn-sm mx-1" (click)="deleteNotifications(notification.id)">
                                    <i class="fa fa-trash"></i>
                                </div>
                                

                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="!notificationList.length">
                        <tr>
                            <td colspan="7">
                                <div class="d-flex justify-content-center align-items-center" style="height: 48vh">
                                    <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="notificationList.length" class="flex_row_center_spacebetween"
                style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
                <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{(notificationList | searchPipe : form.value.searchValue).length/pageOption |
                    number: '1.0-0'}} {{'of'|translate}}
                    {{(notificationList | searchPipe : form.value.searchValue).length}} {{'entries'|translate}}</div>
                <pagination-controls (pageChange)="page = $event"></pagination-controls>
            </div>
        </div>
    </div>
</div>

<div
  class="modal"
  id="staticBackdropAdd"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropAddLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content" style="border: none">
      <div class="modal-header addtriphead">
        <div class="headtext addlabels">
          {{ addNotification.id ? ('Edit'|translate) : ('Add'|translate) }} {{'Notification'|translate}}
        </div>
        <button
          type="button"
          class="btnclose"
          id="btnclose"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span class="material-icons"> close </span>
        </button>
      </div>
      <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
        <div class="row">
          <div class="col-12">
            <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Type'|translate}}</label>
                <select class="form-select" name="type" [(ngModel)]="addNotification.type" name="pageOption">
                    <option *ngFor="let item of masterData.type;let i = index" value="{{item.type}}" class="select_options">{{item.type}}</option>
                </select>
                
                <span
                  class="text-danger validation"
                  *ngIf="errors?.type"
                >
                  {{ errors.type }}
                </span>
              </div>
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Channels'|translate}}</label>
               <mat-select  [(ngModel)]="addNotification.notificators" multiple>
                      <mat-option *ngFor="let item of masterData.channel" [value]="item.type">{{item.type}}</mat-option>
                    </mat-select>
                  
                
                <span
                  class="text-danger validation"
                  *ngIf="errors?.notificators"
                >
                  {{ errors.notificators }}
                </span>
              </div>
              <div class="form-group pb-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="addNotification.always"
                    (change)="checkboxEvent($event.target)"
                    id="always"
                  />
                  <label class="form-check-label" for="always"
                    >{{'All Devices'|translate}}</label
                  >
                </div>
              </div>
          </div>
        </div>

        <div class="flex_row_center_spacebetween pt-4 pb-2">
            <div class="col-8"></div>
            
            <div class="col-2 ">
              <button
                mat-raised-button
                class="cancelbtn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {{'Cancel'|translate}}
              </button>
            </div>
            <div class="col-2">
              <button
                mat-raised-button
                class="cancelbtn"
                (click)="submit()"
              >
                {{'Save'|translate}}
              </button>
            </div>
         
        </div>
      </div>
    </div>
  </div>
</div>



