import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { UsersettingService } from 'src/app/services/usersetting.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: 'app-rto-master',
  templateUrl: './rto-master.component.html',
  styleUrls: ['./rto-master.component.css']
})
export class RTOMasterComponent implements OnInit {

  pageOption = 10;
  RtoMasterList = [];
  rtoId = "";
  editForm = false;
  userId = localStorage.getItem("userId");
  page: number = 1;
  addRTOMasterForm: FormGroup;
  submitted = false;
  searchRTOModel = "";

  states = [
    "Gujarat",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry"
  ]

  constructor(private rtoModel: UsersettingService, private fb: FormBuilder, private customValidator: ValidationService) {
    this.addRTOMasterForm = this.fb.group(
      {
        rtoName: ['', Validators.required],
        rtoCode: ['', Validators.required],
        city: ['', Validators.required],
        status: ['', Validators.required],
        state: ['', Validators.required]
      });
  }

  ngOnInit(): void {
    this.getAllRtoMaster();
  }

  get AddRtoMasterControl() {
    return this.addRTOMasterForm.controls;
  }

  getAllRtoMaster() {
    this.rtoModel.getRtoMaster().subscribe(resp => {
      console.log(resp);
      this.RtoMasterList = resp;
    })
  }

  onRTOMasterSubmit() {
    if (this.editForm) {
      let body = {
        "body": {
          RTO_Code: this.addRTOMasterForm.value.rtoCode,
          RTO_Name: this.addRTOMasterForm.value.rtoName,
          city: this.addRTOMasterForm.value.city,
          state: this.addRTOMasterForm.value.state,
          status: this.addRTOMasterForm.value.status
        },
        _id:this.rtoId 
      }
      this.rtoModel.updateRtoMaster(body).subscribe(resp => {
        Swal.fire({
          icon: "success",
          title: "Your Form has been Updated",
          showConfirmButton: false,
          timer: 1500,
        });
        this.addRTOMasterForm.reset();
        $('#staticBackdrop').modal("hide");
        this.getAllRtoMaster();
        this.editForm = false;
      })
    } else {
      let body = {
        RTO_Code: this.addRTOMasterForm.value.rtoCode,
        RTO_Name: this.addRTOMasterForm.value.rtoName,
        city: this.addRTOMasterForm.value.city,
        state: this.addRTOMasterForm.value.state,
        status: this.addRTOMasterForm.value.status
      }
      this.rtoModel.addRtoMaster(body).subscribe(resp => {
        Swal.fire({
          icon: "success",
          title: "Your Form has been Submitted",
          showConfirmButton: false,
          timer: 1500,
        });
        this.addRTOMasterForm.reset();
        $('#staticBackdrop').modal("hide");
        this.getAllRtoMaster();
      })
    }
  }

  onEditRtoMaster(rto){
    this.editForm=true;
    this.addRTOMasterForm.setValue({
      rtoName: rto.RTO_Name,
      rtoCode: rto.RTO_Code,
      city: rto.city,
      status: rto.status,
      state: rto.state
    })
    this.rtoId = rto._id;
  }

  onDeleteRtoMaster(rtoId){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this RtoMaster!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.rtoModel.deleteRtoMaster(rtoId).subscribe(resp => {
          console.log(resp);
          this.getAllRtoMaster();
        });
        Swal.fire(
          'Deleted!',
          'RtoMaster has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'RtoMaster is safe :)',
          'error'
        )
      }
    })
  }

  onCloseModal(){
    this.addRTOMasterForm.reset();
  }

  onAddRTOMaster() {
    this.editForm = false;
    this.addRTOMasterForm.reset();
  }

  values(event) {
    if (event.target.value) {
      this.RtoMasterList = this.RtoMasterList.filter(s => s.state.includes(event.target.value));
      console.log(this.RtoMasterList)
    }
    else {
      this.getAllRtoMaster();
    }
  }


}
