<app-common-modal-popup [modelSize]="'modal-lg'" class="">
  <div class="" modelheadertext>{{'Create task'|translate}}</div>
  <div class="container-fluid" modeldata>
      <form action="" [formGroup]="taskform">
          <div class="row">
              <div class="col-12 col-md-4">
                  <div class="form-group pb-2">
                    <label for="cname" class="pb-2">{{'Company '|translate}}</label> <span>*</span>
                    <select
                    class="form-select taskspanel" 
                    id="cname"  
                    formControlName="clientId"
                    (change)="getClientDetails($event)"
                  >
                    <option value="0">Select</option>
                    <option
                      value="{{ c.email }}"
                      *ngFor="let c of customerList"
                    >
                      {{ c.name }}
                    </option>
                  </select>

                  <span class="text-danger validation"
                  *ngIf="(taskform.get('clientId').touched || submitted) && taskform.get('clientId').value === '0'">
                  *{{ 'Please select client' | translate }}
                </span>
                
                    <!-- <input
                      type="text"
                      class="form-control inputfields"
                      id="name"
                      aria-describedby="name"
                     formControlName="clientId"
                    /> -->
                    <!-- <span class="text-danger validation"
                    *ngIf="(taskform.clientId.touched || submitted)&& taskform.clientId.errors?.required">
                    *{{'Confirm Password'|translate}} {{'is required'|translate}}
                  </span> -->
                  </div>
              </div> 
              <div class="col-12 col-md-4">
                  <div class="form-group pb-2">
                    <label for="type" class="pb-2">{{'Trip Type '|translate}}</label> <span>*</span>
  
                    <select
                      class="form-select me-2   taskspanel"
                      id="type"
                      style="height: 6.2vh;"
                      formControlName="triptype"
                    >
                    <option value="0" class="select_options" >Select</option>
                    <option value="pickup" class="select_options">Pickup</option>
                    <option value="drop" class="select_options">Drop</option>
                   
                            
                    </select> 

                 
                    <span class="text-danger validation"
           *ngIf="(taskform.get('triptype').touched || submitted) && taskform.get('triptype').value === '0'">
           *{{ 'Please select trip' | translate }}
         </span>
                  </div>
                </div> 
                <div class="col-12 col-md-4">
                  <div class="form-group pb-2">
                    <label for="escort1" class="pb-2">{{'Escort '|translate}}</label> <span>*</span>
  
                    <select
                      class="form-select me-2  taskspanel"
                      id="escort1"
                      style="height: 6.2vh;"
                      formControlName="escort"
                    >
                    <!-- <option value="0" class="select_options" >Select</option> -->
                    <option value="1" class="select_options">Yes</option>
                    <option value="0" class="select_options">No</option>
                   
                            
                    </select> 
                   
           <!-- <span class="text-danger validation"
           *ngIf="(taskform.get('escort').touched || submitted)">
           *{{ 'Escort is required' | translate }}
         </span> -->

                  </div>
                </div>
                  <div class="col-12 col-md-4">
                      <div class="form-group pb-2">
                          <label for="date" class="pb-2">{{ 'Select Date and Shift Time' | translate }}</label>
                          <span>*</span>
                          <div class="d-flex align-items-center">
                            <input
                              type="text"
                              id="date"
                              bsDatepicker
                              [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                              style="height: 6.2vh; border-radius: 5px; width: 101px;"
                              placeholder=" dd/mm/yyyy"
                              formControlName="date"
                            />
                        
                            <div class="form-group">
                             
                              <mat-form-field class="example-full-width" appearance="fill"> <!-- Adjust margin as needed -->
                                <input type="text"   matInput
                                placeholder="Select Shift" aria-label="Number"
                                [formControl]="shiftControl" 
                                [matAutocomplete]="auto" 
                                style="width: 18vh;">
                               <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" style="width: 18vh;">
                              <mat-option *ngFor="let shift of filteredShifts | async" [value]="shift" style="width: 18vh;">
                              {{ shift }}
                              </mat-option>
                              </mat-autocomplete>
                              </mat-form-field>
                            </div>
                          
                          </div>
                          
                      </div>
                  </div>      
                <div class="col-12 col-md-4">
                  <div class="form-group pb-2">
                    <label for="plannedEmp" class="pb-2">{{'Planned Employees '|translate}}</label> <span>*</span>
                    <input
                      type="text"
                      class="form-control inputfields"
                      id="plannedEmp"
                      aria-describedby="name"
                      formControlName="plannedemp"
                      autocomplete="off"
                 
                    />
                    <span class="text-danger validation"
                    *ngIf="(taskformControl.plannedemp.touched || submitted)&& taskformControl.plannedemp.errors?.required">
                    *{{'Planned employees required'|translate}} 
                  </span>
                  </div>
                </div> 
                <div class="col-12 col-md-4">
                  <div class="form-group pb-2">
                    <label for="actualEmp" class="pb-2">{{'Actual Employees '|translate}}</label> <span>*</span>
                    <input
                      type="text"
                      class="form-control inputfields"
                      id="actualEmp"
                      aria-describedby="name"
                      formControlName="actualemp"
                      autocomplete="off"
                 
                    />
                    <span class="text-danger validation"
                    *ngIf="(taskformControl.actualemp.touched || submitted)&& taskformControl.actualemp.errors?.required">
                    *{{'Actual employees required'|translate}} 
                  </span>
                    <!-- <span class="text-danger validation" *ngIf="errors?.name">
                      {{ errors.name }}
                    </span> -->
                  </div>
                </div> 
                <div class="col-12 col-md-12">
                  <div class="form-group pb-2">
                    <label for="address1" class="pb-2">{{'Address '|translate}}</label> <span>*</span>
                    <input
                    #inputField
                      type="text"
                      class="form-control inputfields"
                      id="address1"
                      formControlName="address"
                    />

                    <span class="text-danger validation"
                    *ngIf="(taskformControl.address.touched || submitted)&& taskformControl.address.errors?.required">
                    *{{'Address is required'|translate}} 
                  </span>
                    <!-- <span class="text-danger validation" *ngIf="errors?.name">
                      {{ errors.name }}
                    </span> -->
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group pb-2">
                    <label for="city1" class="pb-2">{{'Flap No'|translate}}</label>
                    <input
                      type="text"
                      class="form-control inputfields"
                      id="city1"
                      autocomplete="off"
                      formControlName="flapno"
                    />
                    <!-- <span class="text-danger validation"
                    *ngIf="(taskformControl.city.touched || submitted)&& taskformControl.city.errors?.required">
                    *{{'City is required'|translate}} 
                  </span> -->
                    <!-- <span class="text-danger validation" *ngIf="errors?.name">
                      {{ errors.name }}
                    </span> -->
                  </div>
                </div>
                <!-- <div class="col-12 col-md-4">
                  <div class="form-group pb-2">
                    <label for="pin" class="pb-2">{{'Pincode '|translate}}</label> <span>*</span>
                    <input
                      type="text"
                      class="form-control inputfields"
                      id="pin"
                      autocomplete="off"
                      formControlName="pincode"
                 
                    />
                  
                  </div>
                </div> -->
                <!-- <div class="col-12 col-md-4">
                  <div class="form-group pb-2">
                    <label for="name" class="pb-2">{{'Shift Timing '|translate}}</label> <span>*</span>
                    <input
                      type="text"
                      class="form-control inputfields"
                      id="name"
                      aria-describedby="name"
                      value="50"
                      formControlName="shift"
                       readonly               
                    />
                  
                  </div>
                </div>  -->
                <div class="d-flex align-items pt-4 pb-2">
                  <button
                    mat-raised-button
                    type="submit"
                    class="cancelbtn"
                    (click)="submit()"
                  >
                  {{'SAVE' | translate}}
                  </button>
                  <button
                    mat-raised-button
                    type="button"
                    class="cancelbtn bg-secondary ms-3"
                    (click)="cancel()"
                  >
                  {{'CANCEL' | translate}}
                  </button>
                </div>
               
          </div>
      </form>
    
  </div>   
</app-common-modal-popup>
