import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { url } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  baseUrl = url;
  socketBaseUrl = "https://socket.oneqlik.in/";
  userEmail = "";
  userId = localStorage.getItem("userId")
  todate:any;
  fromDate:any;
  todaysDate: any;
  yesterdayDate: any;

  constructor(private reports: HttpClient) {
    // this.userEmail = JSON.parse(window.atob(window.localStorage.token.split('.')[1])).email;

    // // TODAY & YESTERDAY DATE
    // this.todaysDate = new Date().toISOString();
    // const yesterday = new Date(this.todaysDate)
    // yesterday.setDate(yesterday.getDate() - 1)
    // this.yesterdayDate=yesterday.toISOString()
    // console.log(this.todaysDate, this.yesterdayDate);
    // // END

    // // ISO DATE FORMAT
    // this.todate=new Date();
    // const yesterdays = new Date(this.todate)
    // yesterdays.setDate(yesterdays.getDate() - 1)
    // this.fromDate=yesterdays;
    // console.log(this.todate, this.fromDate);

  }

  googleAddress(body){
    let _url= this.baseUrl + "googleAddress/getGoogleAddress"
    return this.reports.post<any>(_url, body)
  }

  getgeofence(){
    let _url = this.baseUrl + "geofencing/getallgeofence";
    let httpOptions = {
      params: {
        "uid": this.userId
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  // REPORT APIS

  acReports(vId) {
    let _url = this.baseUrl + "notifs/acReport";
    let httpOptions = {
      params: {
        "from_date": this.yesterdayDate,
        "to_date": this.todaysDate,
        "_u": this.userId,
        "vname": vId
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  dailyReports(body) {
    let _url = this.baseUrl + "devices/daily_report";
    return this.reports.post<any>(_url, body)
  }

  daywiseReports(vId) {
    let _url = this.baseUrl + "summary/getDayWiseReport";
    let httpOptions = {
      params: {
        "from": this.yesterdayDate,
        "to": this.todaysDate,
        "user": this.userId,
        "device": vId
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  distanceReport(vId) {
    let _url = this.baseUrl + "summary/distance";
    let httpOptions = {
      params: {
        "from": this.yesterdayDate,
        "to": this.todaysDate,
        "user": this.userId,
        "skip": 0,
        "device": vId
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  geofenceReport(geoid) {
    let _url = this.baseUrl + "notifs/GeoFencingReport";
    let httpOptions = {
      params: {
        "from_date": this.yesterdayDate,
        "to_date": this.todaysDate,
        "_u": this.userId,
        "geoid": geoid
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  idleReport(vId) {
    let _url = this.socketBaseUrl + "stoppage/idleReport";
    let httpOptions = {
      params: {
        "from": this.yesterdayDate,
        "to": this.todaysDate,
        "time": 300000,
        "device": vId
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  ignitionReport(vId) {
    let _url = this.baseUrl + "notifs/ignitionReport";
    let httpOptions = {
      params: {
        "from_date": this.yesterdayDate,
        "to_date": this.todaysDate,
        "_u": this.userId,
        "device": vId
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  loadingUnloadingGroupReport(vId) {
    let _url = this.baseUrl + "trackRouteMap/getTripReport";
    let httpOptions = {
      params: {
        "userid": this.userId,
        "from": this.yesterdayDate,
        "to": this.todate,
        "device": vId
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  loadingTripcountReport(vId){
    let _url = this.baseUrl + "trackRouteMap/getTripCountReport";
    let httpOptions = {
      params: {
        "userid": this.userId,
        "from": this.yesterdayDate,
        "to": this.todate,
        "device": vId
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  overSpeedReport(vId){
    let _url = this.baseUrl + "notifs/overSpeedReport";
    let httpOptions = {
      params: {
        "from_date": this.yesterdayDate,
        "to_date": this.todaysDate,
        "_u": this.userId,
        "device": vId
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  poiReport(){
    let _url = this.baseUrl + "poi/getPois";
    let httpOptions = {
      params: {
        "user": this.userId,
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  poidatatable(body){
    let _url = this.baseUrl + "poi/datatable";
    return this.reports.post<any>(_url, body)
  }

  trackRouteUser(){
    let _url = this.baseUrl + "trackRoute/user/" + this.userId;
    return this.reports.get<any>(_url)
  }

  routeviolationReport(routeid){
    let _url = this.socketBaseUrl + "notifs/RouteVoilationReprot";
    let httpOptions = {
      params: {
        "from_date": this.yesterdayDate,
        "to_date": this.todaysDate,
        "_u": this.userId,
        "trackid":routeid
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  sosReport(vId){
    let _url = this.baseUrl + "notifs/statusReport";
    let httpOptions = {
      params: {
        "from_date": this.yesterdayDate,
        "to_date": this.todaysDate,
        "_u": this.userId,
        "device": vId
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  speedVariationReport(vId){
    let _url = this.baseUrl + "gps/getGpsSpeedReport";
    let httpOptions = {
      params: {
        "time": this.todaysDate,
        "imei": vId,
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  stoppageReport(vId){
    let _url = this.baseUrl + "stoppage/stoppageReport";
    let httpOptions = {
      params: {
        "from_date": this.yesterdayDate,
        "to_date": this.todaysDate,
        "_u": this.userId,
        "vname": vId
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  summaryReport(vId){
    let _url = this.baseUrl + "summary/summaryReport";
    let httpOptions = {
      params: {
        "from": this.yesterdayDate,
        "to": this.todaysDate,
        "user": this.userId,
        "device": vId
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  tripReport(){
    let _url = this.baseUrl + "trackRouteMap/getTripReport";
    let httpOptions = {
      params: {
        "userid": this.userId,
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  usertripReport(vId){
    let _url = this.baseUrl + "user_trip/trip_detail";
    let httpOptions = {
      params: {
        "from_date": this.yesterdayDate,
        "to_date": this.todaysDate,
        "uId": this.userId,
        "device": vId
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  valueScreenReport(vId){
    let _url = this.baseUrl + "valueScreenReport";
    let httpOptions = {
      params: {
        "from_date": this.yesterdayDate,
        "to_date": this.todaysDate,
        "uId": this.userId,
        "device": vId
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }

  workinghoursReport(vId){
    let _url = this.baseUrl + "notifs/ignitionReportForMobile";
    let httpOptions = {
      params: {
        "from_date": this.yesterdayDate,
        "to_date": this.todaysDate,
        "_u": this.userId,
        "device": vId
      }
    };
    return this.reports.get<any>(_url, httpOptions)
  }


}
