import { Injectable } from '@angular/core';
import { debug } from 'console';

import { of, Subject } from 'rxjs';
import { mergeMap, map, tap } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment.prod';

declare var google: any, io: any, $: any, moment: any; // Declare the 'google' variable

@Injectable({
  providedIn: 'root',
})
export class TrackdashboardService {
  bounds: any;
  poi_data: any = '';

  constructor(private hs: CommonService) {
    this.bounds = new google.maps.LatLngBounds();
  }
  // map _ moving
  cord_arr(start: any, end: any, step: any) {
    var n = step; // the number of coordinates you want

    var coordinates = [];
    for (var i = n - 1; i > 0; i--) {
      coordinates.push({
        lat: (start.lat * i) / n + (end.lat * (n - i)) / n,
        lng: (start.lng * i) / n + (end.lng * (n - i)) / n,
      });
    }
    return coordinates;
  }
  db_degreesToRadians(degrees: any) {
    return (degrees * Math.PI) / 180;
  }
  db_computeHeading(lat1: any, lon1: any, lat2: any, lon2: any) {
    const φ1 = this.db_degreesToRadians(lat1);
    const φ2 = this.db_degreesToRadians(lat2);
    const Δλ = this.db_degreesToRadians(lon2 - lon1);

    const y = Math.sin(Δλ) * Math.cos(φ2);
    const x =
      Math.cos(φ1) * Math.sin(φ2) - Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ);
    let heading = Math.atan2(y, x);

    // Convert radians to degrees
    heading = (heading * 180) / Math.PI;

    // Adjust the range of the heading to be [0, 360)
    heading = (heading + 360) % 360;

    return heading;
  }
  db_move(_data: any, step: any, marker: any, map: any) {
    let that = this;
    let cnt = 0;

    var start = {
      lat: _data.data.sec_last_location.lat,
      lng: _data.data.sec_last_location.long,
    };
    var end = {
      lat: _data.data.last_location.lat,
      lng: _data.data.last_location.long,
    };
    // marker.setPosition(end);

    var n = step; // the number of coordinates you want

    var coordinates = this.cord_arr(start, end, 100);

    let head = this.db_computeHeading(start.lat, start.lng, end.lat, end.lng);

    _data.setInterval = setInterval(function () {
      if (
        coordinates[cnt] != undefined &&
        coordinates[cnt].lng != undefined &&
        coordinates[cnt].lat != undefined
      ) {
        if (
          !Number.isNaN(coordinates[cnt].lng) &&
          !Number.isNaN(coordinates[cnt].lat)
        ) {
          if (
            cnt > 2 &&
            coordinates[cnt - 1].lat != coordinates[cnt].lat &&
            coordinates[cnt - 1].lng != coordinates[cnt].lng
          ) {
            head = that.db_computeHeading(
              coordinates[cnt - 1].lat,
              coordinates[cnt - 1].lng,
              coordinates[cnt].lat,
              coordinates[cnt].lng
            );
            let latlng = new google.maps.LatLng(
              coordinates[cnt].lat,
              coordinates[cnt].lng
            );

            const element: any = document.querySelector(
              `img[src~='${marker.icon.url}']`
            );
            if (element) {
              element.style.transform = 'rotate(' + head + 'deg)';
            }

            if ((_data?.setCenter || false) == true) {
              map.setCenter(latlng);
            }
            marker.setPosition(latlng);

            // if (that.isMarkerInFocus(marker, map) == false) {
            //   map.setCenter(latlng);
            // }
            //
          }
        }
      }

      if (cnt <= step) {
        cnt = cnt + 1;
      }
    }, 10);
    return _data.setInterval;
  }
  isMarkerInFocus(marker: any, map: any) {
    const bounds = map.getBounds();
    if (bounds && marker.getPosition()) {
      return bounds.contains(marker.getPosition());
    }
    return false;
  }

  create_line(flightPlanCoordinates: any, map: any, color: any) {
    const flightPath = new google.maps.Polyline({
      path: flightPlanCoordinates,
      geodesic: true,
      strokeColor: color, // '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });

    flightPath.setMap(map);
    return flightPath;
  }
  create_poi_marker(poi: any, map: any, id: any) {
    let mark = new google.maps.Marker({
      position: poi,
      map: map,
      label: (id + 1).toString(),
      title: poi.poi.poiname,
      // icon: 'https://chart.googleapis.com/chart?chst=d_map_xpin_icon&chld=pin|bank-dollar|52B552',
      // icon: '/assets/images/stop.png',
    });
    return mark;
  }
  get_routePath(resp: any) {
    return resp[0].routePath.map((d: any) => {
      return {
        lat: d.location.coordinates[1],
        lng: d.location.coordinates[0],
      };
    });
  }





  // create_line_with_poi_bounds(pth: any, bounds: any, map: any) {
  //   bounds = new google.maps.LatLngBounds();
  //   bounds.extend(pth[0]);
  //   bounds.extend(pth[Math.floor(pth.length / 2)]);
  //   bounds.extend(pth.at(-1));
  //   map.fitBounds(bounds);
  // }
  // create_line_with_poi(
  //   resp_all: any,
  //   map: any,
  //   map_arr: any,
  //   schedule_id: any
  // ) {
  //   let that = this;
  //   console.log('this', this);
  //   map.setZoom(15);
  //   let resp: any = resp_all[0];
  //   let pth = that.get_routePath(resp);
  //   const flightPath = that.create_line(pth, map, '#FF0000');
  //   let pth2 = resp_all[1].map((d: any) => ({ lat: d.lat, lng: d.lng }));
  //   console.log('new history=>', pth2);
  //   const flightPath2 = that.create_line(pth2, map, '#00FF00');
  //   that.create_line_with_poi_bounds(pth2, this.bounds, map);

  //   map_arr.push(flightPath);
  //   map_arr.push(flightPath2);
  //   //poi start
  //   let poi = resp[0].poi.map((d: any) => {
  //     d.lat = d.poi.location.coordinates[1];
  //     d.lng = d.poi.location.coordinates[0];
  //     d.data = d;
  //     return d;
  //   });

  //   for (let i = 0; i < poi.length; i++) {
  //     let mark = that.create_poi_marker(poi[i], map, i);

  //     mark.addListener(
  //       'click',
  //       (function (r) {
  //         console.log('r', r);
  //         return () => {
  //           console.log('that', that);
  //           that.api_student_list_resp_sub.next({
  //             limit: 999999,
  //             page: 1,
  //             mark: mark,
  //             map: map,
  //             poi: r._id,
  //             data: r,
  //             schedule_id: schedule_id,
  //           });
  //         };
  //       })(poi[i])
  //     );
  //     map_arr.push(mark);
  //   }
  // }
  set_map_null(map_arr: any, map: any) {
    for (let i = 0; i < map_arr.length; i++) {
      map_arr[i].setMap(null);
    }
  }
  ioTest() {
    debugger
    const basicToken = localStorage.getItem('socketBasicToken');
      const apiUrl = `wss://${environment.baseUrl}:8082/api/socket?token=${basicToken}`;
    let that = this;
    // let _io = io.connect('http://app.schoolqlik.com:3001/gps', {
    let _io =  this.hs.socketConnection = new WebSocket(apiUrl);
    return _io;
  }

  get_bus_status(Device_ID: any) {

    // console.log(
    //   'get_bus_status=>',
    //   Device_ID.Device_Name,
    //   'speed=>',
    //   Device_ID.last_speed,
    //   'acc=>',
    //   Device_ID.last_ACC
    // );
    let status: any = 1;

    if (Device_ID.status == 'NO DATA') {
      status = 1;
    } else if (Device_ID.status == 'STOPPED') {
      status = 1;
    } else if (Device_ID.status == 'OUT OF REACH') {
      status = 2;
    } else if (Device_ID.status == 'Running') {
      status = 0;
    }
    return status;
  }
  infowindow: any;
  _pgData: any = {
    infowindow: {},
  };
  infoWindowcontent(_data: any, addr: any) {
    let dt: any = '',
      dt_diff: any = '';
    if (_data?.last_ping_on || '' != '') {
      dt = moment(_data?.last_ping_on).format('DD-MM-YYYY hh:mm:ss A');

      dt_diff = new Date(
        new Date().getTime() - new Date(_data?.last_ping_on).getTime()
      )
        .toISOString()
        .substr(11, 8);
    }
    console.log("_data=>",_data)
    // <pre>${JSON.stringify(_data, null, 2)}</pre> <!-- This will print JSON data in a readable format -->
    return  `<div>
    
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <td colspan="2" class="text-center fs-5 fw-bold">${_data?.Device_Name || ''}</td>
        </tr>
      </thead>
      <tbody>

        <tr><td>Address</td><td>${addr[0].address}</td></tr>
        <tr><td>Last updated</td><td>${dt} (${dt_diff})</td></tr>
        <tr><td>GPS Positioned</td><td>${_data?.gpsTracking === '1' ? 'Yes' : _data?.gpsTracking || ''}</td></tr>
        <tr><td>GPS Signal</td><td>${_data?.gsmSignal || ''}</td></tr>
        <tr><td>Driver's Name</td><td>${_data?.driverDetail?.first_name || ''}</td></tr>
        <tr><td>Driver's Number</td><td>${_data?.driverDetail?.phone || ''}</td></tr>
        <tr><td>Speed</td><td>${_data?.last_speed || 0} kmph</td></tr>
      </tbody>
    </table>
  </div>`;
  }
  run_io(live_data: any, map: any, _io: any, Device_ID: any) {
debugger
    let that = this;
    live_data[Device_ID.Device_ID] = {
      data: Device_ID,
    };

    if (Device_ID?.last_location) {
      let pos = {
        lat: Device_ID?.last_location?.lat || 0,
        lng: Device_ID?.last_location?.long || 0,
      };
      this.bounds.extend(pos);
      map.fitBounds(this.bounds);
      live_data[Device_ID.Device_ID].marker = new google.maps.Marker({
        position: pos,
        map: map,
        // title: Device_ID.Device_ID,
        label: {
          //text: 'dhananjay',
          //text: Device_ID.Device_Name,
          text: Device_ID?.vehicle?.name || Device_ID.Device_Name,
          className: 'custom-label-map',
          color: 'white',
          fontSize: '12px',
          fontWeight: 'bold',
        },
      });
      live_data[Device_ID.Device_ID].marker.addListener('click', () => {
        that._pgData.selected = Device_ID.Device_ID;
        map.setCenter(live_data[Device_ID.Device_ID].marker.position);

        let dt: any = '',
          dt_diff: any = '';
        if (live_data[Device_ID.Device_ID]?.data?.last_ping_on || '' != '') {
          dt = moment(
            live_data[Device_ID.Device_ID]?.data?.last_ping_on
          ).format('DD-MM-YYYY hh:mm:ss A');

          dt_diff = new Date(
            new Date().getTime() -
              new Date(
                live_data[Device_ID.Device_ID]?.data?.last_ping_on
              ).getTime()
          )
            .toISOString()
            .substr(11, 8);
        }

        // this.hs
        //   .post('googleAddress/getGoogleAddress', [
        //     live_data[Device_ID.Device_ID].data.last_location,
        //   ])
        //   .subscribe((resp) => {
        //     console.log('address=>', resp);
        //     that._pgData.infowindow = new google.maps.InfoWindow({
        //       content: that.infoWindowcontent(
        //         live_data[Device_ID.Device_ID].data,
        //         resp
        //       ),
        //       ariaLabel: 'Uluru',
        //     });
        //     that._pgData.infowindow.open({
        //       anchor: live_data[Device_ID.Device_ID].marker,
        //       map: map,
        //     });
        //   });
      });

      let status = that.get_bus_status(Device_ID);
      live_data[Device_ID.Device_ID].marker.setIcon({
        url:
          '/assets/images/' +
          Device_ID.iconType +
          status +
          '.png?id=' +
          Device_ID.Device_ID,
        scaledSize: { height: 40, width: 20 },
        size: new google.maps.Size(60, 60),
        origin: new google.maps.Point(-30, 0),
        anchor: new google.maps.Point(38, 20),
        scale: 2,
      });
    }

    _io.emit('acc', Device_ID.Device_ID);
    console.log('call emit', Device_ID.Device_ID);
    _io.on(
      Device_ID.Device_ID,
      function (d1: any, d2: any, d3: any, d4: any, d5: any) {
        // if (that._pgData.selected == d3.Device_ID) {
        //   that.hs
        //     .post('googleAddress/getGoogleAddress', [
        //       live_data[Device_ID.Device_ID].data.last_location,
        //     ])
        //     .subscribe((resp: any) => {
        //       console.log('address=>', resp);
        //       let cnt = that.infoWindowcontent(d3, resp);

        //       that._pgData.infowindow.setContent(cnt);
        //     });
        // }
        live_data[d3.Device_ID].data = d3;

        // if (d4.last_location && live_data[d2].marker) {
        //   let t = {
        //     lat: d4.last_location.lat,
        //     lng: d4.last_location.long,
        //   };

        //   // live_data[d2].marker.setPosition(t);
        // }

        if (live_data[d3.Device_ID].setInterval) {
          clearInterval(live_data[d3.Device_ID].setInterval);
        }

        let status = that.get_bus_status(d3);
        //  let status = Math.floor(Math.random() * 4 + 1) - 1;
        live_data[Device_ID.Device_ID].marker.setIcon({
          url:
            '/assets/images/' +
            Device_ID.iconType +
            status +
            '.png?id=' +
            Device_ID.Device_ID,
          scaledSize: { height: 40, width: 20 },
          size: new google.maps.Size(60, 60),
          origin: new google.maps.Point(-30, 0),
          anchor: new google.maps.Point(38, 20),
          scale: 2,
        });
        that.db_move(
          live_data[d3.Device_ID],
          500,
          live_data[d3.Device_ID].marker,
          map
        );
      }
    );
  }
}