<!-- <p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ Device Model</span></p> -->
<app-common-modal-popup>
  <div class="" modelheadertext *ngIf="gridMode">{{'Device Model'|translate}}</div>
  <div class="" modelheadertext *ngIf="addMode">Add Model</div> 
  <div class="container-fluid " modeldata *ngIf="gridMode">
    <div class="row p-0">
      <div class="card p-0 headcard">
  
        <!-- HEADER WITH ADD POI BTNS -->
        <div class="bg-light d-flex align-items-center p-2">
          <div class="d-flex align-items-center justify-content-between w-100">
            
            <div [formGroup]="form" class="input-group searchvehicles">
              <input (keyup)="page =1" formControlName="searchValue" name="searchValue"
                                      type="text" class="form-control reportsbox" placeholder="{{'Search'|translate}}"
                                      autocomplete="off" />
              <div class="input-group-append bg-white">
                <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh;">
                  <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
                </button>
              </div>
            </div>
            <button *ngIf="superAdmin" class="d-flex align-items-center p-2 bg-white" title="{{' Add Model'|translate}}" (click)="onAddModelMaster()">
              <span class="material-icons" style="font-size: 2.1vh;">
                add
              </span>
            </button>
          </div>           
        </div>
        <!-- DATE DOWNLOAD HEADER -->
        <div class="card-header flex_row_center_spacebetween">
          <div class="flex_row_center_spacebetween">
            <select class="form-select selectimportant" [(ngModel)]="pageOption" name="pageOption">
              <option value="10" class="select_options">10</option>
              <option value="20" class="select_options">20</option>
              <option value="30" class="select_options">30</option>
              <option value="40" class="select_options">40</option>
              <option value="50" class="select_options">50</option>
              <option value="60" class="select_options">60</option>
            </select>
          </div>
          <div style="display: flex;flex-direction: row;align-items: center;">
            <span class="btn"><img src="/assets/icons/report_refresh_gray.png" alt="refresh" style="width: 96%;"></span>
            <span class="btn"><img src="/assets/icons/report_download_gray.png" alt="download" style="width: 96%;"></span>
          </div>
        </div>
        <!-- MODEL MASTER TABLE -->
        <div class="card-body common_scroller fixed_height p-0">
          <table class="table table-borderless m-0">
  
            <thead class="bg-light">
              <tr>
              
                <th class="table_head_text text-left">{{'Model'|translate}}</th>
                <th class="table_head_text text-left">{{'apk Command'|translate}}</th>
                <th class="table_head_text text-left">{{'IP Command'|translate}}</th>
                <th class="table_head_text text-left">{{'timezone Command'|translate}}</th>
                <th class="table_head_text text-left">Action</th>
              </tr>
            </thead>
  
            <tbody *ngIf="ModelMasterList.length">
              <tr *ngFor="let model of ModelMasterList  | searchPipe : form.value.searchValue |  paginate : {
                  itemsPerPage: 10,
                  currentPage: page,
                  totalItems: (ModelMasterList  | searchPipe : form.value.searchValue).length
                };let i=index">
              
                <td class="table_data text-left">{{model.modelName}}</td>
                <td class="table_data text-left">{{model.apkCommand}}</td>
                <td class="table_data text-left">{{model.ipCommand}}</td>
                <td class="table_data text-left">{{model.timezoneCommand}}</td>
               
                <td class="table_data text-center" style="cursor: pointer;">
                  <!-- <div class="dropdown">
                    <button class="btn dropdown-toggle p-0 border-none" type="button" id="dropdownMenuButton1"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      <img src="/assets/icons/hollow_options.png" alt="car">
                    </button>
                    <ul class="dropdown-menu option_drop py-0" aria-labelledby="dropdownMenuButton1">
                      <li><a class="dropdown-item pt-2" style="justify-content: flex-start;" data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop" (click)="onEditModelMaster(model)">
                          <img src="/assets/icons/editgeo.png" alt="edit">
                          <span class="editDeletebtns" style="padding-left: 1.5vh;">{{'Edit'|translate}}</span>
                        </a></li>
                      <li><a class="dropdown-item py-2" style="justify-content: flex-start;"
                          (click)="onDeleteModelMaster(model._id)">
                          <img src="/assets/icons/deletegeo.png" alt="edit">
                          <span class="editDeletebtns" style="padding-left: 2.5vh;">{{'Delete'|translate}}</span>
                        </a></li>
                    </ul>
                  </div> -->
                </td>
              </tr>
            </tbody>
  
            <tbody *ngIf="!ModelMasterList.length">
              <tr>
                <td colspan="9">
                  <div class="d-flex justify-content-center align-items-center" style="height: 48vh;">
                    <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE">
                  </div>
                </td>
              </tr>
            </tbody>
  
          </table>
        </div>
        <!-- PAGINATION -->
        <div *ngIf="ModelMasterList.length" class="flex_row_center_spacebetween"
          style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
          <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{(ModelMasterList  | searchPipe : form.value.searchValue).length/10 | number: '1.0-0'}} {{'of'|translate}}
            {{(ModelMasterList  | searchPipe : form.value.searchValue).length}} {{'entries'|translate}}</div>
          <pagination-controls (pageChange)="page = $event"></pagination-controls>
        </div>
  
      </div>
    </div>
  </div>
  <div class="container-fluid " modeldata *ngIf="addMode">
    <form [formGroup]="addModelMasterForm" (ngSubmit)="onModelMasterSubmit()">

      <div class="row">
        <div class="col-6">
          <div class="form-group pb-2">
            <label for="manufacturer" class="addlabels pb-2">Model Name</label>
            <input type="text" formControlName="modelName" class="form-control inputfields"
              style="background: #FFF;box-shadow:none;color:#000;" id="modelName" aria-describedby="modelName">
            <span class="text-danger validation"
              *ngIf="(AddModelMasterControl.modelName.touched || submitted) && AddModelMasterControl.modelName.errors?.required">
              *Model Name is required.
            </span>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group pb-2">
            <label for="apkCommand" class="addlabels pb-2">{{'apk Command'|translate}}</label>
            <input type="text" formControlName="apkCommand" class="form-control inputfields"
              style="background: #FFF;box-shadow:none;color:#000;" id="apkCommand" aria-describedby="apkCommand">
            <span class="text-danger validation"
              *ngIf="(AddModelMasterControl.apkCommand.touched || submitted) && AddModelMasterControl.apkCommand.errors?.required">
              *apk Command is required.
            </span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group pb-2">
            <label for="ipCommand" class="addlabels pb-2">IP Command</label>
            <input type="text" formControlName="ipCommand" class="form-control inputfields"
              style="background: #FFF;box-shadow:none;color:#000;" id="ipCommand" aria-describedby="ipCommand">
            <span class="text-danger validation"
              *ngIf="(AddModelMasterControl.ipCommand.touched || submitted) && AddModelMasterControl.ipCommand.errors?.required">
              *IP Command is required.
            </span>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group pb-2">
            <label for="timezoneCommand" class="addlabels pb-2">Timezone Command</label>
            <input type="text" formControlName="timezoneCommand" class="form-control inputfields"
              style="background: #FFF;box-shadow:none;color:#000;" id="timezoneCommand" aria-describedby="timezoneCommand">
            <span class="text-danger validation"
              *ngIf="(AddModelMasterControl.timezoneCommand.touched || submitted) && AddModelMasterControl.timezoneCommand.errors?.required">
              *Timezone Command is required.
            </span>
          </div>
        </div>
      </div>

      

      <div class="d-flex align-items-center pt-4 pb-2">
        <button mat-raised-button  type="submit" class="cancelbtn">SUBMIT</button>
        <button mat-raised-button type="button" class="cancelbtn ms-3"
          (click)="onCloseModal()">{{'CANCEL'|translate}}</button>
        
       </div>

    </form>
  </div>
</app-common-modal-popup>
