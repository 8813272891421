import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardDataService } from 'src/app/services/dashboard-data.service';
import { ValidationService } from 'src/app/services/validation.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { environment } from '../../../environments/environment';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  reactiveForm: FormGroup;
  submitted = false;
  logoShow: boolean = true;
  loginShow: boolean = false;
  isPasswordVisiable = false;
  constructor(
    private fb: FormBuilder,
    private customValidator: ValidationService,
    private router: Router,
    private http: AuthService,
    private dash_http: DashboardDataService,
    private commonService: CommonService
  ) {
    this.reactiveForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      keepsignedin: [true],
    });
  }
  siteDetails :any;
  ngOnInit(): void {
    this.siteDetails = this.commonService.getSiteDetails();
    console.log('site details',this.siteDetails)
    // localStorage.setItem('token', 'dfsdfsdfsdfd');
    // localStorage.setItem('userId', 'dfsdfdsf');
    // if (localStorage.getItem('token')) {
    //   this.router.navigate(['/main/dashboard']);
    // }
    setTimeout(() => {
      this.logoShow = false;
      this.loginShow = true;
    }, 2000);

    // this.http.getRequest('devices/', []).subscribe((resp: any) => {
    //   console.log('--------------devices----devices', resp);
    // });

    this.http
      .getRequest('server?_dc=' + new Date().getTime(), [])
      .subscribe((resp: any) => {});
  }

  get registerFormControl() {
    return this.reactiveForm.controls;
  }
  
  getQueryParameter(name) {
    console.log('window.location.search', window.location.search);
    return (window.location.search.match('[?&]' + name + '=([^&]*)') || [])[1];
  }
  
  async onSubmit() {
    this.submitted = true;
    if (this.reactiveForm.valid) {
      const body = new HttpParams()
        .set('email', this.reactiveForm.value.username)
        .set('password', this.reactiveForm.value.password);

      this.commonService.postFormRequest('session', body).then((resp: any) => { 
        if (resp?.status) {
          if(resp?.data.attributes.role === 'driver'){
            Swal.fire({
              icon: "error",
              title: "Unauthorized user",
              showConfirmButton: false,
              timer: 1500,
            });
            return
          }
          if(resp?.data.attributes.role === 'client'){
            Swal.fire({
              icon: "error",
              title: "Unauthorized user",
              showConfirmButton: false,
              timer: 1500,
            });
            return
          }
          localStorage.setItem('userData', JSON.stringify(resp.data));
          localStorage.setItem('token', resp.data.token);
          localStorage.setItem(
            'socketBasicToken',
            btoa(
              this.reactiveForm.value.username +
                ':' +
                this.reactiveForm.value.password
            )
          );
          console.log(btoa(
            this.reactiveForm.value.username +
              ':' +
              this.reactiveForm.value.password
          ));
          this.router.navigateByUrl('/main/tracking');
        }
        else{
          Swal.fire({
            icon: "error",
            title: "Unauthorized user",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      },
      (err) => {
      });
    }
  }
}
