import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css'],
})
export class ForgotpasswordComponent implements OnInit {
  accountVerifyForm: FormGroup;
  resetForm: FormGroup;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private customValidator: ValidationService,
    private router: Router,
    private http: AuthService
  ) {
    this.accountVerifyForm = this.fb.group({
      verifyType: ['Email ID', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });

    this.resetForm = this.fb.group(
      {
        password: [
          '',
          Validators.compose([
            Validators.required,
            this.customValidator.patternValidator(),
          ]),
        ],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: this.customValidator.MatchPassword(
          'password',
          'confirmPassword'
        ),
      }
    );
  }

  ngOnInit(): void {
    document.addEventListener('DOMContentLoaded', (event) => {
      this.OTPInput();
    });
  }

  get registerFormControl() {
    return this.resetForm.controls;
  }

  OTPInput() {
    const inputs: any = document.querySelectorAll('#otp > *[id]');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('keydown', (event: any) => {
        console.log(event);
        if (event.key === 'Backspace') {
          inputs[i].classList.remove('inputDone');
          inputs[i].value = '';
          if (i !== 0) inputs[i - 1].focus();
        } else {
          inputs[i].classList.add('inputDone');
          if (i === inputs.length - 1 && inputs[i].value !== '') {
            return true;
          } else if (event.keyCode > 47 && event.keyCode < 58) {
            inputs[i].value = event.key;
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          } else if (event.keyCode > 64 && event.keyCode < 91) {
            inputs[i].value = String.fromCharCode(event.keyCode);
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          } else if (event.keyCode > 95 && event.keyCode < 106) {
            inputs[i].value = event.key;
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          }
        }
      });
    }
  }

  onSubmit(accountVerifyForm) {
    this.submitted = true;
    if (this.accountVerifyForm.valid) {
      console.log(accountVerifyForm.value);
      alert('Form Submitted succesfully!!!');
    }
  }

  onReset() {
    this.submitted = true;
    if (this.resetForm.valid) {
      console.log(this.resetForm.value);
      alert('Form Submitted succesfully!!!');
    }
  }
}
