<!-- <p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ {{'Licenses'|translate}}</span></p> -->
<app-common-modal-popup>
<div class="" modelheadertext *ngIf="gridMode">{{'Licenses'|translate}}</div>
<div class="" modelheadertext *ngIf="addMode">{{superAdmin ? ('Add license'|translate):('Purchase license'|translate)}}</div>
    <div class="container-fluid" modeldata *ngIf="gridMode">
        <div class="row p-0">
            <div class="card p-0 headcard">
                <!-- HEADER WITH ADD POI BTNS -->
                <div class="p-2 bg-light">
                    <div class="d-flex align-items-center justify-content-between"> 
                        <form [formGroup]="form">
                            <div class="input-group searchvehicles bg-white">

                                <input (keyup)="page =1" formControlName="searchValue" name="searchValue"
                                    type="text" class="form-control reportsbox" placeholder="{{'Search'|translate}}"
                                    autocomplete="off" />

                                <div class="input-group-append">
                                    <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh">
                                        <img (click)="searchData()" src="/assets/icons/searchicon.png" alt="search"
                                            style="width: 2.3vh" />
                                    </button>
                                </div>
                            </div>
                        </form>
                        <button *ngIf="superAdmin; else purchaseLicenseBtn" 
                            class="addbtns d-flex align-items-center p-2"
                            (click)="addLicenseButton()"
                            title="{{'Add license'|translate}}">
                            <span class="material-icons" style="font-size: 2.1vh">
                                add
                            </span>
                        <!-- {{'Add license'|translate}} -->
                        </button>
                        <ng-template #purchaseLicenseBtn>
                            <button class="addbtns d-flex align-items-center p-2"                            
                            (click)="purchaseLicense()"
                            title="{{'Purchase license'|translate}}">
                                <span class="material-icons" style="font-size: 2.1vh">
                                    add
                                </span>
                                <!-- {{'Purchase license'|translate}} -->                            
                            </button>
                        </ng-template>                    
                    </div>                   
                </div>
                <!-- DATE DOWNLOAD HEADER -->
                <div class="card-header flex_row_center_spacebetween">
                    <div class="flex_row_center_spacebetween">
                        <select class="form-select selectimportant" [(ngModel)]="pageOption" name="pageOption">
                            <option value="10" class="select_options">10</option>
                            <option value="20" class="select_options">20</option>
                            <option value="30" class="select_options">30</option>
                            <option value="40" class="select_options">40</option>
                            <option value="50" class="select_options">50</option>
                            <option value="60" class="select_options">60</option>
                        </select>
                    </div>
                    <div style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 27vh;
            ">
                        <div class="d-flex flex-row align-items-center" style="padding: 0.4vh 0 0 0">
                            <span class="addlabels">{{'Active'|translate}}</span>
                            <mat-slide-toggle style="padding-left: 1vh"></mat-slide-toggle>
                        </div>
                        <span (click)="getAllLicenses()" class="btn"><img src="/assets/icons/report_refresh_gray.png" alt="refresh"
                                style="width: 96%" /></span>
                        <span class="btn"><img src="/assets/icons/report_download_gray.png" alt="download"
                                style="width: 96%" /></span>
                    </div>
                </div>
                <!-- CUSTOMER TABLE -->
                <div class="card-body common_scroller fixed_height p-0">
                    <table class="table table-borderless m-0">
                        <thead class="bg-light">
                            <tr>
                                <th class="table_head_text">{{'Name'|translate}}</th>
                                <th class="table_head_text">{{'Order ID'|translate}}</th>
                                <th class="table_head_text">{{'Payment ID'|translate}}</th>
                                <th class="table_head_text">{{'Currency'|translate}}</th>
                                <th class="table_head_text">{{'Amount'|translate}}</th>
                                <th class="table_head_text">{{'Units'|translate}}</th>
                                <th class="table_head_text">{{'Date'|translate}}</th>
                                
                                <th class="table_head_text">{{'Status'|translate}}</th>
                                <th class="table_head_text">{{'RazorPay Status'|translate}}</th>
                            </tr>
                        </thead>

                        <tbody *ngIf="licensesList.length">
                            <tr *ngFor="let customer of licensesList  | searchPipe : form.value.searchValue |  paginate : {
                itemsPerPage: pageOption,
                currentPage: page,
                totalItems: (licensesList  | searchPipe : form.value.searchValue).length
                };let i=index">
                                <td class="table_data">{{ customer.userName ? customer.userName : '' }}</td>
                                <td class="table_data">{{ customer.orderId }}</td>
                                <td class="table_data">{{ customer.paymentId }}</td>
                                <td class="table_data">{{ customer.currency }}</td>
                                <td class="table_data">{{ customer.amount }}</td>
                                <td class="table_data">{{ customer.units }}</td>
                                <td class="table_data">{{ customer.transactiondate | date: 'dd/MM/yyyy hh:mm a'  }}</td>
                                <td class="table_data">{{ customer.status }}</td>
                                <td class="table_data">{{ customer.razorPayPaymentStatus }}</td>
                                <!-- <td class="table_data">
                    <div
                    class="btn btn-primary btn-sm mx-1"
                    (click)="edtUser(customer)"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    >
                    <i class="fa fa-edit"></i>
                    </div>
                    <div
                    class="btn btn-danger btn-sm mx-1"
                    (click)="deleteUser(customer.id)"
                    >
                    <i class="fa fa-trash"></i>
                    </div>
                    
                </td> -->
                            </tr>
                        </tbody>

                        <tbody *ngIf="!licensesList.length">
                            <tr>
                                <td colspan="7">
                                    <div class="d-flex justify-content-center align-items-center" style="height: 48vh">
                                        <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="licensesList.length" class="flex_row_center_spacebetween"
                    style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
                    <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{(licensesList | searchPipe : form.value.searchValue).length/pageOption |
                        number: '1.0-0'}} {{'of'|translate}}
                        {{(licensesList | searchPipe : form.value.searchValue).length}} {{'entries'|translate}}</div>
                    <pagination-controls (pageChange)="page = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid" modeldata *ngIf="addMode">
        <form name="licensesForm" >
            <div class="row">
                <div class="col-12 col-md-6" *ngIf="superAdmin">
                    <ng-container *ngIf="superAdmin">
                        <label for="userId" class="">{{'Dealer'|translate}}</label>
                        <div class="search-dealer bg-light px-1 py-1">
                        <mat-form-field  class="example-full-width" appearance="fill">
                        
                            <input  type="text" placeholder="Select Dealer" aria-label="Number" matInput 
                            [formControl]="myDealerControl"
                              [matAutocomplete]="auto" />
                            <mat-autocomplete autoActiveFirstOption [displayWith]="displayFn" #auto="matAutocomplete"
                              (optionSelected)="dealerSelected($event.option.value)">
                              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option.name}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                    </div>
                    <span class="text-danger validation" *ngIf="errors?.userId">
                            {{ errors.userId }}
                        </span>
                    </ng-container>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group pb-2">
                        <label for="name" class="pb-2">{{'Single Unit Amount'|translate}}</label>
                        <input disabled type="number" [value]="addLicensesForm.amount" name="amount"
                            class="form-control " id="amount" aria-describedby="amount" />
                        <span class="text-danger validation" *ngIf="errors?.amount">
                            {{ errors.amount }}
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group pb-2">
                        <label for="name" class="pb-2">{{'Currency'|translate}}</label>
                        <input name="currency" type="text" disabled [value]="addLicensesForm.currency"
                            class="form-control " id="identifier" aria-describedby="identifier" />
                        <span class="text-danger validation" *ngIf="errors?.currency">
                            {{ errors.currency }}
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group pb-2">
                        <label for="name" class="pb-2">{{'Count'|translate}}</label>
                        <input name="devicecount" (change)="onChangeCount()" type="number" min="0" max="100" [(ngModel)]="addLicensesForm.devicecount"
                            class="form-control " id="identifier" aria-describedby="identifier" />
                        <span class="text-danger validation" *ngIf="errors?.devicecount">
                            {{ errors.devicecount }}
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group pb-2">
                        <label for="name" class="pb-2">{{'Tax'|translate}} (%)</label>
                        <input disabled type="number" min="0"  [(ngModel)]="addLicensesForm.tax" name="tax"
                            class="form-control " id="identifier" aria-describedby="identifier" />
                        <span class="text-danger validation" *ngIf="errors?.tax">
                            {{ errors.tax }}
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group pb-2">
                        <label for="name" class="pb-2">{{'Total'|translate}}</label>
                        <input disabled type="number" min="0"  [(ngModel)]="addLicensesForm.total" name="total"
                            class="form-control " id="identifier" aria-describedby="identifier" />
                        <span class="text-danger validation" *ngIf="errors?.total">
                            {{ errors.total }}
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-6" *ngIf="superAdmin">
                    <div *ngIf="superAdmin" class="form-group pb-2">
                        <label for="name" class="pb-2">{{'Status'|translate}}</label>
                        <select
                        name="status"
                        class="form-control"
                        [(ngModel)]="addLicensesForm.status"
                        
                        >
                        <option value="" class="select_options">Select</option>
                        <option value="Completed" class="select_options">Completed</option>
                        <option value="Pending" class="select_options">Pending</option>
                        </select>
                        <span class="text-danger validation" *ngIf="errors?.status">
                            {{ errors.status }}
                        </span>
                    </div>
                </div>
                
            </div>             
            <button type="button" class="btn btn-primary" (click)="onOrderPlace()"> {{superAdmin ? ('Save' |translate) : ('Checkout'|translate)}}</button>
            <button type="button" class="btn btn-secondary ms-3" (click)="onCancelClick()"> Cancel </button>
        </form>
    </div>
</app-common-modal-popup>

<!-- <div class="modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content" style="border: none">
            <div class="modal-header addtriphead">
                <div class="headtext">
                    {{superAdmin ? ('Add license'|translate):('Purchase license'|translate)}}
                </div>
                <button type="button" class="btnclose" data-bs-dismiss="modal" aria-label="Close">
                    <span class="material-icons"> close </span>
                </button>
            </div>
            <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
                <form name="licensesForm" >
                    
                    <ng-container *ngIf="superAdmin">
                        <label for="userId" class="">{{'Dealer'|translate}}</label>
                    <div class="search-dealer">
                        <mat-form-field  class="example-full-width" appearance="fill">
                        
                            <input  type="text" placeholder="Select Dealer" aria-label="Number" matInput 
                            [formControl]="myDealerControl"
                              [matAutocomplete]="auto" />
                            <mat-autocomplete autoActiveFirstOption [displayWith]="displayFn" #auto="matAutocomplete"
                              (optionSelected)="dealerSelected($event.option.value)">
                              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option.name}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                    </div>
                    <span class="text-danger validation" *ngIf="errors?.userId">
                            {{ errors.userId }}
                        </span>
                    </ng-container>
                    
                    <div class="form-group pb-2">
                        <label for="name" class="pb-2">{{'Single Unit Amount'|translate}}</label>
                        <input disabled type="number" [value]="addLicensesForm.amount" name="amount"
                            class="form-control " id="amount" aria-describedby="amount" />
                        <span class="text-danger validation" *ngIf="errors?.amount">
                            {{ errors.amount }}
                        </span>
                    </div>
                    <div class="form-group pb-2">
                        <label for="name" class="pb-2">{{'Currency'|translate}}</label>
                        <input name="currency" type="text" disabled [value]="addLicensesForm.currency"
                            class="form-control " id="identifier" aria-describedby="identifier" />
                        <span class="text-danger validation" *ngIf="errors?.currency">
                            {{ errors.currency }}
                        </span>
                    </div>
                    <div class="form-group pb-2">
                        <label for="name" class="pb-2">{{'Count'|translate}}</label>
                        <input name="devicecount" (change)="onChangeCount()" type="number" min="0" max="100" [(ngModel)]="addLicensesForm.devicecount"
                            class="form-control " id="identifier" aria-describedby="identifier" />
                        <span class="text-danger validation" *ngIf="errors?.devicecount">
                            {{ errors.devicecount }}
                        </span>
                    </div>
                    <div class="form-group pb-2">
                        <label for="name" class="pb-2">{{'Tax'|translate}} (%)</label>
                        <input disabled type="number" min="0"  [(ngModel)]="addLicensesForm.tax" name="tax"
                            class="form-control " id="identifier" aria-describedby="identifier" />
                        <span class="text-danger validation" *ngIf="errors?.tax">
                            {{ errors.tax }}
                        </span>
                    </div>
                    <div class="form-group pb-2">
                        <label for="name" class="pb-2">{{'Total'|translate}}</label>
                        <input disabled type="number" min="0"  [(ngModel)]="addLicensesForm.total" name="total"
                            class="form-control " id="identifier" aria-describedby="identifier" />
                        <span class="text-danger validation" *ngIf="errors?.total">
                            {{ errors.total }}
                        </span>
                    </div>
                    <div *ngIf="superAdmin" class="form-group pb-2">
                        <label for="name" class="pb-2">{{'Status'|translate}}</label>
                        <select
                        name="status"
                        class="form-control"
                        [(ngModel)]="addLicensesForm.status"
                        
                        >
                        <option value="" class="select_options">Select</option>
                        <option value="Completed" class="select_options">Completed</option>
                        <option value="Pending" class="select_options">Pending</option>
                        </select>
                        <span class="text-danger validation" *ngIf="errors?.status">
                            {{ errors.status }}
                        </span>
                    </div>
                    <button type="button" class="btn btn-primary" (click)="onOrderPlace()"> {{superAdmin ? ('Save' |translate) : ('Checkout'|translate)}}</button>
                </form>
            </div>
        </div>
    </div>
</div> -->
<div style="display:none" class="novopay-response-modal modal fade" role="dialog"     [ngClass]="{'show': showModal}"
  id="response-modal"
	[ngStyle]="{'display': 'none'}">
    {{response}}
	</div>

<div style="display: none" class="novopay-response-modal modal fade" role="dialog"     [ngClass]="{'show': showModal}"
  id="razorpay-response"
	[ngStyle]="{'display': 'none'}">
    {{razorpayResponse}}
	</div>