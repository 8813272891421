<p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ Route name</span></p>

    <div class="container-fluid ">
    
      <div class="row p-0" *ngIf="RouteTable">
        <div class="card p-0 headcard">
    
          <!-- HEADER WITH ADD POI BTNS -->
          <div class="card-header1 flex_row_center_spacebetween">
            <div class="cardHead_text">Route Name</div>
            <ul class="nav nav-pills card-header-pills">
              <li class="nav-item">
                <div style="display: flex;flex-direction: row;">
                  <button mat-raised-button class="addbtns px-3 py-1" style="margin-right: 1vh;" (click)="onAddRouteBtn()">
                    <span class="material-icons" style="font-size: 2.1vh;"> add</span> Create route
                  </button>
                  <div class="input-group searchvehicles">
                    <input type="text" class="form-control reportsbox" placeholder="{{'Search'|translate}}"
                      aria-label="Recipient's username" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh;">
                        <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
    
          <!-- DATE DOWNLOAD HEADER -->
          <div class="card-header flex_row_center_spacebetween">
            <div class="flex_row_center_spacebetween" style="width: 32vh;">
              <select class="form-select selectimportant" [(ngModel)]="pageOption" name="pageOption">
                <option value="10" class="select_options">10</option>
                <option value="20" class="select_options">20</option>
                <option value="30" class="select_options">30</option>
                <option value="40" class="select_options">40</option>
                <option value="50" class="select_options">50</option>
                <option value="60" class="select_options">60</option>
              </select>
              <select class="form-select device" [(ngModel)]="routestatus" name="routestatus">
                <option value="" class="select_options" disabled>Route Status</option>
                <option value="Active" class="select_options">Active</option>
                <option value="Inactive" class="select_options">Inactive</option>
                <option value="Restricted" class="select_options">Restricted</option>
              </select>
            </div>
            <div style="display: flex;flex-direction: row;align-items: center;">
              <span class="btn"><img src="/assets/icons/report_refresh.png" alt="refresh" style="width: 96%;"></span>
              <span class="btn"><img src="/assets/icons/report_download.png" alt="download" style="width: 96%;"></span>
            </div>
          </div>
    
          <!-- POI LIST TABLE -->
          <div class="card-body common_scroller fixed_height p-0">
            <table class="table table-borderless m-0" style="border-bottom: 1px solid #E3E7EC;">
    
              <thead style="background-color:  #24282E;">
                <tr>
                  <th class="table_head_text text-center" style="width: 10%;">Route Name</th>
                  <th class="table_head_text text-center" style="width: 31%;">Source</th>
                  <th class="table_head_text text-center">Destination</th>
                  <th class="table_head_text text-center">Route status</th>
                  <th class="table_head_text text-center">Speed Alert (Kms.)</th>
                  <th class="table_head_text text-center">Action</th>
                </tr>
              </thead>
    
              <tbody *ngIf="routeList.length">
                <tr *ngFor="let route of routeList;let i = index">
                  <td class="table_data text-center">{{route.name}}</td>
                  <td class="table_data text-center">{{route.source}}</td>
                  <td class="table_data text-center">{{route.destination}}</td>
                  <td class="table_data text-center">{{route.status}}</td>
                  <td class="table_data text-center">{{route.speedAlerts}}</td>
                  <td class="table_data text-center" style="cursor: pointer;">
                    <div class="dropdown">
                      <button class="btn dropdown-toggle p-0 border-none" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="/assets/icons/hollow_options.png" alt="car">
                      </button>
                      <ul class="dropdown-menu option_drop py-0" aria-labelledby="dropdownMenuButton1">
                        <li><a class="dropdown-item pt-2" style="justify-content: flex-start;">
                            <img src="/assets/icons/editgeo.png" alt="edit">
                            <span class="editDeletebtns" style="padding-left: 1.5vh;">{{'Edit'|translate}}</span>
                          </a></li>
                        <li><a class="dropdown-item py-2" style="justify-content: flex-start;">
                            <img src="/assets/icons/deletegeo.png" alt="edit">
                            <span class="editDeletebtns" style="padding-left: 2.5vh;">{{'Delete'|translate}}</span>
                          </a></li>
                        <li><a class="dropdown-item pt-2" style="justify-content: flex-start;" data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop">
                            <img src="/assets/icons/table_vehicle.png" alt="edit">
                            <span class="editDeletebtns" style="padding-left: 1.5vh;">Tracked Vehicles</span>
                          </a></li>
                        <li><a class="dropdown-item py-2" style="justify-content: flex-start;">
                            <img src="/assets/icons/route.png" alt="edit">
                            <span class="editDeletebtns" style="padding-left: 2.5vh;">View Route</span>
                          </a></li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>

              <tbody *ngIf="!routeList.length">
                <tr>
                  <td colspan="6">
                    <div class="d-flex justify-content-center align-items-center" style="height: 48vh;">
                      <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE">
                    </div>
                  </td>
                </tr>
              </tbody>
    
            </table>
            <div *ngIf="routeList.length" class="flex_row_center_spacebetween" style="padding: 1vh 2vh;">
              <div>{{'Showing'|translate}} 1 to 9 of 9 entries</div>
              <div>1 2 3 4 6 7 8 9 10</div>
            </div>
          </div>
    
        </div>
      </div>
    
      <div class="row p-0" *ngIf="!RouteTable">
    
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 first_col" id="routeForm">
          <div class="card addmain_card p-0">
            <div class="addgeohead" style="padding-bottom: 0.75rem;">Create Route</div>
            <div class="card geofence_card2" style="height: 73.1vh;">
              <form [formGroup]="addRouteForm" (ngSubmit)="onRouteSubmit()">
    
                <div class="form-group pb-2">
                  <label for="sourcename" class="addlabels pb-2">Source</label>
                  <input type="text" formControlName="sourcename" class="form-control inputfields" id="sourcename"
                    aria-describedby="sourcename">
                  <span class="text-danger validation"
                    *ngIf="(RouteFormControl.sourcename.touched || submitted) && RouteFormControl.sourcename.errors?.required">
                    *Sourcename is required.
                  </span>
                </div>
    
                <div class="form-group pb-2">
                  <label for="destination" class="addlabels pb-2">Destination</label>
                  <input type="text" class="form-control inputfields" id="destination" formControlName="destination"
                    aria-describedby="destination">
                  <span class="text-danger validation"
                    *ngIf="(RouteFormControl.destination.touched || submitted) && RouteFormControl.destination.errors?.required">
                    *Destination is required.
                  </span>
                </div>
    
                <div class="form-group pb-2">
                  <label for="address" class="addlabels pb-2">Add Address</label>
                  <input type="text" class="form-control inputfields" id="address" formControlName="address"
                    aria-describedby="address">
                  <span class="text-danger validation"
                    *ngIf="(RouteFormControl.address.touched || submitted) && RouteFormControl.address.errors?.required">
                    *Address is required.
                  </span>
                </div>
    
                <div class="form-group pb-2">
                  <label for="routename" class="addlabels pb-2">Route Name</label>
                  <input type="text" formControlName="routename" class="form-control inputfields" id="routename"
                    aria-describedby="routename">
                  <span class="text-danger validation"
                    *ngIf="(RouteFormControl.routename.touched || submitted) && RouteFormControl.routename.errors?.required">
                    *Routename is required.
                  </span>
                </div>
    
                <div class="form-group pb-2">
                  <label for="vehiclename" class="addlabels pb-2">Vehicle Name</label>
                  <select class="form-select form_select1" formControlName="vehiclename">
                    <option value="" selected>Select Vehicle</option>
                    <option value="DL12CG1001" selected>DL12CG1001</option>
                    <option value="DL12CG1001">DL12CG1001</option>
                    <option value="DL12CG1001">DL12CG1001</option>
                    <option value="DL12CG1001">DL12CG1001</option>
                    <option value="DL12CG1001">DL12CG1001</option>
                  </select>
                </div>
    
                <div class="row pb-3 pt-2">
                  <div class="col-6"
                    style="display: flex;flex-direction: row;justify-content:space-between;align-items: center;">
                    <mat-checkbox formControlName="checkhalt" matTooltip="Speed Alert"></mat-checkbox>
                    <div class="addlabels">Speed Alert</div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <div class="input-group inputborder">
                        <input type="number" formControlName="time" class="form-control inputfields" style="border:none;"
                          aria-describedby="basic-addon2">
                        <div class="input-group-append">
                          <span class="input-group-text searchIcon" style="padding: 1.1vh;color: #fff;"
                            id="basic-addon2">Km/Hr</span>
                        </div>
                      </div>
                    </div>
                    <span class="text-danger validation"
                      *ngIf="(RouteFormControl.time.touched || submitted) && RouteFormControl.time.errors?.required">
                      *Km/Hr is required
                    </span>
                  </div>
                </div>
    
                <div class="form-group pb-2">
                  <label for="totaldistance" class="addlabels pb-2">Total Distance</label>
                  <input type="number" formControlName="totaldistance" class="form-control inputfields" id="totaldistance"
                    aria-describedby="totaldistance">
                  <span class="text-danger validation"
                    *ngIf="(RouteFormControl.totaldistance.touched || submitted) && RouteFormControl.totaldistance.errors?.required">
                    *Total distance is required.
                  </span>
                </div>
    
                <div class="row" style="padding-top: 2vh;">
                  <div class="col-6">
                    <button mat-raised-button type="submit" class="submitbtn">CREATE</button>
                  </div>
                  <div class="col-6">
                    <button mat-raised-button class="cancelbtns">SHOW ROUTE</button>
                  </div>
                </div>
    
              </form>
            </div>
          </div>
        </div>
    
        <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-12 col_map" id="myMap"> 
    
          <!-- GOOGLE MAP -->
          <div class="card main_card p-0">
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
              <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
            </agm-map>
          </div>
    
          <!-- MAP RIGHT ICONS -->
          <div class="btn-group-vertical" role="group" aria-label="Basic example"
            style="position: absolute;top: 2vh;right: 3vh;">
            <button matTooltip="Back" type="button" class="btn mapbtns" style="margin-top: 1vh;border-radius: 5px;padding-bottom: 0;" (click)="onClickBack()">
              <span class="material-icons" style="color: #fff;"> 
                arrow_back
              </span>
            </button>
            <button type="button" class="btn mapbtns" style="margin-top: 1vh;border-radius: 5px;">
              <img src="/assets/icons/map1.png" alt="mapicon">
            </button>
            <button type="button" class="btn mapbtns" style="margin-top: 1vh;border-radius: 5px;">
              <img src="/assets/icons/map4.png" alt="mapicon">
            </button>
            <button type="button" class="btn mapbtns" style="margin-top: 1vh;border-radius: 5px;">
              <img src="/assets/icons/map2.png" alt="mapicon">
            </button>
            <button type="button" class="btn mapbtns openbtn" style="margin-top: 1vh;border-radius: 5px;">
              <img src="/assets/icons/map6.png" alt="mapicon">
            </button>
          </div>
    
          <!-- MAP BOTTOM ICONS -->
          <div class="btn-group" role="group" aria-label="Basic example" style="position: absolute;bottom: 3vh;left: 4vh;">
            <button type="button" class="btn mapbtns" style="margin-left: 2vh;border-radius: 5px;padding: 0.5vh 0.8vh;">
              <img src="/assets/icons/map8.png" alt="mapicon">
            </button>
            <button type="button" class="btn mapbtns" style="margin-left: 2vh;border-radius: 5px;padding: 0.5vh 0.8vh;">
              <img src="/assets/icons/map9.png" alt="mapicon">
            </button>
            <button type="button" class="btn mapbtns" style="margin-left: 2vh;border-radius: 5px;padding: 0.5vh 0.8vh;"
              (click)="getFullWidth()">
              <img src="/assets/icons/map10.png" alt="mapicon">
            </button>
          </div>
    
        </div>
    
      </div>
    
    </div>
    
<!-- UNTRACKED VEHICLES MODAL -->
    
    <div class="modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content content1">
          <div class="modal-header headerModal">
    
            <select class="form-select selectimportant" [(ngModel)]="pageOptionModal" name="pageOptionModal">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
              <option value="60">60</option>
            </select>
    
            <div class="input-group searchvehicles">
              <input type="text" class="form-control reportsbox" placeholder="Search Vehicle"
                aria-label="Recipient's username" aria-describedby="basic-addon2">
              <div class="input-group-append">
                <button class="btn" type="button" style="padding:0.6vh 1.4vh 1.1vh 1.4vh;">
                  <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
                </button>
              </div>
            </div>
    
            <button type="button" class="btnclose" data-bs-dismiss="modal" aria-label="Close">
              <span class="material-icons">
                close
              </span>
            </button>
          </div>
          <div class="modal-body p-0 headerModal" style="background: #FFF;border-radius: 5px;">
            <table class="table table-borderless m-0">
              <thead style="background-color:  #24282E;">
                <tr>
                  <th class="table_head_text text-center" style="width: 1%;">Select</th>
                  <th class="table_head_text text-center">Vehicle Name</th>
                  <th class="table_head_text text-center">IMEI</th>
                </tr>
              </thead>
    
              <tbody>
                <tr>
                  <td class="table_data text-center" style="background: rgba(36, 40, 46, 0.1);"><input type="checkbox"></td>
                  <td class="table_data text-center">GJ14FT5326</td>
                  <td class="table_data text-center">234kelbn2d37dfiuef2</td>
                </tr>
                <tr>
                  <td class="table_data text-center" style="background: rgba(36, 40, 46, 0.1);"><input type="checkbox"></td>
                  <td class="table_data text-center">GJ14FT5326</td>
                  <td class="table_data text-center">234kelbn2d37dfiuef2</td>
                </tr>
                <tr>
                  <td class="table_data text-center" style="background: rgba(36, 40, 46, 0.1);"><input type="checkbox"></td>
                  <td class="table_data text-center">GJ14FT5326</td>
                  <td class="table_data text-center">234kelbn2d37dfiuef2</td>
                </tr>
                <tr>
                  <td class="table_data text-center" style="background: rgba(36, 40, 46, 0.1);"><input type="checkbox"></td>
                  <td class="table_data text-center">GJ14FT5326</td>
                  <td class="table_data text-center">234kelbn2d37dfiuef2</td>
                </tr>
                <tr>
                  <td class="table_data text-center" style="background: rgba(36, 40, 46, 0.1);"><input type="checkbox"></td>
                  <td class="table_data text-center">GJ14FT5326</td>
                  <td class="table_data text-center">234kelbn2d37dfiuef2</td>
                </tr>
                <tr>
                  <td class="table_data text-center" style="background: rgba(36, 40, 46, 0.1);"><input type="checkbox"></td>
                  <td class="table_data text-center">GJ14FT5326</td>
                  <td class="table_data text-center">234kelbn2d37dfiuef2</td>
                </tr>
                <tr>
                  <td class="table_data text-center" style="background: rgba(36, 40, 46, 0.1);"><input type="checkbox"></td>
                  <td class="table_data text-center">GJ14FT5326</td>
                  <td class="table_data text-center">234kelbn2d37dfiuef2</td>
                </tr>
                <tr>
                  <td class="table_data text-center" style="background: rgba(36, 40, 46, 0.1);"><input type="checkbox"></td>
                  <td class="table_data text-center">GJ14FT5326</td>
                  <td class="table_data text-center">234kelbn2d37dfiuef2</td>
                </tr>
                <tr>
                  <td class="table_data text-center" style="background: rgba(36, 40, 46, 0.1);"><input type="checkbox"></td>
                  <td class="table_data text-center">GJ14FT5326</td>
                  <td class="table_data text-center">234kelbn2d37dfiuef2</td>
                </tr>
              </tbody>
    
            </table>
            <div class="flex_row_center_spacebetween" style="padding: 2vh;border-top: 1px solid #E3E7EC;">
              <div>{{'Showing'|translate}} 1 to 9 of 9 entries</div>
              <div>1 2 3 4 6 7 8 9 10</div>
            </div>
          </div>
          <div class="flex_row_center_spacebetween">
            <button mat-raised-button class="cancelbtn">{{'CANCEL'|translate}}</button>
            <button mat-raised-button class="cancelbtn">{{'UPDATE'|translate}}</button>
          </div>
        </div>
      </div>
    </div>

