<p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ {{'Driver Master'|translate}}</span></p>

<div class="container-fluid ">
  <div class="row p-0">
    <div class="card p-0 headcard">

      <!-- HEADER WITH ADD POI BTNS -->
      <div class="card-header1 flex_row_center_spacebetween">
        <div class="cardHead_text">{{'Driver Master'|translate}}</div>
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item">
            <div style="display: flex;flex-direction: row;">
              <button mat-raised-button class="addbtns px-3 py-1" style="margin-right: 1vh;" data-bs-toggle="modal"
              data-bs-target="#staticBackdrop" (click)="onAddDealer()">
                <span class="material-icons" style="font-size: 2.1vh;">
                  add
                </span> {{'Add Driver'|translate}}
              </button>
              <div class="input-group searchvehicles">
                <input type="text" class="form-control reportsbox" placeholder="{{'Search'|translate}}" ([ngModel])="searchdealer" (input)="values($event)"
                name="searchdealer" aria-label="Recipient's username" aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh;">
                    <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- DATE DOWNLOAD HEADER -->
      <div class="card-header flex_row_center_spacebetween">
        <div class="flex_row_center_spacebetween" style="width: 47vh;">
          <input type="date" id="meeting" value="2022-03-26" [(ngModel)]="fromdate" name="fromdate" />
          <span class="to">{{'To'|translate}}</span>
          <input type="date" id="meeting" value="2022-03-27" [(ngModel)]="todate" name="todate" />
          <select class="form-select selectimportant" [(ngModel)]="pageOption" name="pageOption">
            <option value="10" class="select_options">10</option>
            <option value="20" class="select_options">20</option>
            <option value="30" class="select_options">30</option>
            <option value="40" class="select_options">40</option>
            <option value="50" class="select_options">50</option>
            <option value="60" class="select_options">60</option>
          </select>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;">
          <span class="btn"><img src="/assets/icons/report_refresh.png" alt="refresh" style="width: 96%;"></span>
          <span class="btn"><img src="/assets/icons/report_download.png" alt="download" style="width: 96%;"></span>
        </div>
      </div>
      <!-- DRIVER MASTER TABLE -->
      <div class="card-body common_scroller fixed_height p-0">
        <table class="table table-borderless m-0" >

          <thead style="background-color:  #24282E;">
            <tr>
              <th class="table_head_text text-center">{{'Driver Name'|translate}}</th>
              <th class="table_head_text text-center">{{'Driver Address'|translate}}</th>
              <th class="table_head_text text-center">{{'Driver Status'|translate}}</th>
              <th class="table_head_text text-center">{{'License Number'|translate}}</th>
              <th class="table_head_text text-center">{{'Salary'|translate}}</th>
              <th class="table_head_text text-center">{{'DOJ'|translate}}</th>
              <th class="table_head_text text-center">{{'Action'|translate}}</th>
            </tr>
          </thead>

          <tbody *ngIf="driverMasterList.length">
            <tr *ngFor="let master of driverMasterList |  paginate : {
                itemsPerPage: 10,
                currentPage: page,
                totalItems: this.driverMasterList.length
              };let i=index">
              <td class="table_data text-center">{{master.name}}</td>
              <td class="table_data text-center">{{master.address}}</td>
              <td class="table_data text-center">{{master.status}}</td>
              <td class="table_data text-center">{{master.license_number}}</td>
              <td class="table_data text-center">{{master.salary}}</td>
              <td class="table_data text-center">{{master.date_of_joining | date: 'medium'}}</td>
              <td class="table_data text-center" style="cursor: pointer;">
                <div class="dropdown">
                  <button class="btn dropdown-toggle p-0 border-none" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="/assets/icons/hollow_options.png" alt="car">
                  </button>
                  <ul class="dropdown-menu option_drop py-0" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item pt-2" style="justify-content: flex-start;" data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop" (click)="onEditDriver(master)">
                        <img src="/assets/icons/editgeo.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 1.5vh;">{{'Edit'|translate}}</span>
                      </a></li>
                    <li><a class="dropdown-item py-2" style="justify-content: flex-start;" (click)="onDeleteDriver(master._id)">
                        <img src="/assets/icons/deletegeo.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 2.5vh;">{{'Delete'|translate}}</span>
                      </a></li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!driverMasterList.length">
            <tr>
              <td colspan="7">
                <div class="d-flex justify-content-center align-items-center" style="height: 48vh;">
                  <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE">
                </div>
              </td>
            </tr>
          </tbody>

        </table>
      </div>
      <!-- PAGINATION -->
      <div *ngIf="driverMasterList.length" class="flex_row_center_spacebetween"
        style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
        <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{driverMasterList.length/10 | number: '1.0-0'}} {{'of'|translate}}
          {{driverMasterList.length}} {{'entries'|translate}}</div>
        <pagination-controls (pageChange)="page = $event"></pagination-controls>
      </div>

    </div>
  </div>
</div>

<!-- ADD DRIVER MASTER MODAL -->

<div class="modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content content1">
      <div class="modal-header addtriphead">
        <div class="headtext" *ngIf="!editForm">{{'Add Driver'|translate}}</div>
        <div class="headtext" *ngIf="editForm">{{'Edit Driver'|translate}}</div>
        <button type="button" class="btnclose" data-bs-dismiss="modal" aria-label="Close" (click)="onCloseModal()">
          <span class="material-icons">
            close
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="addDriverMasterForm" (ngSubmit)="onDriverMasterSubmit()">

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="drivername" class="addlabels pb-2">{{'Driver Name'|translate}}</label>
                <input type="text" formControlName="drivername" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="drivername"
                  aria-describedby="drivername">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.drivername.touched || submitted) && AddDriverFormControl.drivername.errors?.required">
                  *{{'Driver Name'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="status" class="addlabels pb-2">{{'Status'|translate}}</label>
                <select class="form-select form_select1" style="background-color: #FFF;box-shadow:none;color:#000;height: 5.6vh;" id="status" formControlName="status">
                  <option value="Active">Active</option>
                  <option value="InActive">InActive</option>
                </select>
                <span class="text-danger validation"
                *ngIf="(AddDriverFormControl.status.touched || submitted) && AddDriverFormControl.status.errors?.required">
                *{{'Status'|translate}} {{'is required'|translate}}.
              </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="driveraddress1" class="addlabels pb-2">{{'Driver Address'|translate}} 01</label>
                <input type="text" formControlName="driveraddress1" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="driveraddress1"
                  aria-describedby="driveraddress1">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.driveraddress1.touched || submitted) && AddDriverFormControl.driveraddress1.errors?.required">
                  *{{'Driver Address'|translate}}1 {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="driveraddress2" class="addlabels pb-2">{{'Driver Address'|translate}} 02</label>
                <input type="text" formControlName="driveraddress2" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="driveraddress2"
                  aria-describedby="driveraddress2">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.driveraddress2.touched || submitted) && AddDriverFormControl.driveraddress2.errors?.required">
                  *{{'Driver Address'|translate}}2 {{'is required'|translate}}.
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="licencenumber" class="addlabels pb-2">{{'License Number'|translate}}</label>
                <input type="number" formControlName="licencenumber" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="licencenumber"
                aria-describedby="licencenumber">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.licencenumber.touched || submitted) && AddDriverFormControl.licencenumber.errors?.required">
                  *{{'License Number'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="contactnumber" class="addlabels pb-2">{{'Contact Number'|translate}}</label>
                <input type="number" formControlName="contactnumber" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="contactnumber"
                aria-describedby="contactnumber">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.contactnumber.touched || submitted) && AddDriverFormControl.contactnumber.errors?.required">
                  *{{'Contact Number'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="salary" class="addlabels pb-2">{{'Salary'|translate}}</label>
                <input type="number" formControlName="salary" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="salary"
                  aria-describedby="salary">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.salary.touched || submitted) && AddDriverFormControl.salary.errors?.required">
                  *{{'Salary'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="djo" class="addlabels pb-2">{{'Date Of Joining'|translate}}</label>
                <input type="date" id="djo" formControlName="djo" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;flex-flow: row !important;width: 100% !important;height: 5.6vh !important;">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.djo.touched || submitted) && AddDriverFormControl.djo.errors?.required">
                  *{{'Date Of Joining'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
          </div>

          <div class="flex_row_center_spacebetween pt-4 pb-2">
            <button mat-raised-button type="button" class="cancelbtn" data-bs-dismiss="modal" (click)="onCloseModal()">{{'CANCEL'|translate}}</button>
            <button mat-raised-button *ngIf="!editForm" type="submit" class="cancelbtn">{{'SAVE' | translate}}</button>
            <button mat-raised-button *ngIf="editForm" type="submit" class="cancelbtn">{{'UPDATE'|translate}}</button>
          </div>

        </form>

      </div>
    </div>
  </div>
</div>


