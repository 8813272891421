<nav class="navbar navbar-expand-sm navbar-dark bg-light fixed-top p-0">
  <div class="container-fluid">
    <a class="navbar-brand" href="javascript:void(0)">
      <div class="profile-info">
        <div class="">
          <img src="/assets/images/ptrack_logo2.jpeg" class="" alt="logo" routerLink="/main/dashboard">
     
        </div>
      </div>
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div style="text-align: -webkit-right;" [class.is_Back_To_Admin]="isBackToAdmin" class=" 
        p-0
          offset-lg-0 offset-md-0 offset-sm-0
        col-xl-3 col-xxl-3 col-lg-3 col-md-6 col-6
        order-3
      ">
      
      <div class="user_profile">
        <button *ngIf="isBackToAdmin" (click)="backToAdmin()" title="Back To Admin" type="button" class="m-2 btn btn-outline-dark"> <i class="fa fa-sign-out pl-2" aria-hidden="true"></i></button>
        <div class="user-text">
          <span class="username d-flex">{{ username }}</span>
        </div>

        <div class="dropdown">
          <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false">
            <img src="/assets/images/profile.png" style="width: 5vh;" alt="profile" matTooltip="Profile" />
          </button>
          <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1">
            <li>
              <a class="dropdown-item" style="border-top: 3px solid #fff"
              (click)="openModel('changepassword')">
                <img src="/assets/icons/changepassword.png" alt="changepassword" class="navIcons" />
                <span class="changepassword" title="{{'Change Password'|translate}}"> {{'Change Password'|translate}} </span>
              </a>
            </li>
           
            <li>
              <a class="dropdown-item" routerLink="/main/accountssettings">
                <img src="/assets/icons/setting.png" alt="changepassword" class="navIcons" />
                <span class="changepassword" title="{{'Account Setting'|translate}}">{{'Account Setting'|translate}} </span>
              </a>
            </li>
            <li>
              <a class="dropdown-item" style="border-top: 3px solid #fff" (click)="onLogout()">
                <img src="/assets/icons/logout.png" alt="changepassword" class="navIcons" />
                <span class="changepassword" title="{{'Logout'|translate}}">{{'Logout'|translate}} </span>
              </a>
            </li>
          </ul>
        </div>
        
      </div>
      </div>
    <div class="collapse navbar-collapse" id="mynavbar">
      <ul class="navbar-nav me-auto d-flex align-items-center">
        <li class="nav-item me-4">
          <a
            routerLink="/main/dashboard"
            class="default"
            style="display: block"
            title="{{'Dashboard'|translate}}"            
          >
            <img src="/assets/icons/dashboard.svg" alt="desktop" class="pinkimg" routerLinkActive="selection-menu" />
          </a>
        </li>
      
        <li class="nav-item me-4">
          <a
            routerLink="/main/tracking"
            class="default"
            style="display: block"
            title="{{'Tracking'|translate}}"            
          >
            <img src="/assets/icons/tracking.svg" class="pinkimg" alt="track" routerLinkActive="selection-menu" />
          </a>
        </li>
         
        <!-- <li class="nav-item me-4">
          <a
            routerLink="/main/vehicleTracking"
            class="default"
            style="display: block"
            title="{{'Vehicle Tracking'|translate}}"            
          >
            <img src="/assets/icons/tracking.svg" class="pinkimg" alt="track" routerLinkActive="selection-menu" />
          </a>
        </li> -->
        <li class="nav-item me-4">
          <a
            routerLink="/main/track"
            class="default"
            style="display: block"
            title="{{'Vehicle Tracking'|translate}}"            
          >
          <i class="bi bi-map-fill"  style="font-size: 3vh;
            color: gray;" routerLinkActive="selection-menu"></i>
            <!-- <img src="/assets/icons/tracking.svg" class="pinkimg" alt="track" routerLinkActive="selection-menu" /> -->
          </a>
        </li>
        <li class="nav-item me-4">  
          <a
            style="display: block"
            class="default"
            href="javascript:void(0);"
            (click)="openModel('tasklist')"
            title="{{'Task List'|translate}}"
          >
            <i class="bi bi-list-task" style="font-size: 3vh;
            color: gray;"></i>
          </a>
        </li>


        <li class="nav-item dropdown me-3" *ngIf="isSupervisor1">
          <a class="nav-link dropdown-toggle d-flex" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-plus-square" style="font-size: 3vh;color: gray;" routerLinkActive="selection-menu"></i>
          </a>

          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" >
            <a
            style="display: block"
            class="default text-decoration-none dropdown-item text-center"
            href="javascript:void(0);"
            (click)="openModel('taskadd');"
            title="{{'Upload Task'|translate}}"
            *ngIf="userData.administrator || userData.userLimit !== 0 || isBackend || isAdmin || isDieselManager"
          >{{'Upload Task'|translate}}
          </a>
            <li>
              <a style="display: block"
              class="default text-decoration-none dropdown-item text-center"
              href="javascript:void(0);"
              (click)="openModel('createtask')"
              title="{{'Create Task'|translate}}"
              *ngIf="userData.administrator || userData.userLimit !== 0 || isBackend || isAdmin || isDieselManager">
              {{'Create Task'|translate}}</a>
            </li>
            <li>
              <a style="display: block"
              class="default text-decoration-none dropdown-item text-center"
              href="javascript:void(0);"
              (click)="openModel('users')"
              title="{{'Users'|translate}}"
              *ngIf="isClient">{{'Users'|translate}}</a>
            </li>
          </ul>


        </li>

        <li class="nav-item me-3">
          <a
            style="display: block"
            class="default"
            href="javascript:void(0);"
            (click)="openModel('reports')"
            title="{{'Reports'|translate}}"
          >
            <img src="/assets/icons/report.svg" alt="report" class="pinkimg" routerLinkActive="selection-menu"/>
          </a>
        </li>
       
        <li class="nav-item dropdown me-3">
       
          <a class="nav-link dropdown-toggle d-flex" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-person-fill icoimg" routerLinkActive="selection-menu"></i>
          </a>
    
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li>
              <a style="display: block"
              class="default text-decoration-none dropdown-item text-center"
              href="javascript:void(0);"
              (click)="openModel('customer')"
              title="{{'Driver'|translate}}"
              *ngIf="userData.administrator || userData.userLimit !== 0 || isBackend || isSupervisor || isAccountant || isCompliance || isAdmin || isDieselManager">{{'Driver'|translate}}</a></li>
            <li>
              <a style="display: block"
              class="default text-decoration-none dropdown-item text-center"
              href="javascript:void(0);"
              (click)="openModel('dealer')"
              title="{{'Supervisor'|translate}}"
              *ngIf="userData.administrator || userData.userLimit !== 0 || isAdmin">{{'Supervisor'|translate}}</a>
            </li>
            <li>
              <a style="display: block"
              class="default text-decoration-none dropdown-item text-center"
              href="javascript:void(0);"
              (click)="openModel('clients')"
              title="{{'Companies'|translate}}"
              *ngIf="userData.administrator || userData.userLimit !== 0 || isAdmin">{{'Companies'|translate}}</a>
            </li>
            <li>
              <a style="display: block"
              class="default text-decoration-none dropdown-item text-center"
              href="javascript:void(0);"
              (click)="openModel('users')"
              title="{{'Users'|translate}}"
              *ngIf="userData.administrator || userData.userLimit !== 0  || isAdmin">{{'Users'|translate}}</a>
            </li>
            <li>
              <a style="display: block"
              class="default text-decoration-none dropdown-item text-center"
              href="javascript:void(0);"
              (click)="openModel('users')"
              title="{{'Users'|translate}}"
              *ngIf="isClient">{{'Users'|translate}}</a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a
            style="display: block;margin-top: 3px;"
            class="default"
            href="javascript:void(0);"
            (click)="openModel('expense')"
            title="{{'Diesel'|translate}}"
            *ngIf="userData.administrator || userData.userLimit !== 0 || isDieselManager || isAdmin"
          >
            <img src="/assets/icons/dieselPump.svg"  alt="report" class="pinkimg" routerLinkActive="selection-menu"/>
          </a>
        </li>
    
        <li class="nav-item me-4">
     
        </li>
      </ul>
    
    </div>
  </div>
</nav>
<app-customers *ngIf="isCustomer || isDealer"></app-customers>
<app-clients *ngIf="isClients"></app-clients>
<app-licenses *ngIf="isLicense"></app-licenses>
<app-reports *ngIf="isReports"></app-reports>
<app-devices *ngIf="isDevices"></app-devices>
<app-model-master *ngIf="isMasterModel"></app-model-master>
<app-task-list *ngIf="isTaskList" [driverList]="driverList1" [dealerList]="dealerList"></app-task-list>
<app-task-add *ngIf="isTaskAdd"></app-task-add>
<app-task-edit *ngIf="isTaskEdit" [taskData]="editTaskData"></app-task-edit>
<app-users *ngIf="isUsers"></app-users>
<app-createtask *ngIf="isCreatetask"></app-createtask>
<app-expense *ngIf="isExpenses" [driverList]="driverList1"></app-expense>
<app-change-password *ngIf="isPasswordChange"></app-change-password>
