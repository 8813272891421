import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moments from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersettingService } from 'src/app/services/usersetting.service';
import { ValidationService } from 'src/app/services/validation.service';
import * as moment from 'moment-timezone';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
declare var $: any;

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.css']
})
export class UserNotificationComponent implements OnInit {
  form:FormGroup;
  userData:any ;
  modelForm:FormGroup;
  todate = '';
  fromdate = '';
  dealerId = '';
  pageOption = 10;
  pageOptionModal = 10;
  page: number = 1;
  submitted = false;
  userId = localStorage.getItem('userId');
  customerList = [];
  addCustomerForm: any;
  editCustomerForm: FormGroup;
  timezoneArray = [];
  currentUrl= {
    name:'',
    isDealer:false,
    pageLable:''
  }
  
  notificationsMasterList= [];
  

  
  deviceList: any = [];
  errors: any = {};
  superAdmin:boolean = false;
  modalRef?: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private user: UsersettingService,
    private fb: FormBuilder,
    private customValidator: ValidationService,
    private commonService: CommonService,
    private router:Router,
    private route:ActivatedRoute
  ) {
    
  }

  ngOnInit(): void {
   
    let userDataObj = localStorage.getItem('userData');
    if (userDataObj) {
      try {
        this.userData = JSON.parse(userDataObj);
        this.userNotifications(this.userData);
        this.superAdmin = this.userData && this.userData.administrator;
      } catch (error) {}
    }
    
    
    this.modelForm = new FormGroup({
      searchValue: new FormControl(''),
     
    });
  
  }
searchData(){

}
checkboxEvent(e: any, type: any) {
    this.addCustomerForm[type] = e.checked;
  }

  

  

  
  userNotifications(user) {
    this.notificationsMasterList = [];
    this.commonService.getRequest('notifications?all=true', []).then((resp: any) => {
      if (resp?.status) {
        this.notificationsMasterList = resp.data;
        this.getUserNotifications(user.id);
      }
    });
  }
  getUserNotifications(userId) {
    this.commonService
      .getRequest('notifications', ['userId=' + userId])
      .then((resp: any) => {
        if (resp?.status) {
          let mappedId = resp.data.map((d: any) => {
            return d.id;
          });
          this.notificationsMasterList.forEach((d: any, k: number) => {
            this.notificationsMasterList[k]['mapUserId'] = userId;
            this.notificationsMasterList[k]['isSelectedDevice'] =
              mappedId.indexOf(d.id) > -1;
          });
          
        }
      });
  }
  assignDevice(user) {
    this.deviceList = [];
    console.log('---------', user);
    this.commonService.getRequest('devices', []).then((resp: any) => {
      if (resp?.status) {
        this.deviceList = resp.data;
        this.getUserDevice(user.id);
      }
    });
  }

  getUserDevice(userId) {
    this.commonService
      .getRequest('devices', ['userId=' + userId])
      .then((resp: any) => {
        if (resp?.status) {
          let mappedId = resp.data.map((d: any) => {
            return d.id;
          });
          this.deviceList.forEach((d: any, k: number) => {
            this.deviceList[k]['mapUserId'] = userId;
            this.deviceList[k]['isSelectedDevice'] =
              mappedId.indexOf(d.id) > -1;
          });
          console.log(
            'resp----this.deviceList-------',
            mappedId,
            this.deviceList
          );
        }
      });
  }

  changeEvent(e: any, notificationId, userId) {
    if (e.checked) {
      this.commonService
        .postRequest('permissions', {
          userId: userId,
          notificationId: notificationId,
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    } else {
      this.commonService
        .deleteRequest('permissions', {
          userId: userId,
          notificationId: notificationId,
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    }
  }
  changeEventDevices(e: any, deviceId, userId) {
    if (e.checked) {
      this.commonService
        .postRequest('permissions', {
          userId: userId,
          deviceId: deviceId,
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    } else {
      this.commonService
        .deleteRequest('permissions', {
          userId: userId,
          deviceId: deviceId,
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    }
  }
}
