
<app-common-modal-popup>
  <div class="" modelheadertext *ngIf="gridMode && reportsName != 'Summary'">{{ reportsName | translate }} {{reportsName == 'AC Report' || reportsName == 'Ignition Report' ? '':'Report'|translate}}</div>
  <div class="" modelheadertext *ngIf="gridMode && reportsName == 'Summary'">{{ 'MIS'|translate}}</div>
<div class="container-fluid" modeldata *ngIf="gridMode">
  <!-- CAROUSEL BUTTONS -->
  <div class="row m-0" *ngIf="false">
    <div class="position-relative">
      <div class="owl-carousel" id="startCarousel">
        <div class="item" *ngFor="let repo of reports; let i = index">
          <div class="card flex-rows" (click)="onClickReports(repo.name)">
            <span>
              <img src="{{ repo.img }}" alt="img" />
            </span>
            <span class="carouselbtnstext">
              {{ repo.name | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- TABLES -->

  <div class="row p-0">
    <div class="card p-0 headcard">
      <!-- HEADERS -->
      <div class="card-header1 flex_row_center_spacebetween" *ngIf="false">
        
        <ul id="nav_pills" class="nav nav-pills card-header-pills">
          <li class="nav-item">
            <div class="reports_dropdown">
            </div>
          </li>
          <li *ngIf="reportsName == 'POI Report'" class="nav-item">
            <div class="reports_dropdown">
              <select
                class="form-select device"
                style="width: 22vh"
                [(ngModel)]="poiId"
                name="poId"
                (change)="onSelectPoi(poiId)"
              >
                <option
                  *ngFor="let poi of poiList; let i = index"
                  value="{{ poi._id }}"
                  class="select_options"
                >
                  {{ poi.poi.poiname |translate}}
                </option>
              </select>
            </div>
          </li>
          <li class="nav-item">
            <div class="searchvehicles">
              <div
                *ngIf="
                  reportsName != 'Geofence Report' &&
                  reportsName != 'Route Violation Report'
                "
              > 
              </div>

              <div *ngIf="reportsName == 'Geofence Report'">
                <select
                  class="form-select device"
                  [(ngModel)]="_id"
                  name="_id"
                  (change)="onSelectgeofence(_id)"
                >
                  <option
                    *ngFor="let geo of geofenceList; let i = index"
                    value="{{ geo._id }}"
                    class="select_options"
                  >
                    {{ geo.geoname }}
                  </option>
                </select>
              </div>

              <div *ngIf="reportsName == 'Route Violation Report'">
                <select
                  class="form-select device"
                  [(ngModel)]="routeId"
                  name="routeId"
                  (change)="onSelectRoute(routeId)"
                >
                  <option
                    *ngFor="let route of userTrackRoutesList; let i = index"
                    value="{{ route._id }}"
                    class="select_options"
                  >
                    {{ route.name }}
                  </option>
                </select>
              </div>
            </div>
            <!-- <div class="input-group searchvehicles">
              <input type="text" class="form-control reportsbox" placeholder="Search Vehicle"
                aria-label="Recipient's username" aria-describedby="basic-addon2">
              <div class="input-group-append">
                <button class="btn" type="button" style="padding:0.6vh 1.4vh 1.1vh 1.4vh;">
                  <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
                </button>
              </div>
            </div> -->
          </li>
          <li class="nav-item">
            <div class="reports_dropdown">
              <!-- <select class="form-select" [(ngModel)]="dayType" name="dayType">
                <option value="Today" class="select_options">Today</option>
                <option value="Yesterday" class="select_options">
                  Yesterday
                </option>
                <option value="Week" class="select_options">Week</option>
                <option value="Month" class="select_options">Month</option>
                <option value="Last Month" class="select_options">
                  Last Month
                </option>
                <option value="Custom" class="select_options">Custom</option>
              </select> -->
            </div>
          </li>
        </ul>
      </div>
      <div class="card-header flex_row_center_spacebetween">
        <span class="to">Report Type</span>
        <select
          [ngStyle]="{ width: '150px' }"
          class="form-select me-2 ms-2"
          [(ngModel)]="reportsName"          
          (change)="onReportTypeChange()"
        >
          <option value="{{repo.name}}" class="select_options" 
          *ngFor="let repo of reports; let i = index"
          >
            <span>
              <img src="{{ repo.img }}" alt="img" />
            </span>
            <span class="carouselbtnstext">
              {{ repo.name == 'Summary'? 'MIS': repo.name | translate }}
            </span>
          </option>          
        </select>
        <!-- (change)="onSelectVehicle(reportsName)" -->
        <!-- (change)="onSelectVehicle(reportsName)" --> 
            
        <div class="flex_row_center_spacebetween" *ngIf="reportsName == 'Summary' || reportsName == 'Attendance'">
          <!-- <input
            *ngIf="reportsName == 'Route'"
            type="date"            
            [(ngModel)]="fromdateP"
            [min]="minDateForRouteReportFilter"
            [max]="maxDateForRouteReportFilter"
            style="height: 5.2vh; border-radius: 5px;"                       
          /> -->
          <input            
            type="date"            
            [(ngModel)]="fromdateP"
            style="height: 5.2vh; border-radius: 5px; width:18vh"                       
          />
          <select class="form-select" style="width:11vh;" [(ngModel)]="fromDateHours">
            <option value="00">00</option>
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
          </select>
          <select class="form-select" style="width: 11vh;" [(ngModel)]="fromDateMinutes">
            <option value="00">00</option>
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
            <option value="31">31</option>
            <option value="32">32</option>
            <option value="33">33</option>
            <option value="34">34</option>
            <option value="35">35</option>
            <option value="36">36</option>
            <option value="37">37</option>
            <option value="38">38</option>
            <option value="39">39</option>
            <option value="40">40</option>
            <option value="41">41</option>
            <option value="42">42</option>
            <option value="43">43</option>
            <option value="44">44</option>
            <option value="45">45</option>
            <option value="46">46</option>
            <option value="47">47</option>
            <option value="48">48</option>
            <option value="49">49</option>
            <option value="50">50</option>
            <option value="51">51</option>
            <option value="52">52</option>
            <option value="53">53</option>
            <option value="54">54</option>
            <option value="55">55</option>
            <option value="56">56</option>
            <option value="57">57</option>
            <option value="58">58</option>
            <option value="59">59</option>
          </select>
          <span class="to p-2">{{'to'|translate}}</span>
          <input 
            type="date"            
            [(ngModel)]="todateP"
            style="height: 5.2vh; border-radius: 5px;width:19vh"                       
          />
          <!-- <input    
            *ngIf="reportsName == 'Route'"        
            type="date"            
            [(ngModel)]="todateP"
            [min]="minDateForRouteReportFilter"
            [max]="maxDateForRouteReportFilter"
            style="height: 5.2vh; border-radius: 5px;"                       
          /> -->
          <select class="form-select" style="width: 11vh;" [(ngModel)]="toDateHours">
            <option value="00">00</option>
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
          </select>
          <select class="form-select" style="width: 11vh;" [(ngModel)]="toDateMinutes">
            <option value="00">00</option>
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
            <option value="31">31</option>
            <option value="32">32</option>
            <option value="33">33</option>
            <option value="34">34</option>
            <option value="35">35</option>
            <option value="36">36</option>
            <option value="37">37</option>
            <option value="38">38</option>
            <option value="39">39</option>
            <option value="40">40</option>
            <option value="41">41</option>
            <option value="42">42</option>
            <option value="43">43</option>
            <option value="44">44</option>
            <option value="45">45</option>
            <option value="46">46</option>
            <option value="47">47</option>
            <option value="48">48</option>
            <option value="49">49</option>
            <option value="50">50</option>
            <option value="51">51</option>
            <option value="52">52</option>
            <option value="53">53</option>
            <option value="54">54</option>
            <option value="55">55</option>
            <option value="56">56</option>
            <option value="57">57</option>
            <option value="58">58</option>
            <option value="59">59</option>
          </select>
        </div>
        <form *ngIf="reportsName !== 'Summary' && false">
          <div class="">

            
              
              <input class="form-control form-control-mt-select" type="text" placeholder="{{'Select Vehicle'|translate}}" aria-label="vehicle" matInput [formControl]="myControl" 
              [matAutocomplete]="auto" />
              <mat-autocomplete autoActiveFirstOption [displayWith]="displayFn" #auto="matAutocomplete"
              (optionSelected)="onSelectVehicle($event.option.value)" >
              
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          
        </div>
      </form>
        <div *ngIf="reportsName == 'Summary'" class="form-control-mt-select">          
          <!-- <mat-label>Colunmn</mat-label> -->
          <mat-select placeholder="{{'Select Vehicle'|translate}}"  [formControl]="myControl" multiple class="d-flex align-items-center justify-content-center flex-column">
            <!-- <mat-form-field>
              <input matInput placeholer="Search" (input)="filterOptione($event.target.value)" />
            </mat-form-field> -->
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{option.name}}</mat-option>
          </mat-select>
        </div>
        <div *ngIf="reportsName == 'Attendance'" class="form-control-mt-select">          
          <input class="form-control form-control-mt-select" type="text" placeholder="{{'Select User'|translate}}" aria-label="vehicle" matInput [formControl]="myControl" 
              [matAutocomplete]="auto" />
              <mat-autocomplete autoActiveFirstOption [displayWith]="displayFn" #auto="matAutocomplete"
              (optionSelected)="onSelectVehicle($event.option.value)" >
              
              <mat-option *ngFor="let option of filteredUserOptions | async" [value]="option">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
        </div>
        <!-- <select
          [ngStyle]="{ width: '200px', 'margin-left': '10px' }"
          class="form-select device"
          [(ngModel)]="Device_ID"
          name="id"
          (change)="onSelectVehicle($event.target)"
        >
          <option
            *ngFor="let device of DeviceArray; let i = index"
            value="{{ device.id }}"
            class="select_options"
          >
            {{ device.name }}
          </option>
        </select> -->
        <select
        *ngIf="reportsName == 'Summary' || reportsName == 'Attendance'" 
          [ngStyle]="{ width: '150px', 'margin-left': '10px' }"
          class="form-select"
          [(ngModel)]="dayType"
          (change)="setReportsDate()"
        >
          <option value="Today" class="select_options">{{'Today'|translate}}</option>
          <option value="Yesterday" class="select_options">{{'Yesterday'|translate}}</option>
          <option
            value="Week"            
            class="select_options"
          >
            {{'Week'|translate}}
          </option>
          <option
            value="Month"            
            class="select_options"
          >
            {{'Month'|translate}}
          </option>
          <option
            value="Last Month"           
            class="select_options"
          >
            {{'Last Month'|translate}}
          </option>
          <option value="Custom" class="select_options">{{'Custom'|translate}}</option>
        </select>
        <select
          style="width:185px !important;margin-left: 10px;height: 5.2vh !important;"
          class="form-select selectimportant"
          [(ngModel)]="reportTypeFilter"
          *ngIf="reportsName == 'Events' && false"
        >
          <option value=""></option>
          <option value="{{t.type}}" *ngFor="let t of typeFilterData">{{t.type}}</option>          
        </select>
        
        <div *ngIf="reportsName == 'Route' && false" class="form-control-mt-select">
          
            <!-- <mat-label>Colunmn</mat-label> -->
            <mat-select placeholder="Colunmn"  [(ngModel)]="colunmnList" multiple class="d-flex align-items-center justify-content-center flex-column">
              <mat-option *ngFor="let item of masterColumnList" [value]="item.value">{{item.label}}</mat-option>
            </mat-select>
          
        </div>
        
        <div
        class="flex-grow-1 p-2"
          id="refresh_downloadBtn"
          style="
            display: flex;
            flex-direction: row;
            justify-content: end;
            
          "
        >
          <div
            *ngIf="reportsName == 'Loading Unloading Report' && false"
            style="display: flex; flex-direction: row"
          >
            <button
              mat-raised-button
              class="px-4"
              (click)="onClickGroup()"
              style="margin-right: 0.7vh"
            >
              {{'Group'|translate}}
            </button>
            <button mat-raised-button class="px-4" (click)="onClickDetails()">
              {{'Details'|translate}}
            </button>
          </div>
          <div style="display: flex; flex-direction: row">
            <button type="button" class="btn btn-info me-2" (click)="onSelectVehicle(null)">Search</button>
            <div class="dropdown">
              <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                Download
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                <li *ngIf="reportsName != 'Trips'">
                  <button type="button" matTooltip="Excel Download" class="btn btn-success w-100 d-flex align-items-center justify-content-center"  (click)="downloadData('xlsx')">
                    <i class="bi bi-file-earmark-excel" style="color: #FFF; font-size: 24px; margin-right: 5px;"></i> Excel
                  </button>
             
                  <!-- <app-export-data *ngIf="reportsName == 'Trips'" [selectedPage]="'Trips'" [dataList]="reportsList"></app-export-data> -->
                </li>
                <li  *ngIf="reportsName === 'Trips'">
                  <button type="button" matTooltip="Excel Download" class="btn btn-success w-100 d-flex align-items-center justify-content-center"  (click)="exportToExcel()">
                    <i class="bi bi-file-earmark-excel" style="color: #FFF; font-size: 24px; margin-right: 5px;"></i> Excel
                  </button>
                  </li>
               
                <!-- <li style="margin-top: 5px;">
                  <button type="button" matTooltip="PDF Download" class="btn btn-success w-100 d-flex align-items-center justify-content-center" (click)="downloadData('pdf')">
                    <i class="bi bi-filetype-pdf" style="color: #FFF; font-size: 24px; margin-right: 5px;"></i> PDF
                  </button>                  
                </li>                 -->
              </ul>
            </div>
            
            <!-- <span class="px-2" (click)="onSelectVehicle(null)"
              ><img class="small-img"
                src="/assets/icons/report_refresh_gray.png"
                alt="refresh"
                style="width: 96%"
            /></span>
            <span *ngIf="reportsName != 'Trips'" class="" (click)="downloadData('xlsx')">
              <img
               class="small-img"
                src="/assets/icons/report_download_gray.png"
                alt="download xlsx"
                style="width: 96%"
              />
            </span> -->
            
            
          </div>
        </div>
      </div>

      <!-- AC REPORTS TABLE -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Route'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh">
                {{'Device Id'|translate}}  
              </th>
              <th class="table_head_text" style="width: 24%"
              (click)="sorting('device_Name')">{{'Device Name'|translate}}
              <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'device_Name'"></i>
              <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'device_Name'"></i>
            </th>
              <th *ngFor="let col of colunmnList" class="table_head_text" (click)="sorting(col)">
                {{getNameOfCol(col) | translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == col"></i>
              <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == col"></i>
              </th>
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <tr *ngFor="let repo of reportsList |  paginate : {
              id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index;">
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              ><ng-container *ngIf="!repo.apiCall">{{getAndSetAPIData(repo)}}</ng-container>
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />
                <!-- {{repo.deviceName}} -->
              </td>
              <td
                class="table_data">
                {{repo.device_Name}}
              </td>
              <ng-container [ngSwitch]="col" *ngFor="let col of colunmnList">
              
                <td *ngSwitchCase="'serverTime'"  class="table_data">{{ repo.serverTime | date: 'dd/MM/yyyy hh:mm a' }}</td>
                <td *ngSwitchCase="'fixTime'"  class="table_data">{{ repo.fixTime | date: 'dd/MM/yyyy hh:mm a' }}</td>
                <td *ngSwitchCase="'deviceTime'"  class="table_data">{{ repo.deviceTime | date: 'dd/MM/yyyy hh:mm a' }}</td>
               
                <td *ngSwitchCase="'latitude'" class="table_data">{{ repo.latitude| number : '1.0-6' }}</td>
                <td *ngSwitchCase="'longitude'" class="table_data">{{ repo.longitude| number : '1.0-6' }}</td>
                <td *ngSwitchCase="'speed'" class="table_data">{{ repo.speed ? (onSpeedgenerateBasedOnuserPreference(repo.speed) | number : '1.0-2'): '0'}} {{userData.attributes.speedUnit|translate}}</td>
                <td *ngSwitchCase="'odometer'" class="table_data">{{ repo.odometer ? (repo.odometer | meterTokm) : (repo.attributes.odometer ?(repo.attributes.odometer | meterTokm) : 0 )}}</td>
                <td *ngSwitchCase="'distance'" class="table_data">{{ repo.distance ? (repo.distance | meterTokm) : (repo.attributes.distance ?(repo.attributes.distance | meterTokm) : 0 )}}</td>
                <td *ngSwitchCase="'totalDistance'" class="table_data">{{ repo.totalDistance ? (repo.totalDistance | meterTokm) : (repo.attributes.totalDistance ?(repo.attributes.totalDistance | meterTokm) : 0 )}}</td>
                <td *ngSwitchCase="'obdSpeed'" class="table_data">{{ repo.obdSpeed ? (repo.obdSpeed | meterTokm) : (repo.attributes.obdSpeed ?(repo.attributes.obdSpeed | meterTokm) : 0 )}}</td>
                <td *ngSwitchCase="'address'" class="table_data">{{ repo.latitude && repo.longitude  && addressBylatLong[repo.latitude+'_'+repo.longitude] ? addressBylatLong[repo.latitude+'_'+repo.longitude] :'NA'}}</td>
                <td *ngSwitchDefault>{{repo[col] ?repo[col] : (repo.attributes && repo.attributes[col] ?  repo.attributes[col] : '') }} </td>
             
              </ng-container>
             </tr>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="4">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <div
          *ngIf="reportsList.length"
          class="flex_row_center_spacebetween"
          style="padding: 1vh 2vh"
        >
          <div>{{'Showing'|translate}} 1 to 9 of 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div> -->
      </div>

      <!-- DAILY Events -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Events'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="width: 24%" (click)="sorting('deviceName')">{{'Device Name'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'deviceName'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'deviceName'"></i></th>
              <th class="table_head_text" style="padding-left: 3vh;" (click)="sorting('eventTime')">
                {{'Fix Time'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'eventTime'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'eventTime'"></i>
              </th>
              <th class="table_head_text">{{'Address'|translate}}</th>
              <th class="table_head_text" style="width: 24%" (click)="sorting('type')">{{'Type'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'type'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'type'"></i></th>
              <!-- <th class="table_head_text" style="width: 23%">Geofence</th> -->
              <th class="table_head_text">{{'Alarm'|translate}}</th>
              <th class="table_head_text">{{'Remarks'|translate}}</th>
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <ng-container *ngFor="let repo of reportsList |  paginate : {
              id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index">
              <!-- <tr style="border-bottom: 1px solid #e3e7ec;">
                <td colspan="8" style="padding: 1.6vh 1vh;"><span style="float:right;">25 Des, 2020 10:30 AM</span> </td>
              </tr> -->
              <tr style="border-bottom: 1px solid #e3e7ec">
                <td class="table_data">
                  {{ repo.deviceName }}
                </td>
                <td class="table_data">{{ repo.eventTime | date: 'dd/MM/yyyy hh:mm a'}}</td>
                <td class="table_data">
                  {{repo.latLng?.address}}
                </td>
                <td class="table_data">
                  {{ repo.type }}
                </td>
                <td class="table_data">
                  {{repo.attributes?.alarm}}
                </td>
                <td class="table_data">
                  {{repo.attributes?.remarks}}
                </td>
              </tr>
            </ng-container>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <div
          *ngIf="reportsList.length"
          class="flex_row_center_spacebetween"
          style="padding: 1vh 2vh"
        >
          <div>{{'Showing'|translate}} 1 to 9 of 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div> -->
      </div>

      <!-- Geofence REPORTS -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Geofence'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="width: 24%" (click)="sorting('deviceName')">{{'Device Name'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'deviceName'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'deviceName'"></i></th>
              <th class="table_head_text" style="width: 24%" (click)="sorting('geoName')">{{'Geofence'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'geoName'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'geoName'"></i></th>
              <th class="table_head_text" style="padding-left: 3vh;" (click)="sorting('eventTime')">
                {{'Fix Time'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'eventTime'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'eventTime'"></i>
              </th>
              <th class="table_head_text" style="width: 24%">{{'Address'|translate}}</th>
              <th class="table_head_text" style="width: 24%" (click)="sorting('type')">{{'Type'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'type'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'type'"></i></th>
              <!-- <th class="table_head_text" style="width: 23%">Geofence</th> -->
              <th class="table_head_text">{{'Alarm'|translate}}</th>
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <ng-container *ngFor="let repo of reportsList |  paginate : {
              id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index">
              <!-- <tr style="border-bottom: 1px solid #e3e7ec;">
                <td colspan="8" style="padding: 1.6vh 1vh;"><span style="float:right;">25 Des, 2020 10:30 AM</span> </td>
              </tr> -->
              <tr style="border-bottom: 1px solid #e3e7ec">
                <td class="table_data">
                  {{ repo.deviceName }}
                </td>
                <td class="table_data">
                  {{ repo.geoName }}
                </td>
                <td class="table_data">{{ repo.eventTime | date: 'dd/MM/yyyy hh:mm a'}}</td>
                <td class="table_data">
                  {{ repo.latLng?.address ? repo.latLng.address : '' }}
                </td>
                <td class="table_data">
                  {{ repo.type }}
                </td>
                <td class="table_data">
                  {{repo.attributes?.alarm}}
                </td>
              </tr>
            </ng-container>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- AC REPORTS -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'AC Report'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="width: 24%" (click)="sorting('deviceName')">{{'Device Name'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'deviceName'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'deviceName'"></i></th>
              <th class="table_head_text" style="width: 24%">{{'Address'|translate}}</th>
              <th class="table_head_text" style="padding-left: 3vh;" (click)="sorting('eventTime')">
                {{'Fix Time'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'eventTime'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'eventTime'"></i>
              </th>
              <th class="table_head_text" style="width: 24%" (click)="sorting('type')">{{'Type'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'type'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'type'"></i></th>
              <!-- <th class="table_head_text" style="width: 23%">Geofence</th> -->
              <!-- <th class="table_head_text">{{'Alarm'|translate}}</th> -->
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <ng-container *ngFor="let repo of reportsList |  paginate : {
              id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index">
              <!-- <tr style="border-bottom: 1px solid #e3e7ec;">
                <td colspan="8" style="padding: 1.6vh 1vh;"><span style="float:right;">25 Des, 2020 10:30 AM</span> </td>
              </tr> -->
              <tr style="border-bottom: 1px solid #e3e7ec">
                <td class="table_data">
                  {{ repo.deviceName }}
                </td>
                <td class="table_data">
                  {{ repo.latLng?.address ? repo.latLng.address : '' }}
                </td>
                <td class="table_data">{{ repo.eventTime | date: 'dd/MM/yyyy hh:mm a'}}</td>
                <td class="table_data">
                  {{ repo.type }}
                </td>
                <!-- <td class="table_data">
                  {{repo.attributes?.alarm}}
                </td> -->
              </tr>
            </ng-container>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Ignition Report -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Ignition Report'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="width: 24%" (click)="sorting('deviceName')">{{'Device Name'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'deviceName'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'deviceName'"></i></th>
              <th class="table_head_text" style="width: 24%">{{'Address'|translate}}</th>
              <th class="table_head_text" style="padding-left: 3vh;" (click)="sorting('eventTime')">
                {{'Fix Time'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'eventTime'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'eventTime'"></i>
              </th>
              <th class="table_head_text" style="width: 24%" (click)="sorting('type')">{{'Type'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'type'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'type'"></i></th>
              
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <ng-container *ngFor="let repo of reportsList |  paginate : {
              id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index">
              
              <tr style="border-bottom: 1px solid #e3e7ec">
                <td class="table_data">
                  {{ repo.deviceName }}
                </td>
                <td class="table_data">
                  {{ repo.latLng?.address ? repo.latLng.address : '' }}
                </td>
                <td class="table_data">{{ repo.eventTime | date: 'dd/MM/yyyy hh:mm a'}}</td>
                <td class="table_data">
                  {{ repo.type }}
                </td>
                
              </tr>
            </ng-container>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- trips REPORTS -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Trips'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 10%" (click)="sorting('id')">{{'Task ID'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'id'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'id'"></i></th>
                <th class="table_head_text" style="width: 12%">
                  {{'Company Name'|translate}}
                </th>
                <th class="table_head_text" style="width: 12%" (click)="sorting('type')">
                {{'Task Type'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'type'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'type'"></i>
              </th>
             <th class="table_head_text" style="width: 23%" (click)="sorting('pickuplocation')">{{'Address'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'pickuplocation'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'pickuplocation'"></i>
              </th>
              <th class="table_head_text" style="width: 12%">{{'Status'|translate}}</th>               
              <th class="table_head_text" (click)="sorting('name')">{{'Driver Name'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'name'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'name'"></i>
              </th>
              <!-- <th class="table_head_text" style="width: 23%" (click)="sorting('pickuplocation')">{{'Address'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'pickuplocation'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'pickuplocation'"></i>
              </th> -->
              <th class="table_head_text" (click)="sorting('createdBy')">{{'Created on'|translate}} 
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'createdBy'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'createdBy'"></i>
              </th>
              <th class="table_head_text">{{'Start Time'|translate}}</th>
              <th class="table_head_text">{{'End Time'|translate}}</th>
              <th class="table_head_text">{{'Distance'|translate}}</th>
              <th class="table_head_text">{{'Duration'|translate}}</th>
              <th class="table_head_text">{{'Shift Timing'|translate}}</th>
              <th class="table_head_text">{{'Remarks'|translate}}</th>
              <!-- <th class="table_head_text" (click)="sorting('status')">{{'Status'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'status'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'status'"></i>
              </th>
              <th class="table_head_text">{{'Pickup / Delivery'|translate}}                
              </th>   
              <th class="table_head_text">{{'Escort'|translate}}                
              </th>            -->


              <!-- //custome code by  vivek -->
           <!-- <th class="table_head_text">{{'Task ID'|translate}}</th>
                                    <th class="table_head_text">{{'Client Name'|translate}}</th>
                                    <th class="table_head_text">{{'Task Type'|translate}}</th>
                                    <th class="table_head_text">{{'Address'|translate}}</th>
                                    <th class="table_head_text">{{'Status'|translate}}</th>
                                    <th class="table_head_text">{{'Driver Name'|translate}}</th>
                                    <th class="table_head_text">{{'Created On'|translate}}</th>
                                    <th class="table_head_text">{{'Start Time'|translate}}</th>
                                    <th class="table_head_text">{{'End Time'|translate}}</th>
                                    <th class="table_head_text">{{'Distance'|translate}}</th>
                                    <th class="table_head_text">{{'Duration'|translate}}</th>
                                    <th class="table_head_text">{{'Shift Timing'|translate}}</th>
                                    <th class="table_head_text">{{'Remarks'|translate}}</th> -->
            </tr>
          </thead>
          <!-- <ng-container class="scroller"> -->
          <tbody *ngIf="reportsList.length">
            <!-- <tr style="border-bottom: 1px solid #e3e7ec;">
              <td colspan="8" style="padding: 1.6vh 1vh;"><span style="float:right;">25 Des, 2020 10:30 AM</span> </td>
            </tr> -->            
            <tr
              *ngFor="let customer of reportsList |  paginate : {
                id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index"
              style="border-bottom: 1px solid #e3e7ec"
            >
            <td class="table_data">{{ customer.id ? customer.id : '' }}</td>
            <td class="table_data">{{ customer.clientid }}</td>
            <td class="table_data">{{ customer.type}}</td>
            <td class="table_data">{{ customer.pickuplocation }}</td>
            <td class="table_data">{{customer.status.charAt(0).toUpperCase() + customer.status.slice(1) }}</td>
            <td class="table_data">{{ customer.attributes.driverName  }}</td>
            <td class="table_data">{{customer.date | date: 'dd/MM/yyyy hh:mm a'  }}</td>
            <td class="table_data">{{ customer.attributes.tripstartime ? (customer.attributes.tripstartime | date: 'dd/MM/yyyy hh:mm a') : '' }}</td>
            <td class="table_data">{{ customer.attributes.tripendtime ?  (customer.attributes.tripendtime | date: 'dd/MM/yyyy hh:mm a') : '' }}</td>
            <td class="table_data">{{ customer.distance}}</td>
            <td class="table_data">{{calculateDuration(customer.attributes.tripstartime,customer.attributes.tripendtime)}}</td>
            <td class="table_data">{{ customer.attributes.Shift_Timing }}</td>
            <td class="table_data">{{ customer.attributes.remarks ? customer.attributes.remarks : ''}}</td>
           
           
           
            <!-- <td class="table_data">{{ customer.expectedendtime | date: 'dd/MM/yyyy hh:mm a'  }}</td>
            <td class="table_data">{{ customer.status }}</td>
            <td class="table_data">{{ customer.type }}</td>
            <td class="table_data">{{ customer.attributes?.escort }}</td> -->
                                  <!-- //custome code by  vivek -->   
                                    <!-- <td class="table_data">{{ customer.id ? customer.id : '' }}</td>
                                    <td class="table_data">{{customer.clientid}}</td>
                                    <td class="table_data">{{ customer.type.charAt(0).toUpperCase() + customer.type.slice(1) }}</td>
                                    <td class="table_data">{{ customer.pickuplocation }}</td>
                                    <td class="table_data">{{ customer.status.charAt(0).toUpperCase() + customer.status.slice(1) }}</td>
                                    <td class="table_data">{{ customer.attributes.driverName }}</td>
                                    <td class="table_data">{{ customer.date | date: 'dd/MM/yyyy hh:mm a' }}</td>
                                    <td class="table_data">{{ customer.attributes.tripstartime ? (customer.attributes.tripstartime | date: 'dd/MM/yyyy hh:mm a') : 'N/A' }}</td>
                                    <td class="table_data">{{ customer.attributes.tripendtime ?  (customer.attributes.tripendtime | date: 'dd/MM/yyyy hh:mm a') : 'N/A' }}</td>
                                    <td class="table_data">{{ customer.distance}}</td>
                                    <td class="table_data">{{calculateDuration(customer.attributes.tripstartime,customer.attributes.tripendtime)}}</td>
                                    <td class="table_data">{{ customer.attributes.Shift_Timing }}</td>
                                    <td class="table_data">{{ customer.attributes.remarks ? customer.attributes.remarks : 'N/A'}}</td> -->                              
                                    </tr>
          </tbody>
          <!-- </ng-container> -->
          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                class="d-flex justify-content-center align-items-center"
                style="height: 40vh"
              >
              <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
               
                <ng-template #notFound>
                  <img 
                  src="/assets/images/datanotfound.png"
                  alt="NO DATA AVAILABLE IN TABLE"
                />
                </ng-template>
               
              </div>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <div
          *ngIf="reportsList.length"
          class="flex_row_center_spacebetween"
          style="padding: 1vh 2vh"
        >
          <div>{{'Showing'|translate}} 1 to 9 of 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div> -->
      </div>

      <!-- stops REPORT -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Stops'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 24%" (click)="sorting('deviceName')">
              {{'Device Name'|translate}}
              <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'deviceName'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'deviceName'"></i>
              </th>
              <th class="table_head_text" style="width: 12%" (click)="sorting('startTime')">
                {{'Start Time'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'startTime'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'startTime'"></i>
              </th>
              <th class="table_head_text" style="width: 24%" (click)="sorting('startOdometer')">{{'Odometer'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'startOdometer'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'startOdometer'"></i></th>
              <th class="table_head_text" style="width: 23%" (click)="sorting('endTime')">{{'End Time'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'endTime'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'endTime'"></i></th>
              <th class="table_head_text" style="width: 16%" (click)="sorting('duration')">{{'Duration'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'duration'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'duration'"></i></th>
              <th class="table_head_text" style="width: 23%">{{'Address'|translate}}</th>
              <th class="table_head_text" style="width: 16%" (click)="sorting('engineHours')">{{'Engine Hours'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'engineHours'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'engineHours'"></i></th>
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <tr
              *ngFor="let repo of reportsList |  paginate : {
                id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index"
              style="border-bottom: 1px solid #e3e7ec"
            >
              <td class="table_data">{{repo.deviceName}}</td>
              <td class="table_data"><ng-container *ngIf="!repo.apiCall">{{getAndSetAPIData(repo)}}</ng-container>{{ repo.startTime | date: 'dd/MM/yyyy hh:mm a'}} </td>
              <td class="table_data">{{ repo.startOdometer  | meterTokm}}</td>
              <td class="table_data">
                {{ repo.endTime | date: 'dd/MM/yyyy hh:mm a'}}
              </td>
              <td class="table_data">
                {{ repo.duration |convert:'hh:mm'}}
              </td>
              <td class="table_data">{{ repo.latitude && repo.longitude  && addressBylatLong[repo.latitude+'_'+repo.longitude] ? addressBylatLong[repo.latitude+'_'+repo.longitude] :'NA'}}</td>
              <td class="table_data">
                {{ repo.engineHours  |convert:'hh:mm'}}
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <div
          *ngIf="reportsList.length"
          class="flex_row_center_spacebetween"
          style="padding: 1vh 2vh"
        >
          <div>{{'Showing'|translate}} 1 to 9 of 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div> -->
      </div>

      <!-- summary REPORT  -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Summary'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 24%" (click)="sorting('deviceName')">
                {{'Device Name'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'deviceName'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'deviceName'"></i>
              </th>
              <th class="table_head_text" style="width: 15%" (click)="sorting('startTime')">
                {{'Start Date'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'startTime'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'startTime'"></i>
              </th>
              <th class="table_head_text" style="width: 15%" (click)="sorting('endTime')">
                {{'End Date'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'endTime'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'endTime'"></i>
              </th>
              <th class="table_head_text" style="width: 15%" (click)="sorting('distance')">{{'Distance'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'distance'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'distance'"></i></th>
              <th class="table_head_text" style="width: 13%">{{'Start Address'|translate}}</th>
              <th class="table_head_text" style="width: 13%">{{'End Address'|translate}}</th>
              <th class="table_head_text" style="width: 13%" (click)="sorting('startOdometer')">{{'Odometer Start'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'startOdometer'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'startOdometer'"></i></th>
              <th class="table_head_text" style="width: 10%" (click)="sorting('endOdometer')">{{'Odometer End'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'endOdometer'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'endOdometer'"></i></th>
              <th class="table_head_text" style="width: 10%" (click)="sorting('averageSpeed')">{{'Average Speed'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'averageSpeed'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'averageSpeed'"></i></th>
              <th class="table_head_text" (click)="sorting('maxSpeed')">{{'Maximum Speed'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'maxSpeed'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'maxSpeed'"></i>
              </th>
              <th class="table_head_text" (click)="sorting('engineHours')">{{'Engine Hours'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'engineHours'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'engineHours'"></i>
              </th>
              <th class="table_head_text" (click)="sorting('idleHours')">{{'Idle Hours'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'idleHours'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'idleHours'"></i>
              </th>
              <th class="table_head_text" (click)="sorting('movingHours')">{{'Moving Hours'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'movingHours'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'movingHours'"></i>
              </th>
              <th class="table_head_text" (click)="sorting('stopHours')">{{'Stop Hours'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'stopHours'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'stopHours'"></i>
              </th>
              <th class="table_head_text">{{'Replay'|translate}}</th>
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <tr
              *ngFor="let repo of reportsList |  paginate : {
                id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index"
              style="border-bottom: 1px solid #e3e7ec"
            >
            
              <td class="table_data">{{ repo.deviceName}}</td>
              <td class="table_data">{{ repo.startTime | date: 'dd/MM/yyyy hh:mm a' }}</td>
              <td class="table_data">{{ repo.endTime | date: 'dd/MM/yyyy hh:mm a' }}</td>
              <td class="table_data">{{ repo.distance | meterTokm}}</td>
              <td class="table_data">{{ repo.startPosition?.address}}</td>
              <td class="table_data">{{ repo.endPosition?.address}}</td>
              <td class="table_data">{{ repo.startOdometer | meterTokm }}</td>
              <td class="table_data">{{ repo.endOdometer | meterTokm }}</td>
              <td class="table_data">{{ onSpeedgenerateBasedOnuserPreference(repo.averageSpeed)  | number : '1.0-2' }} {{userData.attributes.speedUnit|translate}}</td>
              <td class="table_data">{{ onSpeedgenerateBasedOnuserPreference(repo.maxSpeed)  | number : '1.0-2' }} {{userData.attributes.speedUnit|translate}}</td>
              <td class="table_data">{{ repo.engineHours | convert:'hh:mm'}}</td>
              <td class="table_data">{{ repo.idleHours | convert:'hh:mm'}}</td>
              <td class="table_data">{{ repo.movingHours | convert:'hh:mm'}}</td>
              <td class="table_data">{{ repo.stopHours | convert:'hh:mm'}}</td>
              <td class="table_data"><div class="btn btn-primary btn-sm mx-1" (click)="playSummary(repo)"
                title="Generate History">
                    <i class="fa fa-play"></i>
                </div></td>

            </tr>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <div
          *ngIf="reportsList.length"
          class="flex_row_center_spacebetween"
          style="padding: 1vh 2vh"
        >
          <div>{{'Showing'|translate}} 1 to 9 of 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div> -->
      </div>
      <!-- summary REPORT  -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Attendance'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 24%" (click)="sorting('userName')">
                {{'Employee Name'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'userName'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'userName'"></i>
              </th>
              <th class="table_head_text" style="width: 15%" (click)="sorting('datetime')">
                {{'Date'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'datetime'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'datetime'"></i>
              </th>
              <th class="table_head_text" style="width: 15%" (click)="sorting('latitude')">
                {{'latitude'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'latitude'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'latitude'"></i>
              </th>
              <th class="table_head_text" style="width: 15%" (click)="sorting('longitude')">
                {{'longitude'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'longitude'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'longitude'"></i></th>
              <th class="table_head_text" style="width: 13%">{{'Photo'|translate}}</th>              
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <tr
              *ngFor="let repo of reportsList |  paginate : {
                id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index"
              style="border-bottom: 1px solid #e3e7ec"
            >
            
              <td class="table_data">{{ repo.userName}}</td>
              <td class="table_data">{{ repo.datetime | date: 'dd/MM/yyyy hh:mm a' }}</td>              
              <td class="table_data">{{ repo.latitude }}</td>
              <td class="table_data">{{ repo.longitude }}</td>
              <td class="table_data">{{ repo.imagePath }}</td>
            </tr>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <div
          *ngIf="reportsList.length"
          class="flex_row_center_spacebetween"
          style="padding: 1vh 2vh"
        >
          <div>{{'Showing'|translate}} 1 to 9 of 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div> -->
      </div>

      <!-- GEOFENCE REPORT  -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Daily Summary'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 15%" (click)="sorting('deviceName')">
                {{'Device Name'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'deviceName'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'deviceName'"></i>
              </th>
              <th class="table_head_text" style="width: 15%" (click)="sorting('startTime')">
                {{'Start Date'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'startTime'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'startTime'"></i>
              </th>
              <th class="table_head_text" style="width: 15%" (click)="sorting('endTime')">
                {{'End Date'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'endTime'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'endTime'"></i>
              </th>
              <th class="table_head_text" style="width: 20%">
                {{'Start Address'|translate}}                
              </th>
              <th class="table_head_text" style="width: 20%">
                {{'End Address'|translate}}                
              </th>
              <th class="table_head_text" style="width: 15%" (click)="sorting('distance')">{{'Distance'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'distance'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'distance'"></i></th>
              <th class="table_head_text" style="width: 13%" (click)="sorting('startOdometer')">{{'Odometer Start'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'startOdometer'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'startOdometer'"></i></th>
              <th class="table_head_text" style="width: 10%" (click)="sorting('endOdometer')">{{'Odometer End'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'endOdometer'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'endOdometer'"></i></th>
              <th class="table_head_text" style="width: 31%" (click)="sorting('averageSpeed')">{{'Average Speed'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'averageSpeed'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'averageSpeed'"></i></th>
              <th class="table_head_text" (click)="sorting('maxSpeed')">{{'Maximum Speed'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'maxSpeed'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'maxSpeed'"></i>
              </th>
              <th class="table_head_text" (click)="sorting('engineHours')">{{'Engine Hours'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'engineHours'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'engineHours'"></i>
              </th>
              <th class="table_head_text">{{'Replay'|translate}}</th>
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <tr
              *ngFor="let repo of reportsList |  paginate : {
                id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index"
              style="border-bottom: 1px solid #e3e7ec"
            >
              <td class="table_data">{{ repo.deviceName }}</td>
              <td class="table_data">{{ repo.startTime | date: 'dd/MM/yyyy hh:mm a' }}</td>
              <td class="table_data">{{ repo.endTime | date: 'dd/MM/yyyy hh:mm a' }}</td>
              <td class="table_data">{{ repo.startPosition?.address }}</td>
              <td class="table_data">{{ repo.endPosition?.address }}</td>
              <td class="table_data">{{ repo.distance| meterTokm  }}</td>
              <td class="table_data">{{ repo.startOdometer  | meterTokm }}</td>
              <td class="table_data">{{ repo.endOdometer  | meterTokm }}</td>
              <td class="table_data">{{ onSpeedgenerateBasedOnuserPreference(repo.averageSpeed) | number : '1.0-2' }} {{userData.attributes.speedUnit|translate}}</td>
              <td class="table_data">{{ onSpeedgenerateBasedOnuserPreference(repo.maxSpeed)  | number : '1.0-2' }} {{userData.attributes.speedUnit|translate}}</td>
              <td class="table_data">{{ repo.engineHours| convert:'hh:mm' }}</td>
              <td class="table_data"><div class="btn btn-primary btn-sm mx-1" (click)="playSummary(repo)"
                title="Generate History">
                    <i class="fa fa-play"></i>
                </div></td>
            </tr>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <div
          *ngIf="reportsList.length"
          class="flex_row_center_spacebetween"
          style="padding: 1vh 2vh"
        >
          <div>{{'Showing'|translate}} 1 to 9 of 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div> -->
      </div>
      <!--  -->

      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Chart'"
      >
        <div class="row">
          <div class="col-4">
            <select
              class="form-select"
              [(ngModel)]="selectChartType"
              name="selectChartType"
              (change)="changeChartType($event.target)"
            >
              <option value="speed" class="select_options">{{'Speed'|translate}}</option>
              <option value="accuracy" class="select_options">{{'Accuracy'|translate}}</option>
              <option value="altitude" class="select_options">{{'Altitude'|translate}}</option>
            </select>
          </div>
          <div class="col-12">
            <div
              id="chart1"
              style="overflow: auto"
              *ngIf="chartOptions?.series?.length && isShowChart"
            >
              <div id="chart">
                <apx-chart
                  [series]="chartOptions.series"
                  [chart]="chartOptions.chart"
                  [xaxis]="chartOptions.xaxis"
                  [stroke]="chartOptions.stroke"
                  [tooltip]="chartOptions.tooltip"
                  [dataLabels]="chartOptions.dataLabels"
                  [legend]="chartOptions.legend"
                  [markers]="chartOptions.markers"
                  [grid]="chartOptions.grid"
                  [yaxis]="chartOptions.yaxis"
                  [title]="chartOptions.title"
                ></apx-chart>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- IDLE REPORT -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Idle Report'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 15%">
                {{'Vehicle Number'|translate}}
              </th>
              <th class="table_head_text" style="width: 15%">{{'Start Time'|translate}}</th>
              <th class="table_head_text" style="width: 13%">{{'End Time'|translate}}</th>
              <th class="table_head_text" style="width: 10%">{{'Duration'|translate}}</th>
              <th class="table_head_text" style="width: 31%">{{'Location'|translate}}</th>
              <th class="table_head_text">{{'AC Status'|translate}}</th>
              <th class="table_head_text"></th>
            </tr>
          </thead>

          <tbody>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding: 1.1vh 0 3vh 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">25 Des, 2020 10:30 AM</td>
              <td class="table_data">25 Des, 2020 10:30 AM</td>
              <td class="table_data">18h:49m</td>
              <td class="table_data" style="border-right: 1px solid #24282e">
                Navsari Rd, Silicon Shoppers, Chandanvan Society, Udhana
                Village, Udhna, Surat, Gujarat 394210
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="runningAll">
                  <span class="running_components">AC On</span>
                  <span class="running_time">18h 17m</span>
                </div>
              </td>
              <td class="p-0">
                <div class="runningAll">
                  <span class="running_components">AC Off</span>
                  <span class="running_time">18h 17m</span>
                </div>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding: 1.1vh 0 3vh 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">25 Des, 2020 10:30 AM</td>
              <td class="table_data">25 Des, 2020 10:30 AM</td>
              <td class="table_data">18h:49m</td>
              <td class="table_data" style="border-right: 1px solid #24282e">
                Navsari Rd, Silicon Shoppers, Chandanvan Society, Udhana
                Village, Udhna, Surat, Gujarat 394210
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="runningAll">
                  <span class="running_components">AC On</span>
                  <span class="running_time">18h 17m</span>
                </div>
              </td>
              <td class="p-0">
                <div class="runningAll">
                  <span class="running_components">AC Off</span>
                  <span class="running_time">18h 17m</span>
                </div>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding: 1.1vh 0 3vh 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">25 Des, 2020 10:30 AM</td>
              <td class="table_data">25 Des, 2020 10:30 AM</td>
              <td class="table_data">18h:49m</td>
              <td class="table_data" style="border-right: 1px solid #24282e">
                Navsari Rd, Silicon Shoppers, Chandanvan Society, Udhana
                Village, Udhna, Surat, Gujarat 394210
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="runningAll">
                  <span class="running_components">AC On</span>
                  <span class="running_time">18h 17m</span>
                </div>
              </td>
              <td class="p-0">
                <div class="runningAll">
                  <span class="running_components">AC Off</span>
                  <span class="running_time">18h 17m</span>
                </div>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding: 1.1vh 0 3vh 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">25 Des, 2020 10:30 AM</td>
              <td class="table_data">25 Des, 2020 10:30 AM</td>
              <td class="table_data">18h:49m</td>
              <td class="table_data" style="border-right: 1px solid #24282e">
                Navsari Rd, Silicon Shoppers, Chandanvan Society, Udhana
                Village, Udhna, Surat, Gujarat 394210
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="runningAll">
                  <span class="running_components">AC On</span>
                  <span class="running_time">18h 17m</span>
                </div>
              </td>
              <td class="p-0">
                <div class="runningAll">
                  <span class="running_components">AC Off</span>
                  <span class="running_time">18h 17m</span>
                </div>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding: 1.1vh 0 3vh 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">25 Des, 2020 10:30 AM</td>
              <td class="table_data">25 Des, 2020 10:30 AM</td>
              <td class="table_data">18h:49m</td>
              <td class="table_data" style="border-right: 1px solid #24282e">
                Navsari Rd, Silicon Shoppers, Chandanvan Society, Udhana
                Village, Udhna, Surat, Gujarat 394210
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="runningAll">
                  <span class="running_components">AC On</span>
                  <span class="running_time">18h 17m</span>
                </div>
              </td>
              <td class="p-0">
                <div class="runningAll">
                  <span class="running_components">AC Off</span>
                  <span class="running_time">18h 17m</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex_row_center_spacebetween" style="padding: 1vh 2vh">
          <div>{{'Showing'|translate}} 1 {{'to'|translate}} 9 {{'of'|translate}} 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div>
      </div>

      <!-- IGNITION REPORT -->
      <!-- <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Ignition Report'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th
                class="table_head_text text-center"
                style="padding-left: 3vh; width: 12%"
              >
                {{'Vehicle Number'|translate}}
              </th>
              <th class="table_head_text text-center" style="width: 21%">
                {{'Date/Time'|translate}}
              </th>
              <th class="table_head_text text-center" style="width: 40%">
                {{'Location'|translate}}
              </th>
              <th class="table_head_text text-center">{{'Details'|translate}}</th>
              <th class="table_head_text text-center"></th>
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <tr
              *ngFor="let repo of reportsList |  paginate : {
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index"
              style="border-bottom: 1px solid #e3e7ec"
            >
              <td
                class="align-items-center table_data text-centerd-flex"
                style="padding: 1.1vh 0 3vh 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />{{ repo.vehicleName }}
              </td>
              <td class="table_data text-center">
                {{ repo.timestamp | date: "medium" }}
              </td>
              <td
                class="table_data text-center"
                style="border-right: 1px solid #24282e"
              >
                {{ repo.address }}
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="runningAll">
                  <span class="running_components">{{'Ignition On'|translate}}</span>
                  <span class="running_time">{{ repo.switch }}</span>
                </div>
              </td>
              <td class="p-0">
                <div class="runningAll">
                  <span class="running_components">{{'Ignition Off'|translate}}</span>
                  <span class="running_time">{{ repo.switch }}</span>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="reportsList.length"
          class="flex_row_center_spacebetween"
          style="padding: 1vh 2vh"
        >
          <div>{{'Showing'|translate}} 1 {{'to'|translate}} 9 {{'of'|translate}} 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div>
      </div> -->

      <!-- LOADING UNLOADING REPORT -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Loading Unloading Report'"
      >
        <table id="report-table"
          *ngIf="group"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 12%">
                {{'Vehicle Number'|translate}}
              </th>
              <th class="table_head_text" style="width: 24%">
                {{'Trip Start Geofence'|translate}}
              </th>
              <th class="table_head_text" style="width: 23%">
                {{'Trip End Geofence'|translate}}
              </th>
              <th class="table_head_text" style="width: 13%">{{'Distance'|translate}}</th>
              <th class="table_head_text">{{'Details'|translate}}</th>
              <th class="table_head_text"></th>
              <th class="table_head_text"></th>
            </tr>
          </thead>

          <tbody>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td colspan="8" style="padding: 1.6vh 1vh">
                <span style="float: right">25 Des, 2020 10:30 AM</span>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding: 1.1vh 0 3vh 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">
                Navsari Rd, Silicon Shoppers, Chandanvan Society, Udhana
                Village, Udhna, Surat, Gujarat 394210
              </td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
              <td class="table_data" style="border-right: 1px solid #24282e">
                192.22Kms
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="runningIcon"
                    />
                    Running</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Utilization %</span>
                  <span class="running_time">192.22Kms</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="stopIcon"
                    />
                    {{'Stop'|translate}}</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Efficiency %</span>
                  <span class="running_time">55Km/Hr</span>
                </div>
              </td>
              <td class="p-0">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="idleIcon"
                    />
                    Idle</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Trips</span>
                  <span class="running_time">02</span>
                </div>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td colspan="8" style="padding: 1.6vh 1vh">
                <span style="float: right">25 Des, 2020 10:30 AM</span>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding: 1.1vh 0 3vh 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">
                Navsari Rd, Silicon Shoppers, Chandanvan Society, Udhana
                Village, Udhna, Surat, Gujarat 394210
              </td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
              <td class="table_data" style="border-right: 1px solid #24282e">
                192.22Kms
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="runningIcon"
                    />
                    Running</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Utilization %</span>
                  <span class="running_time">192.22Kms</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="stopIcon"
                    />
                    {{'Stop'|translate}}</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Efficiency %</span>
                  <span class="running_time">55Km/Hr</span>
                </div>
              </td>
              <td class="p-0">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="idleIcon"
                    />
                    Idle</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Trips</span>
                  <span class="running_time">02</span>
                </div>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td colspan="8" style="padding: 1.6vh 1vh">
                <span style="float: right">25 Des, 2020 10:30 AM</span>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding: 1.1vh 0 3vh 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">
                Navsari Rd, Silicon Shoppers, Chandanvan Society, Udhana
                Village, Udhna, Surat, Gujarat 394210
              </td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
              <td class="table_data" style="border-right: 1px solid #24282e">
                192.22Kms
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="runningIcon"
                    />
                    Running</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Utilization %</span>
                  <span class="running_time">192.22Kms</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="stopIcon"
                    />
                    {{'Stop'|translate}}</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Efficiency %</span>
                  <span class="running_time">55Km/Hr</span>
                </div>
              </td>
              <td class="p-0">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="idleIcon"
                    />
                    Idle</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Trips</span>
                  <span class="running_time">02</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table id="report-table"
          *ngIf="details"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 12%">
                {{'Vehicle Number'|translate}}
              </th>
              <th class="table_head_text" style="width: 24%">{{'Trip Start'|translate}}</th>
              <th class="table_head_text" style="width: 23%">{{'Trip End'|translate}}</th>
              <th class="table_head_text">{{'Details'|translate}}</th>
              <th class="table_head_text"></th>
              <th class="table_head_text"></th>
              <th class="table_head_text"></th>
              <th class="table_head_text"></th>
            </tr>
          </thead>

          <tbody>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td colspan="8" style="padding: 1.6vh 1vh">
                <span style="float: right">25 Des, 2020 10:30 AM</span>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding: 1.1vh 0 3vh 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">
                Navsari Rd, Silicon Shoppers, Chandanvan Society, Udhana
                Village, Udhna, Surat, Gujarat 394210
              </td>
              <td class="table_data" style="border-right: 1px solid #24282e">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components">Loading Start</span>
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Loading Exit</span>
                  <span class="running_time">18h 17m</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components">Unloading Start</span>
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Unloading Exit</span>
                  <span class="running_time">18h 17m</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="runningIcon"
                    />
                    Running</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">{{'Distance(Kms)'|translate}}</span>
                  <span class="running_time">192.22Kms</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="stopIcon"
                    />
                    {{'Stop'|translate}}</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">{{'Duration'|translate}}</span>
                  <span class="running_time">55Km/Hr</span>
                </div>
              </td>
              <td class="p-0">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="idleIcon"
                    />
                    Idle</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Status</span>
                  <span class="running_time">On</span>
                </div>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td colspan="8" style="padding: 1.6vh 1vh">
                <span style="float: right">25 Des, 2020 10:30 AM</span>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding: 1.1vh 0 3vh 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">
                Navsari Rd, Silicon Shoppers, Chandanvan Society, Udhana
                Village, Udhna, Surat, Gujarat 394210
              </td>
              <td class="table_data" style="border-right: 1px solid #24282e">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components">Loading Start</span>
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Loading Exit</span>
                  <span class="running_time">18h 17m</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components">Unloading Start</span>
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Unloading Exit</span>
                  <span class="running_time">18h 17m</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="runningIcon"
                    />
                    Running</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">{{'Distance(Kms)'|translate}}</span>
                  <span class="running_time">192.22Kms</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="stopIcon"
                    />
                    {{'Stop'|translate}}</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">{{'Duration'|translate}}</span>
                  <span class="running_time">55Km/Hr</span>
                </div>
              </td>
              <td class="p-0">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="idleIcon"
                    />
                    Idle</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Status</span>
                  <span class="running_time">On</span>
                </div>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td colspan="8" style="padding: 1.6vh 1vh">
                <span style="float: right">25 Des, 2020 10:30 AM</span>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding: 1.1vh 0 3vh 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">
                Navsari Rd, Silicon Shoppers, Chandanvan Society, Udhana
                Village, Udhna, Surat, Gujarat 394210
              </td>
              <td class="table_data" style="border-right: 1px solid #24282e">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components">Loading Start</span>
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Loading Exit</span>
                  <span class="running_time">18h 17m</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components">Unloading Start</span>
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Unloading Exit</span>
                  <span class="running_time">18h 17m</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="runningIcon"
                    />
                    Running</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">{{'Distance(Kms)'|translate}}</span>
                  <span class="running_time">192.22Kms</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="stopIcon"
                    />
                    {{'Stop'|translate}}</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">{{'Duration'|translate}}</span>
                  <span class="running_time">55Km/Hr</span>
                </div>
              </td>
              <td class="p-0">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="idleIcon"
                    />
                    Idle</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Status</span>
                  <span class="running_time">On</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="flex_row_center_spacebetween" style="padding: 1vh 2vh">
          <div>{{'Showing'|translate}} 1 {{'to'|translate}} 9 {{'of'|translate}} 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div>
      </div>

      <!-- OVER SPEED REPORT -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Over Speed Report'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh">
                {{'Vehicle Number'|translate}}
              </th>
              <th class="table_head_text">{{'Date/Time'|translate}}</th>
              <th class="table_head_text">{{'Overspeed'|translate}}</th>
              <th class="table_head_text">{{'Location'|translate}}</th>
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <tr *ngFor="let repo of reportsList |  paginate : {
              id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index">
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />{{ repo.vehicleName }}
              </td>
              <td class="table_data">{{ repo.timestamp | date: "medium" }}</td>
              <td class="table_data">{{ repo.overSpeed }}</td>
              <td class="table_data">{{ repo.Location }}</td>
            </tr>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="reportsList.length"
          class="flex_row_center_spacebetween"
          style="padding: 1vh 2vh"
        >
          <div>{{'Showing'|translate}} 1 {{'to'|translate}} 9 {{'of'|translate}} 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div>
      </div>

      <!-- POI REPORT -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'POI Report'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 13%">
                {{'Vehicle Number'|translate}}
              </th>
              <th class="table_head_text" style="width: 13%">{{'Device ID'|translate}}</th>
              <th class="table_head_text" style="width: 13%">{{'POI Name'|translate}}</th>
              <th class="table_head_text" style="width: 18%">{{'Arrival Time'|translate}}</th>
              <th class="table_head_text" style="width: 18%">{{'Departure Time'|translate}}</th>
              <th class="table_head_text">{{'Location'|translate}}</th>
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <tr *ngFor="let repo of reportsList |  paginate : {
              id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index">
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />{{ repo.device.Device_Name }}
              </td>
              <td class="table_data">{{ repo.device.Device_ID }}</td>
              <td class="table_data">{{ repo.poi?.poi?.poiname }}</td>
              <td class="table_data">
                {{ repo.arrivalTime | date: "medium" }}
              </td>
              <td class="table_data">
                {{ repo.departureTime | date: "medium" }}
              </td>
              <td class="table_data">{{ repo.poi?.poi?.address }}</td>
            </tr>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="reportsList.length"
          class="flex_row_center_spacebetween"
          style="padding: 1vh 2vh"
        >
          <div>{{'Showing'|translate}} 1 {{'to'|translate}} 9 {{'of'|translate}} 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div>
      </div>

      <!-- ROUTE VIOLATION REPORT -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Route Violation Report'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 20%">
                {{'Vehicle Number'|translate}}
              </th>
              <th class="table_head_text" style="width: 20%">{{'Date/Time'|translate}}</th>
              <th class="table_head_text" style="width: 20%">{{'Route'|translate}}</th>
              <th class="table_head_text">{{'Location'|translate}}</th>
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <tr *ngFor="let repo of reportsList |  paginate : {
              id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index">
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />{{ repo.vehicleName }}
              </td>
              <td class="table_data">{{ repo.timestamp | date: "medium" }}</td>
              <td class="table_data">{{ repo.odo | number: "1.2-2" }}</td>
              <td class="table_data">{{ repo.Location }}</td>
            </tr>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="reportsList.length"
          class="flex_row_center_spacebetween"
          style="padding: 1vh 2vh"
        >
          <div>{{'Showing'|translate}} 1 {{'to'|translate}} 9 {{'of'|translate}} 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div>
      </div>

      <!-- SOS REPORT -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'SOS Report'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 20%">
                {{'Vehicle Number'|translate}}
              </th>
              <th class="table_head_text" style="width: 20%">{{'Date/Time'|translate}}</th>
              <th class="table_head_text" style="width: 20%">{{'Device ID'|translate}}</th>
              <th class="table_head_text">{{'Location'|translate}}</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">DF5845548</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
            </tr>
            <tr>
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">DF5845548</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
            </tr>
            <tr>
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">DF5845548</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
            </tr>
            <tr>
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">DF5845548</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
            </tr>
            <tr>
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">DF5845548</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
            </tr>
            <tr>
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">DF5845548</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex_row_center_spacebetween" style="padding: 1vh 2vh">
          <div>{{'Showing'|translate}} 1 {{'to'|translate}} 9 {{'of'|translate}} 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div>
      </div>

      <!-- SPEED VARIATION REPORT -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Speed Variation Report'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 20%">
                {{'Vehicle Number'|translate}}
              </th>
              <th class="table_head_text" style="width: 20%">{{'Date/Time'|translate}}</th>
              <th class="table_head_text" style="width: 20%">{{'Speed'|translate}}</th>
              <th class="table_head_text">{{'Location'|translate}}</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">55Km/hr</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
            </tr>
            <tr>
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">55Km/hr</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
            </tr>
            <tr>
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">55Km/hr</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
            </tr>
            <tr>
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">55Km/hr</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
            </tr>
            <tr>
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">55Km/hr</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
            </tr>
            <tr>
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">55Km/hr</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex_row_center_spacebetween" style="padding: 1vh 2vh">
          <div>{{'Showing'|translate}} 1 {{'to'|translate}} 9 {{'of'|translate}} 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div>
      </div>

      <!-- STOPPAGE REPORT -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Stoppage Report'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 15%">
                {{'Vehicle Number'|translate}}
              </th>
              <th class="table_head_text" style="width: 17%">{{'From Time'|translate}}</th>
              <th class="table_head_text" style="width: 17%">{{'To Time'|translate}}</th>
              <th class="table_head_text" style="width: 17%">{{'Duration'|translate}}</th>
              <th class="table_head_text">{{'Location'|translate}}</th>
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <tr *ngFor="let repo of reportsList |  paginate : {
              id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index">
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />{{ repo.device.Device_Name }}
              </td>
              <td class="table_data">
                {{ repo.arrival_time | date: "medium" }}
              </td>
              <td class="table_data">
                {{ repo.departure_time | date: "medium" }}
              </td>
              <td class="table_data">{{ repo.duration }}</td>
              <td class="table_data">{{ repo.address }}</td>
            </tr>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="reportsList.length"
          class="flex_row_center_spacebetween"
          style="padding: 1vh 2vh"
        >
          <div>{{'Showing'|translate}} 1 {{'to'|translate}} 9 {{'of'|translate}} 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div>
      </div>

      <!-- SUMMARY REPORT -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Summary Report'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 12%">
                {{'Vehicle Number'|translate}}
              </th>
              <th class="table_head_text" style="width: 22%">{{'Start Point'|translate}}</th>
              <th class="table_head_text" style="width: 21%">{{'End Point'|translate}}</th>
              <th class="table_head_text" style="width: 13%">{{'Distance'|translate}}</th>
              <th class="table_head_text">{{'Details'|translate}}</th>
              <th class="table_head_text"></th>
              <th class="table_head_text"></th>
              <th class="table_head_text"></th>
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <!-- <tr style="border-bottom: 1px solid #e3e7ec;">
              <td colspan="8" style="padding: 1.6vh 1vh;"><span style="float:right;">25 Des, 2020 10:30 AM</span> </td>
            </tr> -->
            <tr
              *ngFor="let repo of reportsList |  paginate : {
                id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index"
              style="border-bottom: 1px solid #e3e7ec"
            >
              <td
                class="align-items-center table_data d-flex"
                style="padding: 1.1vh 0 3vh 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />{{ repo.devObj.Device_Name }}
              </td>
              <td class="table_data">{{ repo.startLocation }}</td>
              <td class="table_data">{{ repo.lastLocation }}</td>
              <td class="table_data" style="border-right: 1px solid #24282e">
                {{ repo.total_odo | number: "1.2-2" }}
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="runningIcon"
                    />
                    Running</span
                  >
                  <span class="running_time">{{
                    repo.today_running | convert
                  }}</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">{{'Trips'|translate}}</span>
                  <span class="running_time">{{ repo.today_trips }}</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="stopIcon"
                    />
                    {{'Stop'|translate}}</span
                  >
                  <span class="running_time">{{
                    repo.today_stopped | convert
                  }}</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">{{'Over Speed'|translate}}</span>
                  <span class="running_time">{{ repo.today_overspeeds }}</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="idleIcon"
                    />
                    Idle</span
                  >
                  <span class="running_time">{{
                    repo.t_idling | convert
                  }}</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">{{'Route Violation'|translate}}</span>
                  <span class="running_time">{{
                    repo.today_routeViolations
                  }}</span>
                </div>
              </td>
              <td class="p-0">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="outofreachIcon"
                    />{{'Out Of Reach'|translate}}</span
                  >
                  <span class="running_time">{{ repo.t_ofr | convert }}</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Fuel Con</span>
                  <span class="running_time">NAN</span>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="reportsList.length"
          class="flex_row_center_spacebetween"
          style="padding: 1vh 2vh"
        >
          <div>{{'Showing'|translate}} 1 {{'to'|translate}} 9 {{'of'|translate}} 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div>
      </div>

      <!-- TRIP REPORT -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Trip Report'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 12%">
                {{'Vehicle Number'|translate}}
              </th>
              <th class="table_head_text" style="width: 14%">{{'From Date'|translate}}</th>
              <th class="table_head_text">{{'From Location'|translate}}</th>
              <th class="table_head_text" style="width: 14%">{{'To Date'|translate}}</th>
              <th class="table_head_text">{{'To Location'|translate}}</th>
              <th class="table_head_text" style="width: 10%">{{'Distance'|translate}}</th>
              <th class="table_head_text" style="width: 5%">{{'Replay'|translate}}</th>
            </tr>
          </thead>

          <tbody>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
              <td class="table_data">192.22Kms</td>
              <td class="table_data" style="text-align: center">
                <img src="/assets/icons/play.png" class="pt-2" alt="play" />
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
              <td class="table_data">192.22Kms</td>
              <td class="table_data" style="text-align: center">
                <img src="/assets/icons/play.png" class="pt-2" alt="play" />
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
              <td class="table_data">192.22Kms</td>
              <td class="table_data" style="text-align: center">
                <img src="/assets/icons/play.png" class="pt-2" alt="play" />
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
              <td class="table_data">192.22Kms</td>
              <td class="table_data" style="text-align: center">
                <img src="/assets/icons/play.png" class="pt-2" alt="play" />
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data">
                Sai Prabhu Complex, Udhna Main Road, Surat - Navsari Rd, Udhna,
                Surat, Gujarat 394210
              </td>
              <td class="table_data">192.22Kms</td>
              <td class="table_data" style="text-align: center">
                <img src="/assets/icons/play.png" class="pt-2" alt="play" />
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex_row_center_spacebetween" style="padding: 1vh 2vh">
          <div>{{'Showing'|translate}} 1 {{'to'|translate}} 9 {{'of'|translate}} 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div>
      </div>

      <!-- USER TRIP REPORT -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'User Trip Report'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 12%">
                {{'Vehicle Number'|translate}}
              </th>
              <th class="table_head_text" style="width: 14%">From Date</th>
              <th class="table_head_text">From {{'Location'|translate}} </th>
              <th class="table_head_text" style="width: 14%">To Date</th>
              <th class="table_head_text">To {{'Location'|translate}} </th>
              <th class="table_head_text" style="width: 10%">{{'Distance'|translate}}</th>
              <th class="table_head_text" style="width: 5%">Replay</th>
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <tr
              *ngFor="let repo of reportsList |  paginate : {
                id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index"
              style="border-bottom: 1px solid #e3e7ec"
            >
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />{{ repo.device.Device_Name }}
              </td>
              <td class="table_data">{{ repo.start_time | date: "medium" }}</td>
              <td class="table_data">{{ repo.startAddress }}</td>
              <td class="table_data">{{ repo.end_time | date: "medium" }}</td>
              <td class="table_data">{{ repo.endAddress }}</td>
              <td class="table_data">{{ repo.distance | number: "1.2-2" }}</td>
              <td class="table_data" style="text-align: center">
                <img src="/assets/icons/play.png" class="pt-2" alt="play" />
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="reportsList.length"
          class="flex_row_center_spacebetween"
          style="padding: 1vh 2vh"
        >
          <div>{{'Showing'|translate}} 1 {{'to'|translate}} 9 {{'of'|translate}} 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div>
      </div>

      <!-- VALUE SCREEN REPORT -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Value Screen Report'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh; width: 15%">
                {{'Vehicle Number'|translate}}
              </th>
              <th class="table_head_text" style="width: 20%">{{'Date/Time'|translate}}</th>
              <th class="table_head_text" style="width: 31%">{{'Location'|translate}}</th>
              <th class="table_head_text">{{'Details'|translate}}</th>
              <th class="table_head_text"></th>
              <th class="table_head_text"></th>
              <th class="table_head_text"></th>
            </tr>
          </thead>

          <tbody>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td colspan="8" style="padding: 1.6vh 1vh">
                <span style="float: right">25 Des, 2020 10:30 AM</span>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding: 1.1vh 0 3vh 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data" style="border-right: 1px solid #24282e">
                Navsari Rd, Silicon Shoppers, Chandanvan Society, Udhana
                Village, Udhna, Surat, Gujarat 394210
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="runningIcon"
                    />
                    Running</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">{{'Distance'|translate}}</span>
                  <span class="running_time">192.22Kms</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="stopIcon"
                    />
                    {{'Stop'|translate}}</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Max Speed</span>
                  <span class="running_time">55Km/Hr</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="idleIcon"
                    />
                    Idle</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Trips</span>
                  <span class="running_time">02</span>
                </div>
              </td>
              <td class="p-0">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="outofreachIcon"
                    />{{'Out Of Reach'|translate}}</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Fuel Con</span>
                  <span class="running_time">N/A Litre</span>
                </div>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td colspan="8" style="padding: 1.6vh 1vh">
                <span style="float: right">25 Des, 2020 10:30 AM</span>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding: 1.1vh 0 3vh 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data" style="border-right: 1px solid #24282e">
                Navsari Rd, Silicon Shoppers, Chandanvan Society, Udhana
                Village, Udhna, Surat, Gujarat 394210
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="runningIcon"
                    />
                    Running</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">{{'Distance'|translate}}</span>
                  <span class="running_time">192.22Kms</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="stopIcon"
                    />
                    {{'Stop'|translate}}</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Max Speed</span>
                  <span class="running_time">55Km/Hr</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="idleIcon"
                    />
                    Idle</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Trips</span>
                  <span class="running_time">02</span>
                </div>
              </td>
              <td class="p-0">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="outofreachIcon"
                    />{{'Out Of Reach'|translate}}</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Fuel Con</span>
                  <span class="running_time">N/A Litre</span>
                </div>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td colspan="8" style="padding: 1.6vh 1vh">
                <span style="float: right">25 Des, 2020 10:30 AM</span>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e7ec">
              <td
                class="align-items-center table_data d-flex"
                style="padding: 1.1vh 0 3vh 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />DL12CG1001
              </td>
              <td class="table_data">01 Des, 2020 10:30 AM</td>
              <td class="table_data" style="border-right: 1px solid #24282e">
                Navsari Rd, Silicon Shoppers, Chandanvan Society, Udhana
                Village, Udhna, Surat, Gujarat 394210
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="runningIcon"
                    />
                    Running</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">{{'Distance'|translate}}</span>
                  <span class="running_time">192.22Kms</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="stopIcon"
                    />
                    {{'Stop'|translate}}</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Max Speed</span>
                  <span class="running_time">55Km/Hr</span>
                </div>
              </td>
              <td class="p-0" style="border-right: 1px solid #24282e">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="idleIcon"
                    />
                    Idle</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Trips</span>
                  <span class="running_time">02</span>
                </div>
              </td>
              <td class="p-0">
                <div class="upperRunningHead">
                  <span class="running_components"
                    ><img
                      src="/assets/icons/small_car.png"
                      alt="smallCar"
                      class="outofreachIcon"
                    />{{'Out Of Reach'|translate}}</span
                  >
                  <span class="running_time">18h 17m</span>
                </div>
                <div class="lowerRunningHead">
                  <span class="running_components">Fuel Con</span>
                  <span class="running_time">N/A Litre</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="flex_row_center_spacebetween" style="padding: 1vh 2vh">
          <div>{{'Showing'|translate}} 1 {{'to'|translate}} 9 {{'of'|translate}} 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div>
      </div>

      <!-- WORKING HOURS REPORT -->
      <div
        class="card-body common_scroller fixed_height p-0"
        *ngIf="reportsName == 'Working Hours Report'"
      >
        <table id="report-table"
          class="table table-borderless m-0"
          style="border-bottom: 1px solid #e3e7ec"
        >
          <thead class="bg-light">
            <tr>
              <th class="table_head_text" style="padding-left: 3vh">
                {{'Vehicle Number'|translate}}
              </th>
              <th class="table_head_text">{{'Working Hours'|translate}}</th>
              <th class="table_head_text">{{'Stopping hours'|translate}}</th>
              <th class="table_head_text">{{'Date/Time'|translate}}</th>
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <tr *ngFor="let repo of reportsList |  paginate : {
              id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index">
              <td
                class="align-items-center table_data d-flex"
                style="padding-left: 3vh"
              >
                <img
                  src="/assets/icons/table_vehicle.png"
                  alt="car"
                  style="margin-right: 1vh"
                />{{ repo.deviceName }}
              </td>
              <td class="table_data">{{ repo.workingHours }}</td>
              <td class="table_data">{{ repo.stopHours }}</td>
              <td class="table_data">
                {{ repo.ign[0].timestamp | date: "medium" }}
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!reportsList.length">
            <tr>
              <td colspan="8">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 40vh"
                >
                <i *ngIf="IsLoading; else notFound" class="fa fa-spinner fa-spin" style="font-size:48px;color:red"></i>
                 
                  <ng-template #notFound>
                    <img 
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                  </ng-template>
                 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="reportsList.length"
          class="flex_row_center_spacebetween"
          style="padding: 1vh 2vh"
        >
          <div>{{'Showing'|translate}} 1 {{'to'|translate}} 9 {{'of'|translate}} 9 {{'entries'|translate}}</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div>
      </div>
    </div>
    <div *ngIf="reportsList.length" class="pagination-flex_row_center_spacebetween"
        style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
        <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{reportsList.length/pageOption | number: '1.0-0'}} {{'of'|translate}}
          {{reportsList.length}} {{'entries'|translate}}</div>
        <div class="d-flex">
          <select
            [ngStyle]="{ width: '200px', 'margin-left': '10px' }"
            class="form-select selectimportant"
            [(ngModel)]="pageOption"
            name="pageOption"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="60">60</option>
            <option value="{{reportsList.length}}">All</option>
          </select>
          <pagination-controls (pageChange)="pageChange($event)" id="rp1"></pagination-controls>
        </div>
      </div>
  </div>
</div>
</app-common-modal-popup>
<!-- DISTANCE REPORT MODAL -->

<div
  class="modal fade"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5
          *ngFor="let name of DeviceNameList; let i = index"
          class="modal-title"
          id="staticBackdropLabel"
        >
          {{ name }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body p-0">
        <table id="report-table" class="table table-borderless m-0">
          <thead class="bg-light">
            <tr>
              <th
                class="table_head_text text-center"
                style="padding-left: 3vh; width: 15%"
              >
                {{'Date'|translate}}
              </th>
              <th class="table_head_text text-center" style="width: 15%">
                {{'Start Time'|translate}}
              </th>
              <th class="table_head_text text-center" style="width: 13%">
                {{'End Time'|translate}}
              </th>
              <th class="table_head_text text-center" style="width: 10%">
                {{'Distance'|translate}}
              </th>
            </tr>
          </thead>

          <tbody *ngIf="reportsList.length">
            <tr *ngFor="let repo of reportsList |  paginate : {
              id: 'rp1',
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: reportsList.length
            };let i=index">
              <td class="table_data text-center">
                {{
                  repo.device?.status_updated_at
                    ? (repo.device.status_updated_at | date: "mediumDate")
                    : ""
                }}
              </td>
              <td class="table_data text-center">{{ repo.startLocation }}</td>
              <td class="table_data text-center">{{ repo.LastLocation }}</td>
              <td class="table_data text-center">
                {{ repo.distance | number: "1.2-2" }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="total_row">
          <span class="total_text">{{'Total'|translate}}</span>
          <span class="total_text">3 Day : 25 Hr : 22 Min : 56 Sec</span>
          <span class="total_text">4713 Kms</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="htmlString-container" #pdfTable [innerHtml]="htmlString" style="display: none;"></div>
