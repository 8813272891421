import { Component, OnInit } from '@angular/core';
// import { MouseEvent } from '@agm/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { GeofenceService } from 'src/app/services/geofence.service';
import { ValidationService } from 'src/app/services/validation.service';
import { CommonService } from 'src/app/services/common.service';
declare const google: any;
declare var $: any;

@Component({
  selector: 'app-geofences',
  templateUrl: './geofences.component.html',
  styleUrls: ['./geofences.component.css'],
})
export class GeofencesComponent implements OnInit {
  zoom: number = 8;
  lat: number;
  lng: number;
  searchGeo = '';
  radius = '';
  userId = localStorage.getItem('userId');
  circleRadius;
  polygons = true;
  circular = false;
  maincard = true;
  addpolygon = false;
  addcircular = false;
  checked = false;
  disabled = false;
  polygonGeofence = true;
  circularGeofence = false;
  fullScreen = false;
  googleMap = true;
  drawPolygonGeofence = false;
  drawCircularGeofence = false;
  submitted = false;
  color: ThemePalette = 'accent';
  geofenceList = [];
  poiList = [];
  polygonList: any[];
  polyArrayLatLng = [];
  circleArrayLatLng = [];
  geonameArray = [];
  polygon: any;

  polygonForm: FormGroup;
  circularForm: FormGroup;

  map: any;

  dd: any = [
    {
      geofence: {
        coordinates: [
          [
            [30.340540087432323, 78.04063285692901],
            [30.29608489517005, 78.09075797899932],
            [30.336391789690683, 77.89918388231963],
            [30.405704529085938, 77.88682426317901],
            [30.406888934979417, 78.29057182177276],
            [30.381421042396628, 78.28439201220245],
            [30.340540087432323, 78.04063285692901],
          ],
        ],
        type: 'Polygon',
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      id: '61ae1a0d0584d2483b86d60e',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'new Geofences',
      entering: true,
      exiting: true,
      type: 'Unloading',
      status: false,
      circle_center: [],
      v: 0,
      vehicleGroup: null,
    },
    {
      geofence: {
        coordinates: [
          [
            [30.480710635331903, 78.02569103134559],
            [30.33858686954149, 78.25640392197059],
            [30.234231418847923, 78.06414317978309],
            [30.490178192225784, 77.69610118759559],
            [30.480710635331903, 78.02569103134559],
          ],
        ],
        type: 'Polygon',
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '61c18cb5edaf507ea9d21379',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'pimpari',
      entering: true,
      exiting: true,
      type: 'Loading',
      status: true,
      circle_center: [],
      v: 0,
      vehicleGroup: null,
    },
    {
      geofence: {
        type: 'Polygon',
        coordinates: [
          [
            [73.728417, 18.616194],
            [73.731056, 18.61653],
            [73.730037, 18.615782],
            [73.728938, 18.615167],
            [73.728417, 18.616194],
          ],
        ],
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '61c3152fcebac3309f069fdd',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'chinchavad',
      entering: true,
      exiting: true,
      type: 'Loading',
      status: true,
      circle_center: [],
      v: 0,
      vehicleGroup: null,
    },
    {
      geofence: {
        type: 'Polygon',
        coordinates: [
          [
            [18.548322, 73.793796],
            [18.609436, 73.847954],
            [18.577417, 73.902886],
            [18.514814, 73.841609],
            [18.548322, 73.793796],
          ],
        ],
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: false,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '628f1759263b9503701ce7c6',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'Farm1',
      entering: true,
      exiting: true,
      status: true,
      circle_center: [],
      v: 0,
      type: 'Unloading',
    },
    {
      geofence: {
        type: 'Polygon',
        coordinates: [
          [
            [73.904012, 18.594385],
            [73.904173, 18.595056],
            [73.905353, 18.595173],
            [73.905648, 18.594344],
            [73.905187, 18.59376],
            [73.904012, 18.594385],
          ],
        ],
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: false,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '62a84609f0d5c03267be935e',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'home',
      entering: true,
      exiting: false,
      type: 'Loading',
      status: true,
      circle_center: [],
      v: 0,
    },
    {
      geofence: {
        coordinates: [
          [
            [19.443354490073318, 81.95704227639173],
            [18.97649898052674, 82.38550907326673],
            [18.872574205562298, 81.84717899514173],
            [19.215279630450194, 81.74830204201673],
            [19.443354490073318, 81.95704227639173],
          ],
        ],
        type: 'Polygon',
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '62a96ce4008c3c0585c00d9d',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'abnuj second poly 2',
      entering: true,
      exiting: true,
      type: 'Unloading',
      status: true,
      circle_center: [],
      v: 0,
    },
    {
      geofence: {
        type: 'Polygon',
        coordinates: [
          [
            [21.68594279230725, 78.63558466949462],
            [21.205337286988406, 79.06405146636962],
            [21.097752507367517, 78.56966670074462],
            [21.40492956020722, 78.41585810699462],
            [21.68594279230725, 78.63558466949462],
          ],
        ],
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '62c56b416f5a8732069e77f6',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'tester',
      entering: true,
      exiting: true,
      type: 'Loading',
      status: true,
      circle_center: [],
      v: 0,
    },
    {
      geofence: {
        type: 'Polygon',
        coordinates: [
          [
            [28.684576017318168, 77.27052673563996],
            [28.632156091045463, 77.29936584696809],
            [28.63155341081541, 76.86815246806184],
            [28.75984608375382, 76.87501892313996],
            [28.684576017318168, 77.27052673563996],
          ],
        ],
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '62c56c1b289c9631eeea8f84',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'tester ',
      entering: true,
      exiting: true,
      type: 'Loading',
      status: true,
      circle_center: [],
      _v: 0,
    },
  ];

  constructor(
    private geofence: GeofenceService,
    private fb: FormBuilder,
    private customValidator: ValidationService,
    private commonService: CommonService
  ) {
    (this.polygonForm = this.fb.group({
      fencename: ['', Validators.required],
      geoaddress: [''],
      // type: ['Loading', Validators.required],
      // checkhalt: [false],
      // time: ['60', Validators.compose([Validators.required])],
      // enableDisplay: [false],
      // enableEntering: [false],
      // enableExiting: [false],
    })),
      (this.circularForm = this.fb.group({
        circularName: ['', Validators.required],
        circularAddress: [''],
        // checkhalt: [false],
        // time: ['60', Validators.compose([Validators.required])],
        // radius: ['', Validators.required],
        // status: [false],
        // enableEntering1: [false],
        // enableExiting1: [false],
      }));
  }

  ngOnInit(): void {
    this.getAllGeofence();
  }

  get polygonFormControl() {
    return this.polygonForm.controls;
  }

  get circularFormControl() {
    return this.circularForm.controls;
  }

  polygonDrawingManager(map: any) {
    const managerOptions = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
        fillColor: 'red',
      },
      drawingMode: 'polygon',
    };
    const drawingManager = new google.maps.drawing.DrawingManager(
      managerOptions
    );
    drawingManager.setMap(map);
    google.maps.event.addListener(
      drawingManager,
      'polygoncomplete',
      (polygon) => {
        const len = polygon.getPath().getLength();
        this.polyArrayLatLng = [];

        for (let i = 0; i < len; i++) {
          const vertex = polygon.getPath().getAt(i);
          const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
          this.polyArrayLatLng.push(vertexLatLng);
        }
        this.polyArrayLatLng.push(this.polyArrayLatLng[0]);
        console.log(this.polyArrayLatLng);
      }
    );
  }

  circularDrawingManager(map: any) {
    const circularOptions = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['circle'],
      },
      circularOptions: {
        draggable: true,
        editable: true,
      },
      drawingMode: 'circle',
    };
    console.log('drawingManager', google);
    const drawingManager = new google.maps.drawing.DrawingManager(
      circularOptions
    );
    drawingManager.setMap(map);
    google.maps.event.addListener(drawingManager, 'circlecomplete', (event) => {
      console.log('event', event);
      var circle;
      circle = event;
      this.circleRadius = circle.getRadius();
      this.circularForm.value.radius = this.circleRadius;
      this.circleArrayLatLng = [
        circle.getCenter().lat(),
        circle.getCenter().lng(),
      ];
      console.log(this.circleArrayLatLng);
    });
  }

  onPolygonSubmit() {
    console.log('this.polygonForm', this.polygonForm);
    if (this.polygonForm.invalid) {
      this.polygonForm.controls.fencename.markAsTouched();
      return;
    }
    // var body = {
    //   uid: this.userId,
    //   geofence: [],
    //   geoname: this.polygonForm.value.fencename,
    //   type: this.polygonForm.value.type,
    //   halt_notif: this.polygonForm.value.checkhalt,
    //   halt_time: this.polygonForm.value.time,
    //   entering: this.polygonForm.value.enableEntering,
    //   exiting: this.polygonForm.value.enableExiting,
    // };
    if (this.polyArrayLatLng?.length) {
      let latLng = [];
      this.polyArrayLatLng.forEach((p) => {
        console.log('------------------p', p);
        latLng.push(p.lat + ' ' + p.lng);
      });
      let dataSet = {
        id: -2,
        name: this.polygonForm.value.fencename,
        description: this.polygonForm.value.fencename,
        area: 'POLYGON((' + latLng.join(', ') + '))',
        calendarId: 0,
        attributes: {},
      };
      console.log('this.polyArrayLatLng', dataSet);

      this.commonService.postRequest('geofences', dataSet).then((resp: any) => {
        if (resp) {
          this.polygonForm.reset();
          this.drawPolygonGeofence = false;
          this.addpolygon = false;
          this.onshowPolygonList();
          this.maincard = true;
          this.googleMap = true;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Geofence Added Successfully!',
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.getAllGeofence();
      });
      // var arr = [];
      // for (let i = 0; i < this.polyArrayLatLng.length; i++) {
      //   arr.push([this.polyArrayLatLng[i].lng, this.polyArrayLatLng[i].lat]);
      // }
      // body.geofence.push(arr);

      // this.geofence.addingGeofence(body).subscribe((resp) => {
      //   console.log(resp);
      //   this.polygonForm.reset();
      //   this.drawPolygonGeofence = false;
      //   this.addpolygon = false;
      //   this.onshowPolygonList();
      //   this.maincard = true;
      //   this.googleMap = true;
      //   Swal.fire({
      //     position: 'center',
      //     icon: 'success',
      //     title: 'Geofence Added Successfully!',
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      // });
    } else {
      alert('Pease Create Polygon Geofence.');
    }
  }

  onCircularSubmit() {
    let error = false;

    console.log('dataSet---------', this.circularForm);
    if (this.circularForm.invalid) {
      this.circularForm.controls.circularName.markAsTouched();
      return;
    }

    // var body = {
    //   halt_notif: this.circularForm.value.checkhalt,
    //   halt_time: this.circularForm.value.time,
    //   poi: [
    //     {
    //       address: this.circularForm.value.circularAddress,
    //       entering: this.circularForm.value.enableEntering1,
    //       exiting: this.circularForm.value.enableExiting1,
    //       location: {
    //         coordinates: this.circleArrayLatLng,
    //         type: 'Point',
    //       },
    //       poiname: this.circularForm.value.circularName,
    //       radius: this.circleRadius,
    //       status: this.circularForm.value.status ? 'Active' : 'Inactive',
    //       user: this.userId,
    //     },
    //   ],
    // };
    // console.log(body);
    console.log('dataSet---------', this.circleArrayLatLng);
    if (this.circleArrayLatLng?.length) {
      console.log('dataSet', this.circleArrayLatLng);
      let dataSet = {
        id: -1,
        name: this.circularForm.value.circularName,
        description: this.circularForm.value.circularName,
        area:
          'CIRCLE (' +
          this.circleArrayLatLng[0] +
          ' ' +
          this.circleArrayLatLng[1] +
          ', ' +
          this.circleRadius +
          ')',
        calendarId: 0,
        attributes: {},
      };
      this.commonService.postRequest('geofences', dataSet).then((resp: any) => {
        if (resp) {
          console.log(resp);
          this.circularForm.reset();
          this.circleArrayLatLng = [];
          this.drawCircularGeofence = false;
          this.addcircular = false;
          this.onshowCircularList();
          this.maincard = true;
          this.googleMap = true;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Geofence Added Successfully!',
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.getAllGeofence();
      });
      // this.geofence.addPoi(body).subscribe((resp) => {
      //   console.log(resp);
      //   this.circularForm.reset();
      //   this.circleArrayLatLng = [];
      //   this.drawCircularGeofence = false;
      //   this.addcircular = false;
      //   this.onshowCircularList();
      //   this.maincard = true;
      //   this.googleMap = true;
      //   Swal.fire({
      //     position: 'center',
      //     icon: 'success',
      //     title: 'Geofence Added Successfully!',
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      // });
    } else {
      alert('Pease Create Circular Geofence.');
    }
  }

  getFullWidth() {
    if (this.fullScreen) {
      document.getElementById('closeFullMap').style.display = 'flex';
      document.getElementById('myMap').style.width = '66.6%';
    } else {
      document.getElementById('myMap').style.width = '100%';
      document.getElementById('myMap').style.paddingLeft = '0';
      document.getElementById('closeFullMap').style.display = 'none';
    }
    this.fullScreen = !this.fullScreen;

    this.map = new google.maps.Map(document.getElementById('myMap'), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 8,
    });
  }

  getAllGeofence() {
    this.circular = false;
    this.polygons = true;
    this.zoom = 6;
    this.lat = 20.5937;
    this.lng = 78.9629;

    this.commonService
      .getRequest('geofences?all=true', [])
      .then((resp: any) => {
        if (resp?.status) {
          
          this.geofenceList = [];

          this.polygonList = [];
          resp.data.forEach(async (r, k) => {
            this.geofenceList.push(r);
            await this.parseGeoJson(r, k);
            let arr = [];

            if (this.geofenceList[k]?.geofence?.coordinates?.length) {
              for (
                let j = 0;
                j < this.geofenceList[k].geofence.coordinates.length;
                j++
              ) {
                arr.push({
                  lat: this.geofenceList[k].geofence.coordinates[j].lat,
                  lng: this.geofenceList[k].geofence.coordinates[j].long,
                });
              }
            }
            let data = {
              coordinates: arr,
              type: r?.geofence?.type,
              show: true,
            };
            this.polygonList.push(data);

          });

          setTimeout(() => {
            console.log('-----------', this.geofenceList);
          }, 2000);

          // for (let i = 0; i < this.geofenceList.length; i++) {
          //   var arr = [];
          //   for (
          //     let j = 0;
          //     j < this.geofenceList[i].geofence.coordinates.length;
          //     j++
          //   ) {
          //     arr.push({
          //       lat: this.geofenceList[i].geofence.coordinates[j].lat,
          //       lng: this.geofenceList[i].geofence.coordinates[j].long,
          //     });
          //   }
          //   var data = {
          //     coordinates: arr,
          //     type: this.geofenceList[i].geofence.type,
          //     show: true,
          //   };
          //   this.polygonList.push(data);
          // }
          console.log(this.polygonList);
        }
      });
  }

  parseGeoJson(geofence, key) {
    return new Promise((resolve) => {
    
      if (geofence.area.indexOf('CIRCLE') > -1) {
        const coordinates = geofence.area
          .replace(/CIRCLE|\(|\)|,/g, ' ')
          .trim()
          .split(/ +/);

        this.geofenceList[key]['geofence'] = {
          type: 'Point',
          coordinates: [parseFloat(coordinates[0]), parseFloat(coordinates[1])],
          radius: parseFloat(coordinates[2]),
        
        };
        this.geofenceList[key]['show'] = true;
        resolve(true);
      } else if (geofence.area.indexOf('POLYGON') > -1) {
        const coordinates = geofence.area
          .replace(/POLYGON|\((|\))|,/g, ' ')
          .trim()
          .split(/ +/);

        let dataSet = [];
        let arr = [];
        coordinates.forEach((c, k) => {
          if (k % 2 === 1) {
            dataSet.push({lat:parseFloat(arr[0]),lng: parseFloat(c)});
            arr = [];
          } else {
            arr.push(c);
          }
        });
        


        this.geofenceList[key]['geofence'] = {
        
          id: geofence.id,
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: dataSet,
          },
          properties: { name: geofence.name },
        };
        this.geofenceList[key]['show'] = true;
       
        resolve(true);
      } else {
        resolve(true);
      }
    });
  }

  getAllPoiList() {
    this.polygons = false;
    this.circular = true;
    // this.geofence.allPoiList().subscribe((resp) => {
    //   console.log(resp);
    //   this.poiList = resp.data;
    //   for (let i = 0; i < this.poiList.length; i++) {
    //     this.poiList[i].show = true;
    //   }
    //   console.log(this.poiList);
    //   this.lat = resp.data[0].poi.location.coordinates[0];
    //   this.lng = resp.data[0].poi.location.coordinates[1];
    // });
  }

  polygonChanged(e, index) {
    this.polygonList[index].show = e.checked;
  }

  circularChanged(e, index) {
    this.poiList[index].show = e.checked;
  }

  onDeletePolygon(geoid) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this geofence!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        // this.geofence.deletingGeofence(geoid).subscribe((resp) => {
        //   console.log(resp);
        // });
        this.commonService
          .deleteRequest('geofences/' + geoid)
          .then((resp: any) => {
            console.log('-------', resp);
          });
        this.onshowPolygonList();
        Swal.fire('Deleted!', 'Your geofence has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your geofence is safe :)', 'error');
      }
    });
  }

  onDeletePoi(poiId) {
    console.log('poiId', poiId);
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this geofence!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        // this.geofence.deletePoi(poiId).subscribe((resp) => {
        //   console.log(resp);
        // });
        this.onshowCircularList();
        Swal.fire('Deleted!', 'Your geofence has been deleted.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your geofence is safe :)', 'error');
      }
    });
  }

  onclickAddPolygon() {
    this.polygons = false;
    this.circular = false;
    this.maincard = false;
    this.addcircular = false;
    this.googleMap = false;
    this.drawCircularGeofence = false;
    this.drawPolygonGeofence = true;
    this.addpolygon = true;
  }

  onclickAddCircular() {
    this.polygons = false;
    this.circular = false;
    this.maincard = false;
    this.addpolygon = false;
    this.googleMap = false;
    this.drawPolygonGeofence = false;
    this.drawCircularGeofence = true;
    this.addcircular = true;

    console.log('-----------', this.lat, this.lng, this.zoom);
  }

  onCancelPolygon() {
    this.drawPolygonGeofence = false;
    this.addpolygon = false;
    this.circularGeofence = false;
    this.polygonGeofence = true;
    this.googleMap = true;
    this.maincard = true;
    this.getAllGeofence();
  }

  onCancelCircular() {
    this.drawCircularGeofence = false;
    this.addcircular = false;
    this.polygonGeofence = false;
    this.circularGeofence = true;
    this.googleMap = true;
    this.maincard = true;
    this.getAllPoiList();
  }

  onEditPolygon(geo) {
    this.maincard = false;
    this.googleMap = false;
    this.drawPolygonGeofence = true;
    this.addpolygon = true;
    this.polygonForm.value.fencename = geo.geoname;
    this.polygonForm.value.type = geo.type;
    this.polygonForm.value.enableEntering = geo.entering;
    this.polygonForm.value.enableExiting = geo.exiting;
  }

  onEditPoi() {
    this.maincard = false;
    this.googleMap = false;
    this.drawCircularGeofence = true;
    this.addcircular = true;
  }

  onshowPolygonList() {
    this.circularGeofence = false;
    this.polygonGeofence = true;
    this.getAllGeofence();
  }

  onshowCircularList() {
    this.polygonGeofence = false;
    this.circularGeofence = true;
    this.getAllPoiList();
  }

  values(event) {
    if (this.polygons) {
      if (event.target.value) {
        this.geofenceList = this.geofenceList.filter((s) =>
          s.geoname.includes(event.target.value)
        );
        console.log(this.geofenceList);
      } else {
        this.geofence.allGeofence().subscribe((resp) => {
          this.geofenceList = resp.data;
        });
      }
    } else if (this.circular) {
      if (event.target.value) {
        this.poiList = this.poiList.filter((s) =>
          s.poi.poiname.includes(event.target.value)
        );
        console.log(this.poiList);
      } else {
        this.geofence.allPoiList().subscribe((resp) => {
          this.poiList = resp.data;
        });
      }
    }
  }

  zoomCircle(geo) {
    // const map = new google.maps.Map(document.getElementById('myMap'));
    // map.setCenter(
    //   new google.maps.LatLng(
    //     geo.geofence.coordinates[0],
    //     geo.geofence.coordinates[1]
    //   )
    // );
    // map.setZoom(10);

    // const cityCircle = new google.maps.Circle({
    //   strokeColor: '#FF0000',
    //   strokeOpacity: 0.8,
    //   strokeWeight: 2,
    //   fillColor: '#FF0000',
    //   fillOpacity: 0.35,
    //   map,
    //   center: {
    //     lat: geo.geofence.coordinates[0],
    //     lng: geo.geofence.coordinates[1],
    //   },
    //   radius: geo.geofence.radius,
    // });
    this.zoom = 8;
    this.lat = 0;
    this.lng = 0;
    this.lat =  geo.geofence.coordinates[0];
    this.lng =  geo.geofence.coordinates[1];
  }

  zoomPolygon(geo) {
    this.zoom = 8;
    this.lat = 0;
    this.lng = 0;
    this.lat = geo.geofence.geometry.coordinates[0].lat;
    this.lng = geo.geofence.geometry.coordinates[0].lng;
    
    
     }
}
