import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'searchPipe'
  })
  export class searchPipe implements PipeTransform {
    transform(values: any[], filter: string,filterKey:string = ''): any {
      if (!values || !values.length) return [];
      if (!filter) return values;
  
      filter = filter.toUpperCase();
  
      if (filter && Array.isArray(values)) {
        if(filterKey) {
          const keys = Object.keys(values[0][filterKey]);
          return values.filter(valData => {
            let v = valData[filterKey];
            return v && keys.some(k => v[k] && v[k].toString().toUpperCase().indexOf(filter) >= 0)
          });
        } else {
          const keys = Object.keys(values[0]);
          return values.filter(v => v && keys.some(k => v[k] && v[k].toString().toUpperCase().indexOf(filter) >= 0));
        }
        
      }
    }
  }