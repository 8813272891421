import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { url } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

// --------------------------------------- ALL HEADER , DASHBOARD & TRACKING PAGE APIS ----------------------------------------------
export class DashboardDataService {
  baseUrl = url;
  socketBaseUrl = 'https://socket.oneqlik.in/';
  userId = localStorage.getItem('userId');
  userEmail = '';
  todaysDate: any;
  yesterday: any;
  yesterdayDate: any;
  initialDate: any;
  lastDate: any;

  loader = false;
  notificationArray = [];
  Language = new Subject<string>();
  DeviceId = new Subject<string>();
  vehiclesCountComponent = new Subject<boolean>();

  constructor(private dashboard: HttpClient) {
    // try{
    // this.userEmail = JSON.parse(window.atob(window.localStorage.token.split('.')[1])).email;
    // }catch(err){
    //   console.log(err);
    // }
  }

  getState(latitude, longitude) {
    let _url =
      'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
      latitude +
      ',' +
      longitude +
      '&key=AIzaSyCy51wsYZFscnibD6aH3h38nlwjf8WQP_s';
    return this.dashboard.get<any>(_url);
  }

  calculateDates() {
    this.todaysDate = new Date().toISOString();
    const yesterday = new Date(this.todaysDate);
    yesterday.setDate(yesterday.getDate() - 1);
    this.yesterdayDate = yesterday.toISOString();
    console.log(this.todaysDate, this.yesterdayDate);
  }

  getDashbaord() {
    this.calculateDates();
    let _url = this.baseUrl + 'gps/getDashboard';
    let httpOptions = {
      params: {
        email: this.userEmail,
        from: this.yesterdayDate,
        to: this.todaysDate,
        id: this.userId,
      },
    };
    return this.dashboard.get<any>(_url, httpOptions);
  }

  dashboard_expense() {
    let _url = this.baseUrl + 'expense/dashboardExpense';
    let httpOptions = {
      params: { id: this.userId },
    };
    return this.dashboard.get<any>(_url, httpOptions);
  }

  getFuelPrice(userstate) {
    let _url = this.socketBaseUrl + 'googleAddress/getFuelPrice1';
    let httpOptions = {
      params: { state: userstate },
    };
    return this.dashboard.get<any>(_url, httpOptions);
  }

  vehicleUtilizePercent() {
    let _url = this.baseUrl + 'gps/vehicleUtilizationPercentage';
    let httpOptions = {
      params: {
        role: 'User',
        id: this.userId,
      },
    };
    return this.dashboard.get<any>(_url, httpOptions);
  }

  shareProfileImage() {
    let _url = this.baseUrl + 'users/shareProfileImage';
    let httpOptions = {
      params: { uid: this.userId },
    };
    return this.dashboard.get<any>(_url, httpOptions);
  }

  searchDeviceName() {
    let _url = this.baseUrl + 'devices/getDeviceByUserDropdown';
    let httpOptions = {
      params: { id: this.userId },
    };
    return this.dashboard.get<any>(_url, httpOptions);
  }

  

  // --------------------------------------------------- TRACKING PAGE APIS -------------------------------------------------------------

  getAllDeviceByUserEmail() {
    let _url = this.baseUrl + 'devices/getDeviceByUserMobile';
    let httpOptions = {
      params: {
        id: this.userId,
        email: this.userEmail,
        skip: '0',
        limit: '10',
      },
    };
    return this.dashboard.get<any>(_url, httpOptions);
  }

  historyTrack(device_id, from, to) {
    console.log('device_id, from, to', device_id, from, to);
    let _url = this.baseUrl + 'gps';
    let httpOptions = {
      params: {
        id: device_id,
        from: from,
        to: to,
      },
    };
    return this.dashboard.get<any>(_url, httpOptions);
  }
}
