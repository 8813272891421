<div class="container-fluid p-0">
  <div class="row m-0" style="height: 88vh;">

    <div class="col-3 pb-1 pt-3 px-0">
      <div class="card side_card">
        <div class="card-header" style="border-bottom: 1px solid #324151;">
          <div class="cardHead_text">
            {{ 'Account Settings' | translate }}
          </div>
        </div>
        <ul class="list-group">
          <li (click)="onClickSettings('general')" class="list-group-item">
            <span class="material-icons list_icon">manage_accounts</span>
            <span class="list_text">{{'General Setting' | translate }}</span>
          </li>
          <li class="list-group-item">
            <span class="material-icons list_icon">settings_applications</span>
            <span class="list_text">{{'Trip Setting' | translate }}</span>
          </li>
          <li class="list-group-item" (click)="onClickSettings('language')">
            <span class="material-icons list_icon">settings_brightness</span>
            <span class="list_text">{{'Language Setting' | translate }}</span>
          </li>
          <li class="list-group-item">
            <span class="material-icons list_icon">paid</span>
            <span class="list_text">{{'Currency Setting' | translate }}</span>
          </li>
          <li class="list-group-item">
            <span class="material-icons list_icon">api</span>
            <span class="list_text">{{'API Setting' | translate }}</span>
          </li>
          <li *ngIf="userData.administrator" class="list-group-item"  routerLink="/main/notifications">
            <span class="material-icons list_icon">notifications</span>
            <span class="list_text">{{'Notification Settings' | translate }}</span>
          </li>
          <li *ngIf="!userData.administrator" class="list-group-item"  (click)="onClickSettings('notification')">
            <span class="material-icons list_icon">notifications</span>
            <span class="list_text">{{'Notification Settings' | translate }}</span>
          </li>
          
          <li class="list-group-item">
            <span class="material-icons list_icon">receipt_long</span>
            <span class="list_text">{{'Transaction Details' | translate }}</span>
          </li>
          <li routerLink="/main/announcement"class="list-group-item">
            <span class="material-icons list_icon" >campaign</span>
            <span class="list_text">{{'Announcement' | translate }}</span>
          </li>
          <li *ngIf="userData.administrator || isDealer" routerLink="/main/support" class="list-group-item">
            <span class="material-icons list_icon" >miscellaneous_services</span>
            <span class="list_text">{{'Support And Services' | translate }}</span>
          </li>
          <li class="list-group-item">
            <span class="material-icons list_icon">room_preferences</span>
            <span class="list_text">{{'Recharge Plans' | translate }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-9 pb-1 pt-3 px-0">

      <!-- GENERAL SETTING -->

      <div class="card p-0 headcard common_scroller" *ngIf="filter=='general'">

        <!-- HEADER WITH EDIT BTN -->
        <div class="card-header flex_row_center_spacebetween" style="padding: 0.4rem 1rem;">
          <div class="cardHead_text">{{'General Setting' | translate}}</div>
          <ul class="nav nav-pills card-header-pills">
            <li class="nav-item">
              <span class="btn"><img src="/assets/icons/accountsetting.png" alt="refresh" style="width:90%"></span>
            </li>
          </ul>
        </div>
        <div class="card-body settingbody">
          <div class="row">
            <div class="col-6" style="display:flex;align-items: center;justify-content: center;">
              <img src="{{siteDetails?.logo}}" alt="userimg" class="userimg">
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-12">
                  <div class="form-group pb-2">
                    <label for="FirstName" class="addlabels pb-2">{{'Name' | translate}}</label>
                    <input disabled type="text" class="form-control inputfields" [(ngModel)]="userData.name" name="FirstName"
                      style="background: #FFF;box-shadow:none;color:#000;" id="First Name"
                      aria-describedby="First Name">
                  </div>
                </div>
                <!-- <div class="col-12">
                  <div class="form-group pb-2">
                    <label for="LastName" class="addlabels pb-2">{{'Last Name' | translate}}</label>
                    <input type="text" class="form-control inputfields" [(ngModel)]="user.LastName" name="LastName"
                      style="background: #FFF;box-shadow:none;color:#000;" id="LastName" aria-describedby="LastName">
                  </div>
                </div> -->
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="email" class="addlabels pb-2">{{'Email Id' | translate}}</label>
                <input disabled type="text" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;"
                  id="email" aria-describedby="email" [(ngModel)]="userData.email" name="email">
              </div>
            </div>
            
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="timezone" class="addlabels pb-2">{{'Timezone' | translate}}</label>
                <select disabled="true"  class="form-select form_select1 common_scroller select-white" [(ngModel)]="userData.attributes.timezone" name="timezone"
                   id="timezone">
                  <option value="" disabled>Select Timezone</option>
                  <option *ngFor="let time of timezoneArray; let i = index" value="{{time.tz}}">
                    {{time.tz}}</option>
                </select>
              </div>
            </div>

          
            
            <div class="col-6">
            <div class="form-group pb-2">
              <label for="speed-unit inline" class="pb-2">{{'Speed Unit'|translate}}</label>
              <select disabled="true" [(ngModel)]="userData.attributes.speedUnit"  class="form-select form_select1 common_scroller select-white" name="pageOption">
                <option value="kn" class="select_options">kn</option>
                <option value="kmh" class="select_options">km/h</option>
                <option value="mph" class="select_options">mph</option>
              </select>
            </div>
          </div><div class="col-6">
            <div class="form-group pb-2">
              <label for="Distance-Unit inline" class="pb-2">{{'Distance Unit'|translate}}</label>
              <select disabled="true" [(ngModel)]="userData.attributes.distanceUnit" class="form-select form_select1 common_scroller select-white" name="pageOption">
                <option value="km" class="select_options">km</option>
                <option value="mi" class="select_options">mi</option>
                <option value="nmi" class="select_options">nmi</option>
              </select>
            </div>
          </div><div class="col-6">
            <div class="form-group pb-2">
              <label for="Altitude-Unit inline" class="pb-2">{{'Altitude Unit'|translate}}</label>
              <select disabled="true" [(ngModel)]="userData.attributes.altitudeUnit" class="form-select form_select1 common_scroller select-white" name="pageOption">
                <option value="m" class="select_options">m</option>
                <option value="ft" class="select_options">ft</option>
              </select>
            </div>
          </div><div class="col-6">
            <div class="form-group pb-2">
              <label for="Volume-Unit inline" class="pb-2">{{'Volume Unit'|translate}}</label>
              <select disabled="true" [(ngModel)]="userData.attributes.volumeUnit" class="form-select form_select1 common_scroller select-white" name="pageOption">
                <option value="ltr" class="select_options">Liter</option>
                <option value="usGal" class="select_options">U.S. Gallon</option>
                <option value="impGal" class="select_options">imp. Gallon</option>
              </select>
            </div>
          </div>
          <div *ngIf="isDealer" class="col-6">
            <div class="form-group pb-2">
              <label for="FirstName" class="addlabels pb-2">{{'Support Contact Number' | translate}}</label>
              <input type="text" class="form-control inputfields" [(ngModel)]="userData.attributes.managerContactNumber" name="FirstName"
                style="background: #FFF;box-shadow:none;color:#000;" id="managerContactNumber"
                aria-describedby="managerContactNumber">
            </div>
          </div>
          <div *ngIf="isDealer" class="col-6">
            <div class="form-group pb-2">
              <label for="FirstName" class="addlabels pb-2">{{'Support Email Address' | translate}}</label>
              <input type="text" class="form-control inputfields" [(ngModel)]="userData.attributes.managerEmailAddress" name="FirstName"
                style="background: #FFF;box-shadow:none;color:#000;" id="managerEmailAddress"
                aria-describedby="managerEmailAddress">
            </div>
          </div>
          </div>

          <div class="row">
            
            <div class="col-6">
            </div>
          </div>
        
        <!-- <div class="card-body" style="border-top: 1px solid #324151;">
            <section class="flex_row_center_spacebetween pt-2">
                <mat-checkbox class="addlabels" [(ngModel)]="checked">{{'Dealer'|translate}}</mat-checkbox>
                <mat-checkbox class="addlabels" [(ngModel)]="checked">{{'Notification Sound'|translate}}</mat-checkbox>
                <mat-checkbox class="addlabels" [(ngModel)]="checked">{{'Payment Gateway'|translate}}</mat-checkbox>
            </section>
            <section class="flex_row_center_spacebetween pt-4 pb-3" style="width: 55%;">
                <mat-checkbox class="addlabels" [(ngModel)]="checked">{{'Show Label'|translate}}</mat-checkbox>
                <mat-checkbox class="addlabels" [(ngModel)]="checked">{{'Driver Management'|translate}}</mat-checkbox>
            </section>
        </div> -->
        <div *ngIf="isDealer" class="flex-direction pb-2">
          <button
            mat-raised-button
            type="submit"
            class="saveBtn"
            (click)="onCustomerSubmit()"
          >
          {{'SAVE' | translate}}
          </button>
        </div>
      </div>
      </div>
      <div class="card p-0 headcard common_scroller" *ngIf="filter=='language'">

        <div class="card-header flex_row_center_spacebetween" style="padding: 0.6rem 1rem;">
          <div class="cardHead_text">{{'Language Setting' | translate}}</div>
        </div>
        <div class="card-body settingbody">
          <div class="row">
            <div class="col-5">
              <div class="flex_row_center_spacebetween">
                <form class="example-form">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>{{'Select Language'|translate}}</mat-label>
                    <input type="text"
                           placeholder="Pick one"
                           aria-label="Number"
                           matInput
                           [(ngModel)]="language" name="language"
                           [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                      <mat-option *ngFor="let lang of available_languages;let i = index" [value]="lang.view">{{lang.view}}</mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </form>
              </div>
            </div>
            <div class="col-2 d-flex align-items-center">
              <button mat-raised-button class="w-100" (click)="onSetLanguageSetting()" style="background-color: #f84c69;font-size: 2.3vh;color: #fff;">{{'SAVE' | translate}}</button>
             </div>
          </div>
        </div>
      </div>
      <div class="card p-0 headcard common_scroller" *ngIf="filter=='notification'">
        <app-user-notification></app-user-notification>
      </div>
    </div>
  </div>
</div>
