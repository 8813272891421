import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment-timezone';
@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.css']
})
export class BulkUploadComponent implements OnInit {
  

  //inside export class
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter();
  arrayBuffer:any;
  file:File;
  loading = false;
  @Input() sectionMode:string= 'DEVICE'
  errorMsg:string = '';
  showError:boolean =false;
  
  @ViewChild("fileInput", {static: false})
  
  // this InputVar is a reference to our input.
  
  InputVar: ElementRef;
  constructor(private commonService:CommonService) { }
  uploadingData = {
    completed:0,
    failed:0,
    total:0
  }
  reset()
  {
   
    // We will clear the value of the input
    // field using the reference variable.
 
    this.InputVar.nativeElement.value = "";
    this.file = null;
    
  }
  ngOnInit(): void {
  }
  onPopupClose() {
    this.closePopup.emit(this.uploadingData.total ? true :false)
  }
  openUpload() {

  }
  onChange(event) {
    this.uploadingData.completed = 0;
      this.uploadingData.failed = 0;
      this.uploadingData.total = 0;
    this.file = event.target.files[0];
  }
  onUpload() {
    this.showError  = false;
    if(this.file) {
      this.loading = !this.loading;
      if(this.sectionMode == 'DEVICE') {
        this.realExcelData();
      } else {
        //this.uploadExcel();
      }
    } else {
      this.showError  = true;
      this.errorMsg = 'Please select file'
    }

   

  }
  realExcelData() {
    let fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, {type:"binary"});
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            this.setDataToAPI(XLSX.utils.sheet_to_json(worksheet,{raw:true}))

        }
        fileReader.readAsArrayBuffer(this.file);
  }
  setDataToAPI(jsonData) {
    this.reset();
    this.uploadingData.total = jsonData.length;
    console.log(jsonData);
    if(jsonData.length) {
      jsonData.forEach(d=>{
        this.addDevices(d);
      })
    }
  }
  addDevices(data) {
   
    let defaultObj = {id: null,
      name: data.RegistrationName,
      expirationTime:moment().add(1, 'years').calendar(),
      uniqueId: data.IMEI,
      groupId: '',
      phone: data.SimNumber,
      model: data.model,
      contact: '',
      category: data.Category ? data.Category :'Default',
      disabled: false,
      attributes: {"speedLimit":60,"mileage":10,"ignitionwire":true,"parkingMode":false}}
    this.commonService
          .postRequest('devices', {
            name: defaultObj.name,
            uniqueId: defaultObj.uniqueId,
            groupId: defaultObj.groupId,
            phone: defaultObj.phone,
            model: defaultObj.model,
            contact: defaultObj.contact,
            category: defaultObj.category,
            disabled: defaultObj.disabled,
            expirationTime:new Date(defaultObj.expirationTime),
            attributes: defaultObj.attributes,
          })
          .then((resp: any) => {
            this.uploadingData.completed++;
          },err=>{
            this.uploadingData.failed++;
          });
  }
}
